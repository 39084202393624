import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import './MobileBlock.scss'

import appIcon from "./../../images/app_icon.png"
import qrCode from "./../../images/qr_code_img.png"
import appStore from "./../../images/app_store.png"
import googlePlay from "./../../images/google_play.png"
import appImg1 from "./../../images/app_img1.png"
import appImg2 from "./../../images/app_img2.png"
interface DiseaseItemProps extends WithTranslation {

}

interface DiseaseItemState { }

class MobileBlock extends React.Component<DiseaseItemProps, DiseaseItemState> {
  constructor(props: DiseaseItemProps) {
    super(props);
    this.state = {};
  }

  render() {
    const { t } = this.props;

    return (
      <div className="mobile-app-block">
        <div className="mobile-app-left">
          <img
            className="app_icon"
            src={appIcon}
            alt="app_icon"
          />
          <p className="mobile-app-title">VideoDoktor</p>
          <p className="mobile-app-desc">
            {t("Verified doctors in your phone")}
          </p>
          <img
            className="qr-code-img"
            src={qrCode}
            alt="qr_code_img"
          />
          <div className="app-images-block">
            <div className="app-markets">
              <div>
                <a
                  href="https://apps.apple.com/ru/app/video-doktor/id1520369601"
                  target="__blank"
                >
                  <img
                    className="app-store"
                    src={appStore}
                    alt="app_store"
                  />
                </a>
              </div>
              <div>
                <a
                  href="https://play.google.com/store/apps/details?id=az.ezgil.videodoctor"
                  target="__blank"
                >
                  <img
                    className="google-play"
                    src={googlePlay}
                    alt="google_play"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="mobile-app-right">
          <img
            className="app_img1"
            src={appImg1}
            alt="app_img1"
          />
          <img
            className="app_img2"
            src={appImg2}
            alt="app_img2"
          />
        </div>
      </div>
    );
  }
}

export default withTranslation()(MobileBlock);
