import { ActionCreator, Action } from "redux";
import { RootState } from "../store";
import { ThunkAction } from "redux-thunk";
import { loadingStart, loadingEnd } from "./loading-actions";
import { LoadingSubject } from "../states/loading-state";
import { pushAlert } from "./alert-actions";
import { IDoctorPosts } from "../models/doctors-posts";
import * as environment from "../app.json";

export const DOCTORS_POSTS_LIST = "DOCTORS_POSTS_LIST";

interface DoctorsPostsList {
  type: typeof DOCTORS_POSTS_LIST;
  postsList:IDoctorPosts[]
}
export const DoctorsPostsList: ActionCreator<DoctorsPostsList> = (postsList:IDoctorPosts[]) => {
  return {
    type: DOCTORS_POSTS_LIST,
    postsList
  };
};

export type DoctorsActionTypes = DoctorsPostsList ;


export const getDoctorsPostsList =
  (): ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch, getState) => {
    dispatch(loadingStart(LoadingSubject.DoctorsPosts));

    try {
      const response = await fetch("/api3/posts");
      if (response.status === 200) {
        const json = await response.json();
        dispatch(DoctorsPostsList(json))
      } else {
        dispatch(pushAlert("Unknown error. Please, check internet connection"));
      }
    } catch (error) {
      dispatch(pushAlert("Unknown error. Please, check internet connection"));
    } finally {
      dispatch(loadingEnd(LoadingSubject.PatientVaccinationList));
    }
  };
