import { i18n } from "i18next";
import { IDictionaryState } from "../states/dictionary-state";
import {
  DictionaryActionType,
  GET_DICTIONARY,
  SAVE_DICTIONARY,
} from "../actions/dictionary-actions";
import { DictionaryTypesEnum } from "../models/dictionary-types.enum";
import { IDictionary } from "../models/dictionary";

const initialState: IDictionaryState = {
  work: undefined,
  city: undefined,
  district: undefined,
  clinicType: undefined,
  contactType: undefined,
  specializationTypes: undefined,
  diseases: undefined,
  services: undefined,
  insuranceCompanies: undefined,
  bloodTypes: undefined,
  languages: undefined,
  procedures: undefined,
  underground: undefined,
  clinics: undefined,
  medicationIntake: undefined,
  academicDegree: undefined,
  analyzes: undefined,
  articleCategories: undefined,
  socialNetworks: undefined,
  adsTypes: undefined,

  cityMap: undefined,
  districtMap: undefined,
  clinicTypeMap: undefined,
  contactTypeMap: undefined,
  specializationTypesMap: undefined, //
  specializationSorted: undefined,
  diseasesMap: undefined,
  servicesMap: undefined,
  insuranceCompaniesMap: undefined,
  bloodTypesMap: undefined,
  languagesMap: undefined,
  proceduresMap: undefined,
  undergroundMap: undefined,
  clinicsMap: undefined,
  medicationIntakeMap: undefined,
  academicDegreeMap: undefined,
  analyzesMap: undefined,
  articleCategoriesMap: undefined,
  socialNetworksMap: undefined,
  adsTypesMap: undefined,
  alphabetDiseases: undefined,
  clinicsSorted: undefined,

  // dictionaryAz: undefined,
  // dictionaryRu: undefined,
  // dictionaryEn: undefined,
};

function toMap(a: IDictionary[]) {
  return a.reduce(function (map, obj) {
    map[obj.id] = obj;
    return map;
  }, {});
}

export function dictionaryReducer(
  state = initialState,
  action: DictionaryActionType
) {
  switch (action.type) {
    case GET_DICTIONARY:
      switch (action.dictionaryType) {
        case DictionaryTypesEnum.Work:
          console.log(action, "actionpayload");
          return { ...state, ...{ work: action.payload } };
        case DictionaryTypesEnum.City:
          return {
            ...state,
            ...{ city: action.payload, cityMap: toMap(action.payload) },
          };
        case DictionaryTypesEnum.District:
          return {
            ...state,
            ...{ district: action.payload, districtMap: toMap(action.payload) },
          };
        case DictionaryTypesEnum.ClinicType:
          return {
            ...state,
            ...{
              clinicType: action.payload,
              clinicTypeMap: toMap(action.payload),
            },
          };
        case DictionaryTypesEnum.ContactType:
          return {
            ...state,
            ...{
              contactType: action.payload,
              contactTypeMap: toMap(action.payload),
            },
          };
        case DictionaryTypesEnum.SpecializationTypes:
          const sSpec: IDictionary[] | undefined = action.payload.sort(
            (a, b) => {
              if (a.name.replace('"', "") < b.name.replace('"', "")) return -1;
              if (a.name.replace('"', "") > b.name.replace('"', "")) return 1;
              return 0;
            }
          );
          let specializations = {};

          for (let diseas of sSpec || []) {
            const firstLetter = diseas.name.replace('"', "")[0];
            if (specializations[firstLetter]) {
              specializations[firstLetter] = [
                ...specializations[firstLetter],
                diseas,
              ];
            } else {
              specializations[firstLetter] = [diseas];
            }
          }
          return {
            ...state,
            ...{
              specializationTypes: action.payload,
              specializationTypesMap: toMap(action.payload),
              specializationSorted: specializations,
            },
          };
        case DictionaryTypesEnum.Diseases:
          //for sorting incoming date this was in componentDidmount
          const sAlph: IDictionary[] | undefined = action.payload.sort(
            (a, b) => {
              if (a.name.replace('"', "") < b.name.replace('"', "")) return -1;
              if (a.name.replace('"', "") > b.name.replace('"', "")) return 1;
              return 0;
            }
          );
          let letters = {};

          for (let diseas of sAlph || []) {
            const firstLetter = diseas.name.replace('"', "")[0];
            if (letters[firstLetter]) {
              letters[firstLetter] = [...letters[firstLetter], diseas];
            } else {
              letters[firstLetter] = [diseas];
            }
          }

          return {
            ...state,
            ...{
              diseases: action.payload,
              diseasesMap: toMap(action.payload),
              alphabetDiseases: letters,
            },
          };

        case DictionaryTypesEnum.Services:
          return {
            ...state,
            ...{ services: action.payload, servicesMap: toMap(action.payload) },
          };
        case DictionaryTypesEnum.BloodTypes:
          return {
            ...state,
            ...{
              bloodTypes: action.payload,
              bloodTypesMap: toMap(action.payload),
            },
          };
        case DictionaryTypesEnum.Analyzes:
          return {
            ...state,
            ...{ analyzes: action.payload, analyzesMap: toMap(action.payload) },
          };
        case DictionaryTypesEnum.Languages:
          return {
            ...state,
            ...{
              languages: action.payload,
              languagesMap: toMap(action.payload),
            },
          };
        case DictionaryTypesEnum.Procedures:
          return {
            ...state,
            ...{
              procedures: action.payload,
              proceduresMap: toMap(action.payload),
            },
          };
        case DictionaryTypesEnum.Underground:
          return {
            ...state,
            ...{
              underground: action.payload,
              undergroundMap: toMap(action.payload),
            },
          };
        case DictionaryTypesEnum.Clinics:
          const sClinic: IDictionary[] | undefined = action.payload.sort(
            (a, b) => {
              if (a.name.replace('"', "") < b.name.replace('"', "")) return -1;
              if (a.name.replace('"', "") > b.name.replace('"', "")) return 1;
              return 0;
            }
          );

          let clinics = {};

          for (let clinic of sClinic || []) {
            const firstLetter = clinic.name.replace('"', "")[0];
            if (clinics[firstLetter]) {
              clinics[firstLetter] = [...clinics[firstLetter], clinic];
            } else {
              clinics[firstLetter] = [clinic];
            }
          }

          return {
            ...state,
            ...{
              clinics: action.payload,
              clinicsMap: toMap(action.payload),
              clinicsSorted: clinics,
            },
          };
        case DictionaryTypesEnum.MedicationIntake:
          return {
            ...state,
            ...{
              medicationIntake: action.payload,
              medicationIntakeMap: toMap(action.payload),
            },
          };
        case DictionaryTypesEnum.AcademicDegree:
          return {
            ...state,
            ...{
              academicDegree: action.payload,
              academicDegreeMap: toMap(action.payload),
            },
          };
        case DictionaryTypesEnum.ArticleCategories:
          return {
            ...state,
            ...{
              articleCategories: action.payload,
              articleCategoriesMap: toMap(action.payload),
            },
          };
        case DictionaryTypesEnum.SocialNetworks:
          return {
            ...state,
            ...{
              socialNetworks: action.payload,
              socialNetworksMap: toMap(action.payload),
            },
          };
        case DictionaryTypesEnum.AdsTypes:
          return {
            ...state,
            ...{ adsTypes: action.payload, adsTypesMap: toMap(action.payload) },
          };
        case DictionaryTypesEnum.InsuranceCompanies:
          return {
            ...state,
            ...{
              insuranceCompanies: action.payload,
              insuranceCompaniesMap: toMap(action.payload),
            },
          };

        default:
          return state;
      }
    case SAVE_DICTIONARY:
      // console.log("SAVE_DICTIONARY");
      // console.log(action.type, "actiontype");

      // console.log(action.language, "TypePayloadLangForDictReducer");
      // console.log(action.dictionaryType, "dictionaryType123");
      // console.log(action.payload, "actionpayload ");
      // let dictionary_lang = ["dictionary" + action.language];
      // console.log(dictionary_lang, "dictionary_lang ");

      switch (action.dictionaryType) {
        case DictionaryTypesEnum.Work:
          return {
            ...state,
            ...{ work: action.payload },
          };
        case DictionaryTypesEnum.City:
          console.log(action.dictionaryType, "DictionaryTypesEnum");

          return {
            ...state,
            ...{ city: action.payload, cityMap: toMap(action.payload) },
          };
        case DictionaryTypesEnum.District:
          return {
            ...state,
            ...{ district: action.payload, districtMap: toMap(action.payload) },
          };
        case DictionaryTypesEnum.ClinicType:
          return {
            ...state,
            ...{
              clinicType: action.payload,
              clinicTypeMap: toMap(action.payload),
            },
          };
        case DictionaryTypesEnum.ContactType:
          return {
            ...state,
            ...{
              contactType: action.payload,
              contactTypeMap: toMap(action.payload),
            },
          };
        case DictionaryTypesEnum.SpecializationTypes:
          const sSpec: IDictionary[] | undefined = action.payload.sort(
            (a, b) => {
              if (a.name.replace('"', "") < b.name.replace('"', "")) return -1;
              if (a.name.replace('"', "") > b.name.replace('"', "")) return 1;
              return 0;
            }
          );
          let specializations = {};

          for (let diseas of sSpec || []) {
            const firstLetter = diseas.name.replace('"', "")[0];
            if (specializations[firstLetter]) {
              specializations[firstLetter] = [
                ...specializations[firstLetter],
                diseas,
              ];
            } else {
              specializations[firstLetter] = [diseas];
            }
          }
          return {
            ...state,
            ...{
              specializationTypes: action.payload,
              specializationTypesMap: toMap(action.payload),
              specializationSorted: specializations,
            },
          };
        case DictionaryTypesEnum.Diseases:
          //for sorting incoming date this was in componentDidmount
          const sAlph: IDictionary[] | undefined = action.payload.sort(
            (a, b) => {
              if (a.name.replace('"', "") < b.name.replace('"', "")) return -1;
              if (a.name.replace('"', "") > b.name.replace('"', "")) return 1;
              return 0;
            }
          );
          let letters = {};

          for (let diseas of sAlph || []) {
            const firstLetter = diseas.name.replace('"', "")[0];
            if (letters[firstLetter]) {
              letters[firstLetter] = [...letters[firstLetter], diseas];
            } else {
              letters[firstLetter] = [diseas];
            }
          }

          return {
            ...state,
            ...{
              diseases: action.payload,
              diseasesMap: toMap(action.payload),
              alphabetDiseases: letters,
            },
          };
        case DictionaryTypesEnum.Services:
          return {
            ...state,
            ...{ services: action.payload, servicesMap: toMap(action.payload) },
          };
        case DictionaryTypesEnum.BloodTypes:
          return {
            ...state,
            ...{
              bloodTypes: action.payload,
              bloodTypesMap: toMap(action.payload),
            },
          };
        case DictionaryTypesEnum.Analyzes:
          return {
            ...state,
            ...{ analyzes: action.payload, analyzesMap: toMap(action.payload) },
          };
        case DictionaryTypesEnum.Languages:
          return {
            ...state,
            ...{
              languages: action.payload,
              languagesMap: toMap(action.payload),
            },
          };
        case DictionaryTypesEnum.Procedures:
          return {
            ...state,
            ...{
              procedures: action.payload,
              proceduresMap: toMap(action.payload),
            },
          };
        case DictionaryTypesEnum.Underground:
          return {
            ...state,
            ...{
              underground: action.payload,
              undergroundMap: toMap(action.payload),
            },
          };
        case DictionaryTypesEnum.Clinics:
          const sClinic: IDictionary[] | undefined = action.payload.sort(
            (a, b) => {
              if (a.name.replace('"', "") < b.name.replace('"', "")) return -1;
              if (a.name.replace('"', "") > b.name.replace('"', "")) return 1;
              return 0;
            }
          );

          let clinics = {};

          for (let clinic of sClinic || []) {
            const firstLetter = clinic.name.replace('"', "")[0];
            if (clinics[firstLetter]) {
              clinics[firstLetter] = [...clinics[firstLetter], clinic];
            } else {
              clinics[firstLetter] = [clinic];
            }
          }

          return {
            ...state,
            ...{
              clinics: action.payload,
              clinicsMap: toMap(action.payload),
              clinicsSorted: clinics,
            },
          };
        case DictionaryTypesEnum.MedicationIntake:
          return {
            ...state,
            ...{
              medicationIntake: action.payload,
              medicationIntakeMap: toMap(action.payload),
            },
          };
        case DictionaryTypesEnum.AcademicDegree:
          return {
            ...state,
            ...{
              academicDegree: action.payload,
              academicDegreeMap: toMap(action.payload),
            },
          };
        case DictionaryTypesEnum.ArticleCategories:
          return {
            ...state,
            ...{
              articleCategories: action.payload,
              articleCategoriesMap: toMap(action.payload),
            },
          };
        case DictionaryTypesEnum.SocialNetworks:
          return {
            ...state,
            ...{
              socialNetworks: action.payload,
              socialNetworksMap: toMap(action.payload),
            },
          };

        case DictionaryTypesEnum.AdsTypes:
          return {
            ...state,
            ...{ adsTypes: action.payload, adsTypesMap: toMap(action.payload) },
          };
        case DictionaryTypesEnum.InsuranceCompanies:
          return {
            ...state,
            ...{
              insuranceCompanies: action.payload,
              insuranceCompaniesMap: toMap(action.payload),
            },
          };

        default:
          return state;
      }
    // return {
    //   ...state,
    //   ...{ ["dictionary" + action.language]: actim on.payload },
    // };

    default:
      return state;
  }
}
