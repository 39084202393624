import React from 'react';
import './faq.scss';
import { TFunction } from 'i18next';
import { withTranslation } from 'react-i18next';
import VDDropdown from '../Layouts/dropdowns';
import { IDropdownItem, IDropdownItemMultilang } from '../../models/dropdown-item';
import { connect } from "react-redux";
import { RootState } from "../../store";

interface FAQProps {
    t: TFunction;
    language: string;
}

interface FAQState {
    item: {title: string; text?: string;}[];
}

const items: IDropdownItemMultilang = {
    en: [
        {
            title: 'Who can use teleconsultation?',
            text: '<p>Teleconsultation is suitable for anyone above 2 years' +
                'old and seeking treatment for non-emergency illnesses. ' +
                'Please note that your user account cannot be used by another person.</p>' +
                '<p>For patients below 16 years old, please ensure you have a guardian ' + 
                '(who must be at least 21 years old) present with you during the teleconsultation.' + 
                ' The guardian has to show his/her valid ID (eg. şəxsiyyət vəsiqəsi, pasport, etc) ' + 
                'to the doctor at the start of the teleconsultation.</p>'
        },
        {
            title: 'What are the charges for a teleconsultation?',
            text: 'Video Doktor charges AZN__ for a 30-minute teleconsultation' +
                ' (inclusive of medication delivery). Beyond 15 minutes, the doctor' +
                ' may request for an additional payment for another 15-minute extension. Medication charges apply.'
        },
        {
            title: 'What conditions are suitable for teleconsultation?',
            text: '<p>' +
                'There are many common conditions (non-emergency in nature) that can be treated via teleconsultation including but not limited to these:' +
                '</p>' +
                '<ul>' +
                '<li>Cough & Cold</li>' +
                '<li>Sore Throat</li>' +
                '<li>Constipation & Diarrhoea</li>' +
                '<li>Skin Irritation & Rashes</li>' +
                '<li>Acne</li>' +
                '<li>Headache & Migraine</li>' +
                '<li>Urinary Tract Infections</li>' +
                '<li>Menstrual Issues</li>' +
                '<li>Gastric problem</li>' +
                '<li>Cold sores</li>' +
                '<li>Muscle aches</li>' +
                '<li>Heel pain</li>' +
                '<li>Conjunctivitis without visual disturbances</li>' +
                '<li>Eyelid problems</li>' +
                '<li>Gout</li>' +
                '<li>Well-controlled chronic illnesses that have already been routinely followed up</li>' +
                '<li>Others: travel health, sexual health, review of health screening results</li>' +
                '</ul>' +
                '<p>The doctor will make a reasonable diagnosis where possible from the presenting history, vital signs or other clinical parameters where available, investigation or imaging results and recommend treatment or referral to the appropriate level of care if necessary.</p>'
        },
        {
            title: 'What conditions are NOT suitable for teleconsultation?',
            text: '<p>Emergency health issues are NOT suitable for teleconsultation. Please seek immediate medical assistance at the nearest Emergency Clinic (Təcili Tibbi Yardım stansiyası və ya xəstəxanası) or call 103 for an ambulance.</p>' +
                '<p>This teleconsultation service is also not for mental health conditions.</p>' +
                '<p>The following conditions are also NOT suitable for teleconsultation:</p>' +
                '<ul>' +
                '<li>Visual disturbances – double vision or blurred vision or loss of vision</li>' +
                '<li>Dizziness and imbalance</li>' +
                '<li>Chest pain</li>' +
                '<li>Breathing difficulties and asthma</li>' +
                '<li>Abdominal pain with fever</li>' +
                '<li>Diarrhoea with black stools</li>' +
                '<li>Fever for more than 3 days</li>' +
                '<li>Weakness or numbness of limbs</li>' +
                '<li>Urinary or bowel incontinence</li>' +
                '<li>Pregnancy complications</li>' +
                '<li>Any injuries</li>' +
                '<li>Bleeding from any wounds/injuries</li>' +
                '<li>1st consultation on chronic illnesses</li>' +
                '</ul>'
        },
        {
            title: 'Can I choose a doctor for the teleconsultation?',
            text: '<p>Yes, you can select a doctor for teleconsultation via “Search Doctor” (by keying in the doctor’s name or clinic), subject to his/her availability.</p>' +
                '<p>You may also choose a doctor that you have previously seen on Video Doktor if he/she is saved on your ‘Favourite Providers’ list. You can add a provider onto your favourite list by selecting the ‘heart’ icon in the appropriate doctor’s profile.</p>'
        },
        {
            title: 'What is Doctor World?',
            text: 'Video Doktor is an integrated healthcare digital platform providing medical convenience at your fingertips. You can use the app (available on Apple App Store and Google Play Store) to:' +
                '<ul>' +
                '<li>Video-consult a doctor (available 24/7 daily)</li>' +
                '<li>Schedule medical appointments</li>' +
                '<li>Manage health records</li>' +
                '<li>Browse health insurance offerings</li>' +
                '<li>Purchase healthcare products and services</li>' +
                '</ul>' +
                '<p>It is a secure platform for doctors to dispense medical care with ease and for patients to receive treatment with confidence and in privacy.</p>'
        }
    ],
    az: [
        {
            title: 'Telekonsultasiyadan kimlər istifadə edə bilər?',
            text: '<p>Telekonsultasiya 2 yaşdan böyük və təxirəsalınmaz ' +
                'sayılmayan xəstəliklərlə əlaqədar müalicəyə ehtiyacı olan ' +
                'hər kəs üçün uyğundur. Lütfən nəzərə alın ki, istifadəçi ' +
                'akkauntunuz digər şəxs tərəfindən istifadə oluna bilməz.</p>' +
                '<p>16 yaşdan kiçik pasientlər üçün, lütfən telekonsultasiya ərzində ' + 
                'yanınızda bir himayədarın (ən azı 21 yaşında olmalıdır) iştirakını ' +
                'təmin edin. Telekonsultasiyanın başlanğıcında himayədar həkimə özünün ' +
                'şəxsiyyətini təsdiq edən etibarlı sənəd (məs. şəxsiyyət vəsiqəsi, pasport və s.) göstərməlidir.</p>'
        },
        {
            title: 'Telekonsultasiya üçün ödəniş məbləği nə qədərdir?',
            text: '<p>Video Doktor vasitəsilə 30-dəqiqəlik telekonsultasiya üçün ödəniş məbləği __ AZN ' +
                'təşkil edir (dərman preparatlarının çatdırılması daxil olmaqla). 15 dəqiqə xaricində, ' +
                'həkim vaxtın daha 15-dəqiqəlik uzanmasına görə əlavə ödəniş istəyə bilər. Dərman ' +
                'preparatlarına görə ödəniş edilir.'
        },
        {
            title: 'Hansı vəziyyətlər telekonsultasiya üçün uyğundur?',
            text: '<p>Aşağıda sadalananlar daxil, lakin bunlarla məhdudlaşmamaqla, bir çox geniş rast gəlinən vəziyyətlər telekonsultasiya vasitəsilə müalicə edilə bilər (xarakterinə görə təxirəsalınmaz sayılmayan):</p>' +
                '<ul>' +
                '<li>Öskürək və Soyuqdəymə</li>' +
                '<li>Boğazda Ağrı</li>' +
                '<li>Qəbizlik və İshal</li>' +
                '<li>Dəri Qıcıqlanması və Səpgiləri</li>' +
                '<li>Sızanaqlar</li>' +
                '<li>Baş ağrısı və Miqren</li>' +
                '<li>Sidik Traktı İnfeksiyaları</li>' +
                '<li>Aybaşı Problemləri</li>' +
                '<li>Mədə problemi</li>' +
                '<li>Herpes</li>' +
                '<li>Əzələ ağrıları</li>' +
                '<li>Dabanda ağrı</li>' +
                '<li>Görmə pozğunluqları olmadan konyunktivit</li>' +
                '<li>Göz qapağında problemlər</li>' +
                '<li>Podaqra</li>' +
                '<li>Artıq rutin planlı müşahidə altında olub, yaxşı kontrol edilən xroniki xəstəliklər</li>' +
                '<li>Digərləri: səyahətə çıxanların sağlamlığı, cinsi sağlamlıq, sağlamlıq skrininqi nəticələrinin təhlili</li>' +
                '</ul>' +
                '<p>Təqdim edilən anamnez, əldə edildiyi halda orqanizmin vəziyyətinin əsas göstəriciləri və ya digər klinik parametrlər, müayinə və ya görüntüləmə nəticələrinə əsaslanaraq, mümkün olduqda həkim müvafiq diaqnoz qoyacaq və müalicə və ya gərəkdikdə, müvafiq səviyyəli tibbi xidmətlər üçün yönəldilmə üzrə tövsiyələrini verəcək.</p>'
        },
        {
            title: 'Telekonsultasiya üçün uyğun OLMAYAN vəziyyətlər hansılardır?',
            text: '<p>Təxirəsalınmaz sağlıq problemləri telekonsultasiya üçün uyğun DEYİL. Təxirəsalınmaz tibbi yardım üçün, lütfən ən yaxınlıqda yerləşən Təcili Tibbi Yardım stansiyası və ya xəstəxanasına müraciət edin və ya təcili yardım maşını çağırmaq üçün 103 nömrəsinə zəng vurun.</p>' +
                '<p>Bu telekonsultasiya xidməti həmçinin psixi durumla bağlı problemlər zamanı istifadə olunmur.</p>' +
                '<p>Həmçinin aşağıdakı vəziyyətlər də telekonsultasiya üçün uyğun DEYİL:</p>' +
                '<ul>' +
                '<li>Görmə pozğunluqları – ikili görmə və ya bulanıq görmə və ya görmənin itməsi</li>' +
                '<li>Başgicəllənmə və müvazinətin pozulması</li>' +
                '<li>Döş qəfəsində ağrı</li>' +
                '<li>Tənəffüs çətinlikləri və bronxial astma</li>' +
                '<li>Qızdırma ilə qarın ağrısı</li>' +
                '<li>Qara nəcis ifrazı ilə ishal</li>' +
                '<li>3 gündən çox çəkən qızdırma</li>' +
                '<li>Ətraflarda zəiflik və ya keyləşmə</li>' +
                '<li>Sidik və ya nəcis saxlamamazlıq</li>' +
                '<li>Hamiləlik ağırlaşmaları</li>' +
                '<li>Hər hansı zədələnmələr</li>' +
                '<li>Hər hansı yara/zədələnmədən qanaxma</li>' +
                '<li>Xroniki xəstəliklər üzrə 1ci konsultasiya</li>' +
                '</ul>'
        },
        {
            title: 'Telekonsultasiya üçün həkimi seçə bilərəmmi?',
            text: '<p>Bəli, müsaitliyindən asılı olaraq, “Həkim Seç” vasitəsilə (həkimin adı və ya klinikanın adını daxil edərək) telekonsultasiya üçün həkimi seçə bilərsiniz.</p>' +
                '<p>Həmçinin Video Doktorda daha öncə gördüyünüz və sizin ‘Seçilmiş Səhiyyə mütəxəssisləri’ siyahınızda saxlanılmış həkimi də seçə bilərsiniz. Müvafiq həkimin profilində ‘ürək’ nişanını seçərək, səhiyyə mütəxəssisini sizin sevimlilər siyahınıza əlavə edə bilərsiniz.</p>'
        },
        {
            title: 'Doctor World nədir?',
            text: '<p>Video Doktor bir toxunuşda rahatlıqla səhiyyə xidmətləri təmin edən inteqrasiya olunmuş tibbi rəqəmsal platformadır. Tətbiqi aşağıdakı məqsədlər üçün istifadə edə bilərsiniz (Apple App Store və Google Play Store vasitəsilə əldə oluna bilər):</p>' +
                '<ul>' +
                '<li>Həkim ilə video-konsultasiya (hər gün 24/7 əlçatandır)</li>' +
                '<li>Həkim qəbulu vaxtının planlaşdırılması</li>' +
                '<li>Tibbi qeydlərin idarə edilməsi</li>' +
                '<li>Tibbi sığorta təkliflərinin nəzərdən keçirilməsi</li>' +
                '<li>Tibb məhsullar və xidmətlərin alınması</li>' +
                '</ul>' +
                '<p>Bu həkimlərin rahat şəkildə tibbi yardım göstərməsi və pasientlərin güvənli və konfidensial müalicə alması üçün etibarlı bir platformadır.</p>'
        }
    ],
    ru: [
        {
            title: 'Кто может воспользоваться телеконсультацией?',
            text: '<p>Телеконсультация подходит всем лицам старше 2-х лет, нуждающимся в лечении неотложных заболеваний. Пожалуйста, обратите внимание на то, что ваша учетная запись пользователя не может использоваться кем-либо еще.</p>' +
                '<p>Для пациентов младше 16 лет, пожалуйста, убедитесь, что во время телеконсультации присутствует лицо, осуществляющее уход (как минимум в возрасте 21-го года). В начале телеконсультации опекун должен предъявить врачу действующий документ, удостоверяющий его / ее личность (например, удостоверение личности, паспорт и др.).</p>'
        },
        {
            title: 'Сколько стоит телеконсультация?',
            text: '<p>Стоимость 30-минутной телеконсультации посредством Видео Доктора составляет __ AZN (включая доставку лекарств). После 15-ти минут, врач может взимать дополнительную плату за 15-минутное продление сеанса. За лекарства взимается плата.</p>'
        },
        {
            title: 'Какие ситуации подходят для телеконсультации?',
            text: '<p>Многие из наиболее распространенных состояний, включая, помимо прочего, следующие, можно лечить с помощью телеконсультации (те, что не считаются по своему характеру неотложными):</p>' +
                '<ul>' +
                '<li>Кашель и простуда</li>' +
                '<li>Боль в горле</li>' +
                '<li>Запор и диарея</li>' +
                '<li>Раздражение кожи и сыпь</li>' +
                '<li>Акне (угревая сыпь)</li>' +
                '<li>Головные боли и мигрени</li>' +
                '<li>Инфекции мочеиспускательного канала</li>' +
                '<li>Менструальные проблемы</li>' +
                '<li>Желудочные проблемы</li>' +
                '<li>Герпес</li>' +
                '<li>Мышечные боли</li>' +
                '<li>Боль в пятке</li>' +
                '<li>Конъюнктивит без нарушений зрения</li>' +
                '<li>Проблемы с глазным веком</li>' +
                '<li>Подагра</li>' +
                '<li>Хронические заболевания, которые уже находятся под обычным плановым наблюдением и хорошо контролируются</li>' +
                '<li>Другое: здоровье путешественников, сексуальное здоровье, анализ результатов скрининга здоровья</li>' +
                '</ul>' +
                '<p>На основании полученного анамнеза, основных показателей состояния организма или других клинических параметров, результатов обследования или визуализации, по возможности, врач поставит соответствующий диагноз и даст рекомендации по лечению или, при необходимости, направит в соответствующие медицинские службы.</p>'
        },
        {
            title: 'Какие ситуации НЕ подходят для телеконсультации?',
            text: '<p>Неотложные проблемы со здоровьем НЕ подходят для телеконсультации. Для получения неотложной медицинской помощи, пожалуйста, позвоните в ближайшую станцию ​​ или больницу Скорой Медицинской Помощи или позвоните по номеру 103, чтобы вызвать машину скорой помощи.</p>' +
                '<p>Эта услуга телеконсультации также не используется при проблемах с психическим здоровьем.</p>' +
                '<p>Также НЕ подходят для телеконсультации следующие ситуации:</p>' +
                '<ul>' +
                '<li>Нарушения зрения - двоение в глазах, затуманенное зрение или потеря зрения</li>' +
                '<li>Головокружение и нарушение равновесия</li>' +
                '<li>Боль в грудной клетке</li>' +
                '<li>Проблемы с дыханием и бронхиальная астма.</li>' +
                '<li>Боль в животе сопровождаемая лихорадкой</li>' +
                '<li>Диарея с черным стулом</li>' +
                '<li>Лихорадка, сохраняющаяся более 3 дней</li>' +
                '<li>Слабость или онемение в конечностях</li>' +
                '<li>Недержание мочи или кала</li>' +
                '<li>Осложнения беременности</li>' +
                '<li>Любые повреждения (травмы)</li>' +
                '<li>Кровотечение из-за любых ран / травм</li>' +
                '<li>1-я консультация по хроническим заболеваниям</li>' +
                '</ul>'
        },
        {
            title: 'Могу ли я выбрать врача для телеконсультации?',
            text: '<p>Да, в зависимости от наличия, вы можете выбрать врача для телеконсультации через опцию «Выберите врача» (вписав имя врача или название клиники).</p>' +
                '<p>Вы также можете выбрать врача, которого вы посещали в Видео Доктор ранее и которого оставили в своем списке «Избранные специалисты здравоохранения». Вы можете добавить медицинского работника в свой список избранных, выбрав символ «Сердце» в профиле соответствующего врача.</p>'
        },
        {
            title: 'Что такое Доктор World?',
            text: '<p>Видео Доктор - это интегрированная медицинская цифровая платформа, которая предоставляет медицинские услуги в одно касание. Вы можете использовать приложение для следующих целей (доступно в Apple App Store и Google Play Store):</p>' +
                '<ul>' +
                '<li>Видеоконсультация с врачом (доступна круглосуточно и без выходных)</li>' +
                '<li>Планирование времени посещения врача</li>' +
                '<li>Управление медицинскими записями</li>' +
                '<li>Изучение предложений по медицинскому страхованию</li>' +
                '<li>Покупка медицинских товаров и услуг</li>' +
                '</ul>' +
                '<p>Это надежная платформа позволяющая врачам, комфортно оказывать медицинскую помощь, а для пациентам – получать безопасное и конфиденциальное лечение.</p>'
        }
    ]
}

class FAQ extends React.Component<FAQProps, FAQState> {
    constructor(props: FAQProps) {
        super(props)
        this.state = {
            item: items[this.props.language]
        }
    }

    componentDidUpdate(prev: FAQProps) {
        if (prev.language !== this.props.language) {
            this.setState({item: items[this.props.language]})
        }
    }

    render() {
        const { t } = this.props;
        return(
            <div className='faq'>
                <div className='faq-container'>
                    <p className='faq__title'>{t('Frequently Asked Questions')}</p>
                    <div className='faq__block'>
                        <VDDropdown title={t('Telecosultations')} items={this.state.item} />
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state: RootState) => ({
    language: state.settings.language
})

export default connect(mapStateToProps)(withTranslation()(FAQ));