import React from 'react';
import { connect } from 'react-redux';
import { Field, InjectedFormProps, reduxForm, FormErrors } from 'redux-form';
import { IUser } from '../../models/user';
import TextInput from '../FormComponents/TextInput';
import { TFunction } from 'i18next';
import { withTranslation } from 'react-i18next';

class ForgotCodeForm extends React.Component<{t: TFunction} & InjectedFormProps<IUser>> {
    render() {
        const { t } = this.props;
        return (
            <div>
                <Field name="code" placeholder={t('Code')} component={TextInput} />
            </div>
        );
    }
}

const validate = (values: IUser, props: {t: TFunction} & InjectedFormProps<IUser>): FormErrors<IUser> => {
    const errors: FormErrors<IUser> = {};
    if (!values.code || values.code.trim() === '') {
        errors.code = props.t('Field couldn\'t be empty');
    }
    return errors;
};

const form = reduxForm<IUser, any>({
    form: 'ForgotCodeForm',
    validate
})(ForgotCodeForm);

export default connect(null)(withTranslation()(form));