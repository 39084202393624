import { ITextMessage } from "./text-message";

export interface IChatMessage {
    id: string;
    deletedDoctor?: boolean;
    deletedPatient?: boolean;

    message: string;
    textMessage?: ITextMessage;

    attachments?: IChatAttachment[];

    doctorId: number;
    patientId: number;

    owner?: Owner;

    timestamp: Date;
    
    updated?: Date;
    created?: Date;
    current?: Date;

    sent?: boolean;
}

export interface IChatAttachment {
    content: string;
    mimeType: string;

    linkDoctor?: string;
    linkPatient?: string;
    linkOwner?: string;
}

export enum Owner {
    Patient = 0, Doctor = 1, System = 2
}