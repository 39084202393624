import React from "react";
import "./DiseaseList.scss";
import Button from "react-bootstrap/Button";
import { connect } from "react-redux";
import Form from "react-bootstrap/Form";
import LineAlphabet from "../lineAlphabet/LineAlphabet";
import { WithTranslation, withTranslation } from "react-i18next";
import { Link, RouteComponentProps } from "react-router-dom";
import { i18n, TFunction } from "i18next";
import Diseases from "../../Diseases/Diseases";
import { getClinic } from "../../../actions/clinic-action";
import { ILoadingState } from "../../../states/loading-state";
import { RootState } from "../../../store";
import MobileBlock from "../../MobileBlock/MobileBlock";
import { IDictionary } from "../../../models/dictionary";
import Nav from "../../NewNav/Nav";

interface DiseaseProps {
  loading: ILoadingState;
  location: Location;
  t: TFunction;
  i18n: i18n;
  specializationSorted: { [key: number]: IDictionary } | undefined;
  letter: string | undefined;

}

interface DiseaseState {
  showSpecializations: boolean;
}

class DiseaseList extends React.Component<
  DiseaseProps & WithTranslation & RouteComponentProps,
  DiseaseState
> {
  constructor(props: DiseaseProps & WithTranslation & RouteComponentProps) {
    super(props);
    this.state = {
      showSpecializations: false,
    };
  }

  showSpecializationsHandler() {
    this.setState({ showSpecializations: !this.state.showSpecializations });
  }

  render() {
    const { t, i18n: { language } } = this.props;
    const path = this.props.match.path;
    console.log(this.props);
    console.log(path, "Path");

    return (
      <>
        <div className="content-wrapper">
          <Nav />
          <div className="doctorSearch-item">
            <LineAlphabet />
            <Diseases diseasesList bigTitle={t("Directory of diseases")} />
            {/* <div style={{ maxWidth: '910px', margin: '0 auto 20px auto' }}><Breadcrumbs location={this.props.location} /></div> */}
          </div>
        </div>
        <div className="title-center">
          <div className="title">{t("All specializations")}</div>
        </div>

        <div className="content-wrapper">
          <div
            className={
              this.state.showSpecializations ? "alphabet opened" : "alphabet"
            }
          >
            {this.props.specializationSorted &&
              Object.keys(this.props.specializationSorted).sort((a, b) => a.localeCompare(b, language)).map((letter, i) => (
                <div className="alphabet-item" key={letter}>
                  <p className={"alphabet-item-title"}>{letter}</p>
                  <div className="alphabet-data-container">
                    {this.props.specializationSorted &&
                      this.props.specializationSorted[letter].sort((a, b) => a.localeCompare(b, language)).map((spec) => (
                        <Link
                          to={`/doctors?specialization=${spec.id}`}
                          key={spec.id}
                        >
                          <p key={spec.name} className="alphabet-data-name">
                            {spec.name}
                          </p>
                        </Link>
                      ))}
                  </div>
                </div>
              ))}
            {!this.state.showSpecializations && <div className="gradient" />}
          </div>
          <div className="btn-home-alphabet-wrapper">
            <button
              onClick={this.showSpecializationsHandler.bind(this)}
              className="blue-btn btn btn-primary"
            >
              {this.state.showSpecializations ? t("Minimize") : t("Show more")}
            </button>
          </div>
        </div>

        <MobileBlock />
      </>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  clinic: state.clinic,
  auth: state.auth,
  patientClinic: state.patientClinic,
  dictionary: state.dictionary,
  doctor: state.doctor,
  loading: state.loading,
  specializationTypes: state.dictionary.specializationTypes,
  // sortedSpecializations: state.dictionary.sortedSpecializations,
  specializationSorted: state.dictionary.specializationSorted,
  letter: state.alph.letter,
});

const mapDispatchToProps = {};

let DiseaseListContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(DiseaseList);

export default withTranslation()(DiseaseListContainer);
