import React from 'react';
import { connect } from 'react-redux';
import Spinner from 'react-spinner-material';
import { setStateLetter } from '../../../actions/alph-actions';
import { RootState } from '../../../store';
import './LineAlphabet.scss';

interface LineAlphabetProps {
  sortAlphabet?: any;
  alphabetDiseases?: Record<string, any>;
  language?: any;
  setStateLetter: (letter: string | undefined) => void;
  selectedLetter?: string;
}

class LineAlphabet extends React.Component<LineAlphabetProps> {
  componentDidUpdate(prevProps: any) {
    if (prevProps.language !== this.props.language) {
      this.props.setStateLetter('');
    }
  }

  render() {
    const { alphabetDiseases, selectedLetter, setStateLetter } = this.props;

    if (!alphabetDiseases) {
      return <Spinner radius={24} color={'#0279BD'} stroke={2} visible={true} />;
    }

    const lettersArray = Object.entries(alphabetDiseases);
    lettersArray.sort((a, b) => a[0].localeCompare(b[0]));

    return (
      <div className="lineAlphabet">
        <div className="dots" />
        {lettersArray.map(([letter], index) => (
          <div
            className={`lineAlphabet-item ${selectedLetter === letter ? 'selected' : ''}`}
            key={index}
            onClick={() => {
              selectedLetter === letter ? setStateLetter('') : setStateLetter(letter);
            }}
          >
            {letter}
          </div>
        ))}
        <div className="dots" />
      </div>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  alphabetDiseases: state.dictionary?.alphabetDiseases,
  language: state.settings.language,
  selectedLetter: state.alph.letter,
});

const mapDispatchToProps = {
  setStateLetter,
};

export default connect(mapStateToProps, mapDispatchToProps)(LineAlphabet);
