import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
  Field,
  InjectedFormProps,
  reduxForm,
  formValueSelector,
  FormErrors,
} from 'redux-form'
import { IPatientDoctorAccess } from '../models/user-doctor-access'
import { RootState } from '../store'
import { IPatientDoctorAccessState } from '../states/patient-doctor-access-state'
import { getPatientMyDoctorList } from '../actions/patient-doctor-action'
import { IDoctorFilter } from '../models/doctor-filter'
import { IAuthState } from '../states/auth-state'
import { IPatientDoctorState } from '../states/patient-doctor-state'
import TextInput from './FormComponents/TextInput'
import DropDownSelect from './FormComponents/DropDownSelect'
import { TFunction } from 'i18next'
import { withTranslation } from 'react-i18next'
import MiniDropDownSelect from './FormComponents/MiniDropDownSelect'
import moment from 'moment'

type Props = {
  t: TFunction
  patientDoctorAccess: IPatientDoctorAccessState
  patientDoctor: IPatientDoctorState
  auth: IAuthState
  doctor_id?: number
  type: number
  getPatientMyDoctorList: (filter: IDoctorFilter | undefined) => void
}

class DoctorDate extends React.Component<
  InjectedFormProps<IPatientDoctorAccess> & Props
> {
  componentWillMount() {
    this.props.getPatientMyDoctorList({})
    var selected = this.props.patientDoctorAccess.selected
    console.log('selected', selected)
    selected &&
      this.props.initialize({
        ...selected,
        startdate: moment(selected.startdate).format(
          'YYYY-MM-DD HH:mm:ss',
        ) as any,
        enddate: moment(selected.enddate).format('YYYY-MM-DD HH:mm:ss') as any,
      })
  }

  render() {
    const { t } = this.props
    return (
      <div>
        <Field
          name='startdate'
          type='datetime-local'
          style={{
            width: '70%',
            marginBottom: '10px',
            paddingLeft: '5px',
          }}
          placeholder={t('Date start')}
          component={TextInput}
        />

        <Field
          name='enddate'
          type='datetime-local'
          style={{
            width: '70%',
            marginBottom: '10px',
            paddingLeft: '5px',
          }}
          placeholder={t('Date end')}
          component={TextInput}
        />
      </div>
    )
  }
}
const validate = (
  values: IPatientDoctorAccess,
  props: { t: TFunction },
): FormErrors<IPatientDoctorAccess> => {
  const errors: FormErrors<IPatientDoctorAccess> = {}
  const { t } = props
  console.log(values)
  if (!values.doctor_id) {
    errors.doctor_id = t("Field couldn't be empty")
  }
  if (!values.startdate) {
    errors.startdate = t("Field couldn't be empty")
  }
  if (!values.enddate) {
    errors.enddate = t("Field couldn't be empty")
  }
  return errors
}

const form = reduxForm<IPatientDoctorAccess & { isChecked: boolean }, any>({
  form: 'DoctorDate',
  validate,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(DoctorDate)

const selector = formValueSelector('DoctorDate')
const mapStateToProps = (state: RootState) => {
  const { type, doctor_id } = selector(state, 'type', 'doctor_id')
  return {
    patientDoctorAccess: state.patientDoctorAccess,
    patientDoctor: state.patientDoctor,
    auth: state.auth,
    type,
    doctor_id,
  }
}

const mapDispatchToProps = {
  getPatientMyDoctorList,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(form))
