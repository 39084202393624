import React, { Component } from 'react';
import { connect } from "react-redux";
import { RootState } from "../../store";
import './Filters.scss';
import Button from 'react-bootstrap/Button';
import { withTranslation } from 'react-i18next';
import { i18n, TFunction } from "i18next";
import { IDoctorState } from '../../states/doctor-state';
import { IDoctor } from '../../models/doctor';
import history from "./../../history";
import { IDictionaryState } from '../../states/dictionary-state';
import { getDoctorAutocomplete, _doctorsAutocomplete } from '../../actions/doctor-action';
import { IDoctorFilter } from '../../models/doctor-filter';
import { IDictionary } from '../../models/dictionary';
import Autosuggest from 'react-autosuggest';
import "./Autosuggest.css";
import { DictionaryTypesEnum } from '../../models/dictionary-types.enum';

interface MyProps {
    t: TFunction;
    i18n: i18n;
    doctor: IDoctorState;
    dictionary: IDictionaryState;
    getDoctorAutocomplete: (filter: IDoctorFilter) => void;
    _doctorsAutocomplete: (doctors: IDoctor[]) => void;
}

interface MyState {
    doctors: IDoctor[];
    doctorAutocomplete: string;
    metroAutocomplete: string;
    metroSuggest: SuggestSection[];
    selectedMetro: IDictionary | null;
    doctorSuggest: SuggestSection[];
    selectedClinic: IDictionary | null;
    selectedDisease: IDictionary | null;
}

interface SuggestSection {
    title: string;
    items: IDictionary[];
}

const getSuggestionValue = (dictionary: IDictionary) => dictionary.name;

const renderSuggestion = (dictionary: IDictionary) => (
    <div>
        {dictionary.name}
    </div>
);

const renderSectionTitle = (section: SuggestSection) => {
    return (
        <strong>{section.title}</strong>
    );
}

const getSectionSuggestions = (section: SuggestSection) => {
    return section.items;
}

const getSectionsItems = (query: string, dict: IDictionary[] | undefined) => {
    if (dict && dict.length) {
        return dict.filter(c =>
            c.name?.toString().toLowerCase().includes(query.toLowerCase()))
    }
    return [];
}

const getDoctors = (doctors: IDoctor[]) => {
    return doctors.map((d) => ({
        id: d.id,
        name: d.surname + ' ' + d.name + ' ' + d.patronymic,
        type: DictionaryTypesEnum.Doctors
    })) as IDictionary[];
}

class FiltersComponent extends Component<MyProps, MyState> {

    constructor(props: MyProps) {
        super(props);
        this.state = {
            doctors: [],
            doctorAutocomplete: '',
            metroAutocomplete: '',
            metroSuggest: [],
            selectedMetro: null,
            doctorSuggest: [],
            selectedClinic: null,
            selectedDisease: null
        };
    }

    componentWillUnmount() {
        this.props._doctorsAutocomplete([]);
    }

    componentDidUpdate(prevProps: MyProps) {
        if (prevProps.doctor.doctorsAutocomplete !== this.props.doctor.doctorsAutocomplete) {
            this.doctorSuggest();
        }
    }

    doctorsSearch = (query: string) => {
        this.props.getDoctorAutocomplete({
            name: query
        });
        this.doctorSuggest();
    }

    regionSearch = (query: string) => {
        if (!query) { return; }

        const { t } = this.props;

        const metroSuggest: SuggestSection[] = [
            {
                title: t("District"),
                items: getSectionsItems(query, this.props.dictionary.district)
            },
            {
                title: t("Metro"),
                items: getSectionsItems(query, this.props.dictionary.underground)
            },
            {
                title: t("City"),
                items: getSectionsItems(query, this.props.dictionary.city)
            }
        ];
        this.setState({ metroSuggest: metroSuggest });
    }

    doctorSuggest = () => {
        const query = this.state.doctorAutocomplete;
        if (!query) { return; }

        const { t } = this.props;

        const doctorSuggest: SuggestSection[] = [
            {
                title: t("Doctor"),
                items: getDoctors(this.props.doctor.doctorsAutocomplete)
            },
            {
                title: t("Clinic"),
                items: getSectionsItems(query, this.props.dictionary.clinics)
            },
            {
                title: t("Disease"),
                items: getSectionsItems(query, this.props.dictionary.diseases)
            }
        ];
        this.setState({ doctorSuggest: doctorSuggest });
    }

    selectDoctor = (e, { newValue, method }) => {
        this.setState({ doctorAutocomplete: newValue });
    }

    selectClinic = (e, { newValue, method }) => {
        this.setState({ metroAutocomplete: newValue });
    }

    selectDisease = (e, { newValue, method }) => {
        //this.setState( { di })
    }

    searchInfo = () => {
        const { selectedClinic, selectedMetro } = this.state;
        let queryString = "";
        let url = '';
        if (selectedMetro) {
            switch (selectedMetro.type) {
                case DictionaryTypesEnum.Underground:
                    queryString += "metro=" + selectedMetro.id
                    break;
                case DictionaryTypesEnum.District:
                    queryString += "district=" + selectedMetro.id
                    break;
                case DictionaryTypesEnum.City:
                    queryString += "city=" + selectedMetro.id
                    break;
                default:
                    break;
            }
            url = '/clinics';
        }
        if (selectedClinic) {
            queryString = queryString.length > 0 ? queryString + '&' : queryString;
            switch (selectedClinic.type) {
                case DictionaryTypesEnum.Clinics:
                    queryString += "clinic=" + selectedClinic.id
                    break;
                case DictionaryTypesEnum.Diseases:
                    queryString += "disease=" + selectedClinic.id
                    break;
                default:
                    break;
            }
            url = '/doctors';
        }
        queryString = queryString.length > 0 ? '?' + queryString : queryString;
        history.push(url + queryString);
    }

    onDoctorSelected = (event: any, { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }) => {
        event.preventDefault();
        if (suggestion && suggestion.type === DictionaryTypesEnum.Doctors) {
            history.push('/doctors?name=' + suggestion.name);
        } else if (suggestion && suggestion.type === DictionaryTypesEnum.Clinics) {
            history.push('/clinics?name=' + suggestion.name);
        }
        else if (suggestion && suggestion.type === DictionaryTypesEnum.Diseases) {
            history.push('/doctors?disease=' + suggestion.id);
        }
        // else if (suggestion && suggestion.type === DictionaryTypesEnum.Doctor) {
        //     history.push('/doctors?disease=' + suggestion.id +'/doctor=' +doctor.id);
        // }
    }

    onMetroSelected = (event: any, { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }) => {
        event.preventDefault();
        this.setState({ selectedMetro: suggestion });
    }

    onDoctorRequested = ({ value }) => {
        console.log('on doctor val', value);
        this.doctorsSearch(value);
    };

    onMetroRequested = ({ value }) => {
        this.regionSearch(value);
    };

    onDoctorClearRequested = () => {
        this.setState({
            doctorSuggest: []
        });
    };

    onMetroClearRequested = () => {
        this.setState({
            metroSuggest: []
        });
    };

    onKeyDown(e) {
        if (e.key === 'Enter') {
            this.searchInfo();
        }
    }

    render() {
        const { metroSuggest, doctorSuggest, metroAutocomplete, doctorAutocomplete } = this.state;
        const { t } = this.props;

        const inputPropsMetro = {
            placeholder: t('City') + ', ' + t('District').toLowerCase() + ', ' + t('Metro station').toLowerCase(),
            value: metroAutocomplete,
            onChange: this.selectClinic,
            onKeyDown: this.onKeyDown.bind(this)
        };

        const inputPropsDoctor = {
            placeholder: t('Doctors') + ', ' + t('Clinics').toLowerCase() + ', ' + t('Diseases').toLowerCase(),
            value: doctorAutocomplete,
            onChange: this.selectDoctor,
            onKeyDown: this.onKeyDown.bind(this)
        };

        console.log('filters')

        return (
            <div className='filters'>
                <div className='filters-wrapper'>
                    <h1 className='big-title'>{t('Find doctor and appoint')}</h1>

                    <div className='dropdown-filters-wrapper'>
                        <div className='dropdown-filters'>
                            <Autosuggest
                                id="DoctorLookup"
                                multiSection={true}
                                suggestions={doctorSuggest}
                                onSuggestionsFetchRequested={this.onDoctorRequested}
                                onSuggestionsClearRequested={this.onDoctorClearRequested}
                                onSuggestionSelected={this.onDoctorSelected}
                                getSuggestionValue={getSuggestionValue}
                                getSectionSuggestions={getSectionSuggestions}
                                renderSuggestion={renderSuggestion}
                                renderSectionTitle={renderSectionTitle}
                                inputProps={inputPropsDoctor}
                            />
                        </div>
                        <div className='dropdown-filters second-dropdown'>
                            <Autosuggest
                                id="MetroLookup"
                                multiSection={true}
                                suggestions={metroSuggest}
                                onSuggestionsFetchRequested={this.onMetroRequested}
                                onSuggestionsClearRequested={this.onMetroClearRequested}
                                onSuggestionSelected={this.onMetroSelected}
                                getSuggestionValue={getSuggestionValue}
                                getSectionSuggestions={getSectionSuggestions}
                                renderSuggestion={renderSuggestion}
                                renderSectionTitle={renderSectionTitle}
                                inputProps={inputPropsMetro}
                            />
                        </div>
                        <Button className='blue-btn' variant="primary" onClick={() => this.searchInfo()}>{t('Search')}</Button>
                    </div>

                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: RootState) => ({
    doctor: state.doctor,
    dictionary: state.dictionary
});

const mapDispatchToProps = {
    getDoctorAutocomplete,
    _doctorsAutocomplete
};

let FiltersComponentContainer = connect(mapStateToProps, mapDispatchToProps)(
    FiltersComponent
);

export default withTranslation()(FiltersComponentContainer);