import React, { ChangeEvent, Component } from 'react';
import { connect } from "react-redux";
import { RootState } from "../../store";
import './Doctors.scss';
import QuickAccessComponentContainer from './../QuickAccess/QuickAccess';

import Calendar, { CalendarProps } from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import {
    Calendar as BigCalendar,
    momentLocalizer,
    CalendarProps as BigCalendarProps,
    DateLocalizer,
    View,
    NavigateAction,
    stringOrDate
} from 'react-big-calendar';
import moment from 'moment';
import 'moment/locale/az';
import 'moment/locale/ru';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock, faCommentAlt, faPhoneAlt, faThumbsUp, faTimes } from '@fortawesome/free-solid-svg-icons';
import { VDButton } from '../Layouts/buttons';
import { IAuthState } from '../../states/auth-state';
import { ISchedulingItemRecordsState } from '../../states/scheduling-item-records-state';
import { ISchedulingItemsState } from '../../states/scheduling-items-state';
import { deleteSchedulingItems, getSchedulingItems, saveBulkSchedulingItem } from "../../actions/scheduling-item-actions";
import { getSchedulingItemRecords, approve, cancel } from "../../actions/scheduling-item-record-actions";
import { getUserById } from "../../actions/auth-actions";
import { ISchedulingItemFilter } from '../../models/scheduling-item-filter';
import { ISchedulingItemRecordFilter } from '../../models/scheduling-item-record-filter';
import { ISchedulingItem } from '../../models/scheduling-item';
import { ISchedulingItemRecord, SchedulingItemRecordStatus, SchedulingItemRecordType } from '../../models/scheduling-item-record';
import Modal from 'react-bootstrap/esm/Modal';
import { getSelfClinicsList } from '../../actions/dictionary-actions';
import 'react-time-picker/dist/TimePicker.css';
import 'react-clock/dist/Clock.css';
import { withTranslation, WithTranslation } from 'react-i18next';
import { BloodTypeEnum } from '../../models/blood-type.enum';
import ToggleButton from 'react-bootstrap/ToggleButton';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import { ISchedulingBulk } from '../../models/scheduling-bulk';
import history from '../../history';
import * as environment from '../../app.json';
import { UserRole } from '../../models/user-role.enum';
import { IDoctor } from '../../models/doctor';
import { getFullDoctorList } from "../../actions/doctor-action";
import { IDoctorFilter } from '../../models/doctor-filter';
import { IDictionary } from '../../models/dictionary';
import { IDictionaryState } from '../../states/dictionary-state';
import Breadcrumbs from './../Breadcrumbs/Breadcrumbs';

import nouserpng from './../../images/no-user-photo.png';
import doctorDefaultAvatar from "./../../images/doctor-default-avatar.png";

moment.locale('az');

interface IBigCalendarEvent {
    id: number;
    type: SchedulingItemRecordType;
    clinicId: number;
    asPatient?: boolean;
    title: string | undefined;
    start: Date | string;
    end: Date | string;
    allDay?: boolean;
    resource?: any;
    hexColor?: string;
    patientId?: number;
    patientName?: string;
    doctorId?: number;
    doctorName?: string;
    doctorImage?: string;
    status?: SchedulingItemRecordStatus;
    recordId?: number;
    offline: boolean;
    online: boolean;
}

interface ScheduleProps {
    localizer: DateLocalizer;
    location: Location;
    language: string;
    dictionary: IDictionaryState;
    auth: IAuthState;
    schedulingItemRecords: ISchedulingItemRecordsState;
    schedulingItems: ISchedulingItemsState;
    selectedDoctor: IDoctor;
    getSchedulingItems: (
        filter: ISchedulingItemFilter
    ) => void;
    getSchedulingItemRecords: (
        filter: ISchedulingItemRecordFilter
    ) => void;
    saveBulkSchedulingItem: (
        items: ISchedulingBulk
    ) => void;
    getUserById: (
        id: number,
        token: string
    ) => void;
    approve: (
        item: ISchedulingItemRecord,
        filter: ISchedulingItemRecordFilter
    ) => void;
    cancel: (
        item: ISchedulingItemRecord,
        filter: ISchedulingItemRecordFilter
    ) => void;
    deleteSchedulingItems: (
        item: ISchedulingItem
    ) => void;
    getFullDoctorList: (clinicId: number) => void;
    getSelfClinicsList: () => void;
}

interface ScheduleState {
    startDate: Date;
    endDate: Date;
    view: View;
    events: IBigCalendarEvent[];
    selectedEvent?: IBigCalendarEvent;
    selectedClinic: number;
    createModal: boolean;
    modalStart: string;
    modalEnd: string;
    modalDate: Date | Date[];
    online: boolean;
    offline: boolean;
    filter: ISchedulingItemFilter | ISchedulingItemRecordFilter;
    currentDate: Date;
    hash: any;
    localizer: DateLocalizer;
    selectedDoctorId: number;
    doctorsList: any;
    doctorsListData: any;
    searchMode: string;
    selectedDoctor: IDoctor | undefined;
}

class DoctorScheduleDetails extends React.Component<ScheduleProps & BigCalendarProps & CalendarProps & WithTranslation, ScheduleState> {
    constructor(props: ScheduleProps & WithTranslation) {
        super(props);
        this.state = {
            startDate: moment().startOf('month').startOf('week').toDate(),
            endDate: moment().endOf('month').endOf('week').toDate(),
            view: 'month',
            events: [],
            selectedEvent: undefined,
            createModal: false,
            modalStart: '10:00',
            modalEnd: '18:00',
            modalDate: new Date(),
            online: true,
            offline: true,
            filter: {},
            currentDate: new Date(),
            hash: {},
            localizer: momentLocalizer(moment),
            selectedDoctorId: 0,
            selectedClinic: 0,
            doctorsList: null,
            doctorsListData: null,
            searchMode: 'contains',
            selectedDoctor: undefined
        };
        this.selectItem = this.selectItem.bind(this);
        this.filterList = this.filterList.bind(this);
    }

    setLocalizer() {
        moment.locale(this.props.language);
        this.setState({ localizer: momentLocalizer(moment) });
    }

    ItemTemplate(data) {
        return <div>{data.Name}</div>;
    }

    async componentDidMount() {
        this.onNavigate(new Date(), 'month');
        this.setLocalizer();
        this.props.getSelfClinicsList();
    }

    async componentWillMount() {
        if (this.props.auth.userToken) {
            const doctorsListData = await this.props.getFullDoctorList(this.props.auth.userToken.clientId);
            this.setState({ doctorsListData: doctorsListData });
            this.setState({ doctorsList: doctorsListData });
        }
    }

    componentDidUpdate(prevProps: ScheduleProps) {
        // let eventArray: IBigCalendarEvent[] = [];
        // let hash = {};
        const { hash } = this.state;

        const { schedulingItems, schedulingItemRecords, t } = this.props;
        if (prevProps.schedulingItemRecords.schedulingItemRecordsUpdate !== schedulingItemRecords.schedulingItemRecordsUpdate) {
            if (schedulingItemRecords.schedulingItemRecordList instanceof Array) {
                schedulingItemRecords.schedulingItemRecordList.map((item: ISchedulingItemRecord) => {
                    hash[item.schedulingItemId] = {
                        id: item.schedulingItemId,
                        asPatient: item.asPatient,
                        patientId: item.patientId,
                        patientName: item.patientName,
                        doctorId: item.doctorId,
                        doctorName: item.doctorName,
                        doctorImage: item.doctorImage,
                        status: item.status,
                        title: item.patientName || t('Empty'),
                        start: moment(item.start).toDate(),
                        end: moment(item.end).toDate(),
                        hexColor: '#4dbdf0',
                        recordId: item.id
                    } as IBigCalendarEvent
                });

                this.setState({ hash: { ...this.state.hash, ...hash } });
                console.log('hashinrecord', hash);
            }
        }

        if (prevProps.schedulingItems.schedulingItemsUpdate !== schedulingItems.schedulingItemsUpdate) {
            // if (schedulingItemRecords.schedulingItemRecordList instanceof Array) {
            //     eventArray = schedulingItemRecords.schedulingItemRecordList.map((item: ISchedulingItemRecord) => (
            //         hash[item.schedulingItemId] = {
            //             id: item.schedulingItemId,
            //             patientId: item.patientId,
            //             asPatient: item.asPatient,
            //             patientName: item.patientName,
            //             doctorId: item.doctorId,
            //             doctorName: item.doctorName,
            //             doctorImage: item.doctorImage,
            //             status: item.status,
            //             title: item.patientName || t('Empty'),
            //             start: moment(item.start).toDate(),
            //             end: moment(item.end).toDate(),
            //             hexColor: '#8dd6d0',
            //             recordId: item.id
            //         } as IBigCalendarEvent)
            //     );
            // }

            if (schedulingItems.schedulingItemsList instanceof Array) {
                const tmp: IBigCalendarEvent[] = schedulingItems.schedulingItemsList.map((item: ISchedulingItem) => {
                    return {
                        id: item.id,
                        asPatient: item.id ? hash[item.id]?.asPatient : undefined,
                        patientId: item.id ? hash[item.id]?.patientId : undefined,
                        patientName: item.id ? hash[item.id]?.patientName : undefined,
                        doctorId: item.id ? item.doctorId : undefined,
                        doctorName: item.id ? hash[item.id]?.doctorName : undefined,
                        doctorImage: item.id ? hash[item.id]?.doctorImage : undefined,
                        status: item.id ? hash[item.id]?.status : undefined,
                        online: item.online,
                        offline: item.offline,
                        title: item.patientName ? `${moment(item.start).format('hh:mm')} - ${item.patientName}` : `${moment(item.start).format('hh:mm')} - ${moment(item.end).format('hh:mm')}`,
                        start: moment(item.start).toDate(),
                        end: moment(item.end).toDate(),
                        hexColor: item.patientName ? '#8dd6d0' : '#a8c9f9',
                        recordId: item.id ? hash[item.id]?.recordId : undefined
                    } as IBigCalendarEvent
                });
                this.setState({ events: tmp });
            }

            console.log('events', hash);
        }

        if (prevProps.language !== this.props.language) {
            this.setLocalizer();
        }
    }

    filterDetails = (view: View, date: Date): ISchedulingItemFilter => {
        const asPatient = false;

        let state: ScheduleState;
        switch (view) {
            case 'month':
                state = {
                    ...this.state,
                    startDate: moment(date).startOf('month').startOf('week').toDate(),
                    endDate: moment(date).endOf('month').endOf('week').toDate()
                }
                break;
            case 'week':
                state = {
                    ...this.state,
                    startDate: moment(date).startOf('week').startOf('day').toDate(),
                    endDate: moment(date).endOf('week').endOf('day').toDate()
                };
                break;
            case 'day':
                state = {
                    ...this.state,
                    startDate: moment(date).startOf('day').toDate(),
                    endDate: moment(date).startOf('day').toDate()
                };
                break;
            default:
                state = {
                    ...this.state,
                    startDate: moment(date).startOf('month').startOf('week').toDate(),
                    endDate: moment(date).endOf('month').endOf('week').toDate()
                };
                break;
        }

        this.setState({
            ...state, filter: {
                startFrom: state.startDate,
                startTo: state.endDate,
                asPatient,
                doctorId: state?.selectedDoctorId,
                actual: true
            }
        });

        return {
            // doctorDescId: doctorId,
            doctorId: state?.selectedDoctorId,
            startFrom: state.startDate,
            startTo: state.endDate,
            asPatient,
            actual: true
        };
    }

    onNavigate = (date: Date, view: View, action?: NavigateAction) => {
        if (((this.state.selectedDoctorId !== 0) && this.props.auth.userToken?.role !== UserRole.Doctor) ||
            this.props.auth.userToken?.role === UserRole.Doctor) {
            const filterSchedule = this.filterDetails(view, date);
            this.props.getSchedulingItems(filterSchedule as ISchedulingItemFilter);
            this.props.getSchedulingItemRecords(filterSchedule);
            this.setState({ currentDate: date });
        } else if ((this.state.selectedDoctorId !== 0) && this.props.auth.userToken?.role === UserRole.Clinic) {
            const filterSchedule = this.filterDetails(view, date);
            this.props.getSchedulingItems(filterSchedule as ISchedulingItemFilter);
            this.props.getSchedulingItemRecords(filterSchedule);
            this.setState({ currentDate: date });
        }
    }

    onView = (view: View) => {
        this.onNavigate(this.state.currentDate, view);
    }

    onSelectEvent = (ev: IBigCalendarEvent) => {
        this.setState({ selectedEvent: ev });
        if (ev.patientId) this.props.getUserById(Number(ev.patientId), this.props.auth.userToken?.auth as string);
    }

    eventStyleGetter = (event: IBigCalendarEvent, start: stringOrDate, end: stringOrDate, isSelected: boolean) => {
        return {
            style: {
                backgroundColor: event.hexColor,
                color: 'black'
            }
        };
    }

    approve = () => {
        const { selectedEvent } = this.state;
        let itemRecord: ISchedulingItemRecord;
        if (selectedEvent && (selectedEvent.status || selectedEvent.status === 0)) {
            itemRecord = {
                id: selectedEvent.recordId,
                type: selectedEvent.type,
                schedulingItemId: selectedEvent.id,
                patientId: selectedEvent.patientId,
                patientName: selectedEvent.patientName,
                start: (new Date(selectedEvent.start)).toISOString(),
                end: (new Date(selectedEvent.end)).toISOString(),
                status: selectedEvent.status,
                asPatient: selectedEvent.asPatient,
                doctorId: selectedEvent.doctorId,
                doctorName: selectedEvent.doctorName,
                doctorImage: selectedEvent.doctorImage
            };
            this.props.approve(itemRecord, this.state.filter);
        }
    }

    haveCancelation(status?: SchedulingItemRecordStatus): boolean {
        switch (status) {
            case SchedulingItemRecordStatus.Created:
            case SchedulingItemRecordStatus.Approved:
            case SchedulingItemRecordStatus.Paid:
                return true;
            default:
                return false;
        }
    }

    haveChat(record: ISchedulingItemRecord): boolean {
        return record.status === SchedulingItemRecordStatus.Ready ||
            record.status === SchedulingItemRecordStatus.Initiated ||
            record.status === SchedulingItemRecordStatus.Accepted;
    }

    cancel = () => {
        const { selectedEvent } = this.state;
        let itemRecord: ISchedulingItemRecord;
        if (selectedEvent && (selectedEvent.status || selectedEvent.status === 0)) {
            itemRecord = {
                id: selectedEvent.recordId,
                type: selectedEvent.type,
                schedulingItemId: selectedEvent.id,
                patientId: selectedEvent.patientId,
                patientName: selectedEvent.patientName,
                start: (new Date(selectedEvent.start)).toISOString(),
                end: (new Date(selectedEvent.end)).toISOString(),
                status: selectedEvent.status,
                asPatient: selectedEvent.asPatient,
                doctorId: selectedEvent.doctorId,
                doctorName: selectedEvent.doctorName,
                doctorImage: selectedEvent.doctorImage
            };
            this.props.cancel(itemRecord, this.state.filter);
        }
    }

    deleteItem = () => {
        this.props.deleteSchedulingItems(this.state.selectedEvent as ISchedulingItem);
        this.setState({ selectedEvent: undefined });
    }

    renderCard() {
        const { t } = this.props;
        const { userProfileById } = this.props.auth;

        const bloodType: string = userProfileById?.blood_type === BloodTypeEnum.IPlus ? 'O(I) Rh+' :
            userProfileById?.blood_type === BloodTypeEnum.IMinus ? 'O(I) Rh-' :
                userProfileById?.blood_type === BloodTypeEnum.IIPlus ? 'A(II) Rh+' :
                    userProfileById?.blood_type === BloodTypeEnum.IIMinus ? 'A(II) Rh-' :
                        userProfileById?.blood_type === BloodTypeEnum.IIIPlus ? 'B(III) Rh+' :
                            userProfileById?.blood_type === BloodTypeEnum.IIIMinus ? 'B(III) Rh-' :
                                userProfileById?.blood_type === BloodTypeEnum.IVPlus ? 'AB(IV) Rh+' :
                                    userProfileById?.blood_type === BloodTypeEnum.IVMinus ? 'AB(IV) Rh-' : '';

        if (!this.state.selectedEvent?.patientId) return (
            <VDButton label={t('Delete appointment')} color='success' onClick={() => this.deleteItem()} style={{ marginTop: '15px' }} />
        )
        if (userProfileById) return (
            <div className='calendar_container_left-side_card'>
                <div className='calendar_container_left-side_card_header'>
                    <div className='calendar_container_left-side_card_header_avatar'>
                        {(this.state.selectedEvent?.asPatient === false) ?
                            <img width={50} src={userProfileById.image ? environment.uploads + 'patients/' + userProfileById.image : nouserpng} /> :
                            <img width={50} src={this.state.selectedEvent?.doctorImage ? environment.uploads + 'doctors/' + this.state.selectedEvent?.doctorImage : doctorDefaultAvatar} />}
                    </div>
                    {/* <div className='calendar_container_left-side_card_header_info'> */}
                    {(this.props.auth.userToken?.role === UserRole.Doctor) ? (
                        <div className='calendar_container_left-side_card_header_info'>
                            <div className='top'>
                                {this.haveCancelation(this.state.selectedEvent?.status) ?
                                    <div className='cancel' onClick={this.cancel.bind(this)}>{t('Cancel record')} <FontAwesomeIcon icon={faTimes} /></div> : <div />}
                                {this.state.selectedEvent?.status === SchedulingItemRecordStatus.Created ?
                                    <div className='accept' onClick={this.approve.bind(this)}>{t('Verify')} <FontAwesomeIcon icon={faThumbsUp} /></div> : <div className='accept empty' />}
                            </div>

                            <div className='bottom'>
                                <div><span> {t('Patient')} </span></div>
                                <div className='patient-name'>{userProfileById.name}</div>
                            </div>
                        </div>
                    ) : (
                        <div className='bottom'>
                            <div className='patient-name'>{userProfileById.name}</div>
                            <div>
                                <span>{t('Gender')}: </span>
                                {userProfileById.sex === 0 ? t('Card::Male', 'Male') : userProfileById.sex === 1 ? t('Card::Female', 'Female') : ''}<br />
                                <span>{t('Date')}: </span>{moment(userProfileById.birthdate).format('DD.MM.YYYY')}<br />
                                <span>{t('Blood type')}: </span>{bloodType}<br />
                            </div>
                        </div>
                    )}
                    {/* </div> */}
                </div>
                {(this.props.auth.userToken?.role === UserRole.Doctor) ? (
                    <div className='calendar_container_left-side_card_details'>
                        <div>
                            <span>{t('Gender')}: </span>
                            {userProfileById.sex === 0 ? t('Card::Male', 'Male') : userProfileById.sex === 1 ? t('Card::Female', 'Female') : ''}<br />
                            <span>{t('Date')}: </span>{moment(userProfileById.birthdate).format('DD.MM.YYYY')}<br />
                            <span>{t('Blood type')}: </span>{bloodType}<br />
                        </div>
                        <div className='icon-buttons'>
                            {/* <VDButton rounded={true} onClick={() => { }} color={'primary'} faIcon={<FontAwesomeIcon icon={faPhoneAlt} />} /> */}
                            {this.haveChat && <VDButton rounded={true} onClick={() => history.push(`/online-appointment?chat=${this.state.selectedEvent?.recordId}`)} color={'primary'} faIcon={<FontAwesomeIcon icon={faCommentAlt} />} />}
                        </div>
                    </div>
                ) : (
                    <div className='info_buttons'>
                        <div className='accept' onClick={this.approve.bind(this)}><FontAwesomeIcon icon={faThumbsUp} /> {t('Verify')} </div>
                        <div className='cancel' onClick={this.cancel.bind(this)}><FontAwesomeIcon icon={faTimes} /> {t('Cancel record')} </div>
                    </div>
                )}
            </div>
        );
    }

    addSchedulingItemHandler() {
        let { modalStart, modalEnd, modalDate, online, offline, selectedDoctorId } = this.state;

        let tempDateStart: Date = new Date(modalDate[0]);
        let tempDateEnd: Date = new Date(modalDate[1] || modalDate[0]);

        tempDateStart.setHours(Number(modalStart.split(':')[0]), Number(modalStart.split(':')[1]));
        tempDateEnd.setHours(Number(modalEnd.split(':')[0]), Number(modalEnd.split(':')[1]));

        this.props.saveBulkSchedulingItem({
            start: (new Date(tempDateStart)).toISOString(),
            end: (new Date(tempDateEnd)).toISOString(),
            online: online,
            offline: offline,
            minutes: 30,
            doctorId: selectedDoctorId,
            clinicId: this.state.selectedClinic
        });
        this.setState({ createModal: false });
    }

    renderAddEventModal() {
        const { t } = this.props;
        const { offline, online } = this.state;
        const clinics = this.props.dictionary.work?.map(d => ({ value: d?.id, label: d?.name, color: '#000' })) || [];
        return (
            <Modal className={'calendar-modal'} show={this.state.createModal} onHide={() => this.setState({ createModal: false })}>
                <Modal.Body>
                    <div className='modal-close'><FontAwesomeIcon icon={faTimes} onClick={() => this.setState({ createModal: false })} /></div>
                    <Modal.Title>{t('Create an appointment')}</Modal.Title>
                    <div className='calendar_modal' style={{ flexDirection: 'column' }}>
                        <Calendar
                            allowPartialRange={true}
                            selectRange={true} onChange={(e) => this.setState({ modalDate: e })} />
                        <div className='calendar-modal__time'>
                            <input className='time-picker' type='time' value={this.state.modalStart} onChange={(e: ChangeEvent<HTMLInputElement>) => this.setState({ modalStart: e.target.value })} />
                            <input className='time-picker' type='time' value={this.state.modalEnd} onChange={(e: ChangeEvent<HTMLInputElement>) => this.setState({ modalEnd: e.target.value })} />
                        </div>
                    </div>
                    <div>
                        <select onChange={(e) => this.setState({ selectedClinic: Number(e.target.value) })}>
                            {clinics.map(clinic => (
                                <option value={clinic.value}>{clinic.label}</option>
                            ))}
                        </select>
                    </div>
                    <div className="appointment-type">
                        <ButtonGroup toggle>
                            <ToggleButton
                                className="visit-type-button"
                                type="checkbox"
                                variant="light"
                                checked={online}
                                value="0"
                                onChange={(e) => this.setState({ online: e.currentTarget.checked })}
                            >
                                {t('Online')}
                            </ToggleButton>
                            <ToggleButton
                                className="visit-type-button"
                                type="checkbox"
                                variant="light"
                                checked={offline}
                                value="0"
                                onChange={(e) => this.setState({ offline: e.currentTarget.checked })}
                            >
                                {t('In the clinic')}
                            </ToggleButton>
                        </ButtonGroup>
                    </div>
                    <div className='modal-button-block'>
                        <button className='blue-btn btn btn-primary' onClick={this.addSchedulingItemHandler.bind(this)}>{t('Add appointment')}</button>
                    </div>
                </Modal.Body>
            </Modal>
        )
    }

    createButtonHandler = () => {
        this.setState({ createModal: true });
    }

    filterList(event) {
        let updatedList = this.state.doctorsList;
        updatedList = this.state.doctorsListData.filter(
            function (item) {
                return ((item.name.toString().toLowerCase().search(event.target.value.toLowerCase()) !== -1)
                    || (item.surname.toString().toLowerCase().search(event.target.value.toLowerCase()) !== -1)
                    || (item.patronymic.toString().toLowerCase().search(event.target.value.toLowerCase()) !== -1));
            });
        this.setState({ doctorsList: updatedList });
    }

    async selectItem(event) {
        let selectedDoctor = this.state.doctorsList.find(o => o.id == event.currentTarget.dataset.id);
        await this.setState({ selectedDoctorId: selectedDoctor.userId });
        this.onNavigate(this.state.currentDate, 'month');
    }

    render() {
        const { t } = this.props;
        const { doctorsList } = this.state;

        console.log('this.props.location', this.props.location);

        return (
            <>
                <div style={{ maxWidth: '1050px', margin: '20px auto', display: 'flex' }}><Breadcrumbs location={this.props.location} /></div>
                <div className='calendar'>
                    <div className='calendar_container'>
                        {(this.props.auth.userToken?.role === UserRole.Doctor) ? (
                            <div className='calendar_container_left-side'>
                                <div className='calendar_container_empty' />
                                <div className='calendar_container_left-side_label'>
                                    {t('Calendar::Calendar', 'Calendar')}
                                </div>
                                {/* <div className='calendar_container_left-side_small-calendar'>
                                <Calendar />
                            </div> */}
                                <div className='calendar_container_left-side_add'>
                                    <VDButton label={t('Add appointment')} color={'primary'} onClick={this.createButtonHandler} />
                                </div>
                                {this.state.selectedEvent && this.renderCard()}
                            </div>
                        ) : (
                            <div className='calendar_container_left-side'>
                                <div className='calendar_container_left-side_clinic_label'>
                                    {t('Calendar::Search for a doctor', 'Search for a doctor')}
                                </div>
                                <input name="myinput" id="inputvalue" type="text"
                                    className="calendar_container_left-side_clinic_input" placeholder={t('FULL NAME')}
                                    onChange={this.filterList} />
                                {
                                    doctorsList instanceof Array && doctorsList.length ?
                                        <div style={{ height: 400, overflow: 'auto' }} >
                                            <ul className="list-group">
                                                {doctorsList.map(listitem => (
                                                    <li
                                                        data-id={listitem.id}
                                                        onClick={this.selectItem}
                                                        key={listitem.id}
                                                        className="list-group-item">
                                                        <div className={(this.state.selectedDoctorId == listitem.userId ? "list-group-item__selected" : "")}>
                                                            <span>{listitem.name + ' ' + listitem.surname + ' ' + listitem.patronymic}</span>
                                                            {listitem.specializations.map(item => (<span className="item_details">{item}</span>))}
                                                        </div>
                                                    </li>
                                                ))
                                                }
                                            </ul>
                                        </div>
                                        : ''
                                }
                                {(this.state.selectedDoctorId !== 0) ?
                                    <div className='calendar_container_left-side_add'>
                                        <VDButton label={t('Add appointment')} color={'primary'} onClick={this.createButtonHandler} />
                                    </div> : ''
                                }
                                {this.state.selectedEvent && this.renderCard()}
                            </div>

                        )}

                        <div className='calendar_container_right-side'>
                            <BigCalendar
                                localizer={this.state.localizer}
                                events={this.state.events}
                                onNavigate={this.onNavigate}
                                onView={this.onView}
                                eventPropGetter={this.eventStyleGetter}
                                onSelectEvent={this.onSelectEvent}
                                messages={{
                                    month: t('Calendar::Month', 'Month'),
                                    week: t('Calendar::Week', 'Week'),
                                    day: t('Calendar::Day', 'Day'),
                                    agenda: t('Calendar::Agenda', 'Agenda'),
                                    today: t('Calendar::Today', 'Today'),
                                    previous: t('Calendar::Back', 'Back'),
                                    next: t('Calendar::Next', 'Next'),
                                    showMore: (count: number) => `${t('Calendar::ShowMore', 'more')} ${count}`,
                                }}
                            />
                        </div>
                    </div>
                </div>
                <QuickAccessComponentContainer />
                {this.renderAddEventModal()}
            </>
        )
    }
}

const mapStateToProps = (state: RootState) => ({
    auth: state.auth,
    dictionary: state.dictionary,
    schedulingItems: state.schedulingItems,
    schedulingItemRecords: state.schedulingItemRecords,
    language: state.settings.language
});

const mapDispatchToProps = {
    getSchedulingItems,
    getSchedulingItemRecords,
    getUserById,
    deleteSchedulingItems,
    cancel,
    approve,
    saveBulkSchedulingItem,
    getFullDoctorList,
    getSelfClinicsList
}

let DoctorScheduleDetailsComponenttContainer = connect(mapStateToProps, mapDispatchToProps)(
    withTranslation()(DoctorScheduleDetails)
);

export default DoctorScheduleDetailsComponenttContainer;