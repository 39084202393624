import { AlphActionTypes, SET_STATE_LETTER } from "../actions/alph-actions";
import { IAlphState } from "../states/alph-state";

const initialState: IAlphState = {
  letter: undefined,
};

export function alphReducer(
  state = initialState,
  action: AlphActionTypes
): IAlphState {
  switch (action.type) {
    case SET_STATE_LETTER:
      return { ...state, letter: action.letter };

    default:
      return state;
  }
}
