import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, InjectedFormProps, reduxForm, formValueSelector, FormErrors } from 'redux-form';
import { IDictionaryState } from '../states/dictionary-state';
import { IAuthState } from '../states/auth-state';
import { RootState } from '../store';
import TextInput from './FormComponents/TextInput';
import { IPatientClinicState } from '../states/patient-clinic-state';
import { IPatientDoctorState } from '../states/patient-doctor-state';
import { IClinic } from '../models/clinic';
import { IDoctorFilter } from '../models/doctor-filter';
import { IPatientDoctor } from '../models/patient-doctor';
import { getPatientMyDoctorList, PATIENT_MY_DOCTOR_LIST, _getPatientDoctorList } from './../actions/patient-doctor-action';
import { IPatientConsultationHistoryState } from '../states/patient-consultation-history-state';
import { IPatientConsultationHistory } from "../models/user-consultation-history";
import { getPatientMyClinicList } from '../actions/patient-clinic-action';
import DropDownSelect from './FormComponents/DropDownSelect';
import UserFilesAddComponent from './FormComponents/UserFilesAdd';
import { IUpload } from '../models/upload';
import { IFile } from '../models/file';
import { TFunction } from 'i18next';
import { withTranslation } from 'react-i18next';
import MiniDropDownSelect from './FormComponents/MiniDropDownSelect';
import MiniTextInput from './FormComponents/MiniTextInput';

type Props = {
    t: TFunction;
    type: number;
    doctor_spec?: number;
    clinic_id?: number;
    doctor_id?: number;
    dictionary: IDictionaryState;
    auth: IAuthState;
    patientClinic: IPatientClinicState;
    patientDoctor: IPatientDoctorState;
    onFilesChange: (addedFiles: IUpload[], deletedFiles: IFile[]) => void;

    patientConsultationHistory: IPatientConsultationHistoryState;

    getPatientMyClinicList: (
        filter: IClinic | undefined
    ) => void;
    getPatientMyDoctorList: (
        filter: IDoctorFilter | undefined
    ) => void;
    _getPatientDoctorList: (patientDoctorList: IPatientDoctor[]) => {
        type: typeof PATIENT_MY_DOCTOR_LIST,
        patientDoctorList: IPatientDoctor[];
    };
}

class UserConsultationsHistoryForm extends React.Component<InjectedFormProps<IPatientConsultationHistory> & Props> {
    constructor(props: InjectedFormProps<IPatientConsultationHistory>
        & Props) {
        super(props);
    }

    componentWillMount() {
        this.props.getPatientMyClinicList(undefined);
        this.props.getPatientMyDoctorList(undefined);
        this.props.patientConsultationHistory.selected && this.props.initialize(this.props.patientConsultationHistory.selected);
    }

    render() {
        const { t } = this.props;
        return (
            <div className='auth-form auth-form-flex'>
                <div className='auth-form__row'>
                    <div className='auth-form__col'>
                        <Field name="clinic_id"
                            label={t('Clinic')}
                            component={MiniDropDownSelect}
                            selectedValue={this.props.clinic_id}
                            valuesForDropdown={this.props.patientClinic.patientClinicList?.map(d => ({ value: d.clinic?.id, label: d.clinic?.name })) || []}
                        />
                    </div>
                    <div className='auth-form__col'>
                        <Field name="diagnosis" placeholder={t('Diagnosis')} component={MiniTextInput}/>
                    </div>
                </div>
                <div className='auth-form__row' >
                    <div className='auth-form__col'>
                        <Field name="doctor_id"
                            label={t('Doctor')}
                            component={MiniDropDownSelect}
                            selectedValue={this.props.doctor_id}
                            valuesForDropdown={this.props.patientDoctor.patientDoctorList?.map(d => ({ value: d.doctor?.id, label: d.doctor?.surname + ' ' + d.doctor?.name + ' ' + d.doctor?.patronymic })) || []}
                        />
                    </div>
                    <div className='auth-form__col'>
                        <Field name="anamnesis" placeholder={t('Anamnesis')} component={MiniTextInput}/>
                    </div>
                </div>
                <div className='auth-form__row'>
                    <div className='auth-form__col'>
                        <Field name="doctor_spec"
                            label={t('Specialization')}
                            component={MiniDropDownSelect}
                            selectedValue={this.props.doctor_spec}
                            valuesForDropdown={this.props.dictionary.specializationTypes?.map(d => ({ value: d?.id, label: d?.name })) || []}
                        />
                    </div>
                    <div className='auth-form__col'>
                        <Field name="objectively" placeholder={t('Objectively')} component={MiniTextInput}/>
                    </div>
                </div>
                <div className='auth-form__row'>
                    <div className='auth-form__col'>
                        <Field name="date" type='date' placeholder={t('Date')} component={MiniTextInput}/>
                    </div>
                    <div className='auth-form__col'>
                        <Field name="conclusion" placeholder={t('Conclusion')} component={MiniTextInput}/>
                    </div>
                </div>
                <div className='auth-form__row'>
                    <div className='auth-form__col'>
                        <Field name="symptoms" placeholder={t('Symptoms')} component={MiniTextInput}/>
                    </div>
                    <div className='auth-form__col'>
                        <UserFilesAddComponent
                            type={'consultation/'}
                            style={{ width: '70%', border: '1px solid #767676' }}
                            uploadedFiles={this.props?.patientConsultationHistory?.selected?.uploadedfiles}
                            onChange={(a, b) => this.props.onFilesChange && this.props.onFilesChange(a, b)}
                        />              
                    </div>
                </div>
            </div>
        );
    }
}

const validate = (values: IPatientConsultationHistory, props: {t: TFunction}): FormErrors<IPatientConsultationHistory> => {
    const errors: FormErrors<IPatientConsultationHistory> = {};
    if (!values.date) {
        errors.date = props.t('Field couldn\'t be empty');
    }
    return errors;
};

const form = reduxForm<IPatientConsultationHistory & { isChecked: boolean }, any>({
    form: 'UserConsultationsHistoryForm',
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
    validate,
})(UserConsultationsHistoryForm);

const selector = formValueSelector('UserConsultationsHistoryForm');

const mapStateToProps = (state: RootState) => {
    const { type, doctor_spec, clinic_id, doctor_id } = selector(state, 'type', 'doctor_spec', 'clinic_id', 'doctor_id') || {};

    return {
        dictionary: state.dictionary,
        patientClinic: state.patientClinic,
        patientDoctor: state.patientDoctor,
        patientConsultationHistory: state.patientConsultationHistory,
        auth: state.auth,
        file: state.file,
        type,
        doctor_spec,
        clinic_id,
        doctor_id,
    }
};

const mapDispatchToProps = {
    getPatientMyClinicList,
    getPatientMyDoctorList,
    _getPatientDoctorList,
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(form));