import React, { Component } from "react";
import shield from "./../../images/shield-dis.png";
import "./ReceptionStandards.scss";
interface MyProps { }

interface MyState { }

class ReceptionStandards extends Component<MyProps, MyState> {
  constructor(props: MyProps) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="reception-standards">
        <h2>Стандарт приема Video-doktor</h2>
        <h3>Гарантия стандарта приема</h3>
        <p>
          Video-doktor разработал единый стандарт первичного приема. Этот
          стандарт гарантирует, что в стоимость приема врача входит
          консультация, осмотр, назначение лечения или диагностики, если без
          дополнительных исследований постановка предварительного диагноза
          невозможна.
        </p>
        <h3>Программа компенсации денежных средств</h3>
        <p>
          Если врач отмечен знаком стандарта
          <span>
            <img src={shield} alt="" />
          </span>
          ,а консультация, осмотр или назначения лечения (диагностики) не вошли
          в стоимость приема, указанную на Video-doktor, мы вернем вам деньги.
          Если консультация, осмотр и назначение лечение вошли в стоимость
          приема, но сам прием оказался дороже, мы вернем вам разницу.
        </p>
        <p>
          Обратите внимание, что прием включает в себя только консультацию,
          осмотр, назначение лечения либо назначение дополнительной диагностики
          (анализов), если это необходимо. Любые другие услуги и манипуляции
          оплачиваются отдельно по прейскуранту клиники.
        </p>

        <p>
          Для получения компенсации нужно написать на{" "}
          <a href="mailto:ezgilmedical@gmail.com">ezgilmedical@gmail.com</a>,
          указав ваше ФИО, телефон, а также приложив скан чека об оплате услуг.
        </p>
        <p>
          <b>P.S.</b> Стандарт первичного приема распространяется только на
          врачей следующих специальностей: дерматолог, гинеколог, ЛОР
          (отоларинголог), гастроэнтеролог, офтальмолог, проктолог, ортопед,
          уролог, венеролог, хирург.
        </p>
        <p>
          <b>P.P.S.</b> Решение о необходимости назначения анализов решает сам
          врач, после очной консультации и осмотра пациента.
        </p>
        <p>
          Мы надеемся, что стандарт первичного приема поможет пациентам
          пользоваться услугами только добросовестных специалистов.
        </p>
      </div>
    );
  }
}

export default ReceptionStandards;
