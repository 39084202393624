import {ThunkAction} from "redux-thunk";
import {Action, ActionCreator} from "redux";
import {loadingEnd, loadingStart} from "./loading-actions";
import {LoadingSubject} from "../states/loading-state";
import {authFetch, signOut} from "./auth-actions";
import {pushAlert} from "./alert-actions";
import * as environment from '../app.json';
import { IIndicatorsInfo } from "../models/indicators";
import { RootState } from "../store";
import { IIndicatorValue } from "../models/indicator-value";
import { GroupType, IIndicatorValueFilter } from "../models/indicator-value-filter";
import i18n from 'i18next';

export const INDICATORS_PAGE_LIMIT = 40;

export const INDICATORS_INFO = 'INDICATORS_INFO';
interface IndicatorsInfoAction {
    type: typeof INDICATORS_INFO;
    indicatorsInfo: IIndicatorsInfo;

}

export const _indicatorsInfo: ActionCreator<IndicatorsInfoAction> = (
    indicatorsInfo: IIndicatorsInfo
) => {
    return {
        type: INDICATORS_INFO,
        indicatorsInfo
    };
};

export const INDICATOR_CHART_VALUES= 'INDICATOR_CHART_VALUES';
interface IndicatorChartValuesAction {
    type: typeof INDICATOR_CHART_VALUES;
    values: IIndicatorValue[];
    filter: IIndicatorValueFilter;
}

export const _indicatorChartValues: ActionCreator<IndicatorChartValuesAction> = (
    values: IIndicatorValue[],
    filter: IIndicatorValueFilter
) => {
    return {
        type: INDICATOR_CHART_VALUES,
        values,
        filter,
    };
};

export const INDICATOR_VALUES= 'INDICATOR_VALUES';
interface IndicatorValuesAction {
    type: typeof INDICATOR_VALUES;
    values: IIndicatorValue[];
    page: number;
    hasMore: boolean;
}

export const _indicatorValues: ActionCreator<IndicatorValuesAction> = (
    values: IIndicatorValue[],
    page: number,
    hasMore: boolean,
) => {
    return {
        type: INDICATOR_VALUES,
        values,
        page,
        hasMore
    };
};

export const DELELTE_INDICATORS = 'DELELTE_INDICATORS';
interface DeleteIndicatorsAction {
    type: typeof DELELTE_INDICATORS;
    values: IIndicatorValue[];
}

export const _deleteIndicators: ActionCreator<DeleteIndicatorsAction> = (
    values: IIndicatorValue[]
) => {
    return {
        type: DELELTE_INDICATORS,
        values
    };
};

export const getIndicatorsInfo = (
    language?: string
): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch, getState) => {
    if (getState().loading.subjects.has(LoadingSubject.IndicatorsInfo)) {
        return;
    }
    try {
        dispatch(loadingStart(LoadingSubject.IndicatorsInfo));

        const response = await authFetch(
            getState, dispatch, environment.baseUrl + 'external/public/indicator/info?lang=' + (language || i18n.language), 'POST',
            {}, { Accept: 'application/json', 'Content-Type': 'application/json' }
        );
        //console.warn('responseIndicator', response)
        const json = await response.json();
        if (response.status === 200) {
            dispatch(_indicatorsInfo(json));
        }
        else {
            console.warn('Error', response);
            dispatch((pushAlert('Error')));
        }
    } catch (e) {
        dispatch(pushAlert('Unknown error. Please, check internet connection'));
    } finally {
        dispatch(loadingEnd(LoadingSubject.IndicatorsInfo));
    }
}


export const getIndicatorValues = (
    filter: IIndicatorValueFilter,
    page?: number
): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch, getState) => {
    dispatch(loadingStart(page === undefined ? LoadingSubject.IndicatorsChart : page ? LoadingSubject.IndicatorValuesNext : LoadingSubject.IndicatorValues));

    try {
        let url = 'external/private/indicator/value/list';
        if (page !== undefined) {
            url += `?limit=${INDICATORS_PAGE_LIMIT*filter.indicatorLineCodes.length}&offset=${INDICATORS_PAGE_LIMIT*filter.indicatorLineCodes.length*page}`;
        }
        //console.warn('getIndicatorValuesFilter, ', filter);
        const response = await authFetch(
            getState, dispatch, environment.baseUrl + url, 'POST',
            filter, { Accept: 'application/json', 'Content-Type': 'application/json' }
        );
        //console.warn('getIndicatorValues', response)
        if (response.status === 200) {
            const json = await response.json();
            if (page === undefined) {
                dispatch(_indicatorChartValues(json, filter));
            } else {
                dispatch(_indicatorValues(json, page + 1, json.length === INDICATORS_PAGE_LIMIT*filter.indicatorLineCodes.length));
            }
        }
        else {
            console.warn('Error', response);
            dispatch((pushAlert('Error')));
        }
    } catch (e) {
        dispatch(pushAlert('Unknown error. Please, check internet connection'));
    } finally {
        dispatch(loadingEnd(page === undefined ? LoadingSubject.IndicatorsChart : page ? LoadingSubject.IndicatorValuesNext : LoadingSubject.IndicatorValues));
    }
}

export const valueSaveBulk = (
    values: IIndicatorValue[],
    success: () => void,
): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch, getState) => {
    dispatch(loadingStart(LoadingSubject.SaveIndicator));

    try {
        const response = await authFetch(
            getState, dispatch, environment.baseUrl + 'external/private/indicator/value/save-bulk', 'POST',
            values, { Accept: 'application/json', 'Content-Type': 'application/json' }
        );

        if (response.status === 200) {
            dispatch(getIndicatorValues(getState().indicators.filter!));
            dispatch(getIndicatorValues({indicatorLineCodes: getState().indicators.filter!.indicatorLineCodes, groupType: GroupType.None, userId: 0}, 0));
            success && success();
        }
        else if (response.status === 401) {
            dispatch(signOut(getState().auth.userToken!.auth));
        }
        else {
            console.warn('valueSaveBulkError', response);
            dispatch((pushAlert('Error')));
        }
    } catch (e) {
        dispatch(pushAlert('Unknown error. Please, check internet connection'));
    } finally {
        dispatch(loadingEnd(LoadingSubject.SaveIndicator));
    }
}

export const valueDeleteBulk = (
    values: IIndicatorValue[],
): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch, getState) => {
    dispatch(loadingStart(LoadingSubject.SaveIndicator));

    try {
        console.warn('valueDeleteBulk', values);
        const response = await authFetch(
            getState, dispatch, environment.baseUrl + 'external/private/indicator/value/delete-bulk', 'POST',
            values, { Accept: 'application/json', 'Content-Type': 'application/json' }
        );

        if (response.status === 200) {
            dispatch(getIndicatorValues(getState().indicators.filter!));
            dispatch(_deleteIndicators(values));
        }
        else if (response.status === 401) {
            dispatch(signOut(getState().auth.userToken!.auth));
        }
        else {
            console.warn('valueDeleteBulkError', response);
            dispatch((pushAlert('Error')));
        }
    } catch (e) {
        dispatch(pushAlert('Unknown error. Please, check internet connection'));
    } finally {
        dispatch(loadingEnd(LoadingSubject.SaveIndicator));
    }
}

export type IndicatorsActionsTypes = IndicatorsInfoAction | IndicatorValuesAction | IndicatorChartValuesAction | DeleteIndicatorsAction;