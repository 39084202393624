import { LoadingSubject } from "../states/loading-state";
import { loadingEnd, loadingStart } from "./loading-actions";
import { pushAlert } from "./alert-actions";
import { fetchSelf, SIGN_IN, signOut, authFetch } from "./auth-actions";
import { ThunkAction } from "redux-thunk";
import { Action, ActionCreator } from "redux";
import * as environment from './../app.json';
import { ISchedulingItemRecordFilter } from "../models/scheduling-item-record-filter";
import { ISchedulingItemRecord, SchedulingItemRecordStatus } from "../models/scheduling-item-record";
import { ISchedulingItemFilter } from "../models/scheduling-item-filter";
import { getSchedulingItems } from "./scheduling-item-actions";
import { RootState } from "../store";
import { UserRole } from "../models/user-role.enum";
import { showConfirm } from "../components/Dialogs";
import i18next from 'i18next';
import history from "../history";

export const SCHEDULING_ITEM_RECORDS_LIST_PAGE_SIZE = 10;

export const PAY_FOR_RECORD = 'PAY_FOR_RECORD';
interface PayForRecordAction {
    type: typeof PAY_FOR_RECORD;
    redirect: string;
}

export const _payForRecord: ActionCreator<PayForRecordAction> = (
    redirect: string,
) => {
    return {
        type: PAY_FOR_RECORD,
        redirect
    };
};

export const SCHEDULING_ITEM_RECORDS_LIST = 'SCHEDULING_ITEM_RECORDS_LIST';
interface SchedulingItemRecordsAction {
    type: typeof SCHEDULING_ITEM_RECORDS_LIST;
    schedulingItemRecordsList: ISchedulingItemRecord[];
    page?: number;
}

const _schedullingItemlist: ActionCreator<SchedulingItemRecordsAction> = (schedulingItemRecordsList: ISchedulingItemRecord[], page?: number) => {
    return {
        type: SCHEDULING_ITEM_RECORDS_LIST,
        schedulingItemRecordsList,
        page,
    };
};
export const SCHEDULING_ITEM_RECORDS_MAP = 'SCHEDULING_ITEM_RECORDS_MAP';
interface SchedulingItemRecordsMapAction {
    type: typeof SCHEDULING_ITEM_RECORDS_MAP;
    schedulingItemRecordsMap: Map<number, ISchedulingItemRecord>;
}

const _schedullingItemMap: ActionCreator<SchedulingItemRecordsMapAction> = (schedulingItemRecordsMap: Map<number, ISchedulingItemRecord>) => {
    return {
        type: SCHEDULING_ITEM_RECORDS_MAP,
        schedulingItemRecordsMap
    };
};

export const SAVE_SCHEDULING_ITEM_RECORD = 'SAVE_SCHEDULING_ITEM_RECORD';
interface SaveSchedulingItemRecordAction {
    type: typeof SAVE_SCHEDULING_ITEM_RECORD;
}

const _saveschedullingItem: ActionCreator<SaveSchedulingItemRecordAction> = () => {
    return {
        type: SAVE_SCHEDULING_ITEM_RECORD,
    };
};

export const CALL_RECORD = 'CALL_RECORD';
interface CallRecordAction {
    type: typeof CALL_RECORD;
    item: ISchedulingItemRecord | null;
}

const _callRecord: ActionCreator<CallRecordAction> = (item: ISchedulingItemRecord | null) => {
    return {
        type: CALL_RECORD,
        item
    };
};

export const SCHEDULING_ITEM_RECORD = 'SCHEDULING_ITEM_RECORD';
interface ApproveSchedulingItemRecordAction {
    type: typeof SCHEDULING_ITEM_RECORD;
    item: ISchedulingItemRecord;
}

const _schedulingItem: ActionCreator<ApproveSchedulingItemRecordAction> = (item: ISchedulingItemRecord) => {
    return {
        type: SCHEDULING_ITEM_RECORD,
        item
    };
};
export const COUNT_SCHEDULING_ITEM_RECORDS = 'COUNT_SCHEDULING_ITEM_RECORDS';
interface CountSchedulingItemRecordAction {
    type: typeof COUNT_SCHEDULING_ITEM_RECORDS;
    count: number;
}

const _countschedullingItem: ActionCreator<CountSchedulingItemRecordAction> = (count: number) => {
    return {
        type: COUNT_SCHEDULING_ITEM_RECORDS,
        count
    };
};

export const GET_SCHEDULING_ITEM_RECORD_BY_ID = 'GET_SCHEDULING_ITEM_RECORD_BY_ID';
interface GetSchedulingItemRecordByIdAction {
    type: typeof GET_SCHEDULING_ITEM_RECORD_BY_ID;
    selectedRecord?: ISchedulingItemRecord;
}

const _getSchedulingItemRecordById: ActionCreator<GetSchedulingItemRecordByIdAction> = (selectedRecord: ISchedulingItemRecord) => {
    return {
        type: GET_SCHEDULING_ITEM_RECORD_BY_ID,
        selectedRecord
    }
}

export type SchedulingItemRecordsType = CountSchedulingItemRecordAction | ApproveSchedulingItemRecordAction |
    SaveSchedulingItemRecordAction | SchedulingItemRecordsAction | SchedulingItemRecordsMapAction | CallRecordAction | 
    GetSchedulingItemRecordByIdAction | PayForRecordAction;

export const fetchCallRecord = (
    item: ISchedulingItemRecord,
): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch, getState) => {

    try {
        dispatch(_callRecord(null));
        const response = await authFetch(getState, dispatch, environment.baseUrl + 'external/private/call/record', 'POST', item);

        if (response.status === 200) {
            const record: ISchedulingItemRecord = await response.json();
            dispatch(_callRecord(record));
        } else {
            console.error(response);
        }
    } catch (e) {
        console.error(e);
    }
}

export const dutySchedulingItemRecord = async (
    item: ISchedulingItemRecord,
    dispatch: any,
    getState: () => RootState,
): Promise<ISchedulingItemRecord | undefined> => {
    const response = await authFetch(
        getState, dispatch, environment.baseUrl +
        'external/private/call/record', 'POST',
        item, { Accept: 'application/json', 'Content-Type': 'application/json' }
    );

    if (response.status === 200) {
        const record: ISchedulingItemRecord = await response.json();
        if (record.status === SchedulingItemRecordStatus.Ready || record.status == SchedulingItemRecordStatus.Initiated || record.status == SchedulingItemRecordStatus.Accepted) {
            return record;
        }
    }

    const saveResponse = await authFetch(
        getState, dispatch, environment.baseUrl +
        'external/private/schedulingItemRecord/save', 'POST',
        item, { Accept: 'application/json', 'Content-Type': 'application/json' }
    );

    if (saveResponse.status === 200) {
        return await saveResponse.json();
    }
}

export const saveSchedulingItemRecord = (
    item: ISchedulingItemRecord,
    filter?: ISchedulingItemFilter,
): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch, getState) => {
    dispatch(loadingStart(LoadingSubject.SaveSchedulingItemRecord));
    console.log('item', item);

    try {
        const response = await authFetch(
            getState, dispatch, environment.baseUrl +
            'external/private/schedulingItemRecord/save', 'POST',
            item, { Accept: 'application/json', 'Content-Type': 'application/json' }
        );
        const json = await response.json();
        if (response.status === 200) {
            dispatch(_saveschedullingItem(json));
            if (filter) {
                dispatch(getSchedulingItems(filter as ISchedulingItemFilter));
                dispatch(getSchedulingItemRecords(filter));
            }
            dispatch(pushAlert('Your appointment request was saved, please wait for doctor\'s confirmation'));
            const confirmation: any = await showConfirm(i18next.t("Appointment created succesfully. Please, wait the doctor's confirmation, you can check it on the My consultations page."));
            console.log('json2', getState().schedulingItemRecords);
            if (confirmation) {
                const chatId = getState().schedulingItemRecords?.schedulingItemRecordList?.find(s => s.schedulingItemId === item.schedulingItemId)?.id;
                history.push(`/online-appointment?chat=${chatId}`);
            }
        }
        else if (response.status === 401) {
            //dispatch(signOut(token));
        }
        else {
            dispatch(pushAlert('Appointment save error'));
        }
    } catch (e) {
        dispatch(pushAlert('Unknown error. Please, check internet connection'));
    } finally {
        dispatch(loadingEnd(LoadingSubject.SaveSchedulingItemRecord));
    }
}

export const getSchedulingItemRecords = (
    filter: ISchedulingItemRecordFilter,
    page?: number,
): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch, getState) => {
    dispatch(loadingStart(page === 0 ? LoadingSubject.SchedulingItemRecordsFull : LoadingSubject.SchedulingItemRecords));
    console.log('filter', filter);
    console.log('page', page);
    
    const access = !!getState().auth.userToken ? 'private' : 'public';

    try {
        const response = await authFetch(getState, dispatch, environment.baseUrl +
            'external/private/schedulingItemRecord/list' + (page === undefined ? '' : `?limit=${SCHEDULING_ITEM_RECORDS_LIST_PAGE_SIZE}&offset=${page * SCHEDULING_ITEM_RECORDS_LIST_PAGE_SIZE}`),
            'POST', {...filter});

        console.log('response', response);

        const json = await response.json();
        if (response.status === 200) {
            dispatch(_schedullingItemlist(json, page));
            if (json && json.length) {
                const map = new Map<number, ISchedulingItemRecord>();
                json.forEach((el: ISchedulingItemRecord) => map.set(el.schedulingItemId, el));
                dispatch(_schedullingItemMap(map));
            }
        }
        else if (response.status === 401) {
            dispatch(signOut(getState().auth.userToken?.auth || ''));
        }
        else {
            dispatch(pushAlert('Scheduling item redords loading error'));
        }
    } catch (e) {
        dispatch(pushAlert('Unknown error. Please, check internet connection'));
    } finally {
        dispatch(loadingEnd(page === 0 ? LoadingSubject.SchedulingItemRecordsFull : LoadingSubject.SchedulingItemRecords));
    }
}


export const getSchedulingItemRecordById = (id: number): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch, getState) => {
    try {
        const response = await authFetch(
            getState, dispatch, environment.baseUrl +
            'external/private/call/record', 'POST',
            {id}, { Accept: 'application/json', 'Content-Type': 'application/json' }
        );
    
        if (response.status === 200) {
            const record: ISchedulingItemRecord = await response.json();
            dispatch(_getSchedulingItemRecordById(record))
        }
    } catch (err) {
        dispatch(pushAlert('Unknown error. Please, check internet connection'));
    }
}

export const countSchedulingItemRecords = (
    filter: ISchedulingItemRecordFilter
): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch, getState) => {
    dispatch(loadingStart(LoadingSubject.CountSchedulingItemRecord));
    console.log('filter', filter);

    try {
        const response = await authFetch(
            getState, dispatch, environment.baseUrl +
            'external/private/schedulingItemRecord/count', 'POST',
            filter, { Accept: 'application/json', 'Content-Type': 'application/json' }
        );

        console.log('response', response);

        const json = await response.json();
        console.log('json', json);
        if (response.status === 200) {
            dispatch(_countschedullingItem(json));
        }
        else if (response.status === 401) {
            //dispatch(signOut(token));
        }
        else {
            dispatch(pushAlert('Scheduling item redords count loading error'));
        }
    } catch (e) {
        dispatch(pushAlert('Unknown error. Please, check internet connection'));
    } finally {
        dispatch(loadingEnd(LoadingSubject.CountSchedulingItemRecord));
    }
}

export const approve = (
    item: ISchedulingItemRecord,
    filter?: ISchedulingItemRecordFilter | ISchedulingItemRecordFilter,
): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch, getState) => {
    dispatch(loadingStart(LoadingSubject.ApprovingSchedulingItemRecord));
    console.log('item', item);

    try {
        const response = await authFetch(
            getState, dispatch, environment.baseUrl +
            'external/private/schedulingItemRecord/approve', 'POST',
            item, { Accept: 'application/json', 'Content-Type': 'application/json' }
        );

        console.log('response', response);

        const json = await response.json();
        console.log('json', json);
        if (response.status === 200) {
            dispatch(_schedulingItem(json));
            if (filter) {
                dispatch(getSchedulingItems(filter as ISchedulingItemFilter));
                dispatch(getSchedulingItemRecords(filter));
            }
        }
        else if (response.status === 401) {
            //dispatch(signOut(token));
        }
        else {
            dispatch(pushAlert('Approving scheduling item redord error'));
        }
    } catch (e) {
        dispatch(pushAlert('Unknown error. Please, check internet connection'));
    } finally {
        dispatch(loadingEnd(LoadingSubject.ApprovingSchedulingItemRecord));
    }
}

export const cancel = (
    item: ISchedulingItemRecord,
    filter?: ISchedulingItemRecordFilter | ISchedulingItemRecordFilter,
): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch, getState) => {
    dispatch(loadingStart(LoadingSubject.ApprovingSchedulingItemRecord));
    console.log('item', item);

    try {
        const response = await authFetch(
            getState, dispatch, environment.baseUrl +
            'external/private/schedulingItemRecord/cancel', 'POST',
            item, { Accept: 'application/json', 'Content-Type': 'application/json' }
        );

        console.log('response', response);

        const json = await response.json();
        console.log('json', json);
        if (response.status === 200) {
            dispatch(_schedulingItem(json));
            if (filter) {
                dispatch(getSchedulingItems(filter as ISchedulingItemFilter));
                dispatch(getSchedulingItemRecords(filter));
            }
        }
        else if (response.status === 401) {
            //dispatch(signOut(token));
        }
        else {
            dispatch(pushAlert('Approving scheduling item redord error'));
        }
    } catch (e) {
        dispatch(pushAlert('Unknown error. Please, check internet connection'));
    } finally {
        dispatch(loadingEnd(LoadingSubject.ApprovingSchedulingItemRecord));
    }
}

export const payForRecord = (
    record: ISchedulingItemRecord,
): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch, getState) => {
    dispatch(loadingStart(LoadingSubject.Payment));
    try {
        const response = await authFetch(getState, dispatch, environment.baseUrl +
            'external/private/schedulingItemRecord/pay', 'POST', JSON.stringify(record)
        );
      
        if (response.status === 200) {
            const json = await response.json();
            console.warn(json);
            if (json.paid) {
                dispatch(pushAlert('Subscription has been successfully issued'))
            } else {
                dispatch(_payForRecord(json ? json.redirect : undefined));
            }
        } else if (response.status === 401) {
            dispatch(signOut(getState().auth.userToken?.auth!));
        } else {
            dispatch(pushAlert('While subscribing an error occurred'));
        }
    } catch (e) {
        dispatch(pushAlert('Unknown error. Please, check internet connection'));
    } finally {
        dispatch(loadingEnd(LoadingSubject.InitiateCall));
        dispatch(loadingEnd(LoadingSubject.Payment));
    }    
}