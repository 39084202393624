import {ISchedulingItemRecordsState} from "../states/scheduling-item-records-state";
import {
    SCHEDULING_ITEM_RECORD,
    SAVE_SCHEDULING_ITEM_RECORD,
    SCHEDULING_ITEM_RECORDS_LIST, SCHEDULING_ITEM_RECORDS_MAP,
    SchedulingItemRecordsType,
    SCHEDULING_ITEM_RECORDS_LIST_PAGE_SIZE,
    CALL_RECORD,
    GET_SCHEDULING_ITEM_RECORD_BY_ID,
    PAY_FOR_RECORD
} from "../actions/scheduling-item-record-actions";
import { ISchedulingItemRecord } from "../models/scheduling-item-record";

const initialState: ISchedulingItemRecordsState = {
    schedulingItemRecordList: undefined,
    schedulingItemRecordsMap: undefined,
    schedulingItemRecordsUpdate: 0,
    callRecord: null,
    selectedRecord: undefined,
}

export function schedulingItemRecordsReducer(
    state = initialState,
    action: SchedulingItemRecordsType
): ISchedulingItemRecordsState {
    let schedulingItemRecordList: ISchedulingItemRecord[];
    switch (action.type) {
        case PAY_FOR_RECORD:
            return {...state, ...{redirect: action.redirect}};
        case CALL_RECORD: {
            return {...state,...{callRecord: action.item}};
        }
        case SCHEDULING_ITEM_RECORDS_LIST:
            schedulingItemRecordList = action.page ? state.schedulingItemRecordList!.concat(action.schedulingItemRecordsList) : action.schedulingItemRecordsList;
            return {...state,...{
                schedulingItemRecordList,
                schedulingItemRecordsPage: action.page,
                schedulingItemRecordsEnd: action.schedulingItemRecordsList.length < SCHEDULING_ITEM_RECORDS_LIST_PAGE_SIZE,
                schedulingItemRecordsUpdate: state.schedulingItemRecordsUpdate + 1}};
        case SAVE_SCHEDULING_ITEM_RECORD:
            return state;
        case SCHEDULING_ITEM_RECORD:
            if (!state.schedulingItemRecordList) {
                return state;
            }
            schedulingItemRecordList = [];
                
            for (const record of state.schedulingItemRecordList) {
                if (record.id === action.item.id) {
                    console.log('SCHEDULING_ITEM_RECORD refreshed');
                    schedulingItemRecordList.push(action.item);
                } else {
                    schedulingItemRecordList.push(record);
                }
            }
            // if (state.schedulingItemRecordsMap) {
            //     const schedulingItemRecordsMap: Map<number,ISchedulingItemRecord> = new Map<number, ISchedulingItemRecord>();
            //     schedulingItemRecordList.forEach((el:ISchedulingItemRecord) => schedulingItemRecordsMap.set(el.schedulingItemId, el));
            //     return {...state, schedulingItemRecordList, schedulingItemRecordsMap};
            // }
            return {...state, schedulingItemRecordList};
        case SCHEDULING_ITEM_RECORDS_MAP:
             return {...state,
                 ...{schedulingItemRecordsMap: action.schedulingItemRecordsMap}};
        case GET_SCHEDULING_ITEM_RECORD_BY_ID:
            return {...state, ...{selectedRecord: action.selectedRecord}}
        default:
            return state;
    }
}
