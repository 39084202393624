import { IDoctor_Add } from "./../models/doctor-clinic-add";
import { IDoctor } from "../models/doctor";
import { Action, ActionCreator } from "redux";
import { IUser } from "../models/user";
import { ThunkAction } from "redux-thunk";
import { loadingEnd, loadingStart } from "./loading-actions";
import { LoadingSubject } from "../states/loading-state";
// import AsyncStorage from "@react-native-community/async-storage";
import { pushAlert } from "./alert-actions";
import * as environment from "../app.json";
import { authFetch, processStageChange, signOut } from "./auth-actions";
import { IDoctorRating } from "../models/doctor-rating";
import { IDoctorFilter } from "../models/doctor-filter";
import i18n from "i18next";
import { RootState } from "../store";
import { IDoctorPrice } from "../models/doctor-price";
import { IDoctorPriceFilter } from "../models/doctor-price-filter";
import { TFunction } from "i18next";
import { showConfirm } from "../components/Dialogs";
import { _profileValues } from "./profile-actions";
import { IDoctorPosts } from "../models/doctors-posts";
import { IClinicDoctorSignUp } from "../models/clinic-doctor-sign-up";
import { WorkSharp } from "@material-ui/icons";
import { IDoctor_Delete } from "../models/doctor-clinic-delete";

// import {_search} from "./content-actions";

let topDoctor = environment.topDoctor;
function topDoctorData() {
  if (i18n.language == "az") topDoctor = environment.topDoctorAz;
  if (i18n.language == "ru") topDoctor = environment.topDoctorRu;
  return topDoctor;
}

export const DOCTORS_PAGE_SIZE = 8;

export const DOCTOR_LIST = "DOCTOR_LIST";
interface DoctorListAction {
  type: typeof DOCTOR_LIST;
  doctors: IDoctor[];
  page: number;
  my: boolean;
}

export const _doctorList: ActionCreator<DoctorListAction> = (
  doctors: IDoctor[],
  page: number,
  my: boolean
) => {
  return {
    type: DOCTOR_LIST,
    doctors,
    page,
    my,
  };
};

export const DOCTOR_PRICE = "DOCTOR_PRICE";
interface DoctorPriceAction {
  type: typeof DOCTOR_PRICE;
  doctorPrice: IDoctorPrice[];
}

const _doctorPrice: ActionCreator<DoctorPriceAction> = (
  doctorPrice: IDoctorPrice[]
) => {
  return {
    type: DOCTOR_PRICE,
    doctorPrice,
  };
};

//actionCreator for post doctors /clinics/newdoctor/{token}
export const ADD_CLINIC_DOCTOR = "ADD_CLINIC_DOCTOR";
interface addClinicDoctor {
  type: typeof ADD_CLINIC_DOCTOR;
  data: IDoctor_Add[];
}
const _addClinicDoctor: ActionCreator<addClinicDoctor> = (
  data: IDoctor_Add[]
) => {
  return { type: ADD_CLINIC_DOCTOR, data };
};

export const DOCTOR_FULL_LIST = "DOCTOR_FULL_LIST";
interface DoctorFullListAction {
  type: typeof DOCTOR_FULL_LIST;
  doctorFullList: IDoctor[];
}

const _doctorFullList: ActionCreator<DoctorFullListAction> = (
  doctorFullList: IDoctor[]
) => {
  console.log(doctorFullList);
  return {
    type: DOCTOR_FULL_LIST,
    doctorFullList,
  };
};

export const DOCTOR_LIST_COUNT = "DOCTOR_LIST_COUNT";
interface DoctorListCountAction {
  type: typeof DOCTOR_LIST_COUNT;
  totalCount: number;
}

export const _doctorListCount: ActionCreator<DoctorListCountAction> = (
  totalCount: number
) => {
  return {
    type: DOCTOR_LIST_COUNT,
    totalCount,
  };
};

// actionCreator for list from /clinics/doctors/TOKEN
export const CLINICS_DOCTORS_LIST = "CLINICS_DOCTORS_LIST";
interface ClinicsDoctorsList {
  type: typeof CLINICS_DOCTORS_LIST;
  clinicsDoctorsList: any;
}
const _clinicsDoctorsList: ActionCreator<ClinicsDoctorsList> = (
  clinicsDoctorsList: []
) => {
  return {
    type: CLINICS_DOCTORS_LIST,
    clinicsDoctorsList,
  };
};

export const DOCTOR = "DOCTOR";
interface DoctorAction {
  type: typeof DOCTOR;
  doctor: IDoctor;
}

const _doctor: ActionCreator<DoctorAction> = (doctor: IDoctor) => {
  return {
    type: DOCTOR,
    doctor,
  };
};

export const DOCTOR_RATING = "DOCTOR_RATING";
interface DoctorRatingAction {
  type: typeof DOCTOR_RATING;
  doctorRating: IDoctorRating[];
}

const _doctorRating: ActionCreator<DoctorRatingAction> = (
  doctorRating: IDoctorRating[]
) => {
  return {
    type: DOCTOR_RATING,
    doctorRating,
  };
};

export const DOCTORS_AUTOCOMPLETE = "DOCTORS_AUTOCOMPLETE";
interface DoctorsAutocompleteAction {
  type: typeof DOCTORS_AUTOCOMPLETE;
  doctorsAutocomplete: IDoctor[];
}

export const _doctorsAutocomplete: ActionCreator<DoctorsAutocompleteAction> = (
  doctorsAutocomplete: IDoctor[]
) => {
  return {
    type: DOCTORS_AUTOCOMPLETE,
    doctorsAutocomplete,
  };
};

export const DOCTOR_CACHED_DETAILS = "DOCTOR_CACHED_DETAILS";
interface DoctorCachedDetailsAction {
  type: typeof DOCTOR_CACHED_DETAILS;
  doctor: IDoctor;
}

export const _doctorCachedDetails: ActionCreator<DoctorCachedDetailsAction> = (
  doctor: IDoctor
) => {
  return {
    type: DOCTOR_CACHED_DETAILS,
    doctor,
  };
};

export const SELECTED_SPEC_ID = "SELECTED_SPEC_ID";
interface DoctorSelectedSpecIdAction {
  type: typeof SELECTED_SPEC_ID;
  specializationsId: number;
}

export const _doctorSelectedSpecId: ActionCreator<
  DoctorSelectedSpecIdAction
> = (specializationsId: number) => {
  return {
    type: SELECTED_SPEC_ID,
    specializationsId,
  };
};

export const UNSELECTED_SPEC_ID = "UNSELECTED_SPEC_ID";
interface DoctorUnSelectedSpecIdAction {
  type: typeof UNSELECTED_SPEC_ID;
  specializationsUnId: number;
}

export const _doctorUnSelectedSpecId: ActionCreator<
  DoctorUnSelectedSpecIdAction
> = (specializationsUnId: number) => {
  return {
    type: UNSELECTED_SPEC_ID,
    specializationsUnId,
  };
};

export const SELECTED_DISEASE_ID = "SELECTED_DISEASE_ID";
interface DoctorSelectedDisIdAction {
  type: typeof SELECTED_DISEASE_ID;
  diseasesId: number;
}

export const _doctorSelectedDisId: ActionCreator<DoctorSelectedDisIdAction> = (
  diseasesId: number
) => {
  return {
    type: SELECTED_DISEASE_ID,
    diseasesId,
  };
};

export const UNSELECTED_DISEASE_ID = "UNSELECTED_DISEASE_ID";
interface DoctorUnSelectedDisIdAction {
  type: typeof UNSELECTED_DISEASE_ID;
  diseasesUnId: number;
}

export const _doctorUnSelectedDisId: ActionCreator<
  DoctorUnSelectedDisIdAction
> = (diseasesUnId: number) => {
  return {
    type: UNSELECTED_DISEASE_ID,
    diseasesUnId,
  };
};

export const SELECTED_PROCEDURE_ID = "SELECTED_PROCEDURE_ID";
interface DoctorSelectedProcIdAction {
  type: typeof SELECTED_PROCEDURE_ID;
  proceduresId: number;
}

export const _doctorSelectedProcId: ActionCreator<
  DoctorSelectedProcIdAction
> = (proceduresId: number) => {
  return {
    type: SELECTED_PROCEDURE_ID,
    proceduresId,
  };
};
export const UNSELECTED_PROCEDURE_ID = "UNSELECTED_PROCEDURE_ID";
interface DoctorUnSelectedProcIdAction {
  type: typeof UNSELECTED_PROCEDURE_ID;
  proceduresUnId: number;
}

export const _doctorUnSelectedProcId: ActionCreator<
  DoctorUnSelectedProcIdAction
> = (proceduresUnId: number) => {
  return {
    type: UNSELECTED_PROCEDURE_ID,
    proceduresUnId,
  };
};

export const SELECTED_PROCEDURE_ID_DELETE = "SELECTED_PROCEDURE_ID_DELETE";
interface DoctorSelectedProcIdDeleteAction {
  type: typeof SELECTED_PROCEDURE_ID_DELETE;
  proceduresIdDelete: number;
}

export const _doctorSelectedProcDeleteId: ActionCreator<
  DoctorSelectedProcIdDeleteAction
> = (proceduresIdDelete: number) => {
  return {
    type: SELECTED_PROCEDURE_ID_DELETE,
    proceduresIdDelete,
  };
};

export const SELECTED_DISEASE_ID_DELETE = "SELECTED_DISEASE_ID_DELETE";
interface DoctorSelectedDisIdDeleteAction {
  type: typeof SELECTED_DISEASE_ID_DELETE;
  diseasesIdDelete: number;
}

export const _doctorSelectedDisDeleteId: ActionCreator<
  DoctorSelectedDisIdDeleteAction
> = (diseasesIdDelete: number) => {
  return {
    type: SELECTED_DISEASE_ID_DELETE,
    diseasesIdDelete,
  };
};

export const SELECTED_SPECIALIZATION_ID_DELETE =
  "SELECTED_SPECIALIZATION_ID_DELETE";
interface DoctorSelectedSpecIdDeleteAction {
  type: typeof SELECTED_SPECIALIZATION_ID_DELETE;
  specializationsIdDelete: number;
}

export const _doctorSelectedSpecDeleteId: ActionCreator<
  DoctorSelectedSpecIdDeleteAction
> = (specializationsIdDelete: number) => {
  return {
    type: SELECTED_SPECIALIZATION_ID_DELETE,
    specializationsIdDelete,
  };
};

export const CLEAR_STATE_INFO = "CLEAR_STATE_INFO";
interface ClearState {
  type: typeof CLEAR_STATE_INFO;
}

export const clearState: ActionCreator<ClearState> = () => {
  return {
    type: CLEAR_STATE_INFO,
  };
};

export const SAVE_PREV_SPEC_VALUES = "SAVE_PREV_SPEC_VALUES";
interface SavePrevSpecValues {
  type: typeof SAVE_PREV_SPEC_VALUES;
  prevSpec: [];
}

export const savePrevSpecValues: ActionCreator<SavePrevSpecValues> = (
  prevSpec: []
) => {
  return {
    type: SAVE_PREV_SPEC_VALUES,
    prevSpec,
  };
};
export const SAVE_PREV_DIS_VALUES = "SAVE_PREV_DIS_VALUES";
interface SavePrevDisValues {
  type: typeof SAVE_PREV_DIS_VALUES;
  prevDis: [];
}

export const savePrevDisValues: ActionCreator<SavePrevDisValues> = (
  prevDis: []
) => {
  return {
    type: SAVE_PREV_DIS_VALUES,
    prevDis,
  };
};
export const SAVE_PREV_PROC_VALUES = "SAVE_PREV_PROC_VALUES";
interface SavePrevProcValues {
  type: typeof SAVE_PREV_PROC_VALUES;
  prevProc: [];
}

export const savePrevProcValues: ActionCreator<SavePrevProcValues> = (
  prevProc: []
) => {
  return {
    type: SAVE_PREV_PROC_VALUES,
    prevProc,
  };
};

export type DoctorActionTypes =
  | DoctorAction
  | DoctorListAction
  | DoctorRatingAction
  | DoctorListCountAction
  | DoctorsAutocompleteAction
  | DoctorCachedDetailsAction
  | DoctorFullListAction
  | DoctorPriceAction
  | addClinicDoctor
  | ClinicsDoctorsList
  | DoctorSelectedSpecIdAction
  | DoctorSelectedDisIdAction
  | DoctorSelectedProcIdAction
  | DoctorSelectedProcIdDeleteAction
  | DoctorSelectedDisIdDeleteAction
  | DoctorSelectedSpecIdDeleteAction
  | DoctorUnSelectedSpecIdAction
  | ClearState
  | DoctorUnSelectedProcIdAction
  | DoctorUnSelectedDisIdAction
  | SavePrevDisValues
  | SavePrevProcValues
  | SavePrevSpecValues;

//thunk for /clinics/doctors/TOKEN
export const getClinicsDoctorsList =
  (): ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch, getState) => {
    dispatch(loadingStart(LoadingSubject.DoctorList));
    try {
      const token = getState().auth.userToken?.auth;

      const response = await fetch(
        // for display doctors of authorized clinic
        topDoctorData() + "clinics/doctorlist?token=" + token,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );
      console.log("ResponseFromDoctorList", response);

      const json = await response.json();
      console.log("JsonFromDoctorList", json);
      dispatch(_clinicsDoctorsList(json));
      dispatch(
        _profileValues(
          getState().profile.sections.find((s) => s.name === "clinicsdoctors"),
          [],
          1,
          false
        )
      );
    } catch (e) {
      console.log("clinicsDoctorsList error", e);
    } finally {
      dispatch(loadingEnd(LoadingSubject.DoctorList));
    }
  };

export const checkDoctorSelectedId =
  (
    v: { name: string }[],
    item: any,
    value: {}[],
    dropName: string,
    doctorId: number
  ): ThunkAction<void, RootState, unknown, Action<string>> =>
  (dispatch) => {
    if (typeof value[0] !== "string" && doctorId) {
      if (dropName === "specializations") {
        dispatch(savePrevSpecValues(value));
      }
      if (dropName === "procedures") {
        dispatch(savePrevProcValues(value));
      }
      if (dropName === "diseases") {
        dispatch(savePrevDisValues(value));
      }
    }
    if (dropName === "specializations") {
      if (!value.includes((i) => (i.Specialization || i) === item.id)) {
        dispatch(_doctorSelectedSpecId(item.id));
      }
    } else if (dropName === "procedures") {
      if (!value.includes((i) => (i.Procedure || i) === item.id)) {
        dispatch(_doctorSelectedProcId(item.id));
      }
    } else if (dropName === "diseases") {
      if (!value.includes((i) => (i.Disease || i) === item.id)) {
        dispatch(_doctorSelectedDisId(item.id));
      }
    }
  };

export const checkDoctorSelectedDeleteId =
  (
    v: { Name: string }[],
    item: { name: string; id: number },
    value: { Name: string; ID: number; Specialization: number }[] | string[],
    dropName: string,
    doctorId: number
  ): ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch, getState) => {
    const selectedInfoId = getState().doctor.doctorInfoId;
    const prevValues = getState().doctor.doctorPrevValues;
    let selectedItem;
    // let selectedItem =  value.find(
    //   (value) => value.Name === item.name
    // );
    await value.forEach((val) =>
      val.Name === item.name || val === item.name
        ? (selectedItem = val)
        : console.log("123")
    );

    if (typeof value[0] !== "string" && doctorId) {
      if (dropName === "specializations") {
        dispatch(savePrevSpecValues(value));
        dispatch(_doctorSelectedSpecDeleteId(selectedItem?.ID));
      }
      if (dropName === "procedures") {
        dispatch(savePrevProcValues(value));

        dispatch(_doctorSelectedProcDeleteId(selectedItem?.ID));
      }
      if (dropName === "diseases") {
        dispatch(savePrevDisValues(value));

        dispatch(_doctorSelectedDisDeleteId(selectedItem?.ID));
      }
    } else if (doctorId) {
      const selectedItemSpec = prevValues.specializations.find(
        (prevValue) => prevValue.Name === selectedItem
      );
      const selectedItemProc = prevValues.procedures.find(
        (prevValue) => prevValue.Name === selectedItemProc
      );
      const selectedItemDis = prevValues.diseases.find(
        (prevValue) => prevValue.Name === selectedItem
      );
      if (dropName === "specializations" && selectedItemSpec) {
        dispatch(_doctorSelectedSpecDeleteId(selectedItemSpec.ID));
      }
      if (dropName === "specializations") {
        for (let i = 0; i < selectedInfoId.specializations.length; i++) {
          if (selectedInfoId.specializations[i] === item.id) {
            dispatch(_doctorUnSelectedSpecId(item.id));
          }
        }
      }
      if (dropName === "procedures" && selectedItemProc) {
        dispatch(_doctorSelectedProcDeleteId(selectedItemProc.ID));
      }
      if (dropName === "procedures") {
        for (let i = 0; i < selectedInfoId?.procedures.length; i++) {
          if (selectedInfoId?.procedures[i] === item.id) {
            dispatch(_doctorUnSelectedProcId(item.id));
          }
        }
      }

      if (dropName === "diseases" && selectedItemDis) {
        dispatch(_doctorSelectedDisDeleteId(selectedItemDis.ID));
      }
      if (dropName === "diseases") {
        for (let i = 0; i < selectedInfoId.diseases.length; i++) {
          if (selectedInfoId.diseases[i] === item.id) {
            dispatch(_doctorUnSelectedDisId(item.id));
          }
        }
      }
    }
  };

// export const checkDoctorSelectedDeleteIdSubmit=(): ThunkAction<void, RootState, unknown, Action<string>> =>
// async (dispatch, getState)=>{

// }

const addDoctorInfo = async (
  info: string,
  infoId: number[],
  id: number | null,
  token: any
) => {
  const infoName = info + "s";
  for (let i = 0; i < infoId.length; i++) {
    await fetch(
      topDoctorData() + `doctor${infoName}?token=${token}&doctor=${id}`,

      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          doctor_id: id,
          [info]: +infoId[i],
        }),
      }
    );
  }
};

export const postClinicDoctor =
  (
    data: IDoctor_Add,
    id: number | null
  ): ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch, getState) => {
    dispatch(loadingStart(LoadingSubject.ClinicsDoctors));
    try {
      const token = getState().auth.userToken?.auth;
      const selectedInfoId = getState().doctor.doctorInfoId;

      const response = await authFetch(
        getState,
        dispatch,
        topDoctorData() + "clinics/newdoctor",
        "POST",
        data,
        {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        "?token="
      );
      if (response.status === 200) {
        const json = await response.json();

        const doctorId = json.payload.id;
        await addDoctorInfo(
          "specialization",
          selectedInfoId.specializations,
          doctorId,
          token
        );
        await addDoctorInfo(
          "disease",
          selectedInfoId.diseases,
          doctorId,
          token
        );
        await addDoctorInfo(
          "procedure",
          selectedInfoId.procedures,
          doctorId,
          token
        );
        await dispatch(clearState());
        await dispatch(getClinicsDoctorsList());
        // dispatch(_addClinicDoctor(json));
      } else if (response.status === 401) {
        // dispatch(signOut(token));
      }
    } catch (e) {
      dispatch(pushAlert("Unknown error. Please, check internet connection"));
    } finally {
      dispatch(loadingEnd(LoadingSubject.ClinicsDoctors));
    }
  };
// thunk for delete doctor /doctorworks/clinicdelete/

export const profileDeleteDoctor =
  (
    doctors: { [key: string]: IDoctor_Add[] }[]
  ): ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch, getState) => {
    dispatch(loadingStart(LoadingSubject.DeleteProfileValue));
    const token = getState().auth.userToken?.auth;
    const clinicid = getState().auth.userToken?.clientId;
    console.log(clinicid, "clinicid");
    let workId;
    try {
      for (const doctor of doctors) {
        for (let i = 0; i < doctor.works.length; i++) {
          console.log(doctor.works[i]["Clinic ID"], "IWorks");
          if (doctor.works[i]["Clinic ID"] === clinicid) {
            workId = doctor.works[i]["ID"];
          }
          break;
        }
        // console.log(value, "value")
        // console.log(value.id, value.works["Clinic ID"], 'ClinicID')
        const response = await fetch(
          topDoctorData() +
            `doctorworks/clinicdelete?id=${workId}&doctor=${doctor.id}&token=${token}`,
          {
            method: "DELETE",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        );

        if (response.status === 401) {
          dispatch(signOut(getState().auth.userToken!.auth));
          return;
        }
        if (response.status !== 200) {
          console.warn("profileValueDeleteBulk", response);
          dispatch(pushAlert("Error"));
          return;
        }
      }

      // dispatch(_deleteProfileValues(values));
    } catch (e) {
      dispatch(pushAlert("Unknown error. Please, check internet connection"));
    } finally {
      dispatch(loadingEnd(LoadingSubject.DeleteProfileValue));
    }
  };

// /clinics/editdoctor/{token}{id}
export const editDoctorData =
  (
    data: IDoctor_Add,
    id: number | null
  ): ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch, getState) => {
    dispatch(loadingStart(LoadingSubject.EditClinicDoctors));
    const token = getState().auth.userToken?.auth;
    const selectedInfoId = getState().doctor.doctorInfoId;
    const selectedInfoIdDelete = getState().doctor.doctorInfoIdForDelete;

    try {
      const response = await fetch(
        topDoctorData() + `clinics/editdoctor?token=${token}&id=${id}`,
        {
          method: "PUT",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            name: data.name,
            surname: data.surname,
            patronymic: data.patronymic,
            sex: data.sex,
            birthdate: data.birthdate,
            education: data.education,
            scientific_degree: data.scientific_degree,
            training_work_abroad: data.training_work_abroad,
            work_experience: data.work_experience,
            additional_information: data.additional_information,
            language: data.language,
            codeDoctors: data.codeDoctors,
            numberLicense: data.numberLicense,
            works: data.works,
            licenseSeries: data.licenseSeries,
            extensionimage: data.extensionimage,
            uploadfileimage: data.uploadfileimage,
            diplom: data.diplom,
            diploma_series: data.diploma_series,
            diploma_number: data.diploma_number,
            services: data.services,
            prices: data.prices,
          }),
        }
      );

      if (response.status === 200) {
        const json = await response.json();
        const doctorId = json.payload.id;

        // await addDoctorSpecialization(data.specializations, doctorId, token);
        if (selectedInfoId.specializations.length !== 0) {
          await addDoctorInfo(
            "specialization",
            selectedInfoId.specializations,
            doctorId,
            token
          );
        }
        if (selectedInfoId.diseases.length !== 0) {
          await addDoctorInfo(
            "disease",
            selectedInfoId.diseases,
            doctorId,
            token
          );
        }
        if (selectedInfoId.procedures.length !== 0) {
          await addDoctorInfo(
            "procedure",
            selectedInfoId.procedures,
            doctorId,
            token
          );
        }

        await deleteDoctorInfo(
          "specializations",
          doctorId,
          selectedInfoIdDelete.specializations,
          getState,
          dispatch
        );
        await deleteDoctorInfo(
          "diseases",
          doctorId,
          selectedInfoIdDelete.diseases,
          getState,
          dispatch
        );
        await deleteDoctorInfo(
          "procedures",
          doctorId,
          selectedInfoIdDelete.procedures,
          getState,
          dispatch
        );
        await dispatch(clearState());
        await dispatch(getClinicsDoctorsList());

        // console.log(json, "JsonFrom post");
        // dispatch(_addClinicDoctor(json));
      } else {
        console.log("DoctorList error", await response.text());
        dispatch(pushAlert("Unknown error. Please, check internet connection"));
      }
    } catch (error) {
      dispatch(pushAlert("Unknown error. Please, check internet connection"));
    } finally {
      // dispatch(loadingEnd(LoadingSubject.EditClinicDoctors));
    }
  };

export const deleteDoctorInfo = async (
  dropName: string,
  doctorId: number | undefined,
  infoId: number[],
  getState,
  dispatch
) => {
  const token = getState().auth.userToken?.auth;

  try {
    for (let info = 0; info < infoId.length; info++) {
      const response = await fetch(
        // /doctorspecializations/clinicdelete/{id}{token}{doctor}
        topDoctorData() +
          `doctor${dropName}/clinicdelete?token=${token}&doctor=${doctorId}&id=${infoId[info]}`,
        {
          method: "DELETE",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );
      if (response.status === 200) {
      } else if (response.status === 401) {
        dispatch(signOut(getState().auth.userToken!.auth));
      } else {
        dispatch(pushAlert("Error"));
      }
    }
  } catch (e) {
    dispatch(pushAlert("Unknown error. Please, check internet connection"));
  } finally {
  }
};

// /clinics/userdoctor/{token}{id}
export const createDoctorAccount =
  (
    data: IClinicDoctorSignUp,
    id
  ): ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch, getState) => {
    dispatch(loadingStart(LoadingSubject.CreateDoctorAccount));
    const token = getState().auth.userToken?.auth;
    console.log("createDoctorCompleted");
    try {
      const response = await fetch(
        topDoctorData() + `clinics/userdoctor?token=${token}&id=${id}`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            phone: data.phone,
            username: data.username,
            email: data.email,
            password: data.password,
          }),
        }
      );

      if (response.status === 200) {
        const json = await response.json();
        dispatch(unDraftDoctor(id));
        dispatch(getClinicsDoctorsList());
        // dispatch(_addClinicDoctor(json));
      } else {
        console.log("DoctorList error", await response.text());
        dispatch(pushAlert("Unknown error. Please, check internet connection"));
      }
    } finally {
      dispatch(loadingEnd(LoadingSubject.CreateDoctorAccount));
    }
  };

export const unDraftDoctor =
  (id: number | null): ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch, getState) => {
    dispatch(loadingStart(LoadingSubject.EditClinicDoctors));
    const token = getState().auth.userToken?.auth;
    const status = 1;
    try {
      const response = await fetch(
        topDoctorData() + `clinics/draftdoctor?token=${token}&id=${id}`,
        {
          method: "PUT",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            status,
          }),
        }
      );

      if (response.status === 200) {
        const json = await response.json();
        // dispatch(_addClinicDoctor(json));
      } else {
        console.log("DoctorList error", await response.text());
        dispatch(pushAlert("Unknown error. Please, check internet connection"));
      }
    } catch (error) {
      dispatch(pushAlert("Unknown error. Please, check internet connection"));
    } finally {
      dispatch(loadingEnd(LoadingSubject.EditClinicDoctors));
    }
  };
export const getDoctorList =
  (
    filter: IDoctorFilter | undefined,
    page = 0
  ): ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch, getState) => {
    dispatch(
      loadingStart(
        page === 0
          ? LoadingSubject.DoctorList
          : LoadingSubject.DoctorListNextPage
      )
    );

    try {
      console.log(
        "external/private/doctor/list" +
          `?limit=${DOCTORS_PAGE_SIZE}&offset=${DOCTORS_PAGE_SIZE * page}`,
        filter
      );

      const access = !!getState().auth.userToken ? "private" : "public";

      const response = await authFetch(
        getState,
        dispatch,
        environment.baseUrl +
          "external/" +
          access +
          "/doctor/list" +
          `?limit=${page < 0 ? 10000 : DOCTORS_PAGE_SIZE}&offset=${
            page < 0 ? 0 : DOCTORS_PAGE_SIZE * page
          }`,
        "POST",
        filter || {},
        { Accept: "application/json", "Content-Type": "application/json" }
      );

      console.log("response", response);

      if (response.status === 200) {
        const json = await response.json();
        console.log("json", json);
        dispatch(_doctorList(json, page, filter?.myDoctors));
        page >= 0 && dispatch(getFullDoctorsCount(filter));
        // dispatch(_search(json, page));
      } else if (response.status === 401) {
        // dispatch(signOut(token));
      } else {
        console.log("DoctorList error", await response.text());
        dispatch(pushAlert("Unknown error. Please, check internet connection"));
      }
    } catch (e) {
      console.log("DoctorList error", e);

      dispatch(pushAlert("Unknown error. Please, check internet connection"));
    } finally {
      dispatch(
        loadingEnd(
          page === 0
            ? LoadingSubject.DoctorList
            : LoadingSubject.DoctorListNextPage
        )
      );
    }
  };

export const getFullDoctorList =
  (clinicId: number): ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch, getState) => {
    dispatch(_doctorFullList([]));
    const access = !!getState().auth.userToken ? "private" : "public";
    console.log(access);

    try {
      const response = await authFetch(
        getState,
        dispatch,
        environment.baseUrl + "external/" + access + "/doctor/list",
        "POST",
        {
          Accept: "application/json",
          "Content-Type": "application/json",
          clinic: clinicId,
        }
      );

      console.log("response", response);

      if (response.status === 200) {
        const json = await response.json();
        console.log("json", json);
        return json;
      } else {
        console.log("DoctorList error", await response.text());
        dispatch(pushAlert("Unknown error. Please, check internet connection"));
      }
    } catch (e) {
      console.log("DoctorList error", e);

      dispatch(pushAlert("Unknown error. Please, check internet connection"));
    } finally {
    }
  };

export const getDoctorListNoRedux =
  (
    filter: IDoctorFilter | undefined,
    page = 0,
    pageSize: number
  ): ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch, getState) => {
    try {
      const access = !!getState().auth.userToken ? "private" : "public";
      const response = await authFetch(
        getState,
        dispatch,
        environment.baseUrl +
          "external/" +
          access +
          "/doctor/list" +
          (pageSize ? `?limit=${pageSize}&offset=${pageSize * page}` : ""),
        "POST",
        filter || {},
        { Accept: "application/json", "Content-Type": "application/json" }
      );

      console.log("response", response);

      if (response.status === 200) {
        const json = await response.json();
        console.log("json", json);
        return json;
      }
    } catch (e) {
      console.log("DoctorList error", e);
    } finally {
    }
  };

export const getDoctorAutocomplete =
  (
    filter: IDoctorFilter | undefined
  ): ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch, getState) => {
    dispatch(_doctorsAutocomplete([]));

    const access = !!getState().auth.userToken ? "private" : "public";
    console.log("auto filter", filter);
    try {
      const response = await authFetch(
        getState,
        dispatch,
        environment.baseUrl +
          "external/" +
          access +
          "/doctor/list" +
          `?limit=20&offset=0`,
        "POST",
        filter || {},
        { Accept: "application/json", "Content-Type": "application/json" }
      );

      console.log("response", response);

      if (response.status === 200) {
        const json = await response.json();
        console.log("json", json);
        dispatch(_doctorsAutocomplete(json));
      } else if (response.status === 401) {
        //dispatch(signOut(token));
      } else {
        console.log("DoctorList error", await response.text());
        dispatch(pushAlert("Unknown error. Please, check internet connection"));
      }
    } catch (e) {
      console.log("DoctorList error", e);

      dispatch(pushAlert("Unknown error. Please, check internet connection"));
    } finally {
    }
  };

export const getFullDoctorsCount =
  (
    filter: IDoctorFilter | undefined
  ): ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch, getState) => {
    const access = !!getState().auth.userToken ? "private" : "public";

    try {
      const response = await authFetch(
        getState,
        dispatch,
        environment.baseUrl + "external/" + access + "/doctor/count",
        "POST",
        filter || {},
        { Accept: "application/json", "Content-Type": "application/json" }
      );

      console.log("response", response);

      if (response.status === 200) {
        const json = await response.json();
        console.log("json", json);
        dispatch(_doctorListCount(json));
      } else if (response.status === 401) {
        //dispatch(signOut(token));
      } else {
        console.log("DoctorList error", await response.text());
        dispatch(pushAlert("Unknown error. Please, check internet connection"));
      }
    } catch (e) {
      dispatch(pushAlert("Unknown error. Please, check internet connection"));
    } finally {
      // dispatch(loadingEnd(page === 0 ? LoadingSubject.DoctorList : LoadingSubject.DoctorListNextPage));
    }
  };

export const getDoctor =
  (id: number): ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch, getState) => {
    // dispatch(loadingStart(LoadingSubject.Doctor));

    try {
      const response = await fetch(topDoctorData() + "doctors/" + id, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });

      console.log("response", response);

      const json = await response.json();
      console.log("json", json);
      if (response.status === 200) {
        dispatch(_doctorCachedDetails(json));
      } else if (response.status === 401) {
        //dispatch(signOut(token));
      } else {
        dispatch(errorDoctor(json.code));
      }
    } catch (e) {
      dispatch(pushAlert("Unknown error. Please, check internet connection"));
    } finally {
      // dispatch(loadingEnd(LoadingSubject.Doctor));
    }
  };

export const getDoctorRating =
  (id: number): ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch, getState) => {
    dispatch(loadingStart(LoadingSubject.DoctorRating));

    try {
      const response = await fetch(topDoctorData() + "doctorratings?id=" + id, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });

      console.log("response", response);

      const json = await response.json();
      console.log("json", json);
      if (response.status === 200) {
        dispatch(_doctorRating(json));
      } else if (response.status === 401) {
        //dispatch(signOut(token));
      } else {
        dispatch(errorDoctor(json.code));
      }
    } catch (e) {
      dispatch(pushAlert("Unknown error. Please, check internet connection"));
    } finally {
      dispatch(loadingEnd(LoadingSubject.DoctorRating));
    }
  };

export const askDutyPrice =
  (
    filter: IDoctorPriceFilter,
    t: TFunction
  ): ThunkAction<Promise<boolean>, RootState, unknown, Action<string>> =>
  async (dispatch, getState) => {
    dispatch(loadingStart(LoadingSubject.InitiateCall));
    try {
      const response = await authFetch(
        getState,
        dispatch,
        environment.baseUrl + "external/public/doctor/price",
        "POST",
        filter || {}
      );

      console.log("response", response);

      if (response.status === 200) {
        const json = await response.json();
        const price = json as IDoctorPrice[];

        return await showConfirm(
          t("Are you sure you want call the duty doctor?"),
          {
            title: price.length
              ? t("Appointment price") + ": " + price[0].duty + " AZN"
              : "",
          }
        );
      } else if (response.status === 401) {
        dispatch(signOut(getState().auth.userToken!.auth));
      } else {
        console.log("DoctorList error", await response.text());
        dispatch(pushAlert("Unknown error. Please, check internet connection"));
      }
    } catch (e) {
      console.log("DoctorList error", e);
      dispatch(pushAlert("Unknown error. Please, check internet connection"));
    } finally {
      dispatch(loadingEnd(LoadingSubject.InitiateCall));
    }

    return false;
  };

export const getDoctorPrice =
  (
    filter: IDoctorPriceFilter
  ): ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch, getState) => {
    dispatch(loadingStart(LoadingSubject.DoctorPrice));

    try {
      const response = await authFetch(
        getState,
        dispatch,
        environment.baseUrl + "external/public/doctor/price",
        "POST",
        filter || {}
      );

      console.log("response", response);

      if (response.status === 200) {
        const json = await response.json();
        dispatch(_doctorPrice(json));
      } else if (response.status === 401) {
        dispatch(signOut(getState().auth.userToken!.auth));
      } else {
        console.log("DoctorList error", await response.text());
        dispatch(pushAlert("Unknown error. Please, check internet connection"));
      }
    } catch (e) {
      console.log("DoctorList error", e);

      dispatch(pushAlert("Unknown error. Please, check internet connection"));
    } finally {
      dispatch(loadingEnd(LoadingSubject.DoctorPrice));
    }
  };

export const getDoctorPriceNoRedux =
  (
    filter: IDoctorPriceFilter
  ): ThunkAction<any, RootState, unknown, Action<string>> =>
  async (dispatch, getState) => {
    dispatch(loadingStart(LoadingSubject.DoctorPrice));

    try {
      const response = await authFetch(
        getState,
        dispatch,
        environment.baseUrl + "external/public/doctor/price",
        "POST",
        filter || {}
      );

      console.log("response", response);
      if (response.status === 200) {
        const json = await response.json();
        return json;
      }
    } catch (e) {
      console.log("DoctorList error", e);

      dispatch(pushAlert("Unknown error. Please, check internet connection"));
    } finally {
      dispatch(loadingEnd(LoadingSubject.DoctorPrice));
    }
  };

export const errorDoctorList =
  (code: number): ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch, getState) => {
    //console.warn('code', code);
    dispatch(pushAlert("Error"));
  };
export const errorDoctor =
  (code: number): ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch, getState) => {
    //console.warn('code', code);
    dispatch(pushAlert("Error"));
  };
