import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, InjectedFormProps, reduxForm, formValueSelector, FormErrors } from 'redux-form';
import { IDictionaryState } from '../states/dictionary-state';
import { IAuthState } from '../states/auth-state';
import { RootState } from '../store';
import TextInput from './FormComponents/TextInput';
import { IPatientClinicState } from '../states/patient-clinic-state';
import { IPatientDoctorState } from '../states/patient-doctor-state';
// import { IFile } from '../models/file';
import { IClinic } from '../models/clinic';
import { IDoctorFilter } from '../models/doctor-filter';
import { IPatientDoctor } from '../models/patient-doctor';
import { getPatientMyDoctorList, PATIENT_MY_DOCTOR_LIST, _getPatientDoctorList } from './../actions/patient-doctor-action';
import { IPatientSurveyState } from '../states/patient-survey-state';
import { IPatientSurvey } from "../models/user-surveys";
import { getPatientMyClinicList } from '../actions/patient-clinic-action';
import DropDownSelect from './FormComponents/DropDownSelect';
import UserFilesAddComponent from './FormComponents/UserFilesAdd';
import { IUpload } from '../models/upload';
import { IFile } from '../models/file';
import { TFunction } from 'i18next';
import { withTranslation } from 'react-i18next';
import MiniDropDownSelect from './FormComponents/MiniDropDownSelect';
import MiniTextInput from './FormComponents/MiniTextInput';

type Props = {
    t: TFunction
    type: number;
    specialization?: number;
    procedures?: string;
    clinic_id?: number;
    doctor_id?: number;
    dictionary: IDictionaryState;
    auth: IAuthState;
    patientClinic: IPatientClinicState;
    patientDoctor: IPatientDoctorState;
    patientSurvey: IPatientSurveyState;
    onFilesChange: (addedFiles: IUpload[], deletedFiles: IFile[]) => void;

    getPatientMyClinicList: (
        filter: IClinic | undefined
    ) => void;
    getPatientMyDoctorList: (
        filter: IDoctorFilter | undefined
    ) => void;
    _getPatientDoctorList: (patientDoctorList: IPatientDoctor[]) => {
        type: typeof PATIENT_MY_DOCTOR_LIST,
        patientDoctorList: IPatientDoctor[];
    };
}

class UserSurveysForm extends React.Component<InjectedFormProps<IPatientSurvey> & Props> {
    constructor(props: InjectedFormProps<IPatientSurvey> & Props) {
        super(props);
    }

    componentWillMount() {
        this.props.getPatientMyClinicList(undefined);
        this.props.getPatientMyDoctorList(undefined);

        if (this.props.patientSurvey.selected) {
            this.props.initialize(this.props.patientSurvey.selected);
        }
    }

    render() {
        const { t } = this.props;
        return (
            <div className='auth-form auth-form-flex'>
                <div className='auth-form__row'>
                    <div className='auth-form__col'>
                        <Field name="clinic_id"
                            label={t('Clinic')}
                            component={MiniDropDownSelect}
                            selectedValue={this.props.clinic_id}
                            valuesForDropdown={this.props.patientClinic.patientClinicList?.map(d => ({ value: d.clinic?.id, label: d.clinic?.name })) || []}
                        />
                    </div>
                    <div className='auth-form__col'>
                        <Field name="date" type='date' placeholder={t('Date')} component={MiniTextInput}/>
                    </div>
                </div>
                <div className='auth-form__row'>
                    <div className='auth-form__col'>
                        <Field name="doctor_id"
                            label={t('Doctor')}
                            component={MiniDropDownSelect}
                            selectedValue={this.props.doctor_id}
                            valuesForDropdown={this.props.patientDoctor.patientDoctorList?.map(d => ({ value: d.doctor?.id, label: d.doctor?.surname + ' ' + d.doctor?.name + ' ' + d.doctor?.patronymic })) || []}
                        />
                    </div>
                    <div className='auth-form__col'>
                        <UserFilesAddComponent
                            type={'examinations/'}
                            style={{ width: '70%', border: '1px solid #767676' }}
                            uploadedFiles={this.props?.patientSurvey?.selected?.uploadedfiles}
                            onChange={(a, b) => this.props.onFilesChange && this.props.onFilesChange(a, b)}
                        /> 
                    </div>
                </div>
                <div className='auth-form__row'>
                    <div className='auth-form__col'>
                        <Field name="specialization"
                            label={t('Specialization')}
                            component={MiniDropDownSelect}
                            selectedValue={this.props.specialization}
                            valuesForDropdown={this.props.dictionary.specializationTypes?.map(d => ({ value: d.id, label: d.name }))}
                        />
                    </div>
                    <div className='auth-form__col'>
                        <Field name="name" placeholder={t('Name')} component={MiniTextInput}/>
                    </div>
                </div>
                <div className='auth-form__row'>
                    <div className='auth-form__col'>
                        <Field name="procedures"
                            label={t('Procedures')}
                            type={'multiselect'}
                            component={MiniDropDownSelect}
                            selectedValue={this.props.procedures}
                            valuesForDropdown={this.props.dictionary.procedures?.map(d => ({ value: d.id, label: d.name }))}
                        />
                    </div>
                    <div className='auth-form__col'>
                        <Field name="conclusion" placeholder={t('Conclusion and recommendations')} component={MiniTextInput}/>
                    </div>
                </div>
            </div>
        );
    }
}

const validate = (values: IPatientSurvey, props: {t: TFunction}): FormErrors<IPatientSurvey> => {
    const errors: FormErrors<IPatientSurvey> = {};
    const { t } = props;

    if (!values.name) {
        errors.name = t('Field couldn\'t be empty');
    }
    if (!values.date) {
        errors.date = t('Field couldn\'t be empty');
    }
    return errors;
};

const form = reduxForm<IPatientSurvey, any>({
    form: 'UserSurveysForm',
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
    validate
})(UserSurveysForm);

const selector = formValueSelector('UserSurveysForm');

const mapStateToProps = (state: RootState) => {
    const { type, specialization, procedures, clinic_id, doctor_id } = selector(state, 'type', 'specialization', 'procedures', 'clinic_id', 'doctor_id') || {};

    return {
        dictionary: state.dictionary,
        patientClinic: state.patientClinic,
        patientDoctor: state.patientDoctor,
        patientSurvey: state.patientSurvey,
        auth: state.auth,
        // file: state.file,
        type,
        specialization,
        procedures,
        clinic_id,
        doctor_id,
    }
};

const mapDispatchToProps = {
    getPatientMyClinicList,
    getPatientMyDoctorList,
    _getPatientDoctorList,
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(form));