import { ActionCreator, Action } from "redux";
import { LoadingSubject } from "../states/loading-state";

export const LOADING_START = 'LOADING_START';
interface LoadingStartAction {
    type: typeof LOADING_START;
    subject: LoadingSubject;
}

export const loadingStart: ActionCreator<LoadingStartAction> = (subject: LoadingSubject) => {
    return {
        type: LOADING_START,
        subject
    };
};

export const LOADING_END = 'LOADING_END';
interface LoadingEndAction {
    type: typeof LOADING_END;
    subject: LoadingSubject;
}

export const loadingEnd: ActionCreator<LoadingEndAction> = (subject: LoadingSubject) => {
    return {
        type: LOADING_END,
        subject
    };
};

export type LoadingActionTypes = LoadingStartAction | LoadingEndAction
