import { IDoctorState } from "../states/doctor-state";
import { SAVE_FILES, DELETE_FILE, SAVE_UPLOADED_FILES, DELETE_UP_FILE, FilesActionTypes } from "../actions/file-action";
import { IFileState } from "../states/file-state";

const initialState: IFileState = {
    files: undefined,
    uploadedFiles: undefined,
    id: 0,
    separate: {}
}

export function fileReducer(
    state = initialState,
    action: FilesActionTypes
): IFileState {
    switch (action.type) {
        case SAVE_FILES:
            if (action.stype) {
                return { ...state, separate: {...state.separate, [action.stype]: {...state.separate[action.stype], files: action.files }} }
            }
            return { ...state, ...{ files: action.files } };
        case DELETE_FILE:
            if (action.stype) {
                return { ...state, separate: {...state.separate, [action.stype]: {...state.separate[action.stype], files: state.files?.filter(file => {
                    return file.ID != action.id;
                }) }} }
            }
            return {
                ...state, files: state.files?.filter(file => {
                    return file.ID != action.id;
                })
            };
        case SAVE_UPLOADED_FILES:
            if (action.stype) {
                return { ...state, separate: {...state.separate, [action.stype]: {...state.separate[action.stype], uploadedFiles: action.uploadedFiles }} }
            }
            return { ...state, ...{ uploadedFiles: action.uploadedFiles } };
        case DELETE_UP_FILE:
            if (action.stype) {
                return { ...state, separate: {...state.separate, [action.stype]: {...state.separate[action.stype], uploadedFiles: state.uploadedFiles?.filter(file => {
                    return file.ID != action.id;
                })}} }
            }
            return {
                ...state, uploadedFiles: state.uploadedFiles?.filter(file => {
                    return file.ID != action.id;
                })
            };
        default:
            return state;
    }
}