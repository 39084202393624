import { ActionCreator, Action } from "redux";
import { ThunkAction } from "redux-thunk";
import { loadingStart, loadingEnd } from "./loading-actions";
import { LoadingSubject } from "../states/loading-state";
import i18n from 'i18next';
import * as environment from '../app.json';
import { pushAlert } from "./alert-actions";
import { authFetch } from "./auth-actions";
import { IPatientVaccination } from "../models/user-vaccination";
import { IFile } from "../models/file";
import { updateFiles } from "./file-action";
import { RootState } from "../store";
import { IUpload } from "../models/upload";

function topDoctorData() {
    let topDoctor = environment.topDoctor;
    if (i18n.language === 'az') topDoctor = environment.topDoctorAz;
    if (i18n.language === 'ru') topDoctor = environment.topDoctorRu;
    return topDoctor;
}

export const PATIENT_VACCINATION_LIST = 'PATIENT_VACCINATION_LIST';
interface PatientVaccinationListAction {
    type: typeof PATIENT_VACCINATION_LIST;
    vaccinations: IPatientVaccination[];
}

export const _patientVaccinationList: ActionCreator<PatientVaccinationListAction> = (vaccinations: IPatientVaccination[]) => {
    return {
        type: PATIENT_VACCINATION_LIST,
        vaccinations
    };
};

export const PATIENT_VACCINATION_ITEM = 'PATIENT_VACCINATION_ITEM';
interface PatientVaccinationItemAction {
    type: typeof PATIENT_VACCINATION_ITEM;
    vaccination: IPatientVaccination | null;
}

export const _patientVaccinationItem: ActionCreator<PatientVaccinationItemAction> = (vaccination: IPatientVaccination | null) => {
    return {
        type: PATIENT_VACCINATION_ITEM,
        vaccination
    };
};

export type PatientVaccinationActionTypes = PatientVaccinationListAction | PatientVaccinationItemAction;

export const getPatientVaccinationList = (
): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch, getState) => {
    dispatch(loadingStart(LoadingSubject.PatientVaccinationList));

    try {
        const response = await authFetch(
            getState, dispatch, topDoctorData() + '/patientinoculations?per-page=1000', 'GET',
            null, {Accept: 'application/json', 'Content-Type': 'application/json'}, '&token='
        );

        console.log('response', response);

        if (response.status === 200) {
            const json = await response.json();
            console.log('json', json);
            dispatch(_patientVaccinationList(json));
        }
        else if (response.status === 401) {
            //dispatch(signOut(token));
        }
        else {
            dispatch(pushAlert('Unknown error. Please, check internet connection'));
            //dispatch(errorPatientDoctorList(json.code, token));
        }
    } catch (e) {
        dispatch(pushAlert('Unknown error. Please, check internet connection'));
    } finally {
        dispatch(loadingEnd(LoadingSubject.PatientVaccinationList));
    }
}

export const getPatientVaccination = (
    id: number,
): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch, getState) => {
    dispatch(loadingStart(LoadingSubject.PatientVaccination));

    try {
        const response = await authFetch(
            getState, dispatch, topDoctorData() + '/patientinoculations/' + id, 'GET',
            null, {Accept: 'application/json', 'Content-Type': 'application/json'}, '?token='
        );

        console.log('response', response);

        if (response.status === 200) {
            const json = await response.json();
            console.log('json', json);
            dispatch(_patientVaccinationItem(json));
        }
        else if (response.status === 401) {
            //dispatch(signOut(token));
        }
        else {
            dispatch(pushAlert('Unknown error. Please, check internet connection'));
            //dispatch(errorPatientDoctorList(json.code, token));
        }
    } catch (e) {
        dispatch(pushAlert('Unknown error. Please, check internet connection'));
    } finally {
        dispatch(loadingEnd(LoadingSubject.PatientVaccination));
    }
}

export const createPatientVaccination = (
    vaccination: IPatientVaccination,
    uploadFiles: IUpload[], deleleFiles: IFile[],
    host_type: number,
    success: () => void,
): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch, getState) => {
    dispatch(loadingStart(LoadingSubject.PatientVaccination));
    console.warn('vaccination', vaccination);
    try {
        const response = await authFetch(
            getState, dispatch, topDoctorData() + '/patientinoculations', 'POST',
            vaccination, {Accept: 'application/json', 'Content-Type': 'application/json'}, '?token='
        );

        console.log('response', response);

        if (response.status === 200) {
            const json = await response.json();
            console.log('json', json);
            const id = json.payload.id;
            await updateFiles(getState, dispatch, uploadFiles, deleleFiles, id, host_type);
            dispatch(getPatientVaccinationList());
            success && success();
        }
        else if (response.status === 401) {
            //dispatch(signOut(token));
        }
        else {
            dispatch(pushAlert('Unknown error. Please, check internet connection'));
            //dispatch(errorPatientDoctorList(json.code, token));
        }
    } catch (e) {
        dispatch(pushAlert('Unknown error. Please, check internet connection'));
    } finally {
        dispatch(loadingEnd(LoadingSubject.PatientVaccination));
    }
}


export const deletePatientVaccination = (
    id: number,
): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch, getState) => {
    dispatch(loadingStart(LoadingSubject.PatientVaccination));

    try {
        const response = await authFetch(
            getState, dispatch, topDoctorData() + '/patientinoculations/' + id, 'DELETE',
            null, {Accept: 'application/json', 'Content-Type': 'application/json'}, '?token='
        );

        console.log('response', response);

        if (response.status === 200) {
            const json = await response.json();
            console.log('json', json);
            dispatch(getPatientVaccinationList());
        }
        else if (response.status === 401) {
            //dispatch(signOut(token));
        }
        else {
            dispatch(pushAlert('Unknown error. Please, check internet connection'));
            //dispatch(errorPatientDoctorList(json.code, token));
        }
    } catch (e) {
        dispatch(pushAlert('Unknown error. Please, check internet connection'));
    } finally {
        dispatch(loadingEnd(LoadingSubject.PatientVaccination));
    }
}


export const updatePatientVaccination = (
    vaccination: IPatientVaccination,
    uploadFiles: IUpload[], deleleFiles: IFile[],
    host_type: number,
    success: () => void,
): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch, getState) => {
    dispatch(loadingStart(LoadingSubject.PatientVaccination));

    try {
        const response = await authFetch(
            getState, dispatch, topDoctorData() + '/patientinoculations/' + vaccination.id, 'PATCH',
            vaccination, {Accept: 'application/json', 'Content-Type': 'application/json'}, '?token='
        );

        console.log('response', response);

        if (response.status === 200) {
            const json = await response.json();
            console.log('json', json);
            await updateFiles(getState, dispatch, uploadFiles, deleleFiles, vaccination.id, host_type);
            dispatch(getPatientVaccinationList());
            success && success();
        }
        else if (response.status === 401) {
            //dispatch(signOut(token));
        }
        else {
            dispatch(pushAlert('Unknown error. Please, check internet connection'));
            //dispatch(errorPatientDoctorList(json.code, token));
        }
    } catch (e) {
        dispatch(pushAlert('Unknown error. Please, check internet connection'));
    } finally {
        dispatch(loadingEnd(LoadingSubject.PatientVaccination));
    }
}