export enum DictionaryTypesEnum {
  City = 1,
  District,
  ClinicType,
  Underground,
  ContactType,
  PaymentMethods,
  SpecializationTypes,
  Diseases,
  Services,
  InsuranceCompanies,
  SocialNetworks,
  SharesTypes,
  AdsTypes,
  ConditionsTypes,
  Languages,
  BloodTypes,
  ICD10,
  Analyzes,
  CommunicationTypes,
  Procedures,
  AcademicDegree,
  ArticleCategories,
  MedicationIntake,
  Clinics,
  Work,
  Doctors = 60,
}
