export interface IIndicator {
    hasHour: boolean;
    chartType: ChartType;
    code: number;
    created: string;
    description: string;
    icon: string;
    id: number;
    lang: string;
    name: string;
    updated: string;
}

export enum ChartType
{
    Line = 0, Candle = 1
}