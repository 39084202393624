import {DELELTE_INDICATORS, IndicatorsActionsTypes, INDICATORS_INFO, INDICATOR_CHART_VALUES, INDICATOR_VALUES,} from "../actions/indicator-actions";
import { IIndicatorValue } from "../models/indicator-value";
import {IIndicatorsState} from "../states/indicators-state";
import * as uuid from 'uuid';

const initialState: IIndicatorsState = {
    chartValues: {},
    chartAllValues: [],
    allValues: [],
    allValuesHasMore: true,
    allValuesPage: 0,

    chartKey: uuid.v4(),
}

export function indicatorsReducer(
    state = initialState,
    action: IndicatorsActionsTypes
): IIndicatorsState {
    let allValues:{[key: number]: IIndicatorValue}[];
    let grp:any;
    switch (action.type) {
        case INDICATORS_INFO:
            return {...state, ...{indicatorsInfo: action.indicatorsInfo}};
        case INDICATOR_CHART_VALUES:
            const chartValues:{[key: number]: IIndicatorValue[]} = {};
            for (let i = action.values.length - 1; i >= 0; i--) {
                const value = action.values[i];
                if (!chartValues[value.indicatorLineCode]) {
                    chartValues[value.indicatorLineCode] = [];
                }
                chartValues[value.indicatorLineCode].push(value);
            }
            allValues = [];
            for (const value of action.values) {
                if (grp?.dateTime !== value.dateTime) {
                    grp = {dateTime: value.dateTime};
                    allValues.push(grp);
                }
                grp[value.indicatorLineCode] = value;
            }
            return {...state, chartValues, chartAllValues: allValues, filter: action.filter, chartKey: uuid.v4()};
        case INDICATOR_VALUES:
            allValues = action.page === 1 ? [] : [...state.allValues];
            for (const value of action.values) {
                if (grp?.dateTime !== value.dateTime) {
                    grp = {dateTime: value.dateTime};
                    allValues.push(grp);
                }
                grp[value.indicatorLineCode] = value;
            }
            return {...state, allValues, allValuesPage: action.page, allValuesHasMore: action.hasMore};
        case DELELTE_INDICATORS:
            allValues = state.allValues.filter(v => !Object.values(v).find(k => action.values.find(j => j.id === k.id)));
            return {...state, allValues};
        default:
            return state;
    }
}
