import {ActionCreator} from "redux";

export const PUSH_ALERT = 'PUSH_ALERT';
interface PushAlertAction {
  type: typeof PUSH_ALERT;
  text: string;
}

export const pushAlert: ActionCreator<PushAlertAction> = (
  text: string,
) => {
    return {
        type: PUSH_ALERT,
        text,
    };
};

export const POP_ALERT = 'POP_ALERT';
interface PopAlertAction {
  type: typeof POP_ALERT;
}

export const popAlert: ActionCreator<PopAlertAction> = () => {
  return {
    type: POP_ALERT
  }
}

export const CONFIRM_ALERT = 'CONFIRM_ALERT'
interface ConfirmAlertAction {
  type: typeof CONFIRM_ALERT;
}

export const confirmAlert: ActionCreator<ConfirmAlertAction> = (confirmation: string, options: {
  okLabel?: string;
  cancelLabel?: string;
  title?: string;
  enableEscape?: boolean;
}) => {
  return {
    type: CONFIRM_ALERT,
    payload: {
      confirmation,
      options
    }
  }
}

export type AlertActionTypes = PopAlertAction | PushAlertAction | ConfirmAlertAction;
