import React from 'react';
import { connect } from 'react-redux';
import { Field, InjectedFormProps, reduxForm, FormErrors, formValueSelector } from 'redux-form';
import TextInput from './FormComponents/TextInput';
import { IProfile } from "../models/profile";
import { RootState } from "../store";
import DropDownSelect from './FormComponents/DropDownSelect';
import { BloodTypeEnum } from "../models/blood-type.enum";
import './PersonalCabinetDoctorForm.scss';
import { WithTranslation, withTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import ImageInput from './FormComponents/ImageInput';
import { IFile } from '../models/file';
import * as environment from '../app.json';

interface PersonalCabinetDoctorFormProps extends InjectedFormProps<IProfile> {
    editMode?: boolean;
    files: IFile[];
    uploadfileimage: any;
}

class PersonalCabinetDoctorForm extends React.Component<PersonalCabinetDoctorFormProps & WithTranslation> {
    render() {
        const { editMode, t } = this.props;
        const valuesForSexDropdown = [{ value: 0, label: t('Male') }, { value: 2, label: t('Male') },
        { value: 1, label: t('Female') }];

        const valuesForBloodTypeDropdown = [{ value: BloodTypeEnum.IPlus, label: 'O(I) Rh+' },
        { value: BloodTypeEnum.IMinus, label: 'O(I) Rh-' },
        { value: BloodTypeEnum.IIPlus, label: 'A(II) Rh+' },
        { value: BloodTypeEnum.IIMinus, label: 'A(II) Rh-' },
        { value: BloodTypeEnum.IIIPlus, label: 'B(III) Rh+' },
        { value: BloodTypeEnum.IIIMinus, label: 'B(III) Rh-' },
        { value: BloodTypeEnum.IVPlus, label: 'AB(IV) Rh+' },
        { value: BloodTypeEnum.IVMinus, label: 'AB(IV) Rh-' },];
        let textInputClassName = 'personal-cabinet-patient-styles_text-input';
        let selectInputClassName = 'personal-cabinet-patient-styles_text-dropdown';
        if (editMode) {
            textInputClassName += ' edit-mode';
            selectInputClassName += ' edit-mode';
        }

        console.log('this.props', this.props);

        return (
            <div className='personal-cabinet-doctor-form-wrapper'>
                <div className='personal-cabinet-doctor-form_main'>
                    <div className='_block0'>
                        <Field
                            name="imageFile"
                            type={'doctors'}
                            readOnly={!editMode}
                            component={ImageInput}
                        />
                    </div>
                    <div className='_block1'>
                        <div className='one_block_row'>
                            <Field
                                _className={textInputClassName}
                                name="name"
                                type="label"
                                placeholder={t('Name')}
                                component={TextInput}
                            />

                            <Field
                                _className={textInputClassName}
                                name="patronymic"
                                type="label"
                                placeholder={t('Patronymic')}
                                component={TextInput}
                            />
                        </div>
                        <div className='one_block_row'>
                            <Field
                                _className={textInputClassName}
                                name="surname"
                                type="label"
                                placeholder={t('Surname')}
                                component={TextInput}
                            />
                            <Field
                                _className={textInputClassName}
                                name="birthdate"
                                type="label"
                                //type='date'
                                placeholder={t('Birthdate')}
                                component={TextInput}
                            />
                        </div>
                        <div className='one_block_row'>
                            <Field
                                _className={selectInputClassName}
                                name="sex"
                                type="label"
                                label={t('Gender')}
                                component={DropDownSelect}
                                valuesForDropdown={valuesForSexDropdown}
                                style={{
                                    width: '100%',
                                    height: '31px',
                                    outline: 'none',
                                    //paddingLeft: '8px',
                                    marginTop: '-7px'
                                }}
                            />
                            <Field
                                _className={textInputClassName}
                                name="work_experience"
                                type="label"
                                placeholder={t('Experience')}
                                component={TextInput}
                            />
                        </div>
                        <Field
                            _className={textInputClassName}
                            name="language"
                            type="label"
                            placeholder={t('Knowledge of languages')}
                            component={TextInput}
                        />
                        <Field
                            _className={textInputClassName}
                            type="label"
                            name="education"
                            placeholder={t('Education')}
                            component={TextInput}
                        />
                        <Field
                            _className={textInputClassName}
                            name="scientific_degree"
                            type="label"
                            placeholder={t('Scientific degree')}
                            component={TextInput}
                        />
                        <Field
                            _className={textInputClassName}
                            name="training_work_abroad"
                            type="label"
                            placeholder={t('Training/work abroad')}
                            component={TextInput}
                        />
                        {/* <Field
                            _className={textInputClassName}
                            name="status"
                            type="label"
                            placeholder={t('Status')}
                            component={TextInput}
                        />
                        <Field
                            _className={textInputClassName}
                            name="hiring_time"
                            type="label"
                            placeholder={t('Hiring time')}
                            component={TextInput}
                        /> */}
                    </div>
                </div>
            </div>
        );
    }
}

const selector = formValueSelector('IProfilePersonalCabinetDoctorForm');

const mapStateToProps = (state: RootState) => {
    let { sex, blood_type } = selector(state, 'sex', 'blood_type');
    sex = sex?.toString();

    return {
        initialValues: state.auth.self as IProfile,
        sex,
        blood_type,
    }
}

const validate = (values: IProfile, props: PersonalCabinetDoctorFormProps & { t: TFunction }): FormErrors<IProfile> => {
    const errors: FormErrors<IProfile> = {};
    const { t } = props;
    if (!values.sex && values.sex !== 0) {
        errors.sex = t('Field couldn\'t be empty');
    }
    if (!values.blood_type) {
        errors.blood_type = t('Field couldn\'t be empty');
    }
    return errors;
};

const form = reduxForm<IProfile, any>({
    form: 'PersonalCabinetDoctorForm',
    validate,
    enableReinitialize: true,
})(PersonalCabinetDoctorForm);

export default connect(mapStateToProps)(withTranslation()(form));
