import React from 'react';
import { TFunction } from 'i18next';
import { withTranslation } from 'react-i18next';
import './team.scss';

import like from './../../images/team/like.png'
interface TeamProps {
    t: TFunction
}

interface TeamState {

}

class Team extends React.Component<TeamProps, TeamState> {
    constructor(props: TeamProps) {
        super(props);
    }

    render() {
        const { t } = this.props;
        return (
            <>
                <div className='reviews-block' >
                    <p className='big-title team-big-title'>{t('Our team')}</p>
                </div>
                <div className='team'>
                    <div className='team__block'>
                        <div>
                            {t('Our task is to achieve widespread use of information technologies in protecting human health, diagnosing and treating patients, preventing, preventing and controlling the spread of various diseases and epidemics.')}
                        </div>
                        <img src={like} />
                        <div className='team__block__text'>
                            {t('Teymur Gamidov')}
                        </div>
                        <div className='team__block__text'>
                            {t('Farid Israfilzade')}
                        </div>
                        <div className='team__block__text'>
                            {t('Marziya Mammadova')}
                        </div>
                        <div className='team__block__text'>
                            {t('Nabil Seyidov')}
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default withTranslation()(Team);