import React, { Component } from 'react';
import { connect } from "react-redux";
import { RootState } from "../../store";
import './Footer.scss';
import { Link, Redirect, RouteComponentProps, withRouter } from 'react-router-dom';
import { IAuthState } from '../../states/auth-state';
import { WithTranslation, withTranslation } from 'react-i18next';
import CallIcon from '@material-ui/icons/Call';
import WhatsApp from '@material-ui/icons/WhatsApp';
import Email from '@material-ui/icons/Email';
import Modal from 'react-bootstrap/Modal'
import history from './../../history';
import { Link as ScrollLink, Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll';
import { toggleAuth } from '../../actions/auth-actions';
import { UserRole } from '../../models/user-role.enum';

import logoSn from "./../../images/logo-sn.png";
import logoIsim from "./../../images/logo-isim.png"
import logoUndp from "./../../images/logo-undp.png"
import logo from "./../../images/logo.png"
import ezgilLogo from "./../../images/ezgil_logo.png"

interface FooterProps {
    auth: IAuthState;
    toggleAuth: (b: boolean) => void;
}

interface FooterState {
    renderRegistration: boolean;
    show: boolean;
    scrollToMobile: boolean;
}

class FooterComponent extends Component<FooterProps & RouteComponentProps & WithTranslation, FooterState> {
    mobileAppBlock: HTMLDivElement | null | undefined;
    constructor(props: FooterProps & RouteComponentProps & WithTranslation) {
        super(props);
        this.state = {
            renderRegistration: false,
            show: false,
            scrollToMobile: false
        };
    }

    mobileApp(): boolean {
        if (this.props.location?.pathname === '/') return true;
        else return false;
    }

    render() {
        const { t, auth: { userToken } } = this.props;
        if (this.props.location?.pathname?.indexOf('/online-appointment') === 0 ||
            this.props.location?.pathname?.indexOf('/home-new') === 0) {
            return null;
        }

        return (
            <React.Fragment>

                {this.state.show &&
                    <Modal dialogClassName="modal-main" centered
                        show={this.state.show}
                        onHide={() => this.setState({ show: false })}
                    >
                        <Modal.Header
                            className='my-meds-modal-header'
                            closeButton>
                        </Modal.Header>

                        <Modal.Body>
                            <div style={{
                                border: '1px solid #03A8F5',
                                padding: '35px 40px 20px 40px',
                                marginBottom: '30px',
                            }} >
                                <p style={{
                                    fontSize: '0.9rem',
                                    color: '#03A8F5',
                                }} >Вы уже зарегистрированны на сайте VideoDoktor.az</p>
                            </div>
                        </Modal.Body>
                    </Modal>
                }

                <div className='footer-wrapper'>
                    <div className='footer-block'>
                        <div className='footer-left-wrapper'>
                            <div className='footer-left'>
                                <Link to='/'>
                                    <div className='footer-logo-block'>
                                        <img className='footer-logo-img' src={logo} alt='logo' />
                                        <p className='footer-logo-title'>VideoDoktor</p>
                                    </div>
                                </Link>
                                <div className='footer-contacts-main'>
                                    <div className='footer-sub-contacts'>
                                        <p className='footer-contacts-title'>{t('Write us')}</p>
                                        <div className='footer-contacts-data'>
                                            <p><a href='mailto:ezgilmedical@gmail.com'><Email fontSize={'small'} /> ezgilmedical@gmail.com</a></p>
                                            <p><a href='mailto:info@ezgil.az'><Email fontSize={'small'} /> info@ezgil.az</a></p>
                                        </div>
                                    </div>
                                    <div className='footer-sub-contacts'>
                                        <p className='footer-contacts-title'>{t('Call us')}</p>
                                        <div className='footer-contacts-data'>
                                            <p><a href='tel:+994512063813'><CallIcon fontSize={'small'} /> (+994) 51-206-38-13</a></p>
                                            <p><a href='https://wa.me/+994512063813'><WhatsApp fontSize={'small'} /> (+994) 51-206-38-13</a></p>
                                        </div>
                                    </div>
                                </div>

                                <div className='logos-block'>
                                    <img src={logoSn} alt='MoH' />
                                    <img src={logoIsim} alt='ISIM' />
                                    <img src={logoUndp} alt='UNDP' />
                                </div>

                            </div>
                            <div className='left-blue'></div>
                        </div>

                        <div className='footer-right'>
                            <div className='footer-1-column'>
                                <h1 className='footer-right-title'>{t('Service')}</h1>
                                <Link to='/app'><p className='footer-right-list-item'>App</p> </Link>
                                <Link to='/about'><p className='footer-right-list-item'>{t('About us')}</p></Link>
                                <Link to='/vacantions'><p className='footer-right-list-item'>{t('Vacantions')}</p></Link>
                                <Link to='/team'><p className='footer-right-list-item'>{t('Team')}</p></Link>
                                <Link to='/contacts'><p className='footer-right-list-item'>{t('Contacts')}</p></Link>
                                <Link to='/faq'><p className='footer-right-list-item'>{t('FAQ')}</p></Link>
                                <Link to='/terms-of-use'><p className='footer-right-list-item'>{t('Terms of use')}</p></Link>
                                <Link to='/privacy'><p className='footer-right-list-item'>{t('Privacy')}</p></Link>
                                <Link to='/agreement'><p className='footer-right-list-item'>{t('Consent to the processing of personal data')}</p></Link>
                                {/* <Link to={{pathname: '/', state: {scrollToMobile: true}}} onClick={this.mobileApp.bind(this)}>
                                    <p className='footer-right-list-item'>{t('Mobile application')}</p>
                                </Link> */}
                                {this.mobileApp() ?
                                    <ScrollLink to='mobile-block' spy={true} smooth={true} offset={0} duration={500} delay={100}>
                                        <p className='footer-right-list-item'>{t('Mobile application')}</p>
                                    </ScrollLink> :
                                    <Link to={{ pathname: '/', state: { scrollToMobile: true } }}>
                                        <p className='footer-right-list-item'>{t('Mobile application')}</p>
                                    </Link>}

                            </div>
                            <div className='footer-2-column'>
                                <h1 className='footer-right-title'>{t('For patient')}</h1>
                                <Link to='/diseases'><p className='footer-right-list-item'>{t('Directory of diseases')}</p></Link>
                                <Link to='/doctors'><p className='footer-right-list-item'>{t('All doctors')}</p></Link>
                                <Link to='/clinics'><p className='footer-right-list-item'>{t('All clinics')}</p></Link>
                                <Link to='/disease-list'><p className='footer-right-list-item'>{t('Disease list')}</p></Link>
                            </div>
                            <div className='footer-3-column'>
                                <div className='footer-3-column-1-subcolumn'>
                                    <h1 className='footer-right-title'>{t('For doctor and clinic')}</h1>

                                    <p className='footer-right-list-item'
                                        onClick={() => {
                                            if (userToken) {
                                                switch (userToken.role) {
                                                    case UserRole.Doctor:
                                                        history.push('/personal-cabinet-doctor');
                                                        break;
                                                    case UserRole.Clinic:
                                                        history.push('/personal-cabinet-clinic');
                                                        break;
                                                    default:
                                                        history.push('/personal-cabinet-patient');
                                                        break;
                                                }
                                            }
                                            else {
                                                this.props.toggleAuth(true);
                                            }
                                        }}>
                                        {t('Profile')}
                                    </p>

                                    <p className='footer-right-list-item'
                                        onClick={() => {
                                            if (this.props.auth.userToken) {
                                                this.setState({ show: true });
                                            }
                                            else {
                                                this.props.toggleAuth(true);
                                            }
                                        }}>
                                        {t('Registration')}
                                    </p>

                                </div>
                                <div className='ezgil-logo-block'>
                                    <img src={ezgilLogo} alt='Ezgil' /><span> Medical Technologies MMC © 2021</span>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className='main-warning'>
                        <p className='main-warning-circle'>0+</p>
                        <p className='main-warning-title'>{t('Information on the site')}</p>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state: RootState) => ({
    auth: state.auth,
});

const mapDispatchToProps = {
    toggleAuth,
};

let FooterComponentContainer = connect(mapStateToProps, mapDispatchToProps)(
    withTranslation()(withRouter(FooterComponent))
);

export default FooterComponentContainer;