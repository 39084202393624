import {IPatientDoctorState} from "../states/patient-doctor-state";
import {IPatientDoctor} from "../models/patient-doctor";
import {
    DELETE_PATIENT_MY_DOCTOR,
    PATIENT_DOCTOR_LIST,
    PATIENT_MY_DOCTOR,
    PATIENT_MY_DOCTOR_LIST, PATIENT_MY_DOCTORS_MAP,
    PATIENT_MY_DOCTORS_RATING_LIST,
    PatientDoctorActionTypes,
    SAVE_PATIENT_MY_DOCTOR,
    UPDATE_PATIENT_MY_DOCTOR
} from "../actions/patient-doctor-action";
import {IDoctorRating} from "../models/doctor-rating";

const initialState: IPatientDoctorState = {
    doctors: undefined,
    patientDoctor: undefined,
    patientDoctorList: undefined,
    patientDoctorListMap: new Map<number, IPatientDoctor>(),
    patientDoctorsRating: new Map<number, IDoctorRating[]>(),
    doctorsMap: undefined,
}

export function patientDoctorReducer(
    state = initialState,
    action: PatientDoctorActionTypes
): IPatientDoctorState {
    let patientDoctorList: IPatientDoctor[] | undefined;
    let patientDoctorListMap: Map<number, IPatientDoctor>;
    switch (action.type) {
        case PATIENT_DOCTOR_LIST:
            return {...state, ...{doctors: action.doctors}};
        case PATIENT_MY_DOCTORS_RATING_LIST:
            const patientDoctorsRating = new Map<number, IDoctorRating[]>();
            if (action.ratingList) {
                action.ratingList.forEach((item) => patientDoctorsRating.set(item.doctorId, item.ratingList));
            }
            return {...state, ...{patientDoctorsRating: patientDoctorsRating}};
        case PATIENT_MY_DOCTOR:
            return {...state, ...{patientDoctor: action.patientDoctor}};
        case PATIENT_MY_DOCTOR_LIST:
            patientDoctorListMap = state.patientDoctorListMap;
            if (patientDoctorListMap && action.patientDoctorList && action.patientDoctorList.length) {
                patientDoctorListMap.clear();
                action.patientDoctorList.map((el) => patientDoctorListMap.set(el.id as number, el));
            }
            return {...state, ...{patientDoctorList: action.patientDoctorList,
                    patientDoctorListMap: patientDoctorListMap}};
        case SAVE_PATIENT_MY_DOCTOR:
            return state;
        case PATIENT_MY_DOCTORS_MAP:
            return {...state, ...{doctorsMap: action.doctorsMap}};
        case UPDATE_PATIENT_MY_DOCTOR:
            patientDoctorList = state.patientDoctorList &&
                state.patientDoctorList.map((el) => {
                    if (el && el.id === action.patientDoctor.id) {
                        console.log('action.patientDoctor.id', action.patientDoctor);
                        return action.patientDoctor;
                    }
                    return el;
                });
            patientDoctorListMap = state.patientDoctorListMap;
            if (patientDoctorListMap &&
                patientDoctorListMap.get(action.patientDoctor.id as number)) {
                patientDoctorListMap.set(action.patientDoctor.id as number, action.patientDoctor);
            }
            return {
                ...state, ...{
                    patientDoctor: action.patientDoctor,
                    patientDoctorListMap: patientDoctorListMap,
                    patientDoctorList: patientDoctorList
                }
            };
         case DELETE_PATIENT_MY_DOCTOR:
             patientDoctorList = state.patientDoctorList &&
                state.patientDoctorList.filter((el) => el && el.id !== action.id);
             patientDoctorListMap = state.patientDoctorListMap;
            if (patientDoctorListMap &&
                patientDoctorListMap.get(action.id)) {
                patientDoctorListMap.delete(action.id);
            }
            return {...state, ...{ patientDoctorListMap: patientDoctorListMap,
                    patientDoctorList: patientDoctorList}};
        default:
            return state;
    }
}
