import { createConfirmation } from 'react-confirm';
import AlertDialog, { AlertProps } from './AlertDialog';
import ConfirmationDialog, { ConfirmationProps } from './ConfirmationDialog';

const defaultConfirmation = createConfirmation(ConfirmationDialog);

export function showConfirm(confirmation: string, options: ConfirmationProps = {}) {
  return defaultConfirmation({ confirmation, ...options });
}

const defaultAlert = createConfirmation(AlertDialog);

export function showAlert(alert: string, options: AlertProps = {}) {
  return defaultAlert({ alert, ...options });
}

