import { IPatientVaccinationState } from "../states/patient-vaccination-state";
import { PatientVaccinationActionTypes, PATIENT_VACCINATION_LIST, PATIENT_VACCINATION_ITEM } from "../actions/patient-vaccination-actions";

const initialState: IPatientVaccinationState = {
}

export function patientVaccinationReducer(
    state = initialState,
    action: PatientVaccinationActionTypes
): IPatientVaccinationState {
    switch (action.type) {
        case PATIENT_VACCINATION_LIST:
            return {...state, ...{list: action.vaccinations}};
        case PATIENT_VACCINATION_ITEM:
            return {...state, ...{selected: action.vaccination}};
        default:
            return state;
    }
}
