import React from 'react';
import { WrappedFieldProps } from "redux-form";
import './CheckBox.scss'

export interface CustomFieldProps {
    name?: string;
    placeholder?: string;
    label?: string;
    type?: string;
    checked?: boolean;
    disableInput?: boolean;
    editable?: boolean;
    multiline?: boolean;
    _className?: any;
}

class ChexBox extends React.Component<WrappedFieldProps & CustomFieldProps> {

    render() {
        const { placeholder, label, checked, _className,
            input: { onChange, value }, meta: { touched, valid, error }, name
        } = this.props;

        return (
            <div className='checkbox-component-wrapper'>
                <p>{label ? label : placeholder}:</p>
                <input
                    name={name}
                    type={'checkbox'}
                    onChange={onChange}
                    checked={value}
                    value={value}
                    placeholder={placeholder}
                />
            </div>
        );
    }
}

export default ChexBox;