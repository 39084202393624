import { Action, ActionCreator } from "redux";
import { ThunkAction } from "redux-thunk";
import { loadingEnd, loadingStart } from "./loading-actions";
import { LoadingSubject } from "../states/loading-state";
import { pushAlert } from "./alert-actions";
import * as environment from '../app.json';
import { authFetch, processStageChange, signOut } from "./auth-actions";
import { IPatientDoctor } from "../models/patient-doctor";
import { IDoctorRating } from "../models/doctor-rating";
import { IPatientDoctorAllow } from "../models/patient-doctor-allow";
import { IDoctor } from "../models/doctor";
import { IDoctorFilter } from "../models/doctor-filter";
import i18n from 'i18next';
import { RootState } from "../store";
import { showAlert, showConfirm } from "./../components/Dialogs";
import { withTranslation } from 'react-i18next';

function topDoctorData() {
    let topDoctor = environment.topDoctor;
    if (i18n.language == 'az') topDoctor = environment.topDoctorAz;
    if (i18n.language == 'ru') topDoctor = environment.topDoctorRu;
    return topDoctor;
}

export const PATIENT_DOCTOR_LIST = 'PATIENT_DOCTOR_LIST';
interface PatientDoctorListAction {
    type: typeof PATIENT_DOCTOR_LIST;
    doctors: IPatientDoctorAllow[];
}

export const _patientDoctorList: ActionCreator<PatientDoctorListAction> = (doctors: IPatientDoctorAllow[]) => {
    return {
        type: PATIENT_DOCTOR_LIST,
        doctors
    };
};

export const PATIENT_MY_DOCTOR = 'PATIENT_MY_DOCTOR';
interface GetPatientMyDoctorAction {
    type: typeof PATIENT_MY_DOCTOR;
    patientDoctor: IPatientDoctor;
}
export const _getPatientDoctor: ActionCreator<GetPatientMyDoctorAction> = (patientDoctor: IPatientDoctor) => {
    return {
        type: PATIENT_MY_DOCTOR,
        patientDoctor
    };
};

export const PATIENT_MY_DOCTOR_LIST = 'PATIENT_MY_DOCTOR_LIST';
interface GetPatientMyDoctorListAction {
    type: typeof PATIENT_MY_DOCTOR_LIST;
    patientDoctorList: IPatientDoctor[];
}
export const _getPatientDoctorList: ActionCreator<GetPatientMyDoctorListAction> = (patientDoctorList: IPatientDoctor[]) => {
    return {
        type: PATIENT_MY_DOCTOR_LIST,
        patientDoctorList
    };
};

export const SAVE_PATIENT_MY_DOCTOR = 'SAVE_PATIENT_MY_DOCTOR';
interface SavePatientMyDoctorAction {
    type: typeof SAVE_PATIENT_MY_DOCTOR;
}
const _savePatientDoctor: ActionCreator<SavePatientMyDoctorAction> = () => {
    return {
        type: SAVE_PATIENT_MY_DOCTOR,
    };
};

export const UPDATE_PATIENT_MY_DOCTOR = 'UPDATE_PATIENT_MY_DOCTOR';
interface UpdatePatientMyDoctorAction {
    type: typeof UPDATE_PATIENT_MY_DOCTOR;
    patientDoctor: IPatientDoctor;
}
const _updatePatientDoctor: ActionCreator<UpdatePatientMyDoctorAction> = (patientDoctor: IPatientDoctor) => {
    return {
        type: UPDATE_PATIENT_MY_DOCTOR,
        patientDoctor
    };
};

export const PATIENT_MY_DOCTORS_MAP = 'PATIENT_MY_DOCTORS_MAP';
interface PatientMyDoctorsMapAction {
    type: typeof PATIENT_MY_DOCTORS_MAP;
    doctorsMap: Map<number, IDoctor>;
}
const _patientDoctorMap: ActionCreator<PatientMyDoctorsMapAction> = (doctorsMap: Map<number, IDoctor>) => {
    return {
        type: PATIENT_MY_DOCTORS_MAP,
        doctorsMap
    };
};

export const DELETE_PATIENT_MY_DOCTOR = 'DELETE_PATIENT_MY_DOCTOR';
interface DeletePatientMyDoctorAction {
    type: typeof DELETE_PATIENT_MY_DOCTOR;
    id: number;
}
const _deletePatientDoctor: ActionCreator<DeletePatientMyDoctorAction> = (id: number) => {
    return {
        type: DELETE_PATIENT_MY_DOCTOR,
        id
    };
};

export const PATIENT_MY_DOCTORS_RATING_LIST = 'PATIENT_MY_DOCTORS_RATING_LIST';
interface PatientMyDoctorsRatingListAction {
    type: typeof PATIENT_MY_DOCTORS_RATING_LIST;
    ratingList: { ratingList: IDoctorRating[], doctorId: number }[];
}
const _patientMyDoctorsRatingList: ActionCreator<PatientMyDoctorsRatingListAction> = (ratingList: { ratingList: IDoctorRating[], doctorId: number }[]) => {
    return {
        type: PATIENT_MY_DOCTORS_RATING_LIST,
        ratingList
    };
};

export type PatientDoctorActionTypes = PatientDoctorListAction | GetPatientMyDoctorAction | PatientMyDoctorsRatingListAction |
    GetPatientMyDoctorListAction | PatientMyDoctorsMapAction | SavePatientMyDoctorAction | UpdatePatientMyDoctorAction | DeletePatientMyDoctorAction;

export const getPatientDoctorList = (
    filter: IDoctorFilter | undefined
): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch, getState) => {
    dispatch(loadingStart(LoadingSubject.PatientDoctorList));

    try {
        let filterPath = '?';
        if (filter) {
            for (let key in filter) {
                if (filter.hasOwnProperty(key) && filter[key]) {
                    filterPath += `${key}=${filter[key]}&`;
                }
            }
        }
        const response = await authFetch(
            getState, dispatch, topDoctorData() + 'patientmydoctors/getdoctors' + filterPath, 'GET',
            null, { Accept: 'application/json', 'Content-Type': 'application/json' }, 'token='
        );

        console.log('response', response);

        const json = await response.json();
        console.log('json', json);

        if (response.status === 200 && json.status !== false) {
            dispatch(_patientDoctorList(json.payload));
            if (json.payload && json.payload.length) {
                const ratingList = await Promise.all(
                    json.payload.map((item: IPatientDoctorAllow) => {
                        return fetch(topDoctorData() + 'doctorratings?id=' + item.id, {
                            method: 'GET',
                            headers: {
                                Accept: 'application/json',
                                'Content-Type': 'application/json',
                            },
                        }).then((response1) => response1 ? response1.json() : null).then((response1) => {
                            return { ratingList: response1, doctorId: item.id };
                        });
                    })
                );
                dispatch(_patientMyDoctorsRatingList(ratingList));
            }
            if (json.code) {
                dispatch(errorPatientDoctorList(json.code, getState().auth.userToken!.auth));
            }
        }
        else if (response.status === 401) {
            //dispatch(signOut(token));
        }
        else {
            dispatch(errorPatientDoctorList(json.code, getState().auth.userToken!.auth));
        }
    } catch (e) {
        dispatch(pushAlert('Unknown error. Please, check internet connection'));
    } finally {
        dispatch(loadingEnd(LoadingSubject.PatientDoctorList));
    }
}

export const getPatientMyDoctorList = (
    filter: IDoctorFilter | undefined = {}
): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch, getState) => {
    dispatch(loadingStart(LoadingSubject.PatientDoctorList));

    try {
        let filterPath = '?';
        if (filter) {
            for (let key in filter) {
                if (filter.hasOwnProperty(key) && filter[key]) {
                    filterPath += `${key}=${filter[key]}&`;
                }
            }
        }
        const response = await authFetch(
            getState, dispatch, topDoctorData() + 'patientmydoctors' + filterPath, 'GET',
            null, { Accept: 'application/json', 'Content-Type': 'application/json' }, 'token='
        );

        console.log('response', response);

        const json = await response.json();
        console.log('json', json);
        if (response.status === 200 && json.status !== false) {
            dispatch(_getPatientDoctorList(json));
            if (json && json.length) {
                const responseWithDoctors = await Promise.all(
                    json.map((item: any) => {
                        return fetch(topDoctorData() + 'doctors/' + item.doctor_id, {
                            method: 'GET',
                            headers: {
                                Accept: 'application/json',
                                'Content-Type': 'application/json',
                            },
                        }).catch((err) => Promise.resolve(null))
                            .then((response1) => response1 ? response1.json() : null).then((response1) => {
                                return { ...item, ...{ doctor: response1 } };
                            });
                    })
                );
                dispatch(_getPatientDoctorList(responseWithDoctors));
            }
        }
        else if (response.status === 401) {
            //dispatch(signOut(token));
        }
        else {
            dispatch(errorPatientDoctorList(json.code, getState().auth.userToken!.auth));
        }
    } catch (e) {
        dispatch(pushAlert('Unknown error. Please, check internet connection'));
    } finally {
        dispatch(loadingEnd(LoadingSubject.PatientDoctorList));
    }
}
export const savePatientDoctor = (
    patientDoctor: IPatientDoctor,
): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch, getState) => {
    dispatch(loadingStart(LoadingSubject.SavePatientDoctor));
    console.log("patientDoctor", patientDoctor);
    try {
        const response = await authFetch(
            getState, dispatch, topDoctorData() + 'patientmydoctors', 'POST',
            patientDoctor, { Accept: 'application/json', 'Content-Type': 'application/json' }, '?token='
        );

        const json = await response.json();
        if (response.status === 200 && json.success) {
            dispatch(_savePatientDoctor(json.payload));
            await dispatch(getPatientMyDoctorList());
            await dispatch(getPatientDoctorList(undefined));
            showAlert('Patient doctor successfully saved')
        }
        else if (response.status === 401) {
            //dispatch(signOut(token));
        }
        else {
            dispatch(errorSavePatientDoctor(json.code, getState().auth.userToken!.auth));
        }
    } catch (e) {
        dispatch(pushAlert('Unknown error. Please, check internet connection'));
    } finally {
        dispatch(loadingEnd(LoadingSubject.SavePatientDoctor));
    }
}
export const updatePatientDoctor = (
    patientDoctor: IPatientDoctor,
): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch, getState) => {
    dispatch(loadingStart(LoadingSubject.UpdatePatientDoctor));
    console.log('patientDoctor', patientDoctor);

    try {
        const response = await authFetch(
            getState, dispatch, topDoctorData() + 'patientmydoctors/' + patientDoctor.id, 'PATCH',
            patientDoctor, { Accept: 'application/json', 'Content-Type': 'application/json' }, '?token='
        );

        console.log('response', response);

        const json = await response.json();
        console.log('json', json);
        if (response.status === 200 && json.status !== false) {
            dispatch(_updatePatientDoctor(patientDoctor));
            showAlert('Patient doctor details successfully  updated');
        }
        else if (response.status === 401) {
            //dispatch(signOut(token));
        }
        else {
            dispatch(errorUpdatePatientDoctor(json.code, getState().auth.userToken!.auth));
        }
    } catch (e) {
        dispatch(pushAlert('Unknown error. Please, check internet connection'));
    } finally {
        dispatch(loadingEnd(LoadingSubject.UpdatePatientDoctor));
    }
}

export const getPatientDoctor = (
    id: number,
): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch, getState) => {
    dispatch(loadingStart(LoadingSubject.GetPatientDoctor));

    try {
        const response = await authFetch(
            getState, dispatch, topDoctorData() + 'patientmydoctors/' + id, 'GET',
            null, { Accept: 'application/json', 'Content-Type': 'application/json' }, '?token='
        );

        console.log('response', response);

        const json = await response.json();
        console.log('json', json);
        if (response.status === 200) {
            dispatch(_getPatientDoctor(json));
        }
        else if (response.status === 401) {
            //dispatch(signOut(token));
        }
        else {
            dispatch(errorPatientDoctor(json.code, getState().auth.userToken!.auth));
        }
    } catch (e) {
        dispatch(pushAlert('Unknown error. Please, check internet connection'));
    } finally {
        dispatch(loadingEnd(LoadingSubject.GetPatientDoctor));
    }
}
export const deletePatientDoctor = (
    id: number,
): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch, getState) => {
    dispatch(loadingStart(LoadingSubject.DeletePatientDoctor));

    console.log('id', id);

    try {
        const response = await authFetch(
            getState, dispatch, topDoctorData() + 'patientmydoctors/' + id, 'DELETE',
            null, { Accept: 'application/json', 'Content-Type': 'application/json' }, '?token='
        );

        const json = await response.json();
        console.log('json', json);
        if (response.status === 200 && json.status !== false) {
            dispatch(_deletePatientDoctor(id));
            showAlert('Patient doctor successfully deleted');
        }
        else if (response.status === 401) {
            //dispatch(signOut(token));
        }
        else {
            dispatch(errorPatientDoctor(json.code, getState().auth.userToken!.auth));
        }
    } catch (e) {
        dispatch(pushAlert('Unknown error. Please, check internet connection'));
    } finally {
        dispatch(loadingEnd(LoadingSubject.DeletePatientDoctor));
    }
}

export const errorPatientDoctorList = (code: number, token: string): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch, getState) => {
    //console.warn('code', code);
    if (code === 103 || code === 104) {
        dispatch(signOut(token));
    } else if (code === 109) {
        showAlert('Unknown error');
    } else if (code === 199) {
        // showAlert('Not found')));
    } else if (code === 401) {
        showAlert('Not patient');
    } else if (code === 900) {
        showAlert('Empty result');
    } else if (code === 901) {
        showAlert('Forbidden');
    } else {
        showAlert('Patient doctors list loading error');
    }
}

export const errorPatientDoctor = (code: number, token: string): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch, getState) => {
    //console.warn('code', code);
    if (code === 103 || code === 104) {
        dispatch(signOut(token));
    } else if (code === 109) {
        showAlert('Unknown error');
    } else if (code === 199) {
        showAlert('Not found');
    } else if (code === 401) {
        showAlert('Not patient');
    } else if (code === 900) {
        showAlert('Empty result');
    } else if (code === 901) {
        showAlert('Forbidden');
    } else {
        showAlert('Patient doctor loading error');
    }
}

export const errorUpdatePatientDoctor = (code: number, token: string): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch, getState) => {
    //console.warn('code', code);
    if (code === 103 || code === 104) {
        dispatch(signOut(token));
    } else if (code === 109) {
        showAlert('Unknown error');
    } else if (code === 199) {
        showAlert('Not found');
    } else if (code === 401) {
        showAlert('Not patient');
    } else if (code === 900) {
        showAlert('Empty result');
    } else if (code === 901) {
        showAlert('Forbidden');
    } else {
        showAlert('Patient doctor updating error');
    }
}

export const errorSavePatientDoctor = (code: number, token: string): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch, getState) => {
    //console.warn('code', code);
    if (code === 103 || code === 104) {
        dispatch(signOut(token));
    } else if (code === 109) {
        showAlert('Unknown error');
    } else if (code === 199) {
        showAlert('Not found');
    } else if (code === 401) {
        showAlert('Not patient');
    } else if (code === 402) {
        showAlert('This doctor already exist');
    } else if (code === 900) {
        showAlert('Empty result');
    } else if (code === 901) {
        showAlert('Forbidden');
    } else {
        showAlert('Patient doctor updating error');
    }
}
