import {IFiltersState} from "../states/flters-state";
import {CLINIC_FILTER, DOCTOR_FILTER, FilterActionType} from "../actions/filter-actions";

const initialState: IFiltersState = {
    clinicFilter: undefined,
    doctorFilter: undefined,
    myDoctorFilter: undefined,
}

export function filtersReducer(
    state = initialState,
    action: FilterActionType
) {
    switch (action.type) {
        case DOCTOR_FILTER:
            if (action.my) {
                return {...state, ...{myDoctorFilter: {...state.doctorFilter, ...action.doctorFilter}}};
            }
            return {...state, ...{doctorFilter: {...state.doctorFilter, ...action.doctorFilter}}};
        case CLINIC_FILTER:
            return {...state, ...{clinicFilter: {...state.clinicFilter, ...action.clinicFilter}}};
        default:
            return state;
    }
}