import { IUserToken } from "../models/user-token";
import { IProfile } from "../models/profile";
import { IUser } from "../models/user";
import { IDoctor } from "../models/doctor";

export interface IAuthState {
    userToken: IUserToken | undefined;
    self?: IProfile | IDoctor;
    user: IUser | undefined;
    processStage: number | 0;
    operation: string | undefined;
    userProfileById: IProfile | undefined;
    authErrorText: string | undefined;
    preUserToken: IUserToken | undefined;
    hasEmail: boolean | undefined;
    newDoctor: number;
    asPatient?: boolean;
    showAuth: boolean;
}
export enum LoginStage {
    Initial = 0,
    PhoneVerification = 1,
    EmailVerification = 2,
    VerifyEmailSuccess = 3,
    VerifyPhoneSuccess = 4,
    VerifyOperationSuccess = 5,
    ChangePasswordSuccess = 6
}