import React, { ReactNode } from "react";
import { WrappedFieldProps } from "redux-form";
import { Multiselect } from "multiselect-react-dropdown";
import "./DropDownSelect.scss";
import { WithTranslation, withTranslation } from "react-i18next";

export interface CustomFieldProps extends WithTranslation {
  name?: string;
  label?: string;
  _className: string;
  type?: string;
  selectedValue?: number;
  style?: any;
  dropName: any;
  valuesForDropdown: Array<any>;
  onClose?: (id: number | undefined) => void;
  placeholder?: string;
  chosed?: boolean;
  doctorId?: number;
  checkDoctorSelectedId?: (
    v: [],
    item: any,
    value: [],
    dropName: string,
    doctorId?: number
  ) => void;
  checkDoctorSelectedDeleteId: (
    v: [],
    item: any,
    value: [],
    dropName: string,
    doctorId?: number
  ) => void;
  //this for margin in adding doctor of clinic (Language)
}

class MiniDropDownSelect extends React.Component<
  WrappedFieldProps & CustomFieldProps
> {
  render() {
    const {
      input: { onChange, onBlur, value },
      label,
      valuesForDropdown,
      onClose,
      dropName,
      doctorId,
      type,
      selectedValue,
      meta: { error, touched, valid },
      _className,
      name,
      placeholder,
      t,
      style,
    } = this.props;

    const errorClassName = !valid && touched ? "error" : "";
    const valueName: {}[] = [];
    let splited = "";
    if (valuesForDropdown && valuesForDropdown.length) {
      valuesForDropdown.sort((a, b) => a.label?.localeCompare(b.label));
    }

    console.log("selected", selectedValue);
    console.log("value", value);
    console.log("valuesForDropdown", valuesForDropdown);
    console.log("onc", onChange);

    let sel: ReactNode;

    if (type === "multiselect") {
      if (
        dropName === "specializations" ||
        dropName === "procedures" ||
        dropName === "diseases"
      ) {
        for (let i = 0; i < value.length; i++) {
          valueName.push(value[i]["Name"] || value[i]);
        }
      } else {
        splited = (value || "").toString().split(",");
      }
      const options =
        valuesForDropdown &&
        valuesForDropdown.map((o) => ({
          id: o.value,
          name: o.label,
        }));

      sel = (
        <Multiselect
          options={options}
          selectedValues={
            options &&
            options.filter((o) =>
              dropName === "specializations" ||
              dropName === "procedures" ||
              dropName === "diseases"
                ? valueName?.indexOf(o.name.toString()) >= 0
                : splited?.indexOf(o.id.toString()) >= 0
            )
          }
          placeholder={placeholder}
          onSelect={(v, item) => {
            if (dropName === "specializations" || "diseases" || "procedures") {
              // this.props.checkDoctorSelectedId &&
              //   this.props.checkDoctorSelectedId(
              //     v,
              //     item,
              //     value,
              //     dropName,
              //     doctorId
              //   );
              onChange(v.map((o) => o.name));
            } else {
              onChange(v.map((o) => o.id).join(","));
            }
          }}
          onRemove={(v, item) => {
            if (dropName === "specializations" || "diseases" || "procedures") {
              // this.props.checkDoctorSelectedDeleteId(
              //   v,
              //   item,
              //   value,
              //   dropName,
              //   doctorId
              // );
              onChange(v.map((o) => o.name));

            } else {
              onChange(v.map((o) => o.id).join(","));
            }
          }}
          displayValue="name"
        />
      );
    } else {
      sel = (
        <select
          style={{ boxShadow: style }}
          className={errorClassName}
          name={name}
          onChange={onChange}
        >
          <option
            key={"not-selected"}
            selected={!value && value !== 0}
            value={""}
          >
            {t("Not selected")}
          </option>
          {valuesForDropdown &&
            valuesForDropdown.map((i) => {
              return (
                <option
                  key={i.value}
                  selected={i.value === value}
                  value={i.value}
                >
                  {i.label}
                </option>
              );
            })}
        </select>
      );
    }

    return (
      <div
        className={
          "mini-text-input-component reg-input-styles" + " " + _className
        }
      >
        <p>{label}</p>
        {sel}
        {!valid && touched ? (
          <p
            className={
              "text-input-component__error" +
              (this.props.chosed ? " doctor-lang-style" : "")
            }
          >
            {error}
          </p>
        ) : null}
      </div>
    );
  }
}

export default withTranslation()(MiniDropDownSelect);
