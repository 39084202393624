import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { connect } from "react-redux";
import { IStatistics } from "../../states/statistics";
import { RootState } from "../../store";
import "./Counter.scss";


interface CounterProps {
  statistics: IStatistics;
}

interface CounterState {}

class Counter extends React.Component<
  CounterProps & WithTranslation,
  CounterState
> {
  constructor(props: CounterProps & WithTranslation) {
    super(props);
    this.state = {};
  }
  render() {
    const { t } = this.props;

    return (
      <div className="new-counter-block">
        <div className="counter-block">
          <div className="counter-item__wrapper">
            <div className="counter-item">
              <p className="counter-title">
                {this.props.statistics?.totalRecords}
              </p>
              <p className="counter-desc">{t("Patient appointed")}</p>
            </div>
            <div className="counter-item">
              <p className="counter-title">
                {this.props.statistics?.totalReviews}
              </p>
              <p className="counter-desc">{t("Comments on site")}</p>
            </div>
            <div className="counter-item">
              <p className="counter-title">
                {this.props.statistics?.totalDoctors}
              </p>
              <p className="counter-desc">{t("Practice doctor in base")}</p>
            </div>
            <div className="counter-item">
              <p className="counter-title">
                {this.props.statistics?.totalClinics}
              </p>
              <p className="counter-desc">{t("Clinics in base")}</p>
            </div>
          </div>
          <div className="counter-slogan">
            <div>{t("These numbers are growing every day!")}</div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  statistics: state.statistics,
});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(Counter));
