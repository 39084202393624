import React, { Component } from 'react';
import { connect } from "react-redux";
import { RootState } from "../../store";
import './About.scss';
import Button from 'react-bootstrap/Button'
import { WithTranslation, withTranslation } from 'react-i18next';
import Carousel from 'react-bootstrap/Carousel';
import history from '../../history';

import InfoBlock1 from "./../../images/about/info-block-1-1.png";
import InfoBlock2 from "./../../images/about/info-block-1-2.png";
import InfoBlock3 from "./../../images/about/info-block-1-3.png";
import Device from "./../../images/about/device.png";
import InfoBlock2_1 from "./../../images/about/info-block-2-1.png";
import InfoBlock2_2 from "./../../images/about/info-block-2-2.png";
import InfoBlock2_3 from "./../../images/about/info-block-2-3.png";
import InfoBlock2_4 from "./../../images/about/info-block-2-4.png";
import AboutSlider1 from "./../../images/about/about-slider-1.png";
import AboutSlider2 from "./../../images/about/about-slider-2.png";
import AboutSlider3 from "./../../images/about/about-slider-3.png";



interface MyProps { }

interface MyState {
    slider_index: number;
    slider_desc: Array<string>;
}

class AboutComponent extends Component<MyProps & WithTranslation, MyState> {

    constructor(props: MyProps & WithTranslation) {
        super(props);
        this.state = {
            slider_index: 1,
            slider_desc: [
                'Step 1 - sign up',
                'Step 2 - select doctor',
                'Step 3 - consultation'
            ]
        };
    }

    render() {
        const { t } = this.props;
        return (
            <div>
                <div className='about-header-block'>
                    <p className='about-header-title'>{t('Our doctors are at your service 24/7!')}</p>
                    <p className='about-header-desc'>{t("At the core of your approach to healthcare is supporting your health, wherever you are, by eliminating the walls of the doctor's office.")}</p>
                </div>
                <p className='about-header-blue-line'>{t('Experienced, licensed doctors working in Azerbaijan and abroad provide consultations via Video Doctor.')}</p>
                <div className='info-block-1'>
                    <div className='info-block-1-1'>
                        <h1 className='info-block-1-1-title'>{t('A modern and effective approach to medical care')}</h1>
                        <p className='info-block-1-1-desc'>{t('In most countries of the world, video tips for getting infected with the coronavirus have already become the norm.')}</p>
                        <img src={InfoBlock1} style={{ float: 'right', width: '190px', marginLeft: '20px' }} />
                        <p className='info-block-1-1-desc'>{t('Video Doctor is the most comfort, efficient and convenient way for patients to make an appointment with a doctor or psychologist.')}</p>
                        <Button className='info-button' onClick={() => history.push('/doctors')} style={{ marginTop: '18px' }} variant="primary">{t('See a doctor')}</Button>
                    </div>
                    <div className='info-block-1-2'>
                        <h1 className='info-block-1-2-title'>{t('Fair and transparent prices')}</h1>
                        <p className='info-block-1-2-desc'>{t("Any person living in Azerbaijan and outside its territories can benefit from Video Doctor's services.")}</p>
                        <img src={InfoBlock2} style={{ float: 'left', width: '190px', marginRight: '20px' }} />
                        <p className='info-block-1-2-desc'>{t("Signing up is free, and the cost of each doctor's appointment is always disclosed to you in advance - with no surprise bills later.")}</p>
                        <Button className='info-button' onClick={() => history.push('/doctors')} style={{ marginTop: '0' }} variant="primary">{t('See a doctor')}</Button>
                    </div>
                    <div className='line-break'></div>
                    <div className='info-block-1-3'>
                        <h1 className='info-block-1-3-title'>{t('Compassionate and trustworthy medical professionals who understand you')}</h1>
                        <img src={InfoBlock3} style={{ float: 'left', width: '300px', marginRight: '20px' }} />
                        <p className='info-block-1-3-desc' style={{ width: '95%' }}>{t("Any person living in Azerbaijan and outside its territories can benefit from Video Doctor's services. Patients with or without insurance can easily use Video Doctor. Signing up is free, and the cost of each doctor's appointment is always disclosed to you in advance - with no surprise bills later.")}</p>
                        <Button className='info-button' onClick={() => history.push('/doctors')} variant="primary">{t('See a doctor')}</Button>
                    </div>
                </div>

                <div className='about-mob-app'>
                    <h1 style={{ fontSize: '25px', textAlign: 'center' }}>{t('Why it is worth choosing VideoDoctor.')}</h1>
                    <p style={{ fontSize: '15px', textAlign: 'center' }}>{t('Consultation on a wide range of questions related to prevention and treatment of various diseases')}</p>
                    <div className='app-mob-wrapper'>
                        <div className='app-mob-1'>
                        <p style={{ marginTop: '110px' }} className='app-mob-p-1'>{t('A wide range of doctors is available!')}</p>
                            <p className='app-mob-p-1'>{t('At a time that is convenient for you, in a form that is comfortable for you!')}</p>
                            <p className='app-mob-p-1'>{t('Without waiting in line at the doctor!')}</p>
                            <p className='app-mob-p-1'>{t('Save time and money!')}</p>
                        </div>
                        <div className='app-mob-2'>
                            <img src={Device}
                                alt='app_img1.png' style={{ width: '280px' }} />

                        </div>
                        <div className='app-mob-3'>
                        <p style={{ marginTop: '110px' }} className='app-mob-p-3'>{t('No need to worry about parking!')}</p>
                            <p className='app-mob-p-3'>{t('Comfortable when traveling!')}</p>
                            <p className='app-mob-p-3'>{t('By maintaining privacy!')}</p>
                            <p className='app-mob-p-3'>{t('Viruses in the clinic - no!')}</p>
                        </div>
                    </div>
                </div>

                <div className='info-block-2'>
                    <div className='info-block-2-1'>
                        <h1 className='info-block-2-1-title'>{t('Emergency medical care and support')}</h1>
                        <img style={{ float: 'right', width: '35%', marginLeft: '20px' }}
                            src={InfoBlock2_1} alt='info-block-2-1-img' />
                        <p className='info-block-2-1-desc'>{t("When you are sick and need a doctor's appointment, Video Doctor's professional team is always by your side. Our specialists, who are working 24/7 in various medical institutions, can help you by guiding you, accept you for live examination, diagnose and start treatment. You can also make appointments for appropriate diagnostic tests and prescriptions for drug therapy 24/7. By trusting Video Doctor specialists, you will get rid of worries and blind behavior on the way to improving your health.")}</p>
                    </div>
                    <div className='line-break'></div>
                    <div className='info-block-2-2'>
                        <h1 className='info-block-2-2-title'>{t('Emergency medical care and support')}</h1>
                        <p className='info-block-2-2-desc'>{t("Video Doctor mobile application is the most convenient way to get emotional and psychological support without leaving your home. During the pandemic, psychiatrists and psychologists in many countries around the world are currently providing needed help to thousands of people every day.")}</p>
                        <img style={{ width: '100%', margin: '107px auto', display: 'block' }}
                            src={InfoBlock2_2} alt='info-block-2-2-img' />

                    </div>
                    <div className='info-block-2-3'>
                        <h1 className='info-block-2-3-title'>{t('Preventive medicine')}</h1>
                        <p className='info-block-2-3-desc' style={{ width: '95%' }}>{t("The phrase «the best defense is prevention» is the basis of modern medical science and, in particular, of «individualized medicine», which has become popular in recent years. Supporting a healthy lifestyle, identifying risk factors and behaviors that lead to the development of diseases, aggravation or progression of existing diseases, and their elimination are issues that Video Doctor pays special attention to and has a motivational effect. Your health is our reward!")}</p>
                        <img style={{ width: '100%', margin: '0 auto', marginBottom: '0', display: 'block' }}
                            src={InfoBlock2_3} alt='info-block-2-3-img' />
                    </div>
                    <div className='info-block-2-4'>
                        <img style={{ float: 'left', width: '50%', marginRight: '20px' }}
                            src={InfoBlock2_4} alt='info-block-2-4-img' />
                        <h1 className='info-block-2-4-title'>{t('Emergency medical care and support')}</h1>
                        <p className='info-block-2-4-desc'>{t("Video Doctor mobile application is the most convenient way to get emotional and psychological support without leaving your home. During the pandemic, psychiatrists and psychologists in many countries around the world are currently providing needed help to thousands of people every day.")}</p>
                    </div>
                </div>

                <div className='titles-desc-about'>
                    <p style={{ width: '60%', margin: '0 auto', fontSize: '13px' }}>{t("Relevant healthcare services through Video Doctor are provided by independent professionals known as Video Doctor specialists. Those medical professionals provide services to you based on their qualifications and licenses of their authorized activities.")}</p>
                    <p style={{ width: '60%', margin: '20px auto', fontSize: '13px' }}>{t("Ezgil Medical Technologies, the creator of the Video Doctor mobile application, does not provide any medical care, mental health support, or other health care services.")}</p>
                </div>

                <div className='about-slider-about'>
                    <h1 className='about-slider-title'>{t('How to get a consultation?')}</h1>
                    <Carousel fade>
                        <Carousel.Item>
                            <img style={{ height: '300px', objectFit: 'contain', margin: '16px auto', display: 'block' }}
                                src={AboutSlider1} alt='about-slider-1' />
                            <p className='about-slider-desc'>{t(this.state.slider_desc[0])}</p>
                        </Carousel.Item>
                        <Carousel.Item>
                            <img style={{ height: '300px', objectFit: 'contain', margin: '16px auto', display: 'block' }}
                                src={AboutSlider2} alt='about-slider-1' />
                            <p className='about-slider-desc'>{t(this.state.slider_desc[1])}</p>
                        </Carousel.Item>
                        <Carousel.Item>
                            <img style={{ height: '300px', objectFit: 'contain', margin: '16px auto', display: 'block' }}
                                src={AboutSlider3} alt='about-slider-1' />
                            <p className='about-slider-desc'>{t(this.state.slider_desc[2])}</p>
                        </Carousel.Item>
                    </Carousel>
                </div>

            </div >
        )
    }
}

const mapStateToProps = (state: RootState) => ({

});

const mapDispatchToProps = {

};

let AboutComponentContainer = connect(mapStateToProps, mapDispatchToProps)(
    withTranslation()(AboutComponent)
);

export default AboutComponentContainer