import { IPatientSurveyState } from "../states/patient-survey-state";
import { PatientSurveyActionTypes, PATIENT_SURVEY_LIST, PATIENT_SURVEY_ITEM } from "../actions/patient-survey-actions";

const initialState: IPatientSurveyState = {
}

export function patientSurveyReducer(
    state = initialState,
    action: PatientSurveyActionTypes
): IPatientSurveyState {
    switch (action.type) {
        case PATIENT_SURVEY_LIST:
            return {...state, ...{list: action.surveys}};
        case PATIENT_SURVEY_ITEM:
            return {...state, ...{selected: action.survey}};
        default:
            return state;
    }
}
