import React from "react";
import { connect } from "react-redux";
import {
  Field,
  InjectedFormProps,
  reduxForm,
  FormErrors,
  ConfigProps,
} from "redux-form";
import { WithTranslation, withTranslation } from "react-i18next";
import { IDictionaryState } from "../../../states/dictionary-state";
import { RootState } from "../../../store";
import { IProfileSection } from "../../../models/profile-section";
import { ILoadingState } from "../../../states/loading-state";
import { ProfileValueType } from "../../../models/profile-value-type";
import MiniTextInput from "../../../forms/FormComponents/MiniTextInput";
import MiniDropDownSelect from "../../../forms/FormComponents/MiniDropDownSelect";
import FilesComponent from "../../../forms/FormComponents/FilesComponent";

type Props = {
  section: IProfileSection;
  loading?: ILoadingState;
  dictionary?: IDictionaryState;
  values: { [key: string]: any };
  onSubmit?: (data: { [key: string]: any }) => void;
  lang: string;
};

class DoctorProfileForm extends React.Component<
  InjectedFormProps<{ [key: string]: any }, WithTranslation & Props, string> &
    WithTranslation &
    Props
> {
  constructor(
    props: InjectedFormProps<
      { [key: string]: any },
      WithTranslation & Props,
      string
    > &
      WithTranslation &
      Props
  ) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      t,
      section: { values },
      dictionary,
      lang,
    } = this.props;
    console.log(dictionary);
    return (
      <div style={{ padding: "0px 0 30px 0" }} className="dp-form">
        {values.map((v) => {
          let name = v.name;
          if (v.multilanguage) {
            name += "_" + lang;
          }
          switch (v.type) {
            case ProfileValueType.string:
              return (
                <Field
                  name={name}
                  label={t(v.title)}
                  placeholder={t(v.title)}
                  type={"text"}
                  key={name}
                  component={MiniTextInput}
                />
              );
            case ProfileValueType.area:
              return (
                <Field
                  name={name}
                  label={t(v.title)}
                  placeholder={t(v.title)}
                  type={"textarea"}
                  key={name}
                  component={MiniTextInput}
                />
              );
            case ProfileValueType.date:
              return (
                <Field
                  name={name}
                  label={t(v.title)}
                  placeholder={t(v.title)}
                  type={"date"}
                  key={name}
                  component={MiniTextInput}
                />
              );
            case ProfileValueType.dictionary:
              return (
                <Field
                  name={name}
                  label={t(v.title)}
                  placeholder={t(v.title)}
                  type={"text"}
                  key={name}
                  valuesForDropdown={
                    dictionary![v.dictionary!]?.map((d) => ({
                      value: d.id,
                      label: d.name,
                    })) || []
                  }
                  component={MiniDropDownSelect}
                />
              );
            case ProfileValueType.decimal:
              return (
                <Field
                  name={name}
                  label={t(v.title)}
                  placeholder={t(v.title)}
                  type={"decimal"}
                  key={name}
                  component={MiniTextInput}
                />
              );
            case ProfileValueType.boolean:
              return (
                <Field
                  name={name}
                  label={t(v.title)}
                  type={"text"}
                  key={name}
                  valuesForDropdown={[
                    { value: 1, label: t("Yes") },
                    { value: 0, label: t("No") },
                  ]}
                  component={MiniDropDownSelect}
                />
              );
            case ProfileValueType.html:
              return (
                <Field
                  name={name}
                  label={t(v.title)}
                  placeholder={t(v.title)}
                  type={"html"}
                  key={name}
                  component={MiniTextInput}
                />
              );
            case ProfileValueType.file:
              return (
                <Field
                  name={name}
                  label={t(v.title)}
                  placeholder={t(v.title)}
                  key={name}
                  component={FilesComponent}
                />
              );
            default:
              return undefined;
          }
        })}
      </div>
    );
  }
}

const validate = (
  values: { [key: string]: any },
  props: Partial<
    ConfigProps<{ [key: string]: any }, WithTranslation & Props, string>
  > &
    WithTranslation &
    Props
): FormErrors<any> => {
  const errors: FormErrors<any> = {};

  const { t, lang } = props;
  for (const l of props.section.values) {
    const name = l.name + (l.multilanguage ? "_" + lang : "");
    const value = values[name];
    console.log(value);
    if (l.required) {
      if (!value || value.toString().trim() === "") {
        errors[name] = t("Field couldn't be empty");
      }
    }
    if (l.limit && l.type === ProfileValueType.file) {
      if (value?.length && value[0].size > l.limit) {
        errors[name] = t("File size cannot be more then {{limit}}KB", {
          limit: l.limit / 1024,
        });
      }
    }
  }
  console.log("errors", errors);
  return errors;
};

const form = reduxForm<{ [key: string]: any }, WithTranslation & Props, string>(
  {
    form: "DoctorProfileForm",
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
    validate,
  }
)(DoctorProfileForm);

const mapStateToProps = (state: RootState, ownProps: Props) => {
  return {
    loading: state.loading,
    dictionary: state.dictionary,
    initialValues: ownProps.values,
  };
};

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(form));
