import { IPatientAllergyState } from "../states/patient-allergy-state";
import { PatientAllergyActionTypes, PATIENT_ALLERGY_LIST, PATIENT_ALLERGY_ITEM } from "../actions/patient-allergy-actions";

const initialState: IPatientAllergyState = {
}

export function patientAllergyReducer(
    state = initialState,
    action: PatientAllergyActionTypes
): IPatientAllergyState {
    switch (action.type) {
        case PATIENT_ALLERGY_LIST:
            return {...state, ...{list: action.allergies}};
        case PATIENT_ALLERGY_ITEM:
            return {...state, ...{selected: action.allergy}};
        default:
            return state;
    }
}
