import { DoctorsActionTypes, DOCTORS_POSTS_LIST } from "../actions/doctors-posts-actions";
import { IDoctorsPostsState } from "../states/doctors-posts-state";

const initialState: IDoctorsPostsState = {
  posts: [],
};

export function doctorsPostsReducer(
  state = initialState,
  action: DoctorsActionTypes
): IDoctorsPostsState {
  switch (action.type) {
    case DOCTORS_POSTS_LIST:
      return { ...state, ...{ posts: action.postsList } };
    default:
      return state;
  }
}
