export interface IDoctorFilter {
    name?: string | null,
    procedure?: number | null,
    specialization?: number | null,
    disease?: number | null,
    sex?: number | null,
    clinic?: number | null,
    language?: number | null,
    onDuty?: boolean,
    myClinics?: boolean,
    myDoctors?: boolean,

    sort?: DoctorSort,
    online?: boolean,
    offline?: boolean,
    desc?: boolean,
    
    metroId?: number;
    districtId?: number;
    cityId?: number;

    start?: string;
    end?: string;
}

export enum DoctorSort
{
    Rating = 0, WorkExperience = 1, Fullname = 2
}

export enum VisitType
{
    Clinic = 1, Online = 0, Home = 2
}