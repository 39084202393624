import { ICallState } from '../states/call-state';
import { CallActionTypes, STAGE, CONNECTION, STAGE_DATA, HANG_UP, AUTO_ANSWER, DISPLAY_CALL_UUID } from '../actions/call-actions';
import { ConnectionState } from '../models/connetion-state';
import { CallStage } from '../models/call-stage';

const initialState: ICallState = {
    stage: CallStage.Idle,
    stageData: {mutedVideo: false, mutedAudio: false, speakerOn: true},
    connection: ConnectionState.Initial,
    connectionData: {},
    decline: false,
}

export function callReducer(
    state = initialState,
    action: CallActionTypes
): ICallState {
    switch (action.type) {
        case DISPLAY_CALL_UUID:
            console.log('ACTION', action);
            return {...state, displayCallUuid: action.uuid}
        case AUTO_ANSWER:
            console.log('ACTION', action);
            return {...state, autoAnswerId: action.messageId, decline: action.decline}
        case STAGE_DATA:
            console.log('ACTION', action);
            return {...state, ...{stageData: {...state.stageData, ...action.stageData}}};
        case STAGE:
            console.log('ACTION', action);
            return {...state, ...{stage: action.stage, stageData: {mutedVideo: false, mutedAudio: false, speakerOn: true, ...state.stageData, ...action.stageData}}};
        case CONNECTION:
            console.log('ACTION', action);
            return {...state, ...{connection: action.connection, connectionData: {...state.connectionData, ...action.connectionData}}};
        case HANG_UP:
            console.log('ACTION', action);
            // if (state.stageData?.selfStream) {
            //     state.stageData?.selfStream.release();
            // }
            return {...state, lastCallUuid: state.stageData?.callUuid ?? state.lastCallUuid, connectionData: {}, ...{stage: CallStage.Idle, stageData: {mutedVideo: false, mutedAudio: false, speakerOn: true}}}
        default:
            return state;
    }
}
