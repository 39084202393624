import React, { Component } from "react";
import { connect as connectRedux } from "react-redux";
import { RootState } from "./../store";
import WelcomeComponentContainer from "./../components/Welcome/Welcome";
import HomeComponentContainer from "./../components/Home/Home";
import Landing from "./../components/Lending/Landing";
import { BrowserRouter, Switch, Route, Router } from "react-router-dom";
import HeaderComponentContainer from "./../components/Header/Header";
import FooterComponentContainer from "./../components/Footer/Footer";
import DoctorsComponentContainer from "./../components/Doctors/Doctors";
import ClinicsComponentContainer from "./../components/Clinics/Clinics";
import MedicalCardComponentContainer from "./../components/MedicalCard/MedicalCard";
import ScrollToTop from "./../ScrollToTop";
import AboutComponentContainer from "./../components/About/About";
import PersonalCabinetPatientComponentContainer from "./../components/PersonalCabinetPatient/PersonalCabinetPatient";
import PersonalCabinetDoctorComponentContainer from "./../components/PersonalCabinetDoctor/PersonalCabinetDoctor";
import {
  _signIn,
  SIGN_IN,
  getUserById,
  fetchSelf,
  syncRefresh,
  restoreLogin,
} from "../actions/auth-actions";
import { IUserToken } from "../models/user-token";
import { IAuthState } from "../states/auth-state";
import { UserRole } from "../models/user-role.enum";
import MyMedicinesComponentContainer from "./../components/MedicalCard/MyMedicines/MyMedicines";
import MyConsultationsHistoryComponentContainer from "./../components/MedicalCard/MyConsultationsHistory/MyConsultationsHistory";
import MySurveysComponentContainer from "./../components/MedicalCard/MySurveys/MySurveys";
import DoctorsAccessComponentContainer from "./../components/MedicalCard/DoctorsAccess/DoctorsAccess";
import MyAllergiesComponentContainer from "./../components/MedicalCard/MyAllergies/MyAllergies";
import MyAnalyzesComponentContainer from "./../components/MedicalCard/MyAnalyzes/MyAnalyzes";
import MyVaccinationsComponentContainer from "./../components/MedicalCard/MyVaccinations/MyVaccinations";
import MyIndicatorsComponentContainer from "../components/MedicalCard/MyIndicators/MyIndicators";
import MyNotesComponentContainer from "./../components/MedicalCard/MyNotes/MyNotes";
import OnlineAppointment from "./../components/OnlineAppointment/OnlineAppointment";
import DoctorDuty from "./../components/DoctorDuty/DoctorDuty";
import Alerts from "./../components/Alerts/Alerts";
import history from "./../history";
import UserDoctorsAccessComponentContainer from "./../components/DoctorsUserAccess/DoctorsUserAccess";
import DoctorScheduleDetails from "../components/Doctors/DoctorScheduleDetails";
import CallModal from "../components/OnlineAppointment/CallModal";
import UnderConstruction from "../components/UnderConstruction/UnderConstruction";
import Vacantions from "../components/Vacantions/Vacantions";
import UserAgreement from "../components/UserAgreement/UserAgreement";
import Diseases from "../components/Diseases/Diseases";
import Team from "../components/Team/Team";
import Contacts from "../components/Contacts/Contacts";
import Download from "../components/Download/Download";
import FAQ from "../components/FAQ/FAQ";
import { getIndicatorsInfo } from "../actions/indicator-actions";
import Purchase from "../components/OnlineAppointment/Purchase";
import HomeNew from "../components/HomeNew/HomeNew";
import ProfileComponentContainer from "../components/MedicalCard/DoctorProfile/DoctorProfile";
import PersonalCabinetClinicComponentContainer from "../components/PersonalCabinetClinic/PersonalCabinetClinic";
import DiseaseListContainer from "../components/Lending/Diseases/DiseaseList";
import DiseaseContainerComponent from "../components/Lending/Diseases/Disease";
import HeaderComponentContainer2 from "../components/Lending/Header2/Header2";
import ReceptionStandards from "../components/ReceptionStandards/ReceptionStandards";

require("react-web-vector-icons/fonts");

interface MyProps {
  restoreLogin: (userToken: IUserToken) => void;
  getUserById: (id: number, token: string) => void;
  auth: IAuthState;
  fetchSelf: (token: string, role: UserRole) => void;
}

interface MyState {}

class Navigation extends Component<MyProps, MyState> {
  constructor(props: MyProps) {
    super(props);
    this.state = {};

    const myToken = localStorage.getItem("az.ezgil.videodoctor.token");
    if (myToken) {
      this.props.restoreLogin(JSON.parse(myToken));
    }
  }

  render() {
    return (
      <Router history={history}>
        <Switch>
          <Route exact path="/">
            <HeaderComponentContainer2 />
          </Route>
          <Route path="/disease-list">
            <HeaderComponentContainer2 />
          </Route>
          <Route path="/disease">
            <HeaderComponentContainer2 />
          </Route>
          <Route>
            <HeaderComponentContainer />
          </Route>
        </Switch>
        <ScrollToTop />
        <Route exact path="/" component={Landing} />
        <Route path="/app" component={HomeComponentContainer} />
        <Route path="/home-new" component={HomeNew} />
        <Route path="/home" component={WelcomeComponentContainer} />
        <Route path="/about" component={AboutComponentContainer} />
        <Route path="/doctors" component={DoctorsComponentContainer} />
        <Route path="/duty-doctors" component={DoctorsComponentContainer} />
        <Route
          path="/my-clinic-doctors"
          component={DoctorsComponentContainer}
        />
        <Route path="/my-doctors" component={DoctorsComponentContainer} />
        <Route
          path="/doctor/schedule-details"
          component={DoctorScheduleDetails}
        />
        <Route path="/clinics" component={ClinicsComponentContainer} />
        <Route path="/my-clinics" component={ClinicsComponentContainer} />
        <Route path="/medical-card" component={MedicalCardComponentContainer} />
        <Route
          path="/personal-cabinet-patient"
          component={PersonalCabinetPatientComponentContainer}
        />
        <Route
          path="/personal-cabinet-doctor"
          component={PersonalCabinetDoctorComponentContainer}
        />
        <Route
          path="/personal-cabinet-clinic"
          component={PersonalCabinetClinicComponentContainer}
        />
        <Route
          path="/clinic/schedule-details"
          component={DoctorScheduleDetails}
        />
        <Route path="/my-medicines" component={MyMedicinesComponentContainer} />
        <Route
          path="/my-consultations-history"
          component={MyConsultationsHistoryComponentContainer}
        />
        <Route path="/my-surveys" component={MySurveysComponentContainer} />
        <Route
          path="/doctors-access"
          component={DoctorsAccessComponentContainer}
        />
        <Route path="/my-allergies" component={MyAllergiesComponentContainer} />
        <Route path="/my-analyzes" component={MyAnalyzesComponentContainer} />
        <Route
          path="/my-vaccinations"
          component={MyVaccinationsComponentContainer}
        />
        <Route
          path="/my-indicators"
          component={MyIndicatorsComponentContainer}
        />
        <Route path="/profile" component={ProfileComponentContainer} />
        <Route path="/my-notes" component={MyNotesComponentContainer} />
        <Route path="/online-appointment" component={OnlineAppointment} />
        <Route path="/doctor-duty" component={DoctorDuty} />
        <Route
          path="/doctors-user-access"
          component={UserDoctorsAccessComponentContainer}
        />
        <Route path="/under-construction" component={UnderConstruction} />
        <Route path="/vacantions" component={Vacantions} />
        <Route path="/terms-of-use" component={UserAgreement} />
        <Route path="/privacy" component={UserAgreement} />
        <Route path="/agreement" component={UserAgreement} />
        <Route path="/diseases" component={Diseases} />
        <Route path="/team" component={Team} />
        <Route path="/contacts" component={Contacts} />
        <Route path="/download" component={Download} />
        <Route path="/faq" component={FAQ} />
        <Route path="/purchase" component={Purchase} />
        <Route path="/verify" component={HomeComponentContainer} />
        <Route path="/disease-list" component={DiseaseListContainer} />
        <Route path="/disease/:id" component={DiseaseContainerComponent} />
        <Route path="/reception-standards" component={ReceptionStandards} />
        <FooterComponentContainer />
        <CallModal />
        {/* <Alerts /> */}
      </Router>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  auth: state.auth,
  indicators: state.indicators,
});

const mapDispatchToProps = {
  restoreLogin,
  getUserById,
  fetchSelf,
  getIndicatorsInfo,
};

let NavigationContainer = connectRedux(
  mapStateToProps,
  mapDispatchToProps
)(Navigation);

export default NavigationContainer;
