import { ActionCreator, Action } from "redux";
import { ThunkAction } from "redux-thunk";
import { loadingStart, loadingEnd } from "./loading-actions";
import { LoadingSubject } from "../states/loading-state";
import i18n from 'i18next';
import * as environment from '../app.json';
import { pushAlert } from "./alert-actions";
import { authFetch } from "./auth-actions";
import { IPatientConsultationHistory } from "../models/user-consultation-history";
import { IFile } from "../models/file";
import { updateFiles } from "./file-action";
import { RootState } from "../store";
import { IUpload } from "../models/upload";

function topDoctorData() {
    let topDoctor = environment.topDoctor;
    if (i18n.language === 'az') topDoctor = environment.topDoctorAz;
    if (i18n.language === 'ru') topDoctor = environment.topDoctorRu;
    return topDoctor;
}

export const PATIENT_CONSULTATION_HISTORY_LIST = 'PATIENT_CONSULTATION_HISTORY_LIST';
interface PatientConsultationHistoryListAction {
    type: typeof PATIENT_CONSULTATION_HISTORY_LIST;
    consultationHistories: IPatientConsultationHistory[];
}

export const _patientConsultationHistoryList: ActionCreator<PatientConsultationHistoryListAction> = (consultationHistories: IPatientConsultationHistory[]) => {
    return {
        type: PATIENT_CONSULTATION_HISTORY_LIST,
        consultationHistories
    };
};

export const PATIENT_CONSULTATION_HISTORY_ITEM = 'PATIENT_CONSULTATION_HISTORY_ITEM';
interface PatientConsultationHistoryItemAction {
    type: typeof PATIENT_CONSULTATION_HISTORY_ITEM;
    consultationHistory: IPatientConsultationHistory | null;
}

export const _patientConsultationHistoryItem: ActionCreator<PatientConsultationHistoryItemAction> = (consultationHistory: IPatientConsultationHistory | null) => {
    return {
        type: PATIENT_CONSULTATION_HISTORY_ITEM,
        consultationHistory
    };
};

export type PatientConsultationHistoryActionTypes = PatientConsultationHistoryListAction | PatientConsultationHistoryItemAction;

export const getPatientConsultationHistoryList = (
): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch, getState) => {
    dispatch(loadingStart(LoadingSubject.PatientConsultationHistoryList));

    try {
        const response = await authFetch(
            getState, dispatch, topDoctorData() + '/patientconsultations?per-page=1000', 'GET',
            null, {Accept: 'application/json', 'Content-Type': 'application/json'}, '&token='
        );

        console.log('response', response);

        if (response.status === 200) {
            const json = await response.json();
            console.log('json', json);
            dispatch(_patientConsultationHistoryList(json));
        }
        else if (response.status === 401) {
            //dispatch(signOut(token));
        }
        else {
            dispatch(pushAlert('Unknown error. Please, check internet connection'));
            //dispatch(errorPatientDoctorList(json.code, token));
        }
    } catch (e) {
        dispatch(pushAlert('Unknown error. Please, check internet connection'));
    } finally {
        dispatch(loadingEnd(LoadingSubject.PatientConsultationHistoryList));
    }
}

export const getPatientConsultationHistory = (
    id: number,
): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch, getState) => {
    dispatch(loadingStart(LoadingSubject.PatientConsultationHistory));

    try {
        const response = await authFetch(
            getState, dispatch, topDoctorData() + '/patientconsultations/' + id, 'GET',
            null, {Accept: 'application/json', 'Content-Type': 'application/json'}, '?token='
        );

        console.log('response', response);

        if (response.status === 200) {
            const json = await response.json();
            console.log('json', json);
            dispatch(_patientConsultationHistoryItem(json));
        }
        else if (response.status === 401) {
            //dispatch(signOut(token));
        }
        else {
            dispatch(pushAlert('Unknown error. Please, check internet connection'));
            //dispatch(errorPatientDoctorList(json.code, token));
        }
    } catch (e) {
        dispatch(pushAlert('Unknown error. Please, check internet connection'));
    } finally {
        dispatch(loadingEnd(LoadingSubject.PatientConsultationHistory));
    }
}

export const createPatientConsultationHistory = (
    consultationhistory: IPatientConsultationHistory,
    uploadFiles: IUpload[], deleleFiles: IFile[],
    host_type: number,
    success: () => void,
): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch, getState) => {
    dispatch(loadingStart(LoadingSubject.PatientConsultationHistory));
    console.warn('consultationhistory', consultationhistory);
    try {
        const response = await authFetch(
            getState, dispatch, topDoctorData() + '/patientconsultations', 'POST',
            consultationhistory, {Accept: 'application/json', 'Content-Type': 'application/json'}, '?token='
        );

        console.log('response', response);

        if (response.status === 200) {
            const json = await response.json();
            console.log('json', json);
            const id = json.payload.id;
            await updateFiles(getState, dispatch, uploadFiles, deleleFiles, id, host_type);
            dispatch(getPatientConsultationHistoryList());
            success && success();
        }
        else if (response.status === 401) {
            //dispatch(signOut(token));
        }
        else {
            dispatch(pushAlert('Unknown error. Please, check internet connection'));
            //dispatch(errorPatientDoctorList(json.code, token));
        }
    } catch (e) {
        dispatch(pushAlert('Unknown error. Please, check internet connection'));
    } finally {
        dispatch(loadingEnd(LoadingSubject.PatientConsultationHistory));
    }
}


export const deletePatientConsultationHistory = (
    id: number,
): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch, getState) => {
    dispatch(loadingStart(LoadingSubject.PatientConsultationHistory));

    try {
        const response = await authFetch(
            getState, dispatch, topDoctorData() + '/patientconsultations/' + id, 'DELETE',
            null, {Accept: 'application/json', 'Content-Type': 'application/json'}, '?token='
        );

        console.log('response', response);

        if (response.status === 200) {
            const json = await response.json();
            console.log('json', json);
            dispatch(getPatientConsultationHistoryList());
        }
        else if (response.status === 401) {
            //dispatch(signOut(token));
        }
        else {
            dispatch(pushAlert('Unknown error. Please, check internet connection'));
            //dispatch(errorPatientDoctorList(json.code, token));
        }
    } catch (e) {
        dispatch(pushAlert('Unknown error. Please, check internet connection'));
    } finally {
        dispatch(loadingEnd(LoadingSubject.PatientConsultationHistory));
    }
}


export const updatePatientConsultationHistory = (
    consultationhistory: IPatientConsultationHistory,
    uploadFiles: IUpload[], deleleFiles: IFile[],
    host_type: number,
    success: () => void,
): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch, getState) => {
    dispatch(loadingStart(LoadingSubject.PatientConsultationHistory));

    try {
        const response = await authFetch(
            getState, dispatch, topDoctorData() + '/patientconsultations/' + consultationhistory.id, 'PATCH',
            consultationhistory, {Accept: 'application/json', 'Content-Type': 'application/json'}, '?token='
        );

        console.log('response', response);

        if (response.status === 200) {
            const json = await response.json();
            console.log('json', json);
            await updateFiles(getState, dispatch, uploadFiles, deleleFiles, consultationhistory.id, host_type);
            dispatch(getPatientConsultationHistoryList());
            success && success();
        }
        else if (response.status === 401) {
            //dispatch(signOut(token));
        }
        else {
            dispatch(pushAlert('Unknown error. Please, check internet connection'));
            //dispatch(errorPatientDoctorList(json.code, token));
        }
    } catch (e) {
        dispatch(pushAlert('Unknown error. Please, check internet connection'));
    } finally {
        dispatch(loadingEnd(LoadingSubject.PatientConsultationHistory));
    }
}