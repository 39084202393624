import {IPatientClinicState} from "../states/patient-clinic-state";
import {IPatientClinic} from "../models/patient-clinic";
import {
    DELETE_PATIENT_MY_CLINIC,
    PATIENT_CLINIC_LIST,
    PATIENT_MY_CLINIC,
    PATIENT_MY_CLINIC_LIST,
    PatientClinicActionTypes, SAVE_PATIENT_MY_CLINIC, UPDATE_PATIENT_MY_CLINIC, MYCLINICS_PAGE_SIZE
} from "../actions/patient-clinic-action";

const initialState: IPatientClinicState = {
    clinics: [],
    patientClinic: undefined,
    patientClinicList: [],
    patientClinicListMap: new Map<number, IPatientClinic>(),
    patientClinicsPage: 1,
    patientClinicsFinal: false,
    clinicsPage: 1,
    clinicsFinal: false,
}

export function patientClinicReducer(
    state = initialState,
    action: PatientClinicActionTypes
): IPatientClinicState {
    let patientClinicList: IPatientClinic[] | undefined;
    let patientClinicListMap: Map<number, IPatientClinic>;
    switch (action.type) {
        case PATIENT_CLINIC_LIST:
            const clinics = action.page > 1 ? state.clinics.concat(action.clinics || []) : action.clinics;
            return {...state, ...{clinics: clinics, clinicsFinal: action.clinics?.length < MYCLINICS_PAGE_SIZE || action.end, clinicsPage: action.page + 1}};
        case PATIENT_MY_CLINIC:
            return {...state, ...{patientClinic: action.patientClinic}};
        case PATIENT_MY_CLINIC_LIST:
            patientClinicListMap = state.patientClinicListMap;
            if (patientClinicListMap && action.patientClinicList && action.patientClinicList.length) {
                if (action.page === 1) {
                    patientClinicListMap.clear();
                }
                // action.patientClinicList.map((el) => patientClinicListMap.set(el.id, el));
            }
            patientClinicList = action.page > 1 ? state.patientClinicList.concat(action.patientClinicList || [])
                : action.patientClinicList;

            return {...state, ...{patientClinicList: patientClinicList,
                    patientClinicsPage: action.page,
                    patientClinicsFinal: action.patientClinicList?.length < MYCLINICS_PAGE_SIZE || action.end,
                    patientClinicListMap: patientClinicListMap}};
        case SAVE_PATIENT_MY_CLINIC:
            return state;
        case UPDATE_PATIENT_MY_CLINIC:
            patientClinicList = state.patientClinicList &&
                state.patientClinicList.map((el) => {
                    if (el && el.id === action.patientClinic.id) {
                        return action.patientClinic;
                    }
                    return el;
                });
            patientClinicListMap = state.patientClinicListMap;
            if (patientClinicListMap &&
                patientClinicListMap.get(action.patientClinic.id as number)) {
                patientClinicListMap.set(action.patientClinic.id as number, action.patientClinic);
            }
            return {
                ...state, ...{
                    patientClinic: action.patientClinic,
                    patientClinicListMap: patientClinicListMap,
                    patientClinicList: patientClinicList
                }
            };
         case DELETE_PATIENT_MY_CLINIC:
             patientClinicList = state.patientClinicList &&
                state.patientClinicList.filter((el) => el && el.id !== action.id);
             patientClinicListMap = state.patientClinicListMap;
            if (patientClinicListMap &&
                patientClinicListMap.get(action.id)) {
                patientClinicListMap.delete(action.id);
            }
            return {...state, ...{ patientClinicListMap: patientClinicListMap,
                    patientClinicList: patientClinicList}};
        default:
            return state;
    }
}
