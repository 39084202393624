import { IPatientAnalyzeState } from "../states/patient-analyze-state";
import { PatientAnalyzeActionTypes, PATIENT_ANALYZE_LIST, PATIENT_ANALYZE_ITEM } from "../actions/patient-analyze-actions";

const initialState: IPatientAnalyzeState = {
}

export function patientAnalyzeReducer(
    state = initialState,
    action: PatientAnalyzeActionTypes
): IPatientAnalyzeState {
    switch (action.type) {
        case PATIENT_ANALYZE_LIST:
            return {...state, ...{list: action.analyzes}};
        case PATIENT_ANALYZE_ITEM:
            return {...state, ...{selected: action.analyze}};
        default:
            return state;
    }
}
