import { IPatientNoteState } from "../states/patient-note-state";
import { PatientNoteActionTypes, PATIENT_NOTE_LIST, PATIENT_NOTE_ITEM } from "../actions/patient-note-actions";

const initialState: IPatientNoteState = {
}

export function patientNoteReducer(
    state = initialState,
    action: PatientNoteActionTypes
): IPatientNoteState {
    switch (action.type) {
        case PATIENT_NOTE_LIST:
            return {...state, ...{list: action.notes}};
        case PATIENT_NOTE_ITEM:
            return {...state, ...{selected: action.note}};
        default:
            return state;
    }
}
