import { ActionCreator, Action } from "redux";
import { ThunkAction } from "redux-thunk";
import { loadingStart, loadingEnd } from "./loading-actions";
import { LoadingSubject } from "../states/loading-state";
import i18n from 'i18next';
import * as environment from '../app.json';
import { pushAlert } from "./alert-actions";
import { authFetch } from "./auth-actions";
import { IPatientAllergy } from "../models/user-allergy";
import { IFile } from "../models/file";
import { updateFiles } from "./file-action";
import { RootState } from "../store";
import { IUpload } from "../models/upload";

function topDoctorData() {
    let topDoctor = environment.topDoctor;
    if (i18n.language === 'az') topDoctor = environment.topDoctorAz;
    if (i18n.language === 'ru') topDoctor = environment.topDoctorRu;
    return topDoctor;
}

export const PATIENT_ALLERGY_LIST = 'PATIENT_ALLERGY_LIST';
interface PatientAllergyListAction {
    type: typeof PATIENT_ALLERGY_LIST;
    allergies: IPatientAllergy[];
}

export const _patientAllergyList: ActionCreator<PatientAllergyListAction> = (allergies: IPatientAllergy[]) => {
    return {
        type: PATIENT_ALLERGY_LIST,
        allergies
    };
};

export const PATIENT_ALLERGY_ITEM = 'PATIENT_ALLERGY_ITEM';
interface PatientAllergyItemAction {
    type: typeof PATIENT_ALLERGY_ITEM;
    allergy: IPatientAllergy | null;
}

export const _patientAllergyItem: ActionCreator<PatientAllergyItemAction> = (allergy: IPatientAllergy | null) => {
    return {
        type: PATIENT_ALLERGY_ITEM,
        allergy
    };
};

export type PatientAllergyActionTypes = PatientAllergyListAction | PatientAllergyItemAction;

export const getPatientAllergyList = (
): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch, getState) => {
    dispatch(loadingStart(LoadingSubject.PatientAllergyList));

    try {
        const response = await authFetch(
            getState, dispatch, topDoctorData() + '/patientallergies?per-page=1000', 'GET',
            null, {Accept: 'application/json', 'Content-Type': 'application/json'}, '&token='
        );

        console.log('response', response);

        if (response.status === 200) {
            const json = await response.json();
            console.log('json', json);
            dispatch(_patientAllergyList(json));
        }
        else if (response.status === 401) {
            //dispatch(signOut(token));
        }
        else {
            dispatch(pushAlert('Unknown error. Please, check internet connection'));
            //dispatch(errorPatientDoctorList(json.code, token));
        }
    } catch (e) {
        dispatch(pushAlert('Unknown error. Please, check internet connection'));
    } finally {
        dispatch(loadingEnd(LoadingSubject.PatientAllergyList));
    }
}

export const getPatientAllergy = (
    id: number,
): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch, getState) => {
    dispatch(loadingStart(LoadingSubject.PatientAllergy));

    try {
        const response = await authFetch(
            getState, dispatch, topDoctorData() + '/patientallergies/' + id, 'GET',
            null, {Accept: 'application/json', 'Content-Type': 'application/json'}, '?token='
        );

        console.log('response', response);

        if (response.status === 200) {
            const json = await response.json();
            console.log('json', json);
            dispatch(_patientAllergyItem(json));
        }
        else if (response.status === 401) {
            //dispatch(signOut(token));
        }
        else {
            dispatch(pushAlert('Unknown error. Please, check internet connection'));
            //dispatch(errorPatientDoctorList(json.code, token));
        }
    } catch (e) {
        dispatch(pushAlert('Unknown error. Please, check internet connection'));
    } finally {
        dispatch(loadingEnd(LoadingSubject.PatientAllergy));
    }
}

export const createPatientAllergy = (
    allergy: IPatientAllergy,
    uploadFiles: IUpload[], deleleFiles: IFile[],
    host_type: number,
    success: () => void,
): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch, getState) => {
    dispatch(loadingStart(LoadingSubject.PatientAllergy));

    try {
        const response = await authFetch(
            getState, dispatch, topDoctorData() + '/patientallergies', 'POST',
            allergy, {Accept: 'application/json', 'Content-Type': 'application/json'}, '?token='
        );

        console.log('response', response);

        if (response.status === 200) {
            const json = await response.json();
            console.log('json', json);
            const id = json.payload.id;
            await updateFiles(getState, dispatch, uploadFiles, deleleFiles, id, host_type);
            dispatch(getPatientAllergyList());
            success && success();
        }
        else if (response.status === 401) {
            //dispatch(signOut(token));
        }
        else {
            dispatch(pushAlert('Unknown error. Please, check internet connection'));
            //dispatch(errorPatientDoctorList(json.code, token));
        }
    } catch (e) {
        dispatch(pushAlert('Unknown error. Please, check internet connection'));
    } finally {
        dispatch(loadingEnd(LoadingSubject.PatientAllergy));
    }
}


export const deletePatientAllergy = (
    id: number,
): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch, getState) => {
    dispatch(loadingStart(LoadingSubject.PatientAllergy));

    try {
        const response = await authFetch(
            getState, dispatch, topDoctorData() + '/patientallergies/' + id, 'DELETE',
            null, {Accept: 'application/json', 'Content-Type': 'application/json'}, '?token='
        );

        console.log('response', response);

        if (response.status === 200) {
            const json = await response.json();
            console.log('json', json);
            dispatch(getPatientAllergyList());
        }
        else if (response.status === 401) {
            //dispatch(signOut(token));
        }
        else {
            dispatch(pushAlert('Unknown error. Please, check internet connection'));
            //dispatch(errorPatientDoctorList(json.code, token));
        }
    } catch (e) {
        dispatch(pushAlert('Unknown error. Please, check internet connection'));
    } finally {
        dispatch(loadingEnd(LoadingSubject.PatientAllergy));
    }
}


export const updatePatientAllergy = (
    allergy: IPatientAllergy,
    uploadFiles: IUpload[], deleleFiles: IFile[],
    host_type: number,
    success: () => void,
): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch, getState) => {
    dispatch(loadingStart(LoadingSubject.PatientAllergy));

    try {
        const response = await authFetch(
            getState, dispatch, topDoctorData() + '/patientallergies/' + allergy.id, 'PATCH',
            allergy, {Accept: 'application/json', 'Content-Type': 'application/json'}, '?token='
        );

        console.log('response', response);

        if (response.status === 200) {
            const json = await response.json();
            console.log('json', json);
            await updateFiles(getState, dispatch, uploadFiles, deleleFiles, allergy.id, host_type);
            dispatch(getPatientAllergyList());
            success && success();
        }
        else if (response.status === 401) {
            //dispatch(signOut(token));
        }
        else {
            dispatch(pushAlert('Unknown error. Please, check internet connection'));
            //dispatch(errorPatientDoctorList(json.code, token));
        }
    } catch (e) {
        dispatch(pushAlert('Unknown error. Please, check internet connection'));
    } finally {
        dispatch(loadingEnd(LoadingSubject.PatientAllergy));
    }
}