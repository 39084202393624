import React from 'react';
import { connect } from 'react-redux';
import { Field, InjectedFormProps, reduxForm, FormErrors } from 'redux-form';
import TextInput from '../FormComponents/TextInput';
import { TFunction } from 'i18next';
import { withTranslation } from 'react-i18next';
import { IUserSignUpRequest } from '../../models/user-sign-up-request';

class ForgotRepeatForm extends React.Component<{t: TFunction} & InjectedFormProps<IUserSignUpRequest>> {
    render() {
        const t = this.props.t;
        return (
            <div>
                <Field
                    name="password"
                    placeholder={t('New password')}
                    type="password"
                    component={TextInput}
                />
                <Field
                    name="password_repeat"
                    placeholder={t('Repeat password')}
                    type="password"
                    component={TextInput}
                />
            </div>
        );
    }
}

const validate = (values: IUserSignUpRequest, props: {t: TFunction}): FormErrors<IUserSignUpRequest> => {
    const errors: FormErrors<IUserSignUpRequest> = {};
    if (!values.password || values.password.trim() === '') {
        errors.password = props.t('Field couldn\'t be empty');
    }
    if (!values.password_repeat || values.password_repeat.trim() === '') {
        errors.password_repeat = props.t('Field couldn\'t be empty');
    }
    if (values.password !== values.password_repeat) {
        errors.password_repeat = props.t('Passwords do not match');
    }
    return errors;
};

const form = reduxForm<IUserSignUpRequest, any>({
    form: 'ForgotRepeatForm',
    validate
})(ForgotRepeatForm);

export default connect(null)(withTranslation()(form));