import {
    SIGN_IN,
    SIGN_UP,
    SIGN_OUT,
    SELF_PROFILE,
    PROCESS_STAGE,
    SAVE_USER,
    SAVE_OPERATION,
    AuthActionTypes,
    USER_BY_ID, PRE_SIGN_IN, NEW_DOCTOR1, NEW_DOCTOR2, NEW_DOCTOR, AUTH_ERROR_TEXT, TOGGLE_AUTH
} from '../actions/auth-actions';
import { IAuthState } from '../states/auth-state';
import i18next from 'i18next';

const tokenString = localStorage.getItem("az.ezgil.videodoctor.token");

const initialState: IAuthState = {
    userToken: tokenString ? JSON.parse(tokenString) : tokenString,
    self: undefined,
    processStage: 0,
    user: undefined,
    operation: undefined,
    userProfileById: undefined,
    authErrorText: undefined,
    preUserToken: undefined,
    hasEmail: undefined,
    newDoctor: 0,
    showAuth: false,
}

export function authReducer(
    state = initialState,
    action: AuthActionTypes
): IAuthState {
    switch (action.type) {
        case TOGGLE_AUTH:
            //console.log('state.showAuth', !state.showAuth);
            return { ...state, showAuth: action.value};
        case PRE_SIGN_IN:
            action.userToken && (action.userToken.date = new Date().getTime());
            return { ...state, ...{ preUserToken: action.userToken, newDoctor: 0 } };
        case SIGN_IN:
            action.userToken && (action.userToken.date = new Date().getTime());
            return { ...state, ...{ userToken: action.userToken } };
        case SIGN_OUT:
            return { userToken: undefined, user: undefined, userProfileById: undefined, processStage: 0, self: undefined, operation: undefined, hasEmail: undefined, authErrorText: undefined, preUserToken: undefined, newDoctor: 0, showAuth: false };
        case SELF_PROFILE:
            return { ...state, ...{ self: action.self } };
        case PROCESS_STAGE:
            return { ...state, ...{ processStage: action.processStage } };
        case SAVE_USER:
            return { ...state, ...{ user: action.user } };
        case SAVE_OPERATION:
            return { ...state, ...{ operation: action.operation, hasEmail: action.hasEmail } };
        case USER_BY_ID:
            return { ...state, ...{ userProfileById: action.userProfileById } };
        case NEW_DOCTOR:
            return { ...state, ...{ newDoctor: 0 } };
        case NEW_DOCTOR1:
            return { ...state, ...{ newDoctor: 1 } };
        case NEW_DOCTOR2:
            return { ...state, ...{ newDoctor: 2 } };
        case AUTH_ERROR_TEXT:
            return { ...state, ...{ authErrorText: i18next.t(action.text) } };
        default:
            return state;
    }
}
