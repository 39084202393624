import { IPatientMedicineState } from "../states/patient-medicine-state";
import { PatientMedicineActionTypes, PATIENT_MEDICINE_LIST, PATIENT_MEDICINE_ITEM } from "../actions/patient-medicine-actions";

const initialState: IPatientMedicineState = {
}

export function patientMedicineReducer(
    state = initialState,
    action: PatientMedicineActionTypes
): IPatientMedicineState {
    switch (action.type) {
        case PATIENT_MEDICINE_LIST:
            return { ...state, ...{ list: action.medicines } };
        case PATIENT_MEDICINE_ITEM:
            return { ...state, ...{ selected: action.medicine } };
        default:
            return state;
    }
}
