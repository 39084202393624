import { IChatMessage } from "./chat-message";
import { ICallHistory } from "./call-history";

export interface IWebsocketOutMessage {
    type: OutMessageType;
    authorization: string;
    browser: boolean;
    lastMessageTimestamp?: Date;
    message?: IChatMessage;
    pushType?: PushType;

    callHistory?: ICallHistory;

    partialId?: string;
    partialPart?: string;
    partialEnd?: boolean;
}

export enum OutMessageType {
    Auth = 0, Message = 1, Partial = 2
}

export enum PushType {
    None = 0, Standard = 1, Voip = 2
}