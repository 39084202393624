import React from 'react';
import { Redirect } from 'react-router-dom';
import { isAndroid, isIOS, isWinPhone } from 'react-device-detect';
import { WithTranslation, withTranslation } from 'react-i18next';
import CSS from 'csstype';

// interface DownloadState {
//     userAgent: string
// }

class Download extends React.Component<WithTranslation, {}> {
    constructor(props: WithTranslation) {
        super(props);
        // this.state = {
        //     userAgent: navigator.userAgent || navigator.vendor
        // }
    }

    componentDidMount() {
        if (isAndroid) window.location.replace('https://play.google.com/store/apps/details?id=az.ezgil.videodoctor');
        if (isIOS) window.location.replace('https://apps.apple.com/ru/app/video-doktor/id1520369601');
    }

    render() {
        const { t } = this.props;
        if (isWinPhone) return(
            <div style={pageStyle}>
                <h3>{t('Windows Phone application is developing')}</h3>
            </div>)
        return(<Redirect to={`/`} />)
    }
}

const pageStyle: CSS.Properties = {
    height: 'calc(100vh - 455px - 110px)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
}

export default withTranslation()(Download);