import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { _getPatientClinic } from "../../actions/patient-clinic-action";
import { IAuthState } from "../../states/auth-state";
import { IDictionaryState } from '../../states/dictionary-state';
import { RootState } from "../../store";
import './Clinics.scss';
import { getDoctorListNoRedux } from "../../actions/doctor-action";
import { IDoctorFilter } from "../../models/doctor-filter";
import { i18n, TFunction } from "i18next";
import { Link } from 'react-router-dom';
import { getDoctor } from "../../actions/doctor-action";

interface MyProps {
    auth: IAuthState;
    dictionary: IDictionaryState;
    i18n: i18n;
    clinicId: number;
    getDoctorListNoRedux: (
        filter: IDoctorFilter | undefined,
        page: number,
        pageSize: number,
    ) => void;
    getDoctor: (
        id: number
    ) => void;
    t: TFunction;
}

interface MyState {
    showAllDoctors: boolean;
    doctorsList: any;
}

class DoctorsListComponent extends Component<MyProps, MyState> {

    constructor(props: MyProps) {
        super(props);
        this.state = {
            showAllDoctors: false,
            doctorsList: null,
        };
    }

    async componentDidMount() {
        const { clinicId } = this.props;

        if (clinicId) {
            const doctorsList = await this.props.getDoctorListNoRedux({ clinic: clinicId }, 0, 12);
            this.setState({ doctorsList: doctorsList })

            console.log('doctorsList', doctorsList)
        }

        const { doctorsList } = this.state

        // if (doctorsList.length) {
        //     doctorsList.map((d) => {
        //         this.props.getDoctor(d.id);
        //     })
        // }

    }

    render() {
        const { t } = this.props;
        const { showAllDoctors } = this.state;
        const { doctorsList } = this.state;

        return (
            <div className='select-your-doctor-block'>
                <h2>{t('Choose your doctor')}:</h2>
                <div className={showAllDoctors ? 'my_doctors_list expand_my_doctors' : 'my_doctors_list'}>

                    {
                        doctorsList instanceof Array && doctorsList.length ? doctorsList.map((doc: any) => {
                            return (
                                <Link to={{ pathname: '/doctors', search: `clinic=${this.props.clinicId}&name=${doc.surname + ' ' + doc.name + ' ' + doc.patronymic}` }} >
                                    {doc.surname + ' ' + doc.name + ' ' + doc.patronymic}
                                </Link>
                            )
                        }) : ''
                    }

                </div>

                {
                    doctorsList instanceof Array && doctorsList.length > 10 &&
                    <div className='show_more_clinic_doctors' onClick={() => { this.setState({ showAllDoctors: !showAllDoctors }) }}>
                        <Link to={'/doctors?clinic=' + this.props.clinicId}>
                            {t('Show more')}
                        </Link>
                    </div>
                }
            </div >
        );
    }
}

const mapStateToProps = (state: RootState) => ({
    auth: state.auth,
    dictionary: state.dictionary,
});

const mapDispatchToProps = {
    getDoctorListNoRedux,
    getDoctor,
};

let DoctorsListComponentContainer = connect(mapStateToProps, mapDispatchToProps)(
    DoctorsListComponent
);

export default withTranslation()(DoctorsListComponentContainer);