import {FONT_SIZE, LANGUAGE, SettingsActionType} from "../actions/settings-actions";
import { ISettingsState } from "../states/settings-state";

const initialState: ISettingsState = {
  language: localStorage.getItem('az.ezgil.videodoctor.language') || 'az',
  fontSize: 16
};

export function settingsReducer(state: ISettingsState = initialState, action: SettingsActionType) {
  switch (action.type) {
    case FONT_SIZE: {
      return {...state, ...{fontSize: action.fontSize}};
    }
    case LANGUAGE:
      return {...state, ...{language: action.language}};
    default:
      return state;
  }
}
