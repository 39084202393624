export interface IClinicFilter {
    name?: string | undefined;
    hospital?: number;
    year_o?: string;
    city?: string | number | undefined;
    region?: string | number | undefined;
    type?: string | number | undefined;
    metro?: string | number | undefined;
    workinghours?: string;
    additional_information?: string;
    map_coor?: string;

    sort?: ClinicSort,
    desc?: boolean,
}

export enum ClinicSort {
    Rating = 0, Name = 1
}