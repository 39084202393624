import React from "react";
import { RootState } from "../store";
import {
  submit,
  reset,
  FormAction,
  Field,
  Form,
  reduxForm,
  InjectedFormProps,
  FormErrors,
  formValueSelector,
  ConfigProps,
} from "redux-form";
import { connect } from "react-redux";
import { WithTranslation, withTranslation } from "react-i18next";
import MiniTextInput from "./FormComponents/MiniTextInput";
import MiniDropDownSelect from "./FormComponents/MiniDropDownSelect";
import FilesComponentContainer from "./FormComponents/FilesComponent";
import { IDictionaryState } from "../states/dictionary-state";
import { IDoctor_Add } from "../models/doctor-clinic-add";
import DropDownSelect from "./FormComponents/DropDownSelect";
import "./DoctorAddClinicForm.scss";
import {
  checkDoctorSelectedId,
  checkDoctorSelectedDeleteId,
} from "../actions/doctor-action";

import arrowDown from "../images/arrow-down.png"
import arrowRight from "../images/arrow-right.png"

type Props = {
  scientific_degree?: number;
  language?: (language: string) => void;
  onSubmit?: (data: IDoctor_Add) => void;
  dictionary?: IDictionaryState;
  values: { [key: string]: any };
  checkDoctorSelectedId?: () => void;
  checkDoctorSelectedDeleteId?: () => void;
};

type State = {
  openedFirst: boolean;
  openedSecond: boolean;
  openedThird: boolean;
  openedFourth: boolean;
};

class DoctorAddClinicForm extends React.Component<
  InjectedFormProps<IDoctor_Add, WithTranslation & Props, string> &
  WithTranslation &
  Props,
  State
> {
  constructor(
    props: InjectedFormProps<IDoctor_Add, WithTranslation & Props, string> &
      WithTranslation &
      Props
  ) {
    super(props);
    this.state = {
      openedFirst: true,
      openedSecond: true,
      openedThird: true,
      openedFourth: true,
    };
  }
  openArrowFirst = () => {
    this.setState({ openedFirst: !this.state.openedFirst });
  };
  openArrowSecond = () => {
    this.setState({ openedSecond: !this.state.openedSecond });
  };
  openArrowThird = () => {
    this.setState({ openedThird: !this.state.openedThird });
  };
  openArrowFourth = () => {
    this.setState({ openedFourth: !this.state.openedFourth });
  };

  render() {
    const { t } = this.props;
    const valuesForSexDropdown = [
      { value: "0", label: t("Male") },
      { value: "1", label: t("Female") },
    ];
    const valuesForScientificDegree =
      this.props.dictionary?.academicDegree?.map((d) => ({
        value: d.id,
        label: d.name,
      }));
    const valuesForService = [{ value: "0", label: t("Video reception") }];
    // const valuesFor = [
    //   { value: "0", label: t("Medical Doctor") },
    //   { value: "1", label: t("Female") },
    // ];
    // const valuesForSpecDropdown = [
    //   { value: "246", label: t("Doctor") },
    //   { value: "247", label: t("Vrach") },
    //   { value: "248", label: t("Female") },
    // ];
    const servicesForPatient = [{ value: "0", label: t("Video reception") }];
    console.log("dictionaryValues", this.props);
    return (
      <>
        <div className="arrowBlock">
          {t("Personal information")}
          {this.state.openedFirst ? (
            <>
              <div>
                <img src={arrowDown} />
              </div>
            </>
          ) : (
            <img src={arrowRight} alt="arrow" />
          )}
        </div>
        {this.state.openedFirst && (
          <div className="openedList__wrapper">
            <div className="openedList">
              <div className="openedRegistList">
                <div className="openedRegistList-title">{t("Name")}</div>
                <Field name="name" component={MiniTextInput} type="text" />
              </div>
              <div className="openedRegistList">
                <div className="openedRegistList-title">{t("Surname")}</div>
                <Field name="surname" component={MiniTextInput} type="text" />
              </div>
              <div className="openedRegistList">
                <div className="openedRegistList-title">{t("Patronymic")}</div>
                <Field
                  name="patronymic"
                  component={MiniTextInput}
                  type="text"
                />
              </div>
            </div>
            <div>
              <div className="openedRegister">
                <div className="openedRegistList gender">
                  <div className="openedRegistList-title">{t("Gender")}</div>
                  <Field
                    name="sex"
                    component={DropDownSelect}
                    _className="personal-cabinet-patient-styles_text-dropdown edit-mode without-label"
                    style={{
                      width: "100%",
                      height: "31px",
                      outline: "none",
                      paddingLeft: "8px",
                      marginTop: "-17px",
                      background: "fff",
                    }}
                    valuesForDropdown={valuesForSexDropdown}
                  />
                </div>
                <div className="openedRegistList">
                  <div className="openedRegistList-title birthdate">
                    {t("Birthdate")}
                  </div>
                  <Field
                    // className="offset22"
                    name="birthdate"
                    component={MiniTextInput}
                    type="date"
                    chosed
                  />
                </div>
              </div>
            </div>
          </div>
        )}

        <div className="arrowBlock">
          {t("Education")}
          {this.state.openedSecond ? (
            <img src={arrowDown} />
          ) : (
            <img src={arrowRight} alt="arrow" />
          )}
        </div>

        {this.state.openedSecond && (
          <div className="openedRegister__wrapper">
            <div className="openedRegistList">
              <div className="openedRegistList-title">{t("Education")}</div>{" "}
              <Field
                name="education"
                component={MiniTextInput}
                type="textarea"
              />
            </div>
            <div className="openedRegistList">
              <div className="openedRegistList-title">
                {t("Training work abroad")}
              </div>
              <Field
                name="training_work_abroad"
                component={MiniTextInput}
                type="textarea"
              />
            </div>
            <div className="openedList__wrapper">
              <div className="openedList">
                <div className="openedRegistList">
                  <div className="openedRegistList-title">
                    {t("Scientific degree")}
                  </div>
                  <Field
                    name="scientific_degree"
                    component={DropDownSelect}
                    _className="personal-cabinet-patient-styles_text-dropdown edit-mode without-label"
                    style={{
                      width: "100%",
                      height: "31px",
                      outline: "none",
                      paddingLeft: "8px",
                      marginTop: "-17px",
                      background: "fff",
                    }}
                    valuesForDropdown={valuesForScientificDegree}
                  />
                </div>
                <div className="openedRegistList">
                  <div className="openedRegistList-title">
                    {t("Work experience")}
                  </div>
                  <Field
                    name="work_experience"
                    component={MiniTextInput}
                    type="input"
                  />
                </div>

                <div className="openedRegistList">
                  <div className="openedRegistList-title">{t("Diploma")}</div>
                  <Field
                    name="diplom"
                    component={FilesComponentContainer}
                    type="input"
                  />
                </div>
              </div>
              <div>
                <div className="openedRegister">
                  <div className="openedRegistList">
                    <div className="openedRegistList-title">
                      {t("Diploma series")}
                    </div>
                    <Field
                      name="diplomserie"
                      component={MiniTextInput}
                      type="input"
                    />
                  </div>
                  <div className="openedRegistList">
                    <div className="openedRegistList-title">
                      {t("Diploma number")}
                    </div>
                    <Field
                      name="diplomnumber"
                      component={MiniTextInput}
                      type="input"
                    />
                  </div>
                  <div className="openedRegistList">
                    <div className="openedRegistList-title gender">
                      {t("Language")}
                    </div>
                    <Field
                      name="language"
                      dropName="language"
                      chosed
                      component={MiniDropDownSelect}
                      type={"multiselect"}
                      placeholder={t("Select")}
                      selectedValues={this.props.values}
                      valuesForDropdown={
                        this.props.dictionary &&
                        this.props.dictionary.languages?.map((d) => ({
                          value: d.id,
                          label: d.name,
                        }))
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        <div className="arrowBlock">
          {t("Clinic and specialty")}
          {this.state.openedThird ? (
            <img src={arrowDown} />
          ) : (
            <img src={arrowRight} alt="arrow" />
          )}
        </div>

        {this.state.openedThird && (
          <div className="openedRegister__wrapper">
            <div className="openedList__wrapper">
              <div>
                <div className="openedRegistList">
                  <div className="openedRegistList-title">
                    {t("Specializations")}
                  </div>
                  <div style={{ maxWidth: "200px" }}>
                    <Field
                      name={"specializations"}
                      dropName="specializations"
                      checkDoctorSelectedId={this.props.checkDoctorSelectedId}
                      checkDoctorSelectedDeleteId={
                        this.props.checkDoctorSelectedDeleteId
                      }
                      component={MiniDropDownSelect}
                      doctorId={this.props.initialValues.id}
                      type="multiselect"
                      placeholder={t("Select")}
                      selectedValues={
                        this.props.dictionary?.specializationTypes
                      }
                      valuesForDropdown={this.props.dictionary?.specializationTypes?.map(
                        (d) => ({
                          value: d.id,
                          label: d.name,
                        })
                      )}
                    />
                  </div>
                </div>
                <div className="openedRegistList">
                  <div className="openedRegistList-title">{t("Diseases")}</div>
                  <div style={{ maxWidth: "200px" }}>
                    <Field
                      name="diseases"
                      placeholder={t("Select")}
                      dropName="diseases"
                      chosed
                      checkDoctorSelectedId={this.props.checkDoctorSelectedId}
                      checkDoctorSelectedDeleteId={
                        this.props.checkDoctorSelectedDeleteId
                      }
                      doctorId={this.props.initialValues.id}
                      component={MiniDropDownSelect}
                      type="multiselect"
                      selectedValues={this.props.dictionary?.diseases}
                      valuesForDropdown={this.props.dictionary?.diseases?.map(
                        (d) => ({
                          value: d.id,
                          label: d.name,
                        })
                      )}
                    />
                  </div>
                </div>
                <div className="openedRegistList">
                  <div className="openedRegistList-title">
                    {t("Procedures")}
                  </div>
                  <div style={{ maxWidth: "200px" }}>
                    <Field
                      name="procedures"
                      placeholder={t("Select")}
                      doctorId={this.props.initialValues.id}
                      component={MiniDropDownSelect}
                      checkDoctorSelectedId={this.props.checkDoctorSelectedId}
                      checkDoctorSelectedDeleteId={
                        this.props.checkDoctorSelectedDeleteId
                      }
                      dropName="procedures"
                      type="multiselect"
                      selectedValues={this.props.dictionary?.procedures}
                      valuesForDropdown={this.props.dictionary?.procedures?.map(
                        (d) => ({ value: d.id, label: d.name })
                      )}
                    />
                  </div>
                </div>
                <div className="openedRegistList">
                  <div className="openedRegistList-title">
                    {t("License series")}
                  </div>
                  <Field
                    name="certificateserie"
                    component={MiniTextInput}
                    type="input"
                  />
                </div>
              </div>
              <div>
                <div className="openedRegistList">
                  <div className="openedRegistList-title">
                    {t("Doctor's code")}
                  </div>
                  <Field name="code" component={MiniTextInput} type="input" />
                </div>
                <div className="openedRegistList">
                  <div className="openedRegistList-title">
                    {t("License number")}
                  </div>
                  <Field
                    name="certificatenumber"
                    component={MiniTextInput}
                    type="input"
                  />
                </div>
                <div className="openedRegistList">
                  <div className="openedRegistList-title">{t("Workplace")}</div>
                  <Field
                    name="works"
                    component={MiniTextInput}
                    type="input"
                    value={this.props.values?.works[0]["Clinic Name"]}
                  />
                </div>
              </div>
            </div>
          </div>
        )}

        <div className="arrowBlock">
          {t("Rates")}
          {this.state.openedFourth ? (
            <div>
              <img src={arrowDown} />
            </div>
          ) : (
            <img src={arrowRight} alt="arrow" />
          )}
        </div>

        {this.state.openedFourth && (
          <div className="openedRegister__wrapper">
            <div className="openedList__wrapper">
              <div className="openedList">
                <div className="openedRegistList">
                  <div className="openedRegistList-title">{t("Services")}</div>
                  <Field
                    component={DropDownSelect}
                    _className="personal-cabinet-patient-styles_text-dropdown edit-mode without-label"
                    style={{
                      width: "100%",
                      height: "31px",
                      outline: "none",
                      paddingLeft: "8px",
                      marginTop: "-17px",
                      background: "fff",
                    }}
                    name="services"
                    valuesForDropdown={servicesForPatient}
                  />
                </div>
                <div className="openedRegistList">
                  <div className="openedRegistList-title">{t("Price")}</div>
                  <Field
                    name="prices"
                    component={MiniTextInput}
                    type="input"
                    value={this.props.values}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}

const validate = (
  values: IDoctor_Add,
  props: Partial<ConfigProps<IDoctor_Add, WithTranslation & Props, string>> &
    WithTranslation &
    Props
): FormErrors<any> => {
  console.log(values, "values of addDoctorForm");
  const errors: FormErrors<any> = {};
  const { t } = props;

  if (!values.name || values.name.trim() === "") {
    errors.name = t("Field couldn't be empty");
  }
  if (!values.surname || values.surname.trim() === "") {
    errors.surname = t("Field couldn't be empty");
  }
  if (!values.patronymic || values.patronymic.trim() === "") {
    errors.patronymic = t("Field couldn't be empty");
  }
  // if (!values.sex || values.sex.trim() === "") {
  //   errors.sex = t("Field couldn't be empty");
  // }
  if (!values.birthdate) {
    errors.birthdate = t("Field couldn't be empty");
  }
  if (!values.education || values.education.trim() === "") {
    errors.education = t("Field couldn't be empty");
  }
  if (!values.scientific_degree) {
    errors.scientific_degree = t("Field couldn't be empty");
  }
  // if (!values.training_work_abroad || values.training_work_abroad.trim() === '') {
  //     errors.training_work_abroad = t('Field couldn\'t be empty');
  // }
  if (!values.work_experience || values.work_experience.trim() === "") {
    errors.work_experience = t("Field couldn't be empty");
  }
  // if (!values.additional_information || values.additional_information.trim() === '') {
  //     errors.additional_information = t('Field couldn\'t be empty');
  // }
  if (
    !values.language ||
    (typeof values.language === "object" && values.language.length === 0)
  ) {
    errors.language = t("Field couldn't be empty");
  }
  if (!values.specializations) {
    errors.specializations = t("Field couldn't be empty");
  }
  if (!values.procedures) {
    errors.procedures = t("Field couldn't be empty");
  }
  if (!values.works) {
    errors.works = t("Field couldn't be empty");
  }
  if (!values.services || values.services.trim() === "") {
    errors.services = t("Field couldn't be empty");
  }
  // if (!values.code || values.code.trim() === '') {
  //     errors.code = t('Field couldn\'t be empty');
  // }
  // if (!values.work || values.work.trim() === '') {
  //     errors.work = t('Field couldn\'t be empty');
  // }
  if (
    !values.uploadfileimage ||
    (Object.keys(values.uploadfileimage).length !== 0 &&
      values.uploadfileimage.constructor === Object)
  ) {
    errors.uploadfileimage = t("Field couldn't be empty");
  }

  return errors;
};

const form = reduxForm<IDoctor_Add, WithTranslation & Props, string>({
  form: "AddDoctorForm",
  validate,
})(DoctorAddClinicForm);
const selector = formValueSelector("AddDoctorForm");

const mapStateToProps = (state: RootState, ownProps: Props) => {
  const { type, sex, scientific_degree, language } =
    selector(state, "type", "sex", "scientific_degree", "language") || null;

  return {
    type,
    sex,
    scientific_degree,
    language: language,
    dictionary: state.dictionary,
    initialValues: ownProps.values,
  };
};

const mapDispatchToProps = {
  submit,
  reset,
  checkDoctorSelectedId,
  checkDoctorSelectedDeleteId,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(form));
