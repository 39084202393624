import s from "./Header.module.scss";
import React, { Component } from "react";
import { connect } from "react-redux";
import { RootState } from "../../../store";
import AuthComponentContainer from "./../../Auth/Auth";
import { IAuthState } from "../../../states/auth-state";
import { language } from "../../../actions/settings-actions";
import {
  Link,
  Redirect,
  RouteComponentProps,
  withRouter,
} from "react-router-dom";
import { WithTranslation, withTranslation } from "react-i18next";
import figma0 from "./../../../images/figma0.png";
interface HeaderProps {
  auth: IAuthState;
  lang: string;
  language: (language: string) => void;
}

interface HeaderState { }

class HeaderComponent2 extends Component<
  HeaderProps & RouteComponentProps & WithTranslation,
  HeaderState
> {
  constructor(props: HeaderProps & RouteComponentProps & WithTranslation) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        <header className={s.header_wrapper}>
          <div className={s.header}>
            <div className={s.header_logo_lang}>
              <Link className={s.header_logo_container} to="/">
                <div className={s.header_logo}>
                  <img
                    width={"80px"}
                    height={"80px"}
                    src={figma0}
                    alt="logo"
                  />
                </div>
                <div className={s.header_title}>VideoDoktor</div>
              </Link>
            </div>
            <div className={s.header_lang}>
              <AuthComponentContainer />
            </div>
          </div>
        </header>
      </>
    );
  }
}
const mapStateToProps = (state: RootState) => ({
  auth: state.auth,
  lang: state.settings.language,
});

const mapDispatchToProps = {
  language,
};

let HeaderComponentContainer2 = connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(withRouter(HeaderComponent2)));

export default HeaderComponentContainer2;