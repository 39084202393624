import React from 'react';
import { connect } from 'react-redux';
import { Field, InjectedFormProps, reduxForm, FormErrors } from 'redux-form';
import { IUser } from '../../models/user';
import { TFunction } from 'i18next';
import { withTranslation } from 'react-i18next';
import TextInputWithoutLabel from '../FormComponents/TextInputWithoutLabel';

class ForgotForm extends React.Component<{t: TFunction} & InjectedFormProps<IUser>> {
    render() {
        return (
            <div>
                <Field name="login" placeholder={this.props.t("Input login, email or phone")} component={TextInputWithoutLabel} />
            </div>
        );
    }
}

const validate = (values: IUser, props: {t: TFunction}): FormErrors<IUser> => {
    const errors: FormErrors<IUser> = {};
    if (!values.login || values.login.trim() === '') {
        errors.login = props.t('Field couldn\'t be empty');
    }
    return errors;
};

const form = reduxForm<IUser, any>({
    form: 'ForgotForm',
    validate
})(ForgotForm);

export default connect(null)(withTranslation()(form));