import React, { Component } from "react";
import { connect } from "react-redux";
import { RootState } from "../../../store";
import "./DoctorProfile.scss";
import { submit, reset, FormAction, change } from "redux-form";
import { ILoadingState, LoadingSubject } from "../../../states/loading-state";
import { IDictionaryState } from "../../../states/dictionary-state";
import Modal from "react-bootstrap/Modal";
import { TFunction } from "i18next";
import { WithTranslation, withTranslation } from "react-i18next";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import PropTypes from "prop-types";
import InfiniteScroll from "react-infinite-scroll-component";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import { showConfirm } from "../../Dialogs";
import { IProfileState } from "../../../states/profile-state";
import { IProfileSection } from "../../../models/profile-section";
import {
  getProfileValue,
  getProfileValues,
  profileValueChangeBulk,
  profileValueDeleteBulk,
  profileValueSave,
} from "../../../actions/profile-actions";
import { UserRole } from "../../../models/user-role.enum";
import history from "./../../../history";
import DoctorProfileForm from "./DoctorProfileForm";
import { LoadingCenter } from "../../Loading/Loading";
import Breadcrumbs from "./../../Breadcrumbs/Breadcrumbs";
import { IProfileValue } from "../../../models/profile-value";
import { IDictionary } from "../../../models/dictionary";
import { ProfileValueType } from "../../../models/profile-value-type";
import moment from "moment";
import Spinner from "react-spinner-material";
import Form from "react-bootstrap/Form";
import { IUserSignUpRequest } from "../../../models/user-sign-up-request";
import {
  signIn,
  signUp,
  authErrorText,
  verifyPhone,
  verifyEmail,
  processStageChange,
  newDoctor,
  newDoctor1,
  newDoctor2,
  _preSignIn,
  restorePassword,
  verifyOperation,
  restoreChangePassword,
  resendVerification,
  toggleAuth,
} from "../../../actions/auth-actions";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { IDoctor_1_Step } from "../../../models/doctor-1-step";
import { IDoctor_2_Step } from "../../../models/doctor-2-step";
import { IUser } from "../../../models/user";
import { IAuthState, LoginStage } from "../../../states/auth-state";
import {
  getClinicsDoctorsList,
  postClinicDoctor,
  createDoctorAccount,
  editDoctorData,
  unDraftDoctor,
  profileDeleteDoctor,
  clearState,
} from "../../../actions/doctor-action";
import DoctorAddClinicForm from "../../../forms/DoctorAddClinicForm";
import { Button, TabContainer } from "react-bootstrap";
import { IDoctor_Add } from "../../../models/doctor-clinic-add";
import NoUserPhoto from "../../../images/doctor-default-avatar.png";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import CreateAccountForm from "../../../forms/create-account-doctor";
import { IClinicDoctorSignUp } from "../../../models/clinic-doctor-sign-up";
import { IDoctor_Delete } from "../../../models/doctor-clinic-delete";

import doctorProfileImg from "./../../../images/error-img.png";
import sortDown from "../../../images/medical-card/small-icons/sort-down.svg";
interface MyProps {
  t: TFunction;
  doctorsList: IDoctor_Add[];
  reset: (form: string) => FormAction;
  loading: ILoadingState;
  auth: IAuthState;
  dictionary: IDictionaryState;
  pushAlert: (text: string) => void;
  location: Location;
  value: number;
  profile: IProfileState;
  onSubmit?: (data: { [key: string]: any }) => void;
  submit: (form: string) => FormAction;
  signIn: (user: IUser, onSuccess: any) => void;
  signUp: (user: IUserSignUpRequest, onSuccess: any) => void;
  _preSignIn: (userToken: any) => void;
  authErrorText: (text: string) => void;
  verifyPhone: (username: string, code: string) => void;
  verifyEmail: (username: string, code: string) => void;
  processStageChange: (processStage: number) => void;
  getClinicsDoctorsList: () => void;
  newDoctor: (
    user: IUserSignUpRequest,
    onSuccess: any,
    doctorStep: any
  ) => void;
  newDoctor1: (user: IDoctor_1_Step, token: string, doctorStep: any) => void;
  newDoctor2: (
    user: IDoctor_2_Step,
    token: string,
    doctorFinal: any,
    doctorStep: any
  ) => void;
  restorePassword: (emailOrLogin: string) => void;
  verifyOperation: (operation: string, code: string) => void;
  restoreChangePassword: (operation: string, user: IUser) => void;
  resendVerification: (
    emailOrUsername: string,
    subject: string,
    operation: string
  ) => void;
  language: (language: string) => void;

  signOut: (token: string) => void;
  toggleAuth: (b: boolean) => void;
  fetchSelf: (token: string, role: UserRole) => void;

  getProfileValues: (section: IProfileSection, page: number) => void;

  getProfileValue: (id: number) => Promise<{ [key: string]: any } | null>;

  profileValueSave: (
    values: { [key: string]: any },
    success: () => void
  ) => void;

  profileValueDeleteBulk: (values: { [key: string]: any }[]) => void;

  profileDeleteDoctor: (values: { [key: string]: IDoctor_Add[] }[]) => void;

  profileValueChangeBulk: (
    toDelete: { [key: string]: any }[],
    toAdd: { [key: string]: any }[]
  ) => void;
  postClinicDoctor: (data: IDoctor_Add, id: number | null) => void;

  createDoctorAccount: (data: IClinicDoctorSignUp, id: number | null) => void;
  editDoctorData: (data: IDoctor_Add, id: number | null) => void;
  unDraftDoctor: (id: number | null) => void;
  clearState: () => void;
}

interface MyState {
  updateItem: { [key: string]: any } | null;
  loading: boolean;
  lang: string;
  removeMode: {} | null;
  changeMode: { [key: string]: any }[] | null;
  searchQuery: string;
  resultData: { [key: string]: any }[];
  searchPressed: boolean;
  openLogin: boolean;
  openRegistration: boolean;
  asDoctor: boolean;
  doctorStep: any;
  doctorFinal: boolean;
  value: any;
  selectedIndex: number;
  activeTabIndex: number;
  isZoomed: boolean;
  modalShow: { [key: string]: any } | null;
  selectedItem: number | null;
}

class ProfileComponent extends Component<MyProps & WithTranslation, MyState> {
  constructor(props: MyProps & WithTranslation) {
    super(props);

    this.state = {
      updateItem: null,
      removeMode: null,
      changeMode: null,
      lang: props.i18n.language,
      loading: false,
      searchQuery: "",
      resultData: [],
      searchPressed: false,
      openRegistration: false,
      asDoctor: false,
      openLogin: false,
      doctorStep: 1,
      doctorFinal: false,
      activeTabIndex: 1,
      isZoomed: false,
      value: 0,
      selectedIndex: 0,
      modalShow: null,
      selectedItem: null,
    };

    const role = props.auth.userToken?.role;

    if (role !== UserRole.Doctor && role !== UserRole.Clinic) {
      history.push("/");
      return;
    }
    const section =
      role === UserRole.Doctor ? props.profile.section : props.profile.section;

    props.getProfileValues(section, 0);
  }

  setActive(i: IProfileSection) {
    this.setState({
      removeMode: null,
      changeMode: null,
      resultData: [],
      searchQuery: "",
      searchPressed: false,
    });
    if (i.name === "clinicsdoctors") {
      this.props.getClinicsDoctorsList();
      return;
    }
    this.props.getProfileValues(i, 0);
  }

  updateListData(page: number) {
    const role = this.props.auth?.userToken?.role;
    const section =
      role === UserRole.Doctor
        ? this.props.profile.section
        : this.props.profile.section;

    this.props.getProfileValues(section, page);
  }

  async confirmDelete() {
    if (await showConfirm("Are you sure to delete this item(s)?")) {
      const lines: { [key: string]: any }[] = [];

      for (const index in this.state.removeMode) {
        if (this.state.removeMode[index]) {
          lines.push(this.props.profile.values[index]);
        }
      }
      this.props.profileValueDeleteBulk(lines);
      this.setState({ removeMode: null });
    }
  }

  onSubmit(data: { [key: string]: any }) {
    this.props.profileValueSave(data, () => {
      this.setState({ updateItem: null });
    });
  }

  setLanguage(
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    lang: string
  ): void {
    e.preventDefault();
    this.setState({ lang });
  }
  itemLabel(item: { [key: string]: any }, value: IProfileValue) {
    const raw = item[value.name];
    if (value.type === ProfileValueType.dictionary) {
      const dict = this.props.dictionary[value.dictionary + "Map"];
      if (dict && dict[raw] !== undefined) {
        return (dict[raw] as IDictionary).name;
      }
    } else if (value.type === ProfileValueType.boolean) {
      return raw == 1 ? this.props.t("Yes") : this.props.t("No");
    } else if (value.type === ProfileValueType.date) {
      return raw === "0000-00-00 00:00:00"
        ? ""
        : moment(raw).format("DD.MM.YYYY");
    }
    return raw;
  }
  handleChange(event) {
    this.setState({ value: event.target.value });
  }
  confirmChange() {
    const { changeMode } = this.state;
    if (!changeMode) {
      return;
    }
    const toDelete: { [key: string]: any }[] = [];
    const toAdd: { [key: string]: any }[] = [];
    for (const c of changeMode) {
      if (c.id && !c.selected) {
        toDelete.push(c);
        continue;
      }
      if (!c.id && c.selected) {
        toAdd.push(c);
      }
    }
    this.props.profileValueChangeBulk(toDelete, toAdd);
    this.setState({ changeMode: null });
  }

  change() {
    let {
      profile: { values },
      dictionary,
    } = this.props;
    const { resultData } = this.state;

    values = resultData.length ? resultData : values;

    const role = this.props.auth?.userToken?.role;
    const section =
      role === UserRole.Doctor
        ? this.props.profile.section
        : this.props.profile.section;

    const map: { [key: string]: boolean } = {};
    if (!values || !section?.values?.length) {
      return;
    }
    const changeMode = values.map((v) => {
      map[v.id] = true;
      return { ...v, selected: true };
    });

    const value = section.values[0];
    const dict = dictionary[value.dictionary || ""] || [];

    if (!dict.length) {
      return;
    }

    const dictData = dict
      .filter((v) => !map[v.id])
      .map((v) => ({ [value.name]: v.id, selected: false }));

    this.setState({ changeMode: changeMode.concat(dictData) });
  }
  tableForDoctorsList() {
    const { t, getProfileValue } = this.props;
    const role = this.props.auth?.userToken?.role;
    const section =
      role === UserRole.Doctor
        ? this.props.profile.section
        : this.props.profile.section;

    const { changeMode, resultData } = this.state;
    const tableValues = section.values.filter((v) => v.table);
    let values = changeMode || this.props.profile.values;

    const singleDictionary =
      section.values?.length === 1 &&
      section.values[0].type === ProfileValueType.dictionary;

    if (resultData.length && singleDictionary) {
      values = resultData;
    }

    if (!resultData.length && this.state.searchPressed) {
      return (
        <div className="no-results">
          <p>no results</p>
        </div>
      );
    }

    return (
      <table
        className={this.props.auth?.userToken?.role === 3 ? "pointer" : ""}
      >
        <tr>
          {tableValues?.map((v) => (
            <th>{t("Doctors of clinic")}</th>
          ))}
        </tr>
      </table>
    );
  }
  table() {
    const { t, getProfileValue } = this.props;
    const role = this.props.auth?.userToken?.role;
    const section =
      role === UserRole.Doctor
        ? this.props.profile.section
        : this.props.profile.section;

    const { changeMode, resultData } = this.state;
    const tableValues = section.values.filter((v) => v.table);
    let values = changeMode || this.props.profile.values;

    if (!values.map) {
      values = [];
    }

    const singleDictionary =
      section.values?.length === 1 &&
      section.values[0].type === ProfileValueType.dictionary;

    if (resultData.length && singleDictionary) {
      values = resultData;
    }

    if (!resultData.length && this.state.searchPressed) {
      return (
        <div className="no-results">
          <p>no results</p>
        </div>
      );
    }

    return (
      <table
        className={this.props.auth?.userToken?.role === 3 ? "pointer" : ""}
      >
        <tbody>
          <tr>
            {tableValues?.map((v) => (
              <th key={v.title}>{t(v.title)}</th>
            ))}
          </tr>

          {values.map((item, index) => {
            return (
              <tr
                key={index}
                onClick={async () => {
                  if (singleDictionary) {
                    return;
                  }
                  if (this.state.removeMode) {
                    return;
                  }
                  // await this.props.getPatientAllergy(item.id);
                  this.setState({ updateItem: {}, loading: true });
                  const updateItem = await getProfileValue(item.id);
                  if (!updateItem) {
                    this.setState({ updateItem: null, loading: false });
                    return;
                  }
                  this.setState({ updateItem, loading: false });
                }}
              >
                {tableValues?.map((v, i) => {
                  return (
                    <td key={i}>
                      {this.state.removeMode && i === 0 && (
                        <div style={{ alignItems: "center" }}>
                          {!this.state.removeMode[index] && (
                            <RadioButtonUncheckedIcon
                              onClick={() =>
                                this.setState({
                                  removeMode: {
                                    ...this.state.removeMode,
                                    [`${index}`]: true,
                                  },
                                })
                              }
                              fontSize="small"
                              htmlColor="#007bff"
                            />
                          )}
                          {this.state.removeMode[index] && (
                            <CheckCircleOutlineIcon
                              onClick={() =>
                                this.setState({
                                  removeMode: {
                                    ...this.state.removeMode,
                                    [`${index}`]: false,
                                  },
                                })
                              }
                              fontSize="small"
                              htmlColor="#007bff"
                            />
                          )}
                        </div>
                      )}
                      {this.state.changeMode && i === 0 && (
                        <div style={{ alignItems: "center" }}>
                          {!item.selected && (
                            <RadioButtonUncheckedIcon
                              onClick={() =>
                                this.setState({
                                  changeMode: this.state.changeMode
                                    ? [
                                      ...this.state.changeMode.map((w, j) => {
                                        if (j === index) {
                                          return { ...w, selected: true };
                                        }
                                        return w;
                                      }),
                                    ]
                                    : [],
                                })
                              }
                              fontSize="small"
                              htmlColor="#007bff"
                            />
                          )}
                          {item.selected && (
                            <CheckCircleOutlineIcon
                              onClick={() =>
                                this.setState({
                                  changeMode: this.state.changeMode
                                    ? [
                                      ...this.state.changeMode.map((w, j) => {
                                        if (j === index) {
                                          return { ...w, selected: false };
                                        }
                                        return w;
                                      }),
                                    ]
                                    : [],
                                })
                              }
                              fontSize="small"
                              htmlColor="#007bff"
                            />
                          )}
                        </div>
                      )}
                      {this.itemLabel(item, v)}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  }

  singleItem() {
    const {
      t,
      profile: { values },
    } = this.props;
    const role = this.props.auth?.userToken?.role;
    const section =
      role === UserRole.Doctor
        ? this.props.profile.section
        : this.props.profile.section;
    const saveLoading = this.props.loading.subjects.has(
      LoadingSubject.SaveProfileValue
    );
    const { lang } = this.state;
    if (!values?.length) {
      return <LoadingCenter />;
    }
    console.log("singleItem", values[0]);
    return (
      <div className="right-block">
        <div className="container-header">
          <div className="title-action">
            <span>{t(section.title)}</span>
            <div className="btns">
              <div>
                <a
                  href="#"
                  className={
                    "lang-link" + (lang === "az" ? " lang-link-selected" : "")
                  }
                  onClick={(e) => this.setLanguage(e, "az")}
                >
                  Az
                </a>
                <a
                  href="#"
                  className={
                    "lang-link" + (lang === "ru" ? " lang-link-selected" : "")
                  }
                  onClick={(e) => this.setLanguage(e, "ru")}
                >
                  Ru
                </a>
                <a
                  href="#"
                  className={
                    "lang-link" + (lang === "en" ? " lang-link-selected" : "")
                  }
                  onClick={(e) => this.setLanguage(e, "en")}
                >
                  En
                </a>
              </div>
              {!this.state.changeMode && (
                <button
                  onClick={() => this.props.submit("DoctorProfileForm")}
                  className="blue-btn btn btn-primary"
                >
                  {saveLoading && (
                    <div style={{ marginRight: "8px" }}>
                      {
                        <Spinner
                          radius={18}
                          color={"#ffffff"}
                          stroke={3}
                          visible={true}
                        />
                      }
                    </div>
                  )}
                  {t("Save")}
                </button>
              )}
            </div>
          </div>
        </div>
        <div className="container-section-2">
          <DoctorProfileForm
            lang={lang}
            onSubmit={(data: { [key: string]: any }) => this.onSubmit(data)}
            section={section}
            values={values[0]}
          />
        </div>
      </div>
    );
  }

  filterSingles(searchQuery: string) {
    const { changeMode } = this.state;
    const section = this.props.profile.section;
    const tableValues = section.values.filter((v) => v.table);

    let resultData: { [key: string]: any }[] = [];

    if (!searchQuery) {
      this.setState({ resultData: resultData, searchPressed: false });
      return;
    }

    let values = changeMode?.length ? changeMode : this.props.profile.values;

    values.map((item, index) => {
      tableValues?.map((v, i) => {
        let ab = this.itemLabel(item, v);
        if (ab.toLowerCase().includes(searchQuery.toLocaleLowerCase())) {
          resultData.push(item);
        }
      });
    });

    this.setState({ resultData: resultData, searchPressed: true });
  }

  showSearchBox() {
    const { t } = this.props;
    const { searchQuery } = this.state;
    return (
      <div className="search-wrapper">
        <Form.Control
          size="sm"
          type="text"
          value={searchQuery}
          onChange={(e) => {
            this.setState({ searchQuery: e.target.value });
            this.filterSingles(e.target.value);
          }}
          placeholder={t("Search") + "..."}
        />
      </div>
    );
  }

  async confirmDeleteDoctor() {
    if (await showConfirm("Are you sure to delete this item(s)?")) {
      this.setState({ loading: true });
      const doctors: { [key: string]: IDoctor_Add[] }[] = [];
      for (const index in this.state.removeMode) {
        if (this.state.removeMode[index]) {
          doctors.push(this.props.doctorsList[index]);
        }
      }
      await this.props.profileDeleteDoctor(doctors);
      this.props.getClinicsDoctorsList();
      this.setState({ loading: false, removeMode: null });
    }
  }

  async onSubmitNewDoctor(data: IDoctor_Add) {
    this.setState({ loading: true });
    await this.props.postClinicDoctor(data, this.state.selectedItem);
    this.setState({ loading: false, updateItem: null });
  }
  async onSubmitNewDoctorAccount(data: IClinicDoctorSignUp) {
    await this.props.createDoctorAccount(data, this.state.selectedItem);
    await this.props.unDraftDoctor(this.state.selectedItem);
    this.props.getClinicsDoctorsList();
    this.setState({ modalShow: null });
  }

  async onSubmitChangeDoctor(data: IDoctor_Add) {
    this.setState({ loading: true });

    await this.props.editDoctorData(data, this.state.selectedItem);
    this.setState({ loading: false, updateItem: null });
  }

  checkDoctorId(data: IDoctor_Add) {
    console.log(data.specializations, "dataForChange");
    if (
      this.props.doctorsList.find(
        (doctor) => doctor.id === this.state.updateItem?.id
      )
    ) {
      this.onSubmitChangeDoctor(data);
    } else {
      this.onSubmitNewDoctor(data);
    }
  }

  render() {
    console.log(this.props.doctorsList, "doctorsList");
    const {
      t,
      profile: { values },
    } = this.props;
    const { lang, loading } = this.state;

    const role = this.props.auth?.userToken?.role;

    const section =
      role === UserRole.Doctor
        ? this.props.profile.section
        : this.props.profile.section;
    const sections =
      role === UserRole.Doctor
        ? this.props.profile.sections
        : this.props.profile.sections;

    const handleClose = () => {
      this.setState({ updateItem: null });

      // this.props.clearState();
    };

    const modalClose = () => {
      this.setState({ modalShow: null });
    };
    const modalOpen = () => {
      this.setState({ modalShow: {} });
    };
    const singleDictionary =
      section.values?.length === 1 &&
      section.values[0].type === ProfileValueType.dictionary;
    const saveLoading = this.props.loading.subjects.has(
      LoadingSubject.SaveProfileValue
    );

    const renderError = () => {
      const errorText = this.props.auth.authErrorText;
      if (errorText) {
        return (
          <div className="auth-error-wrapper">
            <img src={doctorProfileImg} />
            <p>{errorText}</p>
          </div>
        );
      }
    };
    const onSuccess = () => { };

    const onSubmit = (user: any) => {
      const { openLogin, openRegistration, asDoctor } = this.state;
      const { processStage, operation } = this.props.auth;

      this.props.authErrorText("");

      if (processStage === 0) {
        if (!openRegistration) {
          this.props.signIn(user as IUser, onSuccess);
        } else {
          if (asDoctor) {
            this.props.newDoctor(user as IUserSignUpRequest, onSuccess, () =>
              this.setState({ doctorStep: 1 })
            );
          } else {
            this.props.signUp(user as IUserSignUpRequest, onSuccess);
          }
        }
      } else if (processStage === LoginStage.PhoneVerification) {
        this.props.verifyPhone(
          this.props.auth.user?.login as string,
          user.code as string
        );
      } else if (processStage === LoginStage.EmailVerification) {
        this.props.verifyEmail(
          this.props.auth.user?.login as string,
          user.code as string
        );
      } else if (processStage === 4) {
        const isDoctor =
          this.props.auth.preUserToken?.auth &&
          this.props.auth.preUserToken?.role === 2;

        if (isDoctor) {
          let doctorStep = this.state.doctorStep;
          if (this.props.auth.preUserToken?.status === 2) {
            doctorStep = 2;
          }

          if (this.props.auth.preUserToken?.status === 1 && doctorStep === 1) {
            this.props.newDoctor1(
              user,
              this.props.auth.preUserToken?.auth!,
              () => this.setState({ doctorStep: 2 })
            );
          } else if (
            (this.props.auth.preUserToken?.status === 2 ||
              this.props.auth.preUserToken?.status === 1) &&
            doctorStep === 2
          ) {
            this.props.newDoctor2(
              user,
              this.props.auth.preUserToken?.auth!,
              () => this.setState({ doctorFinal: true }),
              () => this.setState({ doctorStep: 3 })
            );
          }
        }
      } else if (processStage === 5) {
        this.props.restorePassword(user.login);
      } else if (processStage === 6) {
        this.props.verifyOperation(operation || "", user.code);
      } else {
        user.login = this.props.auth.user?.login as string;
        user.email = this.props.auth.user?.email as string;
        this.props.restoreChangePassword(operation || "", user);
      }
    };
    console.log("singleDictionary", singleDictionary);

    console.log("fullDictionary", this.props.dictionary);

    console.log("profile props", this.props);
    const patientLoading = this.props.loading.subjects.has(
      LoadingSubject.SignUp
    );
    const medicalLoading = this.props.loading.subjects.has(
      LoadingSubject.NewDoctor
    );
    return (
      <>
        <div style={{ maxWidth: "910px", margin: "20px auto 0px auto" }}>
          <Breadcrumbs location={this.props.location} />
        </div>

        <div className="home">
          {this.state.updateItem && (
            <Modal centered show={!!this.state.updateItem} onHide={handleClose}>
              {section && section.title === "Doctors" ? (
                <>
                  <Modal.Header className="my-meds-modal-header" closeButton>
                    <Modal.Title
                      style={{ flexDirection: "column" }}
                      className="dp-modal-title"
                    >
                      <Tabs
                        value={this.state.activeTabIndex}
                        onChange={this.handleChange}
                      >
                        <Tab disabled label={t("Add doctor")} />
                        <Tab
                          label={
                            this.state.updateItem.id
                              ? t("Change Doctor")
                              : t("Register Doctor")
                          }
                        />
                      </Tabs>
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    {!loading && (
                      <>
                        {this.state.activeTabIndex === 0 && (
                          <div>
                            <TabContainer>
                              <div className="doctorSearch"></div>
                            </TabContainer>
                          </div>
                        )}

                        {this.state.activeTabIndex === 1 && (
                          <div>
                            <DoctorAddClinicForm
                              values={this.state.updateItem}
                              onSubmit={(data: IDoctor_Add) => {
                                console.log(data, 'dataOnSubmit');
                                this.checkDoctorId(data);
                              }}
                            />
                          </div>
                        )}
                      </>
                    )}
                    {loading && <LoadingCenter />}
                    <div className="modal-button-block">
                      <button
                        style={{ marginTop: "20px" }}
                        className="blue-btn btn btn-primary auth-button"
                        onClick={handleClose}
                      >
                        {t("Cancel")}
                      </button>
                      {!loading && (
                        <button
                          style={{ marginTop: "20px" }}
                          className="blue-btn btn btn-primary dark auth-button"
                          onClick={() => this.props.submit("AddDoctorForm")}
                          disabled={saveLoading}
                        >
                          <div style={{ display: "flex" }}>
                            {saveLoading && (
                              <div style={{ marginRight: "8px" }}>
                                {
                                  <Spinner
                                    radius={18}
                                    color={"#ffffff"}
                                    stroke={3}
                                    visible={true}
                                  />
                                }
                              </div>
                            )}
                            {t("Save")}
                          </div>
                        </button>
                      )}
                    </div>
                  </Modal.Body>
                </>
              ) : (
                <>
                  <Modal.Header className="my-meds-modal-header" closeButton>
                    <Modal.Title className="dp-modal-title">
                      {this.state.updateItem.id ? t("Change") : t("Add")}
                      <div>
                        <a
                          href="#"
                          className={
                            "lang-link" +
                            (lang === "az" ? " lang-link-selected" : "")
                          }
                          onClick={(e) => this.setLanguage(e, "az")}
                        >
                          Az
                        </a>
                        <a
                          href="#"
                          className={
                            "lang-link" +
                            (lang === "ru" ? " lang-link-selected" : "")
                          }
                          onClick={(e) => this.setLanguage(e, "ru")}
                        >
                          Ru
                        </a>
                        <a
                          href="#"
                          className={
                            "lang-link" +
                            (lang === "en" ? " lang-link-selected" : "")
                          }
                          onClick={(e) => this.setLanguage(e, "en")}
                        >
                          En
                        </a>
                      </div>
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    {!loading && (
                      <DoctorProfileForm
                        lang={lang}
                        onSubmit={(data: { [key: string]: any }) =>
                          this.onSubmit(data)
                        }
                        section={section}
                        values={this.state.updateItem}
                      />
                    )}

                    {loading && <LoadingCenter />}

                    <div className="modal-button-block">
                      <button
                        className="blue-btn btn btn-primary auth-button"
                        onClick={handleClose}
                      >
                        {t("Cancel")}
                      </button>
                      {!loading && (
                        <button
                          className="blue-btn btn btn-primary dark auth-button"
                          onClick={() => this.props.submit("DoctorProfileForm")}
                          disabled={saveLoading}
                        >
                          <div style={{ display: "flex" }}>
                            {saveLoading && (
                              <div style={{ marginRight: "8px" }}>
                                {
                                  <Spinner
                                    radius={18}
                                    color={"#ffffff"}
                                    stroke={3}
                                    visible={true}
                                  />
                                }
                              </div>
                            )}
                            {t("Save")}
                          </div>
                        </button>
                      )}
                    </div>
                  </Modal.Body>
                </>
              )}
            </Modal>
          )}

          <div className="my-dp-wrapper">
            <div className="left-block">
              {sections.map((i) => (
                <div
                  key={i.name}
                  className={
                    "item" + (section.name === i.name ? " item-active" : "")
                  }
                  onClick={() => this.setActive(i)}
                >
                  {i.icon}
                  <span>{t(i.title)}</span>
                  <img
                    className="arrow-left"
                    src={sortDown}
                  />
                </div>
              ))}
            </div>

            {!section && (
              <div className="right-block">
                <div className="no-item-selected">
                  {t("No profile selected")}
                </div>
              </div>
            )}

            {section && section.singleLoad && this.singleItem()}
            {section && !section.singleLoad && <div className="right-block">
              {section.name === "clinicsdoctors" ? (
                <>
                  <div className="container-header">
                    <div className="title-action">
                      <span>{t(section.title)}</span>
                      <div className="btns">
                        <>
                          <div className="btns">
                            {!this.state.removeMode && (
                              <button
                                onClick={() =>
                                  this.setState({ removeMode: {} })
                                }
                                className="blue-btn btn btn-primary"
                              >
                                {t("Delete")}
                              </button>
                            )}
                            {!this.state.removeMode && (
                              <button
                                className="blue-btn btn btn-primary"
                                onClick={() =>
                                  this.setState({
                                    updateItem: {},
                                    loading: false,
                                  })
                                }
                              >
                                {t("Add")}
                              </button>
                            )}
                            {this.state.removeMode && (
                              <button
                                onClick={() =>
                                  this.setState({ removeMode: null })
                                }
                                className="blue-btn btn btn-primary"
                              >
                                {t("Cancel")}
                              </button>
                            )}
                            {this.state.removeMode && (
                              <button
                                onClick={() => this.confirmDeleteDoctor()}
                                className="blue-btn btn dark btn-primary"
                                disabled={this.state.loading}
                              >
                                {t("Delete 2")}
                              </button>
                            )}
                          </div>
                        </>
                      </div>
                    </div>
                  </div>
                  <div className="container-section-2">
                    <table className="clinicsDoctors-table">
                      <thead>
                        <tr style={{ textAlign: "center" }}>
                          <td>{t("Name")}</td>
                          <td>{t("Photo")}</td>
                          <td>{t("Surname")}</td>
                          <td>{t("Patronymic")}</td>
                          <td>{t("Education")}</td>
                          <td>{t("Account creation")}</td>
                        </tr>
                      </thead>
                      <tbody>
                        {this.props.doctorsList?.map((i, index) => {
                          return (
                            <tr
                              key={i.id}
                              onClick={() => {
                                if (i.user_account === 0) {
                                  if (this.state.removeMode) {
                                    return;
                                  }
                                  this.setState({
                                    updateItem: {},
                                    loading: false,
                                  });
                                  // const updateItem = await getDoctorInfo(i.id);
                                  const updateItem =
                                    this.props.doctorsList[index];

                                  if (!updateItem) {
                                    this.setState({
                                      updateItem: null,
                                      loading: false,
                                    });
                                  }

                                  this.setState({
                                    updateItem,
                                    loading: false,
                                    selectedItem:
                                      this.props.doctorsList[index].id,
                                  });
                                  console.log("SelectedItemId", updateItem);
                                }
                              }}
                            >
                              <td>
                                <div style={{ alignItems: "center" }}>
                                  {this.state.removeMode &&
                                    !this.state.removeMode[index] && (
                                      <RadioButtonUncheckedIcon
                                        onClick={() =>
                                          this.setState({
                                            removeMode: {
                                              ...this.state.removeMode,
                                              [`${index}`]: true,
                                            },
                                          })
                                        }
                                        fontSize="small"
                                        htmlColor="#007bff"
                                      />
                                    )}
                                  {this.state.removeMode &&
                                    this.state.removeMode[index] && (
                                      <CheckCircleOutlineIcon
                                        onClick={() =>
                                          this.setState({
                                            removeMode: {
                                              ...this.state.removeMode,
                                              [`${index}`]: false,
                                            },
                                          })
                                        }
                                        fontSize="small"
                                        htmlColor="#007bff"
                                      />
                                    )}
                                  {i.name}
                                </div>
                              </td>
                              <td onClick={(e) => e.stopPropagation()}>
                                <Zoom zoomMargin={50}>
                                  <img
                                    width="32px"
                                    height="32px"
                                    src={
                                      i.imageFile !== null
                                        ? `https://videodoktor.az/uploads/doctors/${i.imageFile}`
                                        : NoUserPhoto
                                    }
                                    alt="Doctor's photo"
                                  />
                                </Zoom>
                              </td>
                              <td>{i.surname}</td>
                              <td>{i.patronymic}</td>
                              <td>{i.education}</td>

                              {i.user_account === 0 ? (
                                <td
                                  className="create-acc-button"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    this.setState({
                                      selectedItem:
                                        this.props.doctorsList[index].id,
                                    });
                                    modalOpen();
                                  }}
                                >
                                  <div style={{ minWidth: "82px" }}>
                                    {t("Create account")}
                                  </div>
                                </td>
                              ) : (
                                <td
                                  style={{
                                    color: "#808090",
                                  }}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                  }}
                                >
                                  <div
                                    style={{
                                      minWidth: "82px",
                                    }}
                                  >
                                    {t("Create account")}
                                  </div>
                                </td>
                              )}
                            </tr>
                          );
                        })}
                        {this.state.modalShow && (
                          <Modal
                            show={!!this.state.modalShow}
                            onHide={modalClose}
                            centered
                          >
                            <Modal.Header closeButton>
                              <Modal.Title
                                className="my-meds-modal-header"
                                id="contained-modal-title-vcenter"
                              >
                                {t("Create Account for doctor")}
                              </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                              <CreateAccountForm
                                onSubmit={(data: IClinicDoctorSignUp) =>
                                  this.onSubmitNewDoctorAccount(data)
                                }
                              />
                            </Modal.Body>
                            <Modal.Footer>
                              <button
                                className="blue-btn btn btn-primary"
                                onClick={modalClose}
                              >
                                {t("Close")}
                              </button>
                              <button
                                className="blue-btn btn btn-primary dark"
                                onClick={() => {
                                  this.props.submit("createDoctorAccount");
                                }}
                              >
                                {t("Create account")}
                              </button>
                            </Modal.Footer>
                          </Modal>
                        )}
                      </tbody>
                    </table>
                  </div>
                </>
              ) : (
                <>
                  <div className="container-header">
                    <div className="title-action">
                      <span>{t(section.title)}</span>
                      <div className="btns">
                        {!singleDictionary && (
                          <>
                            {!!values?.length && (
                              <div className="btns">
                                {!this.state.removeMode && (
                                  <button
                                    onClick={() =>
                                      this.setState({ removeMode: {} })
                                    }
                                    className="blue-btn btn btn-primary"
                                  >
                                    {t("Delete")}
                                  </button>
                                )}
                                {!this.state.removeMode && (
                                  <button
                                    className="blue-btn btn btn-primary"
                                    onClick={() =>
                                      this.setState({
                                        updateItem: {},
                                        loading: false,
                                      })
                                    }
                                  >
                                    {t("Add")}
                                  </button>
                                )}
                                {this.state.removeMode && (
                                  <button
                                    onClick={() =>
                                      this.setState({ removeMode: null })
                                    }
                                    className="blue-btn btn btn-primary"
                                  >
                                    {t("Cancel")}
                                  </button>
                                )}
                                {this.state.removeMode && (
                                  <button
                                    onClick={() => this.confirmDelete()}
                                    className="blue-btn btn dark btn-primary"
                                  >
                                    {t("Delete")}
                                  </button>
                                )}
                              </div>
                            )}
                          </>
                        )}
                        {singleDictionary && (
                          <>
                            {this.state.changeMode && (
                              <button
                                onClick={() =>
                                  this.setState({ changeMode: null })
                                }
                                className="blue-btn btn btn-primary"
                              >
                                {t("Cancel")}
                              </button>
                            )}
                            {this.state.changeMode && (
                              <button
                                onClick={() => this.confirmChange()}
                                className="blue-btn btn dark btn-primary"
                              >
                                {t("Confirm")}
                              </button>
                            )}
                            {!this.state.changeMode && (
                              <button
                                onClick={() => this.change()}
                                className="blue-btn btn btn-primary"
                              >
                                {t("Change")}
                              </button>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                    {singleDictionary && this.showSearchBox()}
                  </div>
                  <div className="container-section-2">
                    <div id="all-data" className="all-data table_data">
                      {singleDictionary ? (
                        this.table()
                      ) : (
                        <InfiniteScroll
                          next={() => {
                            if (
                              this.props.loading.subjects.has(
                                LoadingSubject.ProfileValues
                              ) ||
                              this.props.loading.subjects.has(
                                LoadingSubject.ProfileValuesNext
                              )
                            ) {
                              return;
                            }
                            this.updateListData(this.props.profile.page);
                          }}
                          scrollableTarget="all-data"
                          hasMore={this.props.profile.hasMore}
                          loader={<LoadingCenter />}
                          dataLength={values.length}
                        >
                          {this.table()}
                          {/* {!this.props.loading.subjects.has(LoadingSubject.ProfileValues) && this.props.profile.values?.map((r, i) => this.renderListItem(r, i))} */}
                        </InfiniteScroll>
                      )}
                    </div>
                  </div>
                </>
              )}
            </div>}
          </div>

          {/* _className='standart-margin-bottom' */}
        </div>
      </>
    );
  }
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
    value: index,
  };
}

const mapStateToProps = (state: RootState) => ({
  auth: state.auth,
  dictionary: state.dictionary,
  profile: state.profile,
  loading: state.loading,
  doctorsList: state.doctor.clinicsDoctorsList,
});

const mapDispatchToProps = {
  submit,
  reset,
  change,

  getProfileValues,
  profileValueSave,
  profileValueDeleteBulk,
  getProfileValue,
  profileValueChangeBulk,
  signIn,
  signUp,
  authErrorText,
  verifyPhone,
  verifyEmail,
  processStageChange,
  newDoctor,
  newDoctor1,
  newDoctor2,
  _preSignIn,
  restorePassword,
  verifyOperation,
  restoreChangePassword,
  resendVerification,
  toggleAuth,
  postClinicDoctor,
  getClinicsDoctorsList,
  createDoctorAccount,
  editDoctorData,
  unDraftDoctor,
  profileDeleteDoctor,
  clearState,
};

let ProfileComponentContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(ProfileComponent));

export default ProfileComponentContainer;
