import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import './Nav.scss'

interface DiseaseItemProps extends WithTranslation {}

interface DiseaseItemState {}

class Nav extends React.Component<DiseaseItemProps, DiseaseItemState> {
  constructor(props: DiseaseItemProps) {
    super(props);
    this.state = {};
  }

  render() {
    const { t } = this.props;

    return (
      <nav className="services">
        <li>
          <Link to="/doctors" className="services-item">
            {t("Doctor's appointment at the clinic")}
          </Link>
        </li>
        <li>
          <Link to="/doctors" className="services-item">
            {t("Online Consultation")}
          </Link>
        </li>
        <li >
          <Link onClick={ (event) => event.preventDefault() } to="/" className="services-item__disabled">
            {t("Diagnostics")}
          </Link>
        </li>
        <li>
          <Link onClick={ (event) => event.preventDefault() } to="/" className="services-item__disabled">
            {t("Services")}
          </Link>
        </li>
      </nav>
    );
  }
}

export default withTranslation()(Nav);
