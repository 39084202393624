import { IDutyState } from "../states/duty-state";
import { SchedulingItemsType, SCHEDULING_DUTY_ITEM, SAVE_SCHEDULING_ITEM } from "../actions/scheduling-item-actions";

const initialState: IDutyState = {
};

export function dutyReducer(state: IDutyState = initialState, action: SchedulingItemsType) {
  switch (action.type) {
    case SCHEDULING_DUTY_ITEM:
      if (!action.dutyItem) {
        return {};
      }
      return {...state, ...{item: action.dutyItem}};
    case SAVE_SCHEDULING_ITEM:
      if (action.item.onDuty) {
        return {...state, ...{item: action.item}};
      }
      return state;
    default:
      return state;
  }
}
