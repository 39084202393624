import React from 'react';

interface OutsideClickProps {
    clickState?: boolean;
    clickHandler: () => void
}

export default class OutsideClick extends React.Component<OutsideClickProps, {}> {
    wrapperRef;
    constructor(props) {
        super(props);
    
        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
      }
    
      componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
      }
    
      componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
      }
    
      /**
       * Set the wrapper ref
       */
      setWrapperRef(node) {
        this.wrapperRef = node;
      }
    
      /**
       * Alert if clicked on outside of element
       */
      handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
          this.props.clickHandler();
        }
      }

      render() {
        return <div ref={this.setWrapperRef}>{this.props.children}</div>;
      }
}