import React, { Component } from 'react';
import Modal from 'react-bootstrap/Modal';
import { connect } from "react-redux";
import { Link } from 'react-router-dom';
import { CallStage } from '../../models/call-stage';
import { ICallState } from '../../states/call-state';
import { RootState } from '../../store';
import history from "../../history";
import { answer, hangUp } from '../../actions/call-actions';
import { IAuthState } from '../../states/auth-state';
import CallIcon from '@material-ui/icons/Call';
import CallEndIcon from '@material-ui/icons/CallEnd';
import VideocamIcon from '@material-ui/icons/Videocam';
import { CallStatus } from '../../models/call-history';
import { fetchCallRecord } from '../../actions/scheduling-item-record-actions';
import { ISchedulingItemRecordsState } from '../../states/scheduling-item-records-state';
import { ISchedulingItemRecord } from '../../models/scheduling-item-record';
import { IChatAttachment } from '../../models/chat-message';

interface MyProps {
    call: ICallState;
    auth: IAuthState;
    schedulingItemRecords: ISchedulingItemRecordsState;
    item: IChatAttachment;
    onClick: () => void;
}

interface MyState {
}

class Attachment extends Component<MyProps, MyState> {

    constructor(props: MyProps) {
        super(props);
        this.state = {
        };
    }

    componentDidUpdate(prevProps: MyProps) {
    }
    
    render() {
        const {call, auth, schedulingItemRecords} = this.props;

        return <p>
            <img onClick={() => this.props.onClick && this.props.onClick()} src={`data:${this.props.item.mimeType};base64,${this.props.item.content}`}></img>
        </p>;
    }
}

const mapStateToProps = (state: RootState) => ({
    call: state.call,
    auth: state.auth,
    schedulingItemRecords: state.schedulingItemRecords,
});

const mapDispatchToProps = {
};

let AttachmentContainer = connect(mapStateToProps, mapDispatchToProps)(
    Attachment
);

export default AttachmentContainer;