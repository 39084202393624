import {Action, ActionCreator} from "redux";
import {ThunkAction} from "redux-thunk";
import {loadingEnd, loadingStart} from "./loading-actions";
import {LoadingSubject} from "../states/loading-state";
import {pushAlert} from "./alert-actions";
import * as environment from '../app.json';
import {authFetch, signOut} from "./auth-actions";
import {IClinic} from "../models/clinic";
import {IPatientClinic} from "../models/patient-clinic";
import i18n from 'i18next';
import { IClinicFilter } from "../models/clinic-filter";
import { RootState } from "../store";
import { showAlert, showConfirm } from "./../components/Dialogs";

function topDoctorData() {
    let topDoctor = environment.topDoctor;
    if (i18n.language === 'az') topDoctor = environment.topDoctorAz;
    if (i18n.language === 'ru') topDoctor = environment.topDoctorRu;
    return topDoctor;
}

export const MYCLINICS_PAGE_SIZE = 20;

export const PATIENT_CLINIC_LIST = 'PATIENT_CLINIC_LIST';
interface PatientClinicListAction {
    type: typeof PATIENT_CLINIC_LIST;
    clinics: IClinic[];
    page: number;
    end: boolean;
}

export const _patientClinicList: ActionCreator<PatientClinicListAction> = (clinics: IClinic[], page: number, end: boolean) => {
    return {
        type: PATIENT_CLINIC_LIST,
        clinics,
        page,
        end,
    };
};

export const PATIENT_MY_CLINIC = 'PATIENT_MY_CLINIC';
interface GetPatientMyClinicAction {
    type: typeof PATIENT_MY_CLINIC;
    patientClinic: IPatientClinic;
}
export const _getPatientClinic: ActionCreator<GetPatientMyClinicAction> = (patientClinic: IPatientClinic) => {
    return {
        type: PATIENT_MY_CLINIC,
        patientClinic
    };
};

export const PATIENT_MY_CLINIC_LIST = 'PATIENT_MY_CLINIC_LIST';
interface GetPatientMyClinicListAction {
    type: typeof PATIENT_MY_CLINIC_LIST;
    patientClinicList: IPatientClinic[];
    page: number;
    end: boolean;
}
export const _getPatientClinicList: ActionCreator<GetPatientMyClinicListAction> = (patientClinicList: IPatientClinic[], page: number, end: boolean) => {
    return {
        type: PATIENT_MY_CLINIC_LIST,
        patientClinicList,
        page,
        end,
    };
};

export const SAVE_PATIENT_MY_CLINIC = 'SAVE_PATIENT_MY_CLINIC';
interface SavePatientMyClinicAction {
    type: typeof SAVE_PATIENT_MY_CLINIC;
}
const _savePatientClinic: ActionCreator<SavePatientMyClinicAction> = () => {
    return {
        type: SAVE_PATIENT_MY_CLINIC,
    };
};

export const UPDATE_PATIENT_MY_CLINIC = 'UPDATE_PATIENT_MY_CLINIC';
interface UpdatePatientMyClinicAction {
    type: typeof UPDATE_PATIENT_MY_CLINIC;
    patientClinic: IPatientClinic;
}
const _updatePatientClinic: ActionCreator<UpdatePatientMyClinicAction> = (patientClinic: IPatientClinic) => {
    return {
        type: UPDATE_PATIENT_MY_CLINIC,
        patientClinic
    };
};

export const DELETE_PATIENT_MY_CLINIC = 'DELETE_PATIENT_MY_CLINIC';
interface DeletePatientMyClinicAction {
    type: typeof DELETE_PATIENT_MY_CLINIC;
    id: number;
}
const _deletePatientClinic: ActionCreator<DeletePatientMyClinicAction> = (id: number) => {
    return {
        type: DELETE_PATIENT_MY_CLINIC,
        id
    };
};

export type PatientClinicActionTypes = PatientClinicListAction | GetPatientMyClinicAction |
    GetPatientMyClinicListAction | SavePatientMyClinicAction | UpdatePatientMyClinicAction | DeletePatientMyClinicAction;

export const getPatientClinicList = (
    filter: IClinicFilter | undefined,
    page: number = 1
): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch, getState) => {
    dispatch(loadingStart(page > 1 ? LoadingSubject.ClinicListNextPage : LoadingSubject.ClinicList));

    try {
        let filterPath = '';
        if (filter) {
            for (let key in filter) {
                if (filter.hasOwnProperty(key) && filter[key]) {
                    filterPath += `&${key}=${filter[key]}`;
                }
            }
        }
        const response = await authFetch(
            // + `?page=${page}`
            getState, dispatch, topDoctorData() + 'patientmyclinics/getclinics?per-page=1000' + filterPath, 'GET',
            null, {Accept: 'application/json', 'Content-Type': 'application/json'}, '&token='
        );

        console.log('response',response);

        const json = await response.json();
        if (response.status === 200 && json.status !== false) {
            const end = response.headers.get('X-Pagination-Page-Count') === response.headers.get('X-Pagination-Current-Page');
            dispatch(_patientClinicList(json.payload, page, end));
        }
        else if (response.status === 401) {
            //dispatch(signOut(token));
        }
        else {
            dispatch(errorPatientClinicList(json.code, getState().auth.userToken!.auth));
        }
    } catch (e) {
        dispatch(pushAlert('Unknown error. Please, check internet connection'));
    } finally {
        dispatch(loadingEnd(page > 1 ? LoadingSubject.ClinicListNextPage : LoadingSubject.ClinicList));
    }
}

export const getPatientMyClinicList = (
    filter: IClinicFilter | undefined,
    page: number = 1
): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch, getState) => {
    dispatch(loadingStart(page > 1 ? LoadingSubject.PatientClinicListNextPage : LoadingSubject.PatientClinicList));

    try {
        const response = await authFetch(
            getState, dispatch, topDoctorData() + 'patientmyclinics' + `?page=${page}`, 'GET',
            null, {Accept: 'application/json', 'Content-Type': 'application/json'}, '&token='
        );

        console.log('response', response);

        const json = await response.json();
        if (response.status === 200 && json.status !== false) {
            const end = response.headers.get('X-Pagination-Page-Count') === response.headers.get('X-Pagination-Current-Page');
            dispatch(_getPatientClinicList(json, page, end));
        }
        else if (response.status === 401) {
            //dispatch(signOut(token));
        }
        else {
            dispatch(errorPatientClinicList(json.code, getState().auth.userToken!.auth));
        }
    } catch (e) {
        dispatch(pushAlert('Unknown error. Please, check internet connection'));
    } finally {
        dispatch(loadingEnd(page > 1 ? LoadingSubject.PatientClinicListNextPage : LoadingSubject.PatientClinicList));
    }
}
export const savePatientClinic = (
    patientClinic: IPatientClinic,
): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch, getState) => {
    dispatch(loadingStart(LoadingSubject.SavePatientClinic));

    try {
        const response = await authFetch(
            getState, dispatch, topDoctorData() + 'patientmyclinics', 'POST',
            patientClinic, {Accept: 'application/json', 'Content-Type': 'application/json'}, '?token='
        );

        console.log('response', response);

        const json = await response.json();
        console.log('json', json);
        if (response.status === 200 && json.status !== false) {
            dispatch(_savePatientClinic(json));
            await dispatch(getPatientClinicList(undefined));
            await dispatch(getPatientMyClinicList(undefined));
            showAlert('Patient clinic successfully saved');
        }
        else if (response.status === 401) {
            //dispatch(signOut(token));
        }
        else {
            dispatch(errorPatientClinic(json.code, getState().auth.userToken!.auth));
        }
    } catch (e) {
        dispatch(pushAlert('Unknown error. Please, check internet connection'));
    } finally {
        dispatch(loadingEnd(LoadingSubject.SavePatientClinic));
    }
}
export const updatePatientClinic = (
    patientClinic: IPatientClinic,
): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch, getState) => {
    dispatch(loadingStart(LoadingSubject.UpdatePatientClinic));

    try {
        const response = await authFetch(
            getState, dispatch, topDoctorData() + 'patientmyclinics', 'PATCH',
            {id: patientClinic.id, description: patientClinic.description}, {Accept: 'application/json', 'Content-Type': 'application/json'}, '?token='
        );

        console.log('response', response);

        const json = await response.json();
        console.log('json', json);
        if (response.status === 200 &&  json.status !== false) {
            dispatch(_updatePatientClinic(patientClinic));
            showAlert('Patient clinic details successfully  updated');
        }
        else if (response.status === 401) {
            //dispatch(signOut(token));
        }
        else {
            dispatch(errorPatientClinic(json.code, getState().auth.userToken!.auth));
        }
    } catch (e) {
        dispatch(pushAlert('Unknown error. Please, check internet connection'));
    } finally {
        dispatch(loadingEnd(LoadingSubject.UpdatePatientClinic));
    }
}

export const getPatientClinic = (
    id: number,
): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch, getState) => {
    dispatch(loadingStart(LoadingSubject.GetPatientClinic));

    try {
        const response = await authFetch(
            getState, dispatch, topDoctorData() + 'patientmyclinics/' + id, 'GET',
            null, {Accept: 'application/json', 'Content-Type': 'application/json'}, '?token='
        );

        console.log('response', response);

        const json = await response.json();
        console.log('json', json);
        if (response.status === 200 && json.status !== false) {
            dispatch(_getPatientClinic(json));
        }
        else if (response.status === 401) {
            //dispatch(signOut(token));
        }
        else {
            dispatch(errorPatientClinic(json.code, getState().auth.userToken!.auth));
        }
    } catch (e) {
        dispatch(pushAlert('Unknown error. Please, check internet connection'));
    } finally {
        dispatch(loadingEnd(LoadingSubject.GetPatientClinic));
    }
}
export const deletePatientClinic = (
    id: number,
): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch, getState) => {
    dispatch(loadingStart(LoadingSubject.DeletePatientClinic));

    console.log('deletePatientClinic');
    try {
        const response = await authFetch(
            getState, dispatch, topDoctorData() + 'patientmyclinics/' + id, 'DELETE',
            null, {Accept: 'application/json', 'Content-Type': 'application/json'}, '?token='
        );

        console.log('response', response);

        const json = await response.json();
        console.log('json', json);
        if (response.status === 200 && json.status !== false) {
            dispatch(_deletePatientClinic(id));
            showAlert('Patient clinic successfully deleted');
        }
        else if (response.status === 401) {
            //dispatch(signOut(token));
        }
        else {
            dispatch(errorPatientClinic(json.code, getState().auth.userToken!.auth));
        }
    } catch (e) {
        dispatch(pushAlert('Unknown error. Please, check internet connection'));
    } finally {
        dispatch(loadingEnd(LoadingSubject.DeletePatientClinic));
    }
}

export const errorPatientClinicList = (code: number, token: string): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch, getState) => {
    //console.warn('code', code);
    if (code === 103) {
        dispatch(signOut(token));
    } else if (code === 900) {
        showAlert('Empty result');
    }  else {
        showAlert('Patient clinics list loading error');
    }
}

export const errorPatientClinic = (code: number, token: string): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch, getState) => {
    //console.warn('code', code);
    if (code === 103 || code === 104) {
        dispatch(signOut(token));
    } else if (code === 109) {
        showAlert('Unknown error');
    } else if (code === 199) {
        showAlert('Not found');
    }  else if (code === 401) {
        showAlert('Not patient');
    }  else if (code === 900) {
        showAlert('Empty result');
    } else if (code === 901) {
        showAlert('Forbidden');
    } else {
        showAlert('Patient clinic loading error');
    }
}