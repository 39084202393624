import React from 'react';
import { connect } from 'react-redux';
import { Field, InjectedFormProps, reduxForm, formValueSelector, FormErrors } from 'redux-form';
import { IPatientAllergy } from '../models/user-allergy';
import { IDictionaryState } from '../states/dictionary-state';
import { IAuthState } from '../states/auth-state';
import { RootState } from '../store';
import { IPatientAllergyState } from '../states/patient-allergy-state';
import { IFile } from '../models/file';
import TextInput from './FormComponents/TextInput';
import UserFilesAddComponent from './FormComponents/UserFilesAdd';
import { IUpload } from '../models/upload';
import { TFunction } from 'i18next';
import { withTranslation } from 'react-i18next';

type Props = {
    t: TFunction;
    type: number;
    specialization?: number;
    clinic_id?: number;
    doctor_id?: number;
    dictionary: IDictionaryState;
    auth: IAuthState;
    patientAllergy: IPatientAllergyState;
    onFilesChange: (addedFiles: IUpload[], deletedFiles: IFile[]) => void;
}

class UserAllergiesForm extends React.Component<InjectedFormProps<IPatientAllergy> & Props> {
    componentWillMount() { this.props.patientAllergy.selected && this.props.initialize(this.props.patientAllergy.selected); }

    render() {
        const { t } = this.props;
        return (
            <div>

                <Field name="name"
                    style={{
                        width: '70%',
                        marginBottom: '10px',
                        paddingLeft: '5px',
                    }}
                    placeholder={t('Allergen')} component={TextInput}
                />

                <Field name="reaction"
                    style={{
                        width: '70%',
                        marginBottom: '10px',
                        paddingLeft: '5px',
                    }}
                    placeholder={t('Reaction')} component={TextInput}
                />

                <UserFilesAddComponent
                    type={'allergy/'}
                    style={{ width: '70%', border: '1px solid #767676' }}
                    uploadedFiles={this.props?.patientAllergy?.selected?.uploadedfiles}
                    onChange={(a, b) => this.props.onFilesChange && this.props.onFilesChange(a, b)}
                />
            </div>
        );
    }
}

const validate = (values: IPatientAllergy, props: {t: TFunction}): FormErrors<IPatientAllergy> => {
    const errors: FormErrors<IPatientAllergy> = {};
    if (!values.name) errors.name = props.t('Field couldn\'t be empty');
    return errors;
};

const form = reduxForm<IPatientAllergy & { isChecked: boolean }, any>({
    form: 'UserAllergiesForm',
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
    validate,
})(UserAllergiesForm);

const selector = formValueSelector('UserAllergiesForm');
const mapStateToProps = (state: RootState) => {
    const type = selector(state, 'type') || null;
    return {
        patientAllergy: state.patientAllergy,
        type,
        // file: state.file,
    }
};

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(form));