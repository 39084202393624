import React from "react";
import { connect } from "react-redux";
import {
  Field,
  InjectedFormProps,
  reduxForm,
  FormErrors,
  formValueSelector,
} from "redux-form";
import TextInput from "./FormComponents/TextInput";
import { RootState } from "../store";
import "./PersonalCabinetClinicForm.scss";
import { TFunction } from "i18next";
import { withTranslation } from "react-i18next";
import ImageInput from "./FormComponents/ImageInput";
import { IClinic } from "../models/clinic";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import DropNCrop from "@synapsestudios/react-drop-n-crop";
import "@synapsestudios/react-drop-n-crop/lib/react-drop-n-crop.min.css";

interface PersonalCabinetClinicFormProps extends InjectedFormProps<IClinic> {
  t: TFunction;
  editMode?: boolean;
}
var AvatarImageCropper = require("react-avatar-image-cropper");
class PersonalCabinetClinicForm extends React.Component<PersonalCabinetClinicFormProps> {
  constructor(props: PersonalCabinetClinicFormProps) {
    super(props);
    this.state = {
      // src: null,
      // crop: {
      //   unit: "%",
      //   width: 20,
      //   aspect: 4 / 3,
      // },
    };
  }

  // onSelectFile = (e) => {
  //   if (e.target.files && e.target.files.length > 0) {
  //     const reader = new FileReader();
  //     reader.addEventListener("load", () =>
  //       this.setState({ src: reader.result })
  //     );
  //     reader.readAsDataURL(e.target.files[0]);
  //   }
  // };

  // // If you setState the crop in here you should return false.
  // onImageLoaded = (image) => {
  //   this.imageRef = image;
  // };

  // onCropComplete = (crop) => {
  //   this.makeClientCrop(crop);
  // };

  // onCropChange = (crop, percentCrop) => {
  //   // You could also use percentCrop:
  //   // this.setState({ crop: percentCrop });
  //   this.setState({ crop });
  // };

  // async makeClientCrop(crop) {
  //   if (this.imageRef && crop.width && crop.height) {
  //     const croppedImageUrl = await this.getCroppedImg(
  //       this.imageRef,
  //       crop,
  //       "newFile.jpeg"
  //     );
  //     this.setState({ croppedImageUrl });
  //   }
  // }

  // getCroppedImg(image, crop, fileName) {
  //   const canvas = document.createElement("canvas");
  //   const pixelRatio = window.devicePixelRatio;
  //   const scaleX = image.naturalWidth / image.width;
  //   const scaleY = image.naturalHeight / image.height;
  //   const ctx = canvas.getContext("2d");

  //   canvas.width = crop.width * pixelRatio * scaleX;
  //   canvas.height = crop.height * pixelRatio * scaleY;

  //   ctx?.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
  //   ctx &&ctx.imageSmoothingQuality = "high";

  //   ctx?.drawImage(
  //     image,
  //     crop.x * scaleX,
  //     crop.y * scaleY,
  //     crop.width * scaleX,
  //     crop.height * scaleY,
  //     0,
  //     0,
  //     crop.width * scaleX,
  //     crop.height * scaleY
  //   );

  //   return new Promise((resolve, reject) => {
  //     canvas.toBlob(
  //       (blob) => {
  //         if (!blob) {
  //           //reject(new Error('Canvas is empty'));
  //           console.error("Canvas is empty");
  //           return;
  //         }
  //         blob.name = fileName;
  //         window.URL.revokeObjectURL(this.fileUrl);
  //         this.fileUrl = window.URL.createObjectURL(blob);
  //         resolve(this.fileUrl);
  //       },
  //       "image/jpeg",
  //       1
  //     );
  //   });
  // }
  apply(file) {
    console.log(file);
  }
  render() {
    console.log(this.props, "PersonalCabinetClinicForm props");
    const { editMode, t } = this.props;
    let textInputClassName = "personal-cabinet-patient-styles_text-input";
    let ImageInputClassName = "personal-cabinet-patient-styles_image-input";
    if (editMode) {
      textInputClassName += " edit-mode";
      ImageInputClassName += "edit-mode";
    }

    return (
      <div className="personal-cabinet-patient-form_main">
        {/* <div
          style={{
            backgroundImage:
              'url("https://lumiere-a.akamaihd.net/v1/images/open-uri20150422-20810-af2aao_4e6f6afc.jpeg?region=0%2C0%2C400%2C300")',
            width: "150px",
            height: "150px",
            margin: "auto",
          }}
        >
          <AvatarImageCropper apply={this.apply.bind(this)} isBack={true} />
        </div> */}
        <div className="_row">
          <div className="_block0">
            <Field
              _className={ImageInputClassName}
              name="image"
              type={"clinics"}
              component={ImageInput}
            />
          </div>
          {/* <div>
            <input type="file" accept="image/*" onChange={this.onSelectFile} />
          </div>
          {src && (
            <ReactCrop
              src={src}
              crop={crop}
              ruleOfThirds
              onImageLoaded={this.onImageLoaded}
              onComplete={this.onCropComplete}
              onChange={this.onCropChange}
            />
          )} */}

          <div className="clinicName">
            <div className="_block1">
              <Field
                _className={textInputClassName}
                name="name"
                placeholder={t("Name of the organization")}
                component={TextInput}
              />
            </div>

            <div className="_block2">
              <Field
                _className={textInputClassName}
                name="login"
                placeholder={t("User name")}
                component={TextInput}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const selector = formValueSelector("IClinicPersonalCabinetClinicForm");

const mapStateToProps = (state: RootState) => {
  let { city, blood_type, image } = selector(
    state,
    "city",
    "blood_type",
    "image"
  );

  console.log("state.auth.self", state.auth.self);
  return {
    initialValues: state.auth.self as IClinic,
    city,
    blood_type,
  };
};

const validate = (
  values: IClinic,
  props: PersonalCabinetClinicFormProps & { t: TFunction }
): FormErrors<IClinic> => {
  const errors: FormErrors<IClinic> = {};
  const { t } = props;
  console.log(props, "PropsForPersonalCabinet");
  if (!values.name || values.name.trim() === "") {
    errors.name = t("Field couldn't be empty");
  }
  return errors;
};

const form = reduxForm<IClinic, any>({
  form: "PersonalCabinetClinicForm",
  validate,
})(PersonalCabinetClinicForm);

export default connect(mapStateToProps)(withTranslation()(form));
