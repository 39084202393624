import { i18n, TFunction } from "i18next";
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { PagingOptions } from "../../models/paging-options";
import { RootState } from "../../store";
import './Doctors.scss';

interface MyProps { 
    t: TFunction;
    i18n: i18n;
    pagingOptions: PagingOptions;
    start: number;
    changePageOptions: (options: PagingOptions) => void;
}

interface MyState { 
}


class Pagination extends Component<MyProps, MyState> {

    constructor(props: MyProps) {
        super(props);
        this.state = {
            
        };
    }

    selectPage = (page: number) => {
        const { pagingOptions } = this.props;
        this.props.changePageOptions({...pagingOptions, page: page});
    }

    render() {
        const {  } = this.state;
        const { pagingOptions, start } = this.props;
        const { totalPages, pageSize, page } = pagingOptions;
        const totalLength = Math.ceil(totalPages / pageSize);
        let pages = [...Array.from(Array(totalLength).keys())].map(c => c + start).map(p => {
            if (p < 4 || p > totalLength - 5) return p;
            if (p - page < 4 && p - page > -4) return p;
            return '...';
        });

        pages = pages.filter((p, i) => i === 0 || pages[i - 1] !== '...');

        return (
            <div className='pagination-doc'>
                {totalLength > 0 && pagingOptions.page > start && <p className='pagination-doc-btn pagination-doc-btn-active' onClick={() => this.selectPage(pagingOptions.page - 1)}>←</p>}
                {pages.map((p) => {
                    if (p === '...') {
                        return <p className="pagination-doc-btn" key="...">...</p>
                    }
                    return (<p key={p} className={ 'pagination-doc-btn' + (p === pagingOptions.page ? ' pagination-doc-btn-active' : '') } onClick={() => this.selectPage(p)}>{start === 0 ? p + 1: p}</p>)
                })}
                {totalLength > 0 && pagingOptions.page < totalLength - 1 + start && <p className='pagination-doc-btn pagination-doc-btn-active' onClick={() => this.selectPage(pagingOptions.page + 1)}>→</p>}
            </div>
        );
    }
}

const mapStateToProps = (state: RootState) => ({

});

const mapDispatchToProps = {

};

let PaginationComponentContainer = connect(mapStateToProps, mapDispatchToProps)(
    Pagination
);

export default withTranslation()(PaginationComponentContainer);