const Agreement: {az: string, ru: string, en: string} = {
    az: `
    <p><strong>"Video Doktor" (Video Həkim)&ndash;un Məlumatlı Razılıq Sazişi </strong></p>
    <p><em>Sonuncu dəyişiklik: 10 May, 2020&ndash;ci il</em></p>
    <p><strong>Xidmətlərin təsviri</strong></p>
    <p><strong>&ldquo;Video Doktor</strong><strong>&rdquo;-</strong><strong>un M&uuml;təxəssisləri</strong>&rdquo; ke&ccedil;idində Tibb M&uuml;təxəssisləri, onlarla sizin eyni fiziki yerdə olmadığınız hallarda interaktiv audio və video texnologiyalardan istifadə edərək səhiyyə xidmətləri g&ouml;stərirlər. Siz Tibb M&uuml;təxəssisi ilə m&uuml;alicə ilə bağlı davamlı əlaqə yarada bilərsiniz, lakin sizin ilk səfəriniz konsultasiya kimi başlaya (məs. sizin &uuml;&ccedil;&uuml;n ən uyğun m&uuml;alicə prosedurunu m&uuml;əyyənləşdirmək &uuml;&ccedil;&uuml;n) bilər və bu zəruri olaraq davamlı m&uuml;alicə əlaqəsinə gətirib &ccedil;ıxarmayacaqdır. Siz Tibb M&uuml;təxəssisi tərəfindən t&ouml;vsiyyə edildikdə və ya digər şəkildə lazım olduqda, təcili tibbi yardım dəstəyi və ya sonrakı tibbi qayğı &uuml;&ccedil;&uuml;n m&uuml;raciət edə və t&ouml;vsiyyə olunan qaydada digər səhiyyə təminat&ccedil;ıları ilə məsləhətləşməyə davam edə bilərsiniz. Xidmətlərimizin &uuml;st&uuml;nl&uuml;kləri arasında səhiyyə xidmətlərindən istifadənin təkmilləşdirilməsi və rahatlıq daxildir. Lakin istənilən səhiyyə xidmətində olduğu kimi, texnologiyadan istifadə ilə bağlı potensial risklər də m&ouml;vcuddur.</p>
    <p><strong><em>Bu risklərə, bunlarla</em></strong> <strong><em>məhdudlaşmamaqla, aşağıdakılar daxildir:</em></strong></p>
    <ul>
    <li>Nadir hallarda, &ouml;t&uuml;r&uuml;lən məlumat səhiyyə və ya sağlamlıqla bağlı qərarların qəbul edilməsi &uuml;&ccedil;&uuml;n yetərsiz ola bilər.</li>
    <li>Elektron avadanlığın (cihazların) və ya internet bağlantısının uğursuzluğuna g&ouml;rə kənarlaşmalar və ya fasilələr ola bilər. Bu kimi hallar baş verdikdə, sizinlə telefon və ya digər əlaqə vasitələri ilə əlaqə saxlanıla bilər.</li>
    <li>Nadir hallarda, sağlamlıq qeydlərinizin və ya ambulator və ya xəstəlik tarix&ccedil;ənizə b&uuml;t&ouml;vl&uuml;kdə &ccedil;ıxış olmaması və ya sizin tərəfinizdən tam məlumat verilməməsi hallarında, bu dərman vasitələrinin mənfi təsirləri və ya digər təsirlərlə nəticələnə bilər.</li>
    <li>Sağlamlıq məlumatlarınızın məxfiliyini qorumaq &uuml;&ccedil;&uuml;n etibarlı təhl&uuml;kəsizlik protokollarını tətbiq etsək də, nadir hallarda təhl&uuml;kəsizlik protokolları da uğursuz ola və bu da məxfiliyin pozulmasına səbəb ola bilər.</li>
    </ul>
    <p><strong><em>Bu razılığı qəbul edərək siz aşağıdakıları başa d&uuml;şd&uuml;y&uuml;n&uuml;z&uuml; və onlar ilə razılaşdığınızı təsdiq edirsiniz:</em></strong></p>
    <ul>
    <li>Bizdən qəbul etdiyiniz səhiyyə xidmətləri və məsləhətlər haqqında məlumatları tibbi sığorta şirkətiniz ilə paylaşa bilərik. Buraya Məxfilik Siyasətimizin şərtlərinə əsasən genetik m&uuml;ayinə, dərman (maddə) asılılığı, əqli sağlamlıq, &ouml;t&uuml;r&uuml;lə bilən xəstəliklər və digər sağlamlıq vəziyyətləri haqqında məlumatlar aid ola bilər.</li>
    <li>Sağlamlıq sığortası paketiniz Xidmətlər &uuml;&ccedil;&uuml;n &ouml;dənişi tam olaraq etmədiyi halda, siz &ouml;dəniş &uuml;&ccedil;&uuml;n tamamilə və ya qismən cavabdehlik daşıya bilərsiniz. Tibb M&uuml;təxəssislərimiz sağlamlıqla bağlı qeydlərinizi səhiyyə xidmətlərinin g&ouml;stərilməsi, koordinasiyası və ya idarə edilməsi ilə əlaqəli məqsədlər &uuml;&ccedil;&uuml;n digər səhiyyə xidməti təminat&ccedil;ıları ilə paylaşa bilər ki, sizin səhiyyə ehtiyaclarınızı qarşılaya bilsinlər.</li>
    <li>Buraya &ldquo;<strong>Video Doktor&rdquo;</strong> Məxfilik Siyasətinin şərtlərinə əsasən genetik m&uuml;ayinə, psixotrop və s. maddə asılılığı, əqli sağlamlıq, &ouml;t&uuml;r&uuml;lə bilən xəstəliklər və digər sağlamlıq vəziyyətləri haqqında məlumatlar aid ola bilər.</li>
    <li>Biz klinik xidmətlərimizin sizin m&uuml;alicə ehtiyaclarınızın bəziləri və ya hamısı &uuml;&ccedil;&uuml;n m&uuml;vafiq olmadığını m&uuml;əyyənləşdirə və Sayt vasitəsilə sizə klinik xidmətlər g&ouml;stərməməyi se&ccedil;ə bilərik.</li>
    <li>Psixoloji yardım və psixoterapiya ilə əlaqədar olaraq, siz, terapiya metodları, istifadə edilən texnika və &uuml;sullar, terapiya m&uuml;ddəti (məlum olarsa) və &ouml;dəniş haqlarının strukturu barədə Tibb M&uuml;təxəssislərindən məlumatları almaq h&uuml;ququna maliksiniz. Siz istənilən zaman digər tibb iş&ccedil;isindən ikinci rəy ala və ya terapiyanı dayandıra bilərsiniz.</li>
    <li>Hesabınızda qeydiyyatdan ke&ccedil;irilmiş b&uuml;t&uuml;n uşaqların qəyyumu və ya şəxsi n&uuml;mayəndəsi kimi &ccedil;ıxış etmək &uuml;&ccedil;&uuml;n h&uuml;quqi səlahiyyətinizin olduğunu təsdiq edirsiniz.</li>
    <li>He&ccedil; bir nəticəyə zəmanət və ya əminlik verilə bilməz.</li>
    </ul>
    <p><strong>Qrup terapiyası </strong></p>
    <p>Siz və Tibb M&uuml;təxəssisi qrup və ya c&uuml;t şəkildə psixoloji yardımı və ya psixoterapiyanı ke&ccedil;irmək qərarına gələrsinizsə, (&uuml;mumilikdə &ldquo;Qrup terapiyası&rdquo;), bu zaman Qrup Terapiyasında m&uuml;zakirə edilən məlumatların psixoloji dəstək və ya terapevtik məqsədlər &uuml;&ccedil;&uuml;n olduğunu və Qrup Terapiyasının iştirak&ccedil;ılarına aid olan hər hansı h&uuml;quqi icraatda istifadə &uuml;&ccedil;&uuml;n nəzərdə tutulmadığını başa d&uuml;ş&uuml;rs&uuml;n&uuml;z.&nbsp; Digər Qrup Terapiyasının lehinə və ya əleyhinə ifadə vermək &uuml;&ccedil;&uuml;n Tibb M&uuml;təxəssisini məhkəməyə &ccedil;ağırmamağa və ya digər Qrup Terapiyasının iştirak&ccedil;ılarına qarşı qeydləri məhkəməyə təqdim etməməyə razılaşırsınız. Hər hansı Qrup Terapiyası iştirak&ccedil;ısının telefonda və ya digər şəkildə ayrılıqda Tibb M&uuml;təxəssisinə s&ouml;ylədiyi istənilən fikrin digər Qrup terapiyası iştirak&ccedil;ıları ilə paylaşıla biləcəyinin yalnız m&uuml;təxəssisin ixtiyarında olduğunu başa d&uuml;ş&uuml;rs&uuml;n&uuml;z. Terapiya prosesi, o c&uuml;mlədən hədəfin m&uuml;əyyənləşdirilməsi və sonlandırma &uuml;&ccedil;&uuml;n məsuliyyəti Tibb M&uuml;təxəssisi ilə paylaşmağa razılaşırsınız.</p>
    <p><strong>Mesajlaşma</strong></p>
    <p>050-111-11-11 telefon n&ouml;mrəsi ilə &ldquo;<strong>&Uuml;zvlərin Dəstəyi</strong><strong>&rdquo;</strong> komandamız ilə əlaqə saxlayaraq Tibb M&uuml;təxəssisinə mesaj g&ouml;ndərə bilərsiniz. &Uuml;zv Dəstəyi və ya Tibb M&uuml;təxəssisinə g&ouml;ndərilən elektron po&ccedil;t məktubları (email) və ya elektron mesajlara dərhal cavab verilməyə bilər. Təcili tibbi yardım g&ouml;zləyirsinizsə, bu zaman 103 və ya 113 n&ouml;mrəsinə zəng etməli, təcili tibbi yardım &ccedil;ağırmalı və ya yaxınlıqda yerləşən təcili tibbi yardım stansiyasına getməlisiniz.</p>
    <p><em>&ldquo;<strong>Video Doktor&rdquo;&ndash;un Tibb M&uuml;təxəssisləri </strong>&uuml;mumi olaraq <strong>&ldquo;Video Doktor</strong></em><strong><em>&rdquo;-un M</em></strong><strong><em>&uuml;təxəssisləri&rdquo;</em></strong><em>&nbsp;adlandırılan m&uuml;stəqil peşəkarlar kimi və ya m&uuml;vafiq tibb m&uuml;əssisəsinin tərkibində fəaliyyət g&ouml;stərir. </em></p>
    `,
    ru: '',
    en: ''
}

export default Agreement;