import {ActionCreator} from "redux";
import {IClinic} from "../models/clinic";
import {IPatientDoctorAllow} from "../models/patient-doctor-allow";
import {IDoctorFilter} from "../models/doctor-filter";
import { IClinicFilter } from "../models/clinic-filter";

export const CLINIC_FILTER = 'CLINIC_FILTER';
interface ClinicFilterAction {
    type: typeof CLINIC_FILTER,
    clinicFilter: IClinicFilter | undefined
}

export const _clinicFilter: ActionCreator<ClinicFilterAction> = (clinicFilter: IClinicFilter | undefined) => {
    return {
        type: CLINIC_FILTER,
        clinicFilter
    }
}
export const DOCTOR_FILTER = 'DOCTOR_FILTER';
interface DoctorFilterAction {
    type: typeof DOCTOR_FILTER,
    doctorFilter: IDoctorFilter | undefined,
    my: boolean,
}

export const _doctorFilter: ActionCreator<DoctorFilterAction> = (doctorFilter: IDoctorFilter | undefined, my: boolean) => {
    return {
        type: DOCTOR_FILTER,
        doctorFilter,
        my,
    }
}

export type FilterActionType = DoctorFilterAction | ClinicFilterAction;