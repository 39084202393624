import { IAlertState } from "../states/alert-state";
import { AlertActionTypes, PUSH_ALERT, POP_ALERT } from "../actions/alert-actions";

const initialState: IAlertState = {
    alert: undefined,
    queue: [],
}

export function alertReducer(
    state = initialState,
    action: AlertActionTypes
): IAlertState {
    let queue;
    switch (action.type) {
        case PUSH_ALERT:
            queue = state.queue.concat([action.text]);
            if (!state.alert) {
                return {...state, ...{queue, alert: queue[0]}};
            }
            return {...state, ...{queue}};
        case POP_ALERT:
            if (state.queue.length === 0) {
                return state;
            }  
            queue = state.queue.slice(1);
            if (queue.length == 0) {
                return {queue, alert: undefined};
            }
            return {...state, ...{queue, alert: queue[0]}};
        default:
            return state;
    }
}
