import React from "react";
import { ILoadingState, LoadingSubject } from "../../states/loading-state";
import { IAuthState } from "../../states/auth-state";
import { RootState } from "../../store";
import { connect } from "react-redux";
import { IDictionaryState } from "../../states/dictionary-state";
import DoctorDutyForm from '../../forms/doctor-duty-form';
import { submit, reset, FormAction } from 'redux-form';
import { getDutyItem, deactivateDutyItem, saveDutyItem } from "../../actions/scheduling-item-actions";
import { ISchedulingItem } from "../../models/scheduling-item";
import moment from "moment";
import './DoctorDuty.scss';
import { TFunction } from "i18next";
import { withTranslation } from 'react-i18next';
import { LoadingCenter } from "../Loading/Loading";
import Breadcrumbs from './../Breadcrumbs/Breadcrumbs';

type Props = {
    t: TFunction;
    submit: (form: string) => FormAction;
    reset: (form: string) => FormAction;
    loading: ILoadingState;
    auth: IAuthState;
    dictionary: IDictionaryState;
    pushAlert: (text: string) => void;
    getDutyItem: () => void;
    deactivateDutyItem: () => void;
    saveDutyItem: (
        item: ISchedulingItem
    ) => void;
    dutyItem?: ISchedulingItem;
    location: Location;
    onSubmit?:(data: ISchedulingItem)=>void;
};

type State = {
}

class DoctorDutyComponent extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
        }
        props.getDutyItem();
    }


    onSubmit(data: ISchedulingItem) {
        if (data.onDuty) {
            const now = new Date();
            const start = moment(data.start, "HH:mm");
            const end = moment(data.end, "HH:mm");
            start.set({ year: now.getFullYear(), date: now.getDate(), month: now.getMonth() }).startOf('minute');
            end.set({ year: now.getFullYear(), date: now.getDate(), month: now.getMonth() }).startOf('minute');
            if (!start.isBefore(end)) {
                end.add(1, 'day');
            }
            this.props.saveDutyItem({
                start: start.toDate() as any as string,
                end: end.toDate() as any as string,
                onDuty: true,
                offline: false,
                online: false,
                clinicId: data.clinicId
            });
        } else {
            const now = new Date();
            this.props.deactivateDutyItem();
        }
    }

    render() {
        const { t } = this.props;

        if (this.props.loading.subjects.has(LoadingSubject.DutyItem)) {
            return <LoadingCenter />;
        }


        return (
            <div className='doctor-duty-wrapper'>
                <div style={{ display: 'flex', margin: '20px 0 -15px 0' }}><Breadcrumbs location={this.props.location} /></div>
                <DoctorDutyForm onSubmit={(data: ISchedulingItem) => this.onSubmit(data)} />
                <button className='blue-btn'
                    onClick={() => {
                        this.props.submit('DoctorDutyForm')
                    }}
                >{t('Save')}</button>
            </div>
        )

    }
}

const mapStateToProps = (state: RootState) => ({
    loading: state.loading,
    auth: state.auth,
    dictionary: state.dictionary,
    dutyItem: state.duty.item
});

const mapDispatchToProps = {
    submit,
    reset,
    getDutyItem,
    deactivateDutyItem,
    saveDutyItem,
}
let DoctorDutyComponentContainer = connect(mapStateToProps, mapDispatchToProps)(
    (withTranslation()(DoctorDutyComponent))
);
export default DoctorDutyComponentContainer;
