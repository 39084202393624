import { ISchedulingItemRecord } from "./scheduling-item-record";

export interface ITextMessage {
    data: string | ICallData | IResponseData;
    type: TextMessageType;
}

export interface ICallData {
    patientUuid: string;
    record: ISchedulingItemRecord;
}


export interface IResponseData {
    doctorUuid: string;
    callUuid: string;
}


export enum TextMessageType {
    Text = 0, Call = 1, Response = 2, Busy = 3
}