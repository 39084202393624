import {ISchedulingItem} from "../models/scheduling-item";
import {LoadingSubject} from "../states/loading-state";
import {loadingEnd, loadingStart} from "./loading-actions";
import {pushAlert} from "./alert-actions";
import {authFetch, signOut} from "./auth-actions";
import {ThunkAction} from "redux-thunk";
import {Action, ActionCreator} from "redux";
import * as environment from './../app.json';
import {ISchedulingItemFilter} from "../models/scheduling-item-filter";
import { UserRole } from "../models/user-role.enum";
import { RootState } from "../store";
import { ISchedulingBulk } from "../models/scheduling-bulk";
import i18next from 'i18next';
import { showAlert } from "../components/Dialogs";

export const SCHEDULING_DUTY_ITEM = 'SCHEDULING_DUTY_ITEM';
interface SchedulingDutyItemAction {
    type: typeof SCHEDULING_DUTY_ITEM;
    dutyItem?: ISchedulingItem;
}
const _dutyItem: ActionCreator<SchedulingDutyItemAction> = (dutyItem?: ISchedulingItem) => {
    return {
        type: SCHEDULING_DUTY_ITEM,
        dutyItem
    };
};

export const SCHEDULING_ITEMS_LIST = 'SCHEDULING_ITEMS_LIST';
interface SchedulingItemsAction {
    type: typeof SCHEDULING_ITEMS_LIST;
    schedulingItemsList: ISchedulingItem[];
}

const _schedullingItemlist: ActionCreator<SchedulingItemsAction> = (schedulingItemsList: ISchedulingItem[]) => {
    return {
        type: SCHEDULING_ITEMS_LIST,
        schedulingItemsList
    };
};

export const SAVE_SCHEDULING_ITEM = 'SAVE_SCHEDULING_ITEM';
interface SaveSchedulingItemAction {
    type: typeof SAVE_SCHEDULING_ITEM;
    item: ISchedulingItem;
}

const _saveschedullingItem: ActionCreator<SaveSchedulingItemAction> = (item: ISchedulingItem) => {
    return {
        type: SAVE_SCHEDULING_ITEM,
        item
    };
};
export const SAVE_MULTIPLE_SCHEDULING_ITEM = 'SAVE_MULTIPLE_SCHEDULING_ITEM';
interface SaveMultipleSchedulingItemAction {
    type: typeof SAVE_MULTIPLE_SCHEDULING_ITEM;
    schedulingItemsList: ISchedulingItem[]
}

const _savemultipleschedullingItem: ActionCreator<SaveMultipleSchedulingItemAction> = (list: ISchedulingItem[]) => {
    return {
        type: SAVE_MULTIPLE_SCHEDULING_ITEM,
        schedulingItemsList: list
    };
};
export const DELETE_SCHEDULING_ITEM = 'DELETE_SCHEDULING_ITEM';
interface DeleteSchedulingItemAction {
    type: typeof DELETE_SCHEDULING_ITEM;
    item: ISchedulingItem;
}

const _deleteschedullingItem: ActionCreator<DeleteSchedulingItemAction> = (item: ISchedulingItem) => {
    return {
        type: DELETE_SCHEDULING_ITEM,
        item
    };
};
export const COUNT_SCHEDULING_ITEMS = 'COUNT_SCHEDULING_ITEMS';
interface CountSchedulingItemAction {
    type: typeof COUNT_SCHEDULING_ITEMS;
    count: number;
}

const _countschedullingItem: ActionCreator<CountSchedulingItemAction> = (count: number) => {
    return {
        type: COUNT_SCHEDULING_ITEMS,
        count
    };
};
export type SchedulingItemsType = CountSchedulingItemAction | DeleteSchedulingItemAction |
    SaveSchedulingItemAction | SchedulingItemsAction | SchedulingDutyItemAction | SaveMultipleSchedulingItemAction;


export const saveDutyItem = (
    item: ISchedulingItem
): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch, getState) => {
    dispatch(loadingStart(LoadingSubject.SaveSchedulingItem));
    try {
        const response = await authFetch(
            getState, dispatch, environment.baseUrl +
            'external/private/schedulingItem/save', 'POST',
            item, { Accept: 'application/json', 'Content-Type': 'application/json' }
        );

        if (response.status === 200) {
            const json = await response.json();
            console.log('json', json);
            dispatch(getDutyItem());

            if (item.onDuty) {
                showAlert(i18next.t('Your duty hours have been successfully saved'));
            } else {
                showAlert(i18next.t('Scheduling item successfully saved'));
            }
            
        }
        else if (response.status === 401) {
            //dispatch(signOut(token));
        }
        else {
            // dispatch(pushAlert('Saving to scheduling item list error'));
            showAlert(i18next.t('An error has occurred'));
        }
    } catch (e) {
        dispatch(pushAlert('Unknown error. Please, check internet connection'));
    } finally {
        dispatch(loadingEnd(LoadingSubject.SaveSchedulingItem));
    }
}


export const saveMultipleSchedulingItem = (
    items: ISchedulingItem[]
): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch, getState) => {
    dispatch(loadingStart(LoadingSubject.SaveMultipleSchedulingItem));
    console.log('item', items);

    try {
        const response =  await Promise.all(
            items.map((item: ISchedulingItem) => {
                authFetch(
                    getState, dispatch, environment.baseUrl +
                    'external/private/schedulingItem/save', 'POST',
                    item, { Accept: 'application/json', 'Content-Type': 'application/json' }
                ).then((response1) => {
                        if (response1?.status === 401) {
                            //dispatch(signOut(token));
                        } else if (response1.status === 200) {
                            return response1.json();
                        }
                        return null});
            })
        );

        console.log('added response', response);
        const resp1 = response ? response.filter((el) => el !== null) : [];
        if (resp1 && resp1.length) {
            dispatch(_savemultipleschedullingItem(resp1));
            dispatch(pushAlert('Scheduling items successfully saved'));
        } else {
            dispatch(pushAlert('Scheduling items error occurred'));
        }
    } catch (e) {
        dispatch(pushAlert('Unknown error. Please, check internet connection'));
    } finally {
        dispatch(loadingEnd(LoadingSubject.SaveMultipleSchedulingItem));
    }
}


export const saveBulkSchedulingItem = (
    items: ISchedulingBulk
): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch, getState) => {
    dispatch(loadingStart(LoadingSubject.SaveMultipleSchedulingItem));
    console.log('item', items);

    try {
        const response = await authFetch(
            getState, dispatch, environment.baseUrl +
            'external/private/schedulingItem/save-bulk', 'POST',
            items, { Accept: 'application/json', 'Content-Type': 'application/json' }
        );
        if (response?.status === 401) {
            //dispatch(signOut(token));
        } else if (response.status !== 200) {
            dispatch(pushAlert('Scheduling items error occurred'));
            return;
        }

        console.log('added response', response);
        const resp1 = await response.json();
        if (resp1 && resp1.length) {
            dispatch(_savemultipleschedullingItem(resp1));
            dispatch(pushAlert('Scheduling items successfully saved'));
        } else {
            dispatch(pushAlert('Scheduling items error occurred'));
        }
    } catch (e) {
        dispatch(pushAlert('Unknown error. Please, check internet connection'));
    } finally {
        dispatch(loadingEnd(LoadingSubject.SaveMultipleSchedulingItem));
    }
}

export const getSchedulingItems = (
    filter: ISchedulingItemFilter
): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch, getState) => {
    dispatch(loadingStart(LoadingSubject.SchedulingItems));

    const access = getState().auth.userToken || localStorage.getItem("az.ezgil.videodoctor.token") ? 'private' : 'public';

    try {
        const response = await authFetch(getState, dispatch, environment.baseUrl +
            'external/' + access + '/schedulingItem/list', 'POST', {...filter})

        const json = await response.json();

        if (response.status === 200) {
            dispatch(_schedullingItemlist(json));
        }
        else if (response.status === 401) {
            //dispatch(signOut(token));
        }
        else {
            dispatch(pushAlert('Scheduling items loading error'));
        }
    } catch (e) {
        dispatch(pushAlert('Unknown error. Please, check internet connection'));
    } finally {
        dispatch(loadingEnd(LoadingSubject.SchedulingItems));
    }
}

export const getDutyItem = (
): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch, getState) => {
    dispatch(loadingStart(LoadingSubject.DutyItem));
    
    try {
        const response = await authFetch(
            getState, dispatch, environment.baseUrl +
            'external/private/schedulingItem/list', 'POST',
            {onDuty: true}, { Accept: 'application/json', 'Content-Type': 'application/json' }
        );

        console.log('response list', response);

        if (response.status === 200) {
            const json = await response.json();
            console.log('json', json);
            dispatch(_dutyItem(json.length > 0 ? json[0] : undefined));
        }
        else if (response.status === 401) {
            //dispatch(signOut(token));
        }
        else {
            dispatch(pushAlert('Scheduling items loading error'));
        }
    } catch (e) {
        dispatch(pushAlert('Unknown error. Please, check internet connection'));
    } finally {
        dispatch(loadingEnd(LoadingSubject.DutyItem));
    }
}

export const deactivateDutyItem = (
): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch, getState) => {
    dispatch(loadingStart(LoadingSubject.DutyItem));
    
    try {
        const response = await authFetch(
            getState, dispatch, environment.baseUrl +
            'external/private/schedulingItem/deactivate', 'POST',
            {onDuty: true}, { Accept: 'application/json', 'Content-Type': 'application/json' }
        );

        console.log('response list', response);

        if (response.status === 200) {
            dispatch(_dutyItem());
        }
        else if (response.status === 401) {
            //dispatch(signOut(token));
        }
        else {
            console.log('text', await response.text());
            dispatch(pushAlert('Scheduling items loading error'));
        }
    } catch (e) {
        console.warn(e);
        dispatch(pushAlert('Unknown error. Please, check internet connection'));
    } finally {
        dispatch(loadingEnd(LoadingSubject.DutyItem));
    }
}

export const countSchedulingItems = (
    filter: ISchedulingItemFilter
): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch, getState) => {
    dispatch(loadingStart(LoadingSubject.CountSchedulingItem));
    console.log('filter', filter);

    try {
        const response = await authFetch(
            getState, dispatch, environment.baseUrl +
            'external/private/schedulingItem/count', 'POST',
            filter, { Accept: 'application/json', 'Content-Type': 'application/json' }
        );

        console.log('response', response);

        const json = await response.json();
        console.log('json', json);
        if (response.status === 200) {
            dispatch(_countschedullingItem(json));
        }
        else if (response.status === 401) {
            //dispatch(signOut(token));
        }
        else {
            dispatch(pushAlert('Scheduling items count loading error'));
        }
    } catch (e) {
        dispatch(pushAlert('Unknown error. Please, check internet connection'));
    } finally {
        dispatch(loadingEnd(LoadingSubject.CountSchedulingItem));
    }
}
export const deleteSchedulingItems = (
    item: ISchedulingItem
): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch, getState) => {
    dispatch(loadingStart(LoadingSubject.DeleteSchedulingItem));
    console.log('item', item);

    try {
        const response = await authFetch(
            getState, dispatch, environment.baseUrl +
            'external/private/schedulingItem/delete', 'POST',
            item, { Accept: 'application/json', 'Content-Type': 'application/json' }
        );

        if (response.status === 200) {
            dispatch(_deleteschedullingItem(item));
        }
        else if (response.status === 401) {
            //dispatch(signOut(token));
        }
        else {
            dispatch(pushAlert('Deleting from scheduling items error'));
        }
    } catch (e) {
        dispatch(pushAlert('Unknown error. Please, check internet connection'));
    } finally {
        dispatch(loadingEnd(LoadingSubject.DeleteSchedulingItem));
    }
}