import React from 'react';
import { TFunction } from 'i18next';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import TermsOfUse from './terms-of-use';
import Agreement from './agreement';
import Privacy from './privacy';
import { connect } from "react-redux";
import { RootState } from '../../store';
import './UserAgreement.scss'

interface UserAgreementProps {
    t: TFunction;
    location: any;
    language: string;
}

interface UserAgreementState {
    pageText: { az: string; ru: string; en: string };
}

class UserAgreement extends React.Component<UserAgreementProps, UserAgreementState> {
    constructor(props: UserAgreementProps) {
        super(props);
        this.state = {
            pageText: { az: '', ru: '', en: '' }
        }
    }

    componentDidMount() {
        const { pathname } = this.props.location;
        switch (pathname) {
            case '/terms-of-use': this.setState({ pageText: TermsOfUse }); break;
            case '/agreement': this.setState({ pageText: Agreement }); break;
            case '/privacy': this.setState({ pageText: Privacy }); break;
        }
    }

    render() {
        return (
            <div className='container'>
                <div className='user_agreement_wrapper'>
                    <div dangerouslySetInnerHTML={{ __html: this.state.pageText[this.props.language] || this.state.pageText['az'] }} />
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state: RootState) => ({
    language: state.settings.language
})

const UserAgreementComponent = connect(mapStateToProps)(withTranslation()(UserAgreement));

export default withRouter(props => <UserAgreementComponent {...props} />);