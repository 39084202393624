import { WithTranslation } from "react-i18next";
import { ISchedulingItemRecord, SchedulingItemRecordStatus } from "../../models/scheduling-item-record";
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import CloseIcon from '@material-ui/icons/Close';
import PaymentIcon from '@material-ui/icons/Payment';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import React from "react";
import './RecordStatus.scss';
import uiTheme from "../../theme";

const RecordStatusItem = ({ icon, color, hideText, text }) => {
    return <div className="record-status">
        {React.createElement(icon, {fontSize: 'small', htmlColor: color})}
        {!hideText && <span>{text}</span>}
        {hideText && <div className={"record-status-tooltip"} style={{background: color}}>{text}</div>}
    </div>
}

export const RecordStatus = ({ item, translationProps, hideText }: { item: ISchedulingItemRecord; translationProps: WithTranslation; hideText?: boolean }) => {
    const alignItems = hideText ? 'flex-end' : 'center';

    if (item?.status === SchedulingItemRecordStatus.Ready ||
        item?.status === SchedulingItemRecordStatus.Initiated ||
        item?.status === SchedulingItemRecordStatus.Accepted ||
        item?.status === SchedulingItemRecordStatus.Success ||
        item?.status === SchedulingItemRecordStatus.Paid
    ) {
        return <RecordStatusItem icon={ThumbUpIcon} color={uiTheme.palette.lightGreenColor} text={translationProps.t('approved')} hideText={hideText}/>;
    }

    if (item?.status === SchedulingItemRecordStatus.Created) {
        return <RecordStatusItem icon={AccessTimeIcon} color={uiTheme.palette.grayLightPrimaryColor} text={translationProps.t('waiting')} hideText={hideText}/>;
    }
    if (item?.status === SchedulingItemRecordStatus.CanceledByDoctor ||
        item?.status === SchedulingItemRecordStatus.CanceledByPatient) {
        return <RecordStatusItem icon={CloseIcon} color={uiTheme.palette.grayLightPrimaryColor} text={translationProps.t('canceled')} hideText={hideText}/>;
    }
    if (item?.status === SchedulingItemRecordStatus.MissedByDoctor ||
        item?.status === SchedulingItemRecordStatus.FailedByDoctor ||
        item?.status === SchedulingItemRecordStatus.FailedBySystem ||
        item?.status === SchedulingItemRecordStatus.Declined ||
        item?.status === SchedulingItemRecordStatus.MissedByPatient) {
        return <RecordStatusItem icon={ErrorOutlineIcon} color={uiTheme.palette.grayLightPrimaryColor} text={translationProps.t('missed\nor failed')} hideText={hideText}/>;
    }
    if (item?.status === SchedulingItemRecordStatus.Approved) {
        return <RecordStatusItem icon={PaymentIcon} color={uiTheme.palette.lightGreenColor} text={translationProps.t('waiting for payment')} hideText={hideText} />
    }
    return null;
}