import React from 'react';
import { TFunction } from 'i18next';
import { withTranslation } from 'react-i18next';
import { GoogleMap, Marker, GoogleMapProps, withGoogleMap } from 'react-google-maps';
import './contacts.scss';
import MapImg from './../../images/contacts/map.png';

interface ContactsProps {
    t: TFunction;
}

interface ContactsState {

}

class Contacts extends React.Component<ContactsProps & GoogleMapProps, ContactsState> {
    constructor(props: ContactsProps & GoogleMapProps) {
        super(props);
    }
    render() {
        const { t } = this.props;
        return (
            <div className='contacts'>
                <div className='contacts__map-mock'>
                    <img src={MapImg} />
                </div>
                {/* <GoogleMaps 
                    containerElement={ <div style={{ height: `633px`, width: `42%` }} /> }
                    mapElement={ <div style={{ height: `100%` }} />} /> */}
                <div className='contacts__info'>
                    <p className='bold-text'>{t('Contacts')}</p>
                    <p>{t('Call us')}: (+994) 51-206-38-13</p>
                    <p>Whatsapp: (+994) 51-206-38-13</p>
                    <p>{t('Electronic mail')}: ezgilmedical@gmail.com<br />
                        info@ezgil.az</p>
                    <p>{t('Address')}: {t("our_address")}</p>
                </div>
            </div>
        )
    }
}

const GoogleMaps = withGoogleMap(props => (
    <GoogleMap>
        <Marker position={{ lat: -34.397, lng: 150.644 }} />
    </GoogleMap>
))

export default withTranslation()(Contacts);