import React, { Component, RefObject } from 'react';
import { connect } from "react-redux";
import { loadChat, sendFiles, sendMessage } from '../../actions/call-actions';
import { ISchedulingItemRecord, SchedulingItemRecordStatus } from '../../models/scheduling-item-record';
import { RootState } from '../../store';
import { ITextMessage, TextMessageType } from '../../models/text-message';
import { PushType } from '../../models/websocket-out-message';
import { IAuthState } from '../../states/auth-state';
import { ICallState } from '../../states/call-state';
import { IWebsocketState } from '../../states/websocket-state';
import { ILoadingState, LoadingSubject } from '../../states/loading-state';
import { Owner } from '../../models/chat-message';
import InfiniteScroll from 'react-infinite-scroll-component';
import Spinner from 'react-spinner-material';
import * as environment from '../../app.json';
import Modal from 'react-bootstrap/Modal';
import FileBase64 from 'react-file-base64';
import Attachment from './Attachment';
import moment from 'moment';
import Viewer from 'react-viewer';
import { ImageDecorator } from 'react-viewer/lib/ViewerProps';
import { TFunction } from 'i18next';
import { withTranslation } from 'react-i18next';
import { IUpload } from '../../models/upload';

import noUserPhoto from './../../images/no-user-photo.png';
import doctorDefAva from './../../images/doctor-default-avatar.png'
import chatAttach from './../../images/chat-attach-image.png'
interface MyProps {
    t: TFunction;
    record: ISchedulingItemRecord;
    sendMessage: (text: ITextMessage, record: ISchedulingItemRecord, pushType: PushType) => void;
    loadChat: (userId: number, to?: Date) => void;
    sendFiles: (files: IUpload[], record: ISchedulingItemRecord, pushType?: PushType | undefined) => void;
    auth: IAuthState;
    call: ICallState;
    loading: ILoadingState;
    websocket: IWebsocketState;
}

interface MyState {
    message?: string;
    filesModal?: { files?: any } | null;
    imageModal?: ImageDecorator[] | null;
    imageModalVisible: boolean
}

class Chat extends Component<MyProps, MyState> {
    textarea?: HTMLTextAreaElement | null;

    constructor(props: MyProps) {
        super(props);
        this.state = {
            imageModalVisible: false,
        };
    }

    participantId() {
        return (this.props.record.patientId === this.props.auth.userToken?.selfId ? this.props.record.doctorId : this.props.record.patientId) || 0;
    }

    componentDidMount() {
        this.props.loadChat(this.participantId());
    }

    loadChatMore() {
        this.props.loadChat(this.participantId(), this.props.websocket.lastDate);
    }

    componentDidUpdate(oldProps: MyProps) {
        if (this.props.record.id !== oldProps.record.id) {
            this.componentDidMount();
        }
    }

    keyPressed(e: React.KeyboardEvent<HTMLTextAreaElement>): void {
        if (e.key !== 'Enter') {
            return;
        }
        e.stopPropagation();
        if (e.altKey) {
            this.textarea?.value && (this.textarea.value += '\n');
            return;
        }
        const data = this.state.message && this.state.message.trim();
        if (data) {
            this.props.sendMessage({ type: TextMessageType.Text, data }, this.props.record, PushType.Standard);
            this.textarea?.value && (this.textarea.value = '');
        }
    }

    haveChat(record: ISchedulingItemRecord) {
        return record.status === SchedulingItemRecordStatus.Ready ||
            record.status === SchedulingItemRecordStatus.Initiated ||
            record.status === SchedulingItemRecordStatus.Accepted;
    }

    waitForChat(record: ISchedulingItemRecord) {
        return record.status === SchedulingItemRecordStatus.Created ||
            record.status === SchedulingItemRecordStatus.Paid;
    }

    render() {
        const { t } = this.props;
        return <>
            <div className='messages-wrapper'>
                <div className='messages-block-main' id="messages-block-main">
                    <InfiniteScroll
                        next={() => {
                            if (!this.props.websocket.loadEarlier || this.props.loading.subjects.has(LoadingSubject.Messages) || this.props.loading.subjects.has(LoadingSubject.MessagesFull)) {
                                return;
                            }
                            this.loadChatMore();
                        }}
                        scrollableTarget="messages-block-main"
                        inverse={true}
                        hasMore={this.props.websocket.loadEarlier}
                        loader={<div className="spinner-wrapper"><Spinner radius={24} color={"#0279BD"} stroke={2} visible={true}></Spinner></div>}
                        dataLength={this.props.websocket.messages?.length || 0}
                    >
                        {this.props.websocket.messages.map(item => {
                            let src = noUserPhoto;
                            if (item.owner === Owner.Doctor && this.props.record.doctorImage) {
                                if (item.doctorId === this.props.record.doctorId) {
                                    if (this.props.record.doctorImage) {
                                        src = environment.uploads + 'doctors/' + this.props.record.doctorImage;
                                    } else {
                                        src = doctorDefAva;
                                    }
                                }
                            } else if (item.owner === Owner.Patient && this.props.record.patientImage) {
                                if (item.patientId === this.props.record.doctorId) {
                                    if (this.props.record.doctorImage) {
                                        src = environment.uploads + 'doctors/' + this.props.record.doctorImage;
                                    } else {
                                        src = doctorDefAva;
                                    }
                                } else {
                                    src = this.props.record.patientImage;
                                }
                            }
                            return <div key={item.id} className={item.doctorId === this.props.auth.userToken?.selfId ? (item.owner === Owner.Doctor ? 'message-item-outgoing' : 'message-item-incoming') : (item.owner === Owner.Patient ? 'message-item-outgoing' : 'message-item-incoming')}>
                                <img className='_img' src={src}></img>
                                <div>
                                    {item.textMessage?.data && <p>
                                        {item.textMessage?.data}
                                    </p>}
                                    {item.attachments?.map(a => <Attachment onClick={() => this.setState({ imageModalVisible: true, imageModal: [{ src: `data:${a.mimeType};base64,${a.content}`, alt: '' }] })} key={a.linkOwner} item={a} />)}
                                    <span className="time">
                                        {moment(item.created).format('DD MMMM YYYY • HH:mm')}
                                    </span>
                                </div>
                            </div>
                        })}
                    </InfiniteScroll>

                    {/* <div className='notification'>
                        <img src={require('./../../images/missed-call.png')} />
                        <p>Пропущенный звонок в 15:30</p>
                    </div> */}
                </div>
            </div>

            {this.haveChat(this.props.record) && <div className='write-message-block'>
                <textarea ref={(r) => this.textarea = r} placeholder='Type a message...' onKeyUp={e => this.keyPressed(e)} onChange={e => this.setState({ message: e.target.value })}></textarea>
                <img src={chatAttach} onClick={() => this.setState({ filesModal: {} })} />
            </div>}

            {this.waitForChat(this.props.record) && <div className='wait-chat-block'>{t('A chat with a doctor will open with the arrival of the appointment time')}</div>}

            <Modal
                size='sm'
                centered
                show={!!this.state.filesModal}
                onHide={() => this.setState({ filesModal: null })}
            >
                <Modal.Body className='modal-online-appointment'>
                    <h5>{t('Send file')}</h5>
                    <FileBase64
                        onDone={(files) => this.setState({ filesModal: { files } })}
                        value={this.state.filesModal?.files}
                    />
                    <button className='btn-modal-window btn btn-primary btn-sm' onClick={() => {
                        this.props.sendFiles([this.state.filesModal?.files], this.props.record, PushType.Standard);
                        this.setState({ filesModal: null });
                    }}>
                        {t('Send')}
                    </button>
                </Modal.Body>
            </Modal>
            <Viewer
                visible={this.state.imageModalVisible}
                onClose={() => this.setState({ imageModalVisible: false })}
                images={this.state.imageModal || undefined}
            />
        </>
    }
}

const mapStateToProps = (state: RootState) => ({
    auth: state.auth,
    call: state.call,
    websocket: state.websocket,
    loading: state.loading,
});

const mapDispatchToProps = {
    loadChat,
    sendMessage,
    sendFiles,
};

let ChatContainer = connect(mapStateToProps, mapDispatchToProps)(
    withTranslation()(Chat)
);

export default ChatContainer