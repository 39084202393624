import React from 'react';
import Spinner from 'react-spinner-material';

interface SaveButtonProps {
    onClickFunc: () => void;
    loading: boolean;
    text: string;
}

interface SaveButtonState {
}

class SaveButtonComponent extends React.Component<SaveButtonProps, SaveButtonState> {
    constructor(props: SaveButtonProps) {
        super(props);
        this.state = {
        }
    }

    render() {
        const { onClickFunc, loading, text } = this.props;

        return (
            <button className='blue-btn btn btn-primary'
                onClick={() => {
                    onClickFunc()
                }}
                disabled={loading}
                style={{ margin: '25px 0 0 auto' }}
            >
                <div style={{ display: 'flex' }}>
                    {loading &&
                        <div style={{ marginRight: '8px' }}>
                            {<Spinner radius={18} color={"#ffffff"} stroke={3} visible={true} />}
                        </div>}
                    {text}
                </div>
            </button>
        )
    }
}

export default SaveButtonComponent;