import { Action, ActionCreator } from "redux";
import { ThunkAction } from "redux-thunk";
import { IStatistics } from "../states/statistics";
import * as environment from '../app.json';
import { pushAlert } from "./alert-actions";

export const GET_STATISTICS: string = 'GET_STATISTICS';

export interface StatisticsAction {
    type: typeof GET_STATISTICS,
    statistics: IStatistics
}

export const _getStatistics: ActionCreator<StatisticsAction> = (statistics: IStatistics) => {
    return {
        type: GET_STATISTICS,
        statistics
    }
}

export const getStatistics = (): ThunkAction<void, {}, unknown, Action<string>> => async dispatch => {
    try {
        const response = await fetch(environment.baseUrl + '/external/public/statistic/info', {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            }
        });

        if (response.status === 200) {
            const json = await response.json();
            dispatch(_getStatistics(json));
        }
        else {
            dispatch(pushAlert('Unknown error. Please, check internet connection'));
        }
    } catch (e) {
        dispatch(pushAlert('Unknown error. Please, check internet connection'));
    }
}