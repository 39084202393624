import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, InjectedFormProps, reduxForm, formValueSelector, FormErrors } from 'redux-form';
import { IPatientMedicine } from "../models/user-medicine";
import { IDictionaryState } from '../states/dictionary-state';
import { IAuthState } from '../states/auth-state';
import { RootState } from '../store';
import { IPatientMedicineState } from '../states/patient-medicine-state';
import TextInput from './FormComponents/TextInput';
import DropDownSelect from './FormComponents/DropDownSelect';
import { TFunction } from 'i18next';
import { withTranslation } from 'react-i18next';
import MiniTextInput from './FormComponents/MiniTextInput';
import MiniDropDownSelect from './FormComponents/MiniDropDownSelect';

type Props = {
    t: TFunction;
    type: number;
    dictionary: IDictionaryState;
    auth: IAuthState;
    patientMedicine: IPatientMedicineState;
    frequency?: number;
    status?: any;
    startdate?: any;
    enddate?: any;
}
class UserMedicinesForm extends React.Component<InjectedFormProps<IPatientMedicine> & Props> {

    componentWillMount() {
        this.props.patientMedicine.selected ? this.props.initialize(this.props.patientMedicine.selected) : this.props.initialize({ status: 0 })
    }

    render() {
        const { t } = this.props;

        const medicineStatusItems = [
            { value: 0, label: t('Going') },
            { value: 1, label: t('Finished') },
        ];

        return (
            <div className='auth-form auth-form-flex'>
                <div className='auth-form__row'>
                    <div className='auth-form__col'>
                        <Field name="name" label={t('Name')} component={MiniTextInput} />
                    </div>
                    <div className='auth-form__col'>
                        <Field name="startdate" type='date' placeholder={t('Date start')}
                            component={MiniTextInput} />
                    </div>
                </div>
                <div className='auth-form__row'>
                    <div className='auth-form__col'>
                        <Field name="dosage" placeholder={t('Dosage')}
                            component={MiniTextInput} />
                    </div>
                    <div className='auth-form__col'>
                        <Field name="enddate" type='date' placeholder={t('Date end')}
                            component={MiniTextInput} />
                    </div>
                </div>
                <div className='auth-form__row'>
                    <div className='auth-form__col'>
                        <Field
                            name="frequency"
                            label={t('Frequency of medication intake')}
                            component={MiniDropDownSelect}
                            selectedValue={this.props.frequency}
                            valuesForDropdown={this.props.dictionary.medicationIntake?.map(d => ({ value: d.id, label: d.name }))}
                        />
                    </div>
                    <div className='auth-form__col'>
                        <Field
                            name="status"
                            label={t('Status')}
                            component={MiniDropDownSelect}
                            selectedValue={this.props.status}
                            valuesForDropdown={medicineStatusItems}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

const validate = (values: IPatientMedicine, props: { t: TFunction }): FormErrors<IPatientMedicine> => {
    const errors: FormErrors<IPatientMedicine> = {};
    const { t } = props;
    if (!values.name) {
        errors.name = t('Field couldn\'t be empty');
    }
    if (!values.startdate) {
        errors.startdate = t('Field couldn\'t be empty');
    }
    if (!values.enddate) {
        errors.enddate = t('Field couldn\'t be empty');
    }

    return errors;
};

const form = reduxForm<IPatientMedicine & { isChecked: boolean }, any>({
    form: 'UserMedicinesForm',
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
    validate,
})(UserMedicinesForm);

const selector = formValueSelector('UserMedicinesForm');

const mapStateToProps = (state: RootState) => {
    const { type, status, frequency, startdate, enddate } = selector(state, 'type', 'status', 'frequency', 'startdate', 'enddate') || {};
    console.log('form', state.form);
    return {
        dictionary: state.dictionary,
        patientMedicine: state.patientMedicine,
        status,
        frequency,
        type,
        startdate,
        enddate
    }
};

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(form));