import React, { Component, RefObject } from 'react';
import { connect } from "react-redux";
import { RootState } from '../../store';
import { IAuthState } from '../../states/auth-state';
import { ICallState } from '../../states/call-state';
import { ILoadingState } from '../../states/loading-state';
import { WithTranslation, withTranslation } from "react-i18next";
import MicIcon from '@material-ui/icons/Mic';
import MicOffIcon from '@material-ui/icons/MicOff';
import CallEndIcon from '@material-ui/icons/CallEnd';
import VideocamIcon from '@material-ui/icons/Videocam';
import VideocamOffIcon from '@material-ui/icons/VideocamOff';
import ChatIcon from '@material-ui/icons/Chat';
import { hangUp, muteTrack } from '../../actions/call-actions';

interface MyProps {
    muteTrack: (video: boolean) => void;
    hangUp: () => void;
    showChat: () => void;
    auth: IAuthState;
    call: ICallState;
    loading: ILoadingState;
}

interface MyState {
}

class Call extends Component<MyProps & WithTranslation, MyState> {
    peerVideo: HTMLVideoElement | null;
    selfVideo: HTMLVideoElement | null;

    constructor(props: MyProps & WithTranslation) {
        super(props);
        this.peerVideo = null;
        this.selfVideo = null;
        this.state = {
        };
    }

    componentDidMount() {
    }

    componentDidUpdate(oldProps: MyProps & WithTranslation) {
        if (this.peerVideo?.srcObject && this.props.call.stageData?.peerStreamURL !== this.peerVideo?.srcObject) {
            this.peerVideo.srcObject = this.props.call.stageData?.peerStreamURL || null;
        }
        if (this.selfVideo?.srcObject && this.props.call.stageData?.selfStreamURL !== this.selfVideo?.srcObject) {
            this.selfVideo.srcObject = this.props.call.stageData?.selfStreamURL || null;
        }
    }

    render() {
        return <div className="video-container">
            <video ref={r => {
                this.peerVideo = r;
                if (!r) {
                    return;
                }
                if (this.props.call.stageData?.peerStreamURL) {
                    this.peerVideo!.srcObject = this.props.call.stageData.peerStreamURL;
                }
            }} className="peer-video" onLoadedMetadata={e => e.currentTarget.play()}></video>
            <video ref={r => {
                this.selfVideo = r;
                if (!r) {
                    return;
                }
                if (this.props.call.stageData?.selfStreamURL) {
                    this.selfVideo!.srcObject = this.props.call.stageData.selfStreamURL;
                }
            }} className="self-video" onLoadedMetadata={e => e.currentTarget.play()} muted={true}></video>
            {!this.props.call.stageData?.peerStreamURL && 
                <div className="connecting-container">
                    <div className="connecting-label">
                        {this.props.t('Connecting...')}
                    </div>                    
                </div>
            }
            <div className="video-buttons">
                {this.props.call.stageData?.mutedAudio && <button className="button-off" onClick={() => this.props.muteTrack(false)}><MicOffIcon/></button>}
                {!this.props.call.stageData?.mutedAudio && <button onClick={() => this.props.muteTrack(false)}><MicIcon/></button>}
                <button className="hang-up" onClick={() => this.props.hangUp()}><CallEndIcon/></button>
                {this.props.call.stageData?.mutedVideo && <button className="button-off" onClick={() => this.props.muteTrack(true)}><VideocamOffIcon/></button>}
                {!this.props.call.stageData?.mutedVideo && <button onClick={() => this.props.muteTrack(true)}><VideocamIcon/></button>}
                <button onClick={() => this.props.showChat && this.props.showChat()}><ChatIcon/></button>
            </div>
        </div>
    }
}

const mapStateToProps = (state: RootState) => ({
    auth: state.auth,
    call: state.call,
    loading: state.loading,
});

const mapDispatchToProps = {
    muteTrack,
    hangUp,
};

let CallContainer = connect(mapStateToProps, mapDispatchToProps)(
    withTranslation()(Call)
);

export default CallContainer