import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import { confirmable } from 'react-confirm';
import './Dialogs.scss';
import { TFunction } from "i18next";
import { withTranslation } from 'react-i18next';

export interface AlertProps {
  okLabel?: string;
  title?: string;
  enableEscape?: boolean;
}

class AlertDialog extends React.Component<AlertProps & { t: TFunction; alert: string, show: boolean, proceed: () => void }> {
  render() {
    const {
      okLabel = 'OK',
      title,
      alert,
      show,
      proceed,
      enableEscape = true,
      t,
    } = this.props;

    return (
      <div className="static-modal">
        <Modal style={{ marginTop: '170px' }} show={show} onHide={() => proceed()} backdrop={enableEscape ? true : 'static'} keyboard={enableEscape}>
          <div className='dialogs-block'>
            <Modal.Header closeButton>
            </Modal.Header>
            <Modal.Body>
              {t(alert)}
            </Modal.Body>
            <Modal.Footer>
              <Button className='blue-btn btn btn-primary' onClick={() => proceed()}>{okLabel}</Button>
            </Modal.Footer>
          </div>
        </Modal>
      </div>
    )
  }
}

export default withTranslation()(confirmable(AlertDialog));