import React, { Component } from 'react';
import { connect } from "react-redux";
import { RootState } from "../../../store";
import './DoctorsAccess.scss';
import FiltersComponentContainer from './../../Filters/Filters';
import QuickAccessComponentContainer from './../../QuickAccess/QuickAccess';
import Services from './../../Services/Services';
import { IAuthState } from "../../../states/auth-state";
import { submit, reset, FormAction, change } from 'redux-form';
import { IDictionaryState } from "../../../states/dictionary-state";
import { IPatientDoctorAccessState } from "../../../states/patient-doctor-access-state";
import { getPatientDoctorAccessList, deletePatientDoctorAccess } from "../../../actions/patient-doctor-access-actions";
import Modal from 'react-bootstrap/Modal'
import UserDoctorsAccessForm from '../../../forms/user-doctors-access-form';
import { IPatientDoctorAccess } from "../../../models/user-doctor-access";
import { _patientDoctorAccessItem, getPatientDoctorAccess, createPatientDoctorAccess, updatePatientDoctorAccess } from "../../../actions/patient-doctor-access-actions";
import { UserRole } from "../../../models/user-role.enum";
import { TFunction, i18n } from 'i18next';
import { withTranslation } from 'react-i18next';
import { showAlert, showConfirm } from "./../../Dialogs";
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { LoadingCenter } from "../../Loading/Loading";
import { ILoadingState, LoadingSubject } from '../../../states/loading-state';
import SaveButton from './../SaveButton/SaveButton';
import moment from 'moment';
import doctorAccessImage from './../../../images/medical-card/md_items_icons/doctor_access.png';

const backgroundImageStyle = {
    backgroundImage: `url(${doctorAccessImage})`
};
interface MyProps {
    t: TFunction,
    i18n: i18n,
    submit: (form: string) => FormAction;
    reset: (form: string) => FormAction;
    auth: IAuthState;
    dictionary: IDictionaryState;
    patientDoctorAccess: IPatientDoctorAccessState;
    getPatientDoctorAccessList: () => void;
    deletePatientDoctorAccess: (id: number) => void;
    createPatientDoctorAccess: (doctorAccess: IPatientDoctorAccess, success: () => void) => void;
    updatePatientDoctorAccess: (doctorAccess: IPatientDoctorAccess, success: () => void) => void;
    getPatientDoctorAccess: (id: number) => void;
    _patientDoctorAccessItem: (doctorAccess: IPatientDoctorAccess | null) => void;
    loading: ILoadingState;
    onSubmit?: (data: IPatientDoctorAccess) => void;
}

interface MyState {
    show: boolean;
    updateItem: boolean;
    removeMode: {} | null;
}

class MyDoctorsAccessComponent extends Component<MyProps, MyState> {

    constructor(props: MyProps) {
        super(props);

        this.state = {
            show: false,
            updateItem: false,
            removeMode: null,
        };
    }

    componentDidMount() {
        this.props.getPatientDoctorAccessList();

    }

    onSubmit(data: IPatientDoctorAccess) {
        if (this.state.updateItem) {
            this.props.updatePatientDoctorAccess({ ...data, startdate: moment(data.startdate).utc().toDate(), enddate: moment(data.enddate).utc().toDate() }, this.handleClose);
        } else {
            this.props.createPatientDoctorAccess({ ...data, startdate: moment(data.startdate).utc().toDate(), enddate: moment(data.enddate).utc().toDate() }, this.handleClose);
        }
    }

    async confirmDelete() {
        const { t } = this.props;

        if (await showConfirm(t("Are you sure you want to delete this item/items?"))) {
            for (const index in this.state.removeMode) {
                if (this.state.removeMode[index]) {
                    let item: any;
                    if (this.props.patientDoctorAccess && this.props.patientDoctorAccess.list) {
                        item = this.props.patientDoctorAccess.list[index];
                    }
                    if (item) {
                        await this.props.deletePatientDoctorAccess(item.id);
                    }
                }
            }
            this.setState({ removeMode: null });
        }
    }

    handleClose = () => {
        this.setState({ show: false })
        this.props._patientDoctorAccessItem(null);
    };

    handleShow = () => { this.setState({ show: true }) };

    render() {

        const itemsList = this.props.patientDoctorAccess.list;

        const { t } = this.props;
        const patientDoctorAccessLoading = this.props.loading.subjects.has(LoadingSubject.PatientDoctorAccess);
        return (
            <div className='doctors_access_wrapper'>
                <Modal centered show={this.state.show} onHide={this.handleClose}>
                    <Modal.Header className='my-meds-modal-header' closeButton>
                        <Modal.Title>{this.state.updateItem ? t('Change doctor access') : t('New doctor access')}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <UserDoctorsAccessForm onSubmit={(data: any) => this.onSubmit(data)} />

                        <SaveButton text={t('Save')}
                            loading={patientDoctorAccessLoading}
                            onClickFunc={() => this.props.submit('UserDoctorsAccessForm')}
                        />
                    </Modal.Body>
                </Modal>

                <div className='medical_card_item_header'>
                    <div className='md_item_title_image'>
                        <div style={backgroundImageStyle}></div>
                        <h1>{t('Access for doctors')}</h1>
                    </div>
                    <div className='button_blck'>
                        <div className='show-more-btn'
                            onClick={() => {
                                this.setState({ updateItem: false })
                                this.props.reset('UserDoctorsAccessForm')
                                this.handleShow();

                            }}
                        >
                            <p className='show-more-btn-title'>{t('Add')}</p>
                        </div>
                    </div>
                </div>

                <div className='medical_card_item_desc'>
                    <p>{t('In the Doctors Access section, you can provide your doctors with temporary, short-term or long-term access to your medical records and dynamic health indicators, regardless of your location or time of day.')}</p>
                </div>

                <div className='medical_card_item_data_block'>
                    <p className='medical_card_item_title'>{this.props.t('All data')}</p>

                    <div className='button_blck'>
                        {!this.state.removeMode && !!itemsList?.length && <button onClick={() => this.setState({ removeMode: {} })} className='blue-btn btn btn-primary'>{t('Delete')}</button>}
                        {this.state.removeMode && <button onClick={() => this.setState({ removeMode: null })} className='blue-btn btn btn-primary cancel-btn'>{t('Cancel')}</button>}
                        {this.state.removeMode && <button onClick={() => this.confirmDelete()} className='blue-btn btn dark btn-primary'>{t('Confirm')}</button>}
                    </div>
                    <div className='table_data'>
                        <table className={this.props.auth?.userToken?.role === 3 ? 'pointer' : ''}>
                            <tr className='centerItem'>
                                <th>{this.props.t('Doctor')}</th>
                                <th>{this.props.t('Startdate')}</th>
                                <th>{this.props.t('Enddate')}</th>
                            </tr>
                            {itemsList instanceof Array && itemsList.map((item: any, index: any) => {
                                return (
                                    <tr onClick={async () => {
                                        if (this.state.removeMode) {
                                            return;
                                        }
                                        await this.props.getPatientDoctorAccess(item.id);
                                        this.setState({ updateItem: true })
                                        this.handleShow();
                                    }}>
                                        <td>
                                            {this.state.removeMode && <div style={{ alignItems: 'center' }}>
                                                {!this.state.removeMode[index] && <RadioButtonUncheckedIcon onClick={() => this.setState({ removeMode: { ...this.state.removeMode, [`${index}`]: true } })} fontSize='small' htmlColor='#007bff' />}
                                                {this.state.removeMode[index] && <CheckCircleOutlineIcon onClick={() => this.setState({ removeMode: { ...this.state.removeMode, [`${index}`]: false } })} fontSize='small' htmlColor='#007bff' />}
                                            </div>}

                                            {item.doctorname}
                                        </td>
                                        <td>{moment(item.startdate).format('DD.MM.YYYY HH:mm')}</td>
                                        <td>{moment(item.enddate).format('DD.MM.YYYY HH:mm')}</td>
                                    </tr>
                                )
                            })}
                        </table>
                        {this.props.loading.subjects.has(LoadingSubject.PatientDoctorAccessList) ? <div style={{ marginTop: '20px' }}><LoadingCenter /></div> :
                            !itemsList || itemsList.length === 0 && <div className='no-items-no-results'> <p>{t('No data available')}</p></div>}
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: RootState) => ({
    auth: state.auth,
    dictionary: state.dictionary,
    patientDoctorAccess: state.patientDoctorAccess,
    loading: state.loading,
});

const mapDispatchToProps = {
    submit,
    reset,
    getPatientDoctorAccessList,
    deletePatientDoctorAccess,
    change,

    createPatientDoctorAccess,
    updatePatientDoctorAccess,
    getPatientDoctorAccess,
    _patientDoctorAccessItem,
};

const MyDoctorsAccessComponentContainer = connect(mapStateToProps, mapDispatchToProps)(
    withTranslation()(MyDoctorsAccessComponent)
);

export default MyDoctorsAccessComponentContainer