import { Middleware, MiddlewareAPI, Dispatch, AnyAction } from "redux";
import { RootState } from "../store";
import { STAGE, CallActionTypes, StageAction, StageDataAction, STAGE_DATA, answer, AUTO_ANSWER, answer2, answer3, hangUp, HANG_UP, stage, displayCallUuid, END_ALL_CALLS } from "../actions/call-actions";
import { CallStage } from "../models/call-stage";
import { CallSubStage } from "../models/call-sub-stage";
import { CallStatus } from "../models/call-history";
import i18n from 'i18next';
import { authFetch } from "../actions/auth-actions";
import * as environment from './../app.json';

export default function callMiddleware(
    config: {} = {}
): Middleware<any, any, any> {
    return (store: MiddlewareAPI<any, RootState>) => (next: Dispatch<AnyAction>) => (
        action: AnyAction
    ) => {            
        const connection = store.getState().call.connectionData;
        const auth = store.getState().auth;
        const call = store.getState().call;
        if (action.type === AUTO_ANSWER && action.messageId !== call.autoAnswerId) {
            console.warn("AUTO_ANSWER " + action.messageId, call);
            if (call.stage === CallStage.Income && action.messageId === call.stageData?.callUuid) {
                if (action.decline) {
                    store.dispatch(hangUp(CallStatus.Rejected))
                } else {
                    store.dispatch(answer(true));
                }
            }
        }
        else
        if (action.type === END_ALL_CALLS) {
            console.log('endAllCalls on endAllCalls');
            if (call.connectionData.peer) {
                call.connectionData.peer.destroy();
            }
            // IncomingCall.dismiss();
            if (call.stageData?.callUuid) {
                store.dispatch(hangUp(CallStatus.Done));
            }
        }
        else
        if (action.type === HANG_UP) {
            console.log('endAllCalls on hangup');
            if (call.connectionData.peer) {
                if (call.stageData?.selfStreamURL) {
                    if ((call.stageData.selfStreamURL as any).stop) {
                        (call.stageData.selfStreamURL as any).stop();
                    } else {
                        call.stageData.selfStreamURL.getTracks().forEach(function(track) { track.stop() })
                    }
                }
                call.connectionData.peer.destroy();
            }
        }
        else
        if (action.type === STAGE) {
            const stageAction = action as StageAction;
            if (stageAction.stage === CallStage.Income) {
                const calUuid = stageAction.stageData?.callUuid;
                (async () => {
                    // if ((await AsyncStorage.getItem('az.ezgil.videodoctor.decline')) === calUuid) {
                    //     store.dispatch(hangUp(CallStatus.Rejected, call.stageData!, auth.userToken?.auth!));
                    // } else if ((await AsyncStorage.getItem('az.ezgil.videodoctor.answer')) === calUuid) {
                    //     store.dispatch(answer(call.connectionData, stageAction.stageData?.record!, auth.userToken!.auth, calUuid!));
                    // } else 
                    if (call.autoAnswerId === calUuid) {
                        if (call.decline) {
                            store.dispatch(hangUp(CallStatus.Rejected))
                        } else {
                            store.dispatch(answer(true));
                        }
                    } else {
                        // console.log(useSimpleUI + ' displayIncomingCall ' + calUuid, action.stageData!.record!.patientId!.toString());
                        if (store.getState().call.displayCallUuid === calUuid) {
                            return;
                        }
                        store.dispatch(displayCallUuid(calUuid));
                        // if (useSimpleUI) {
                        //     IncomingCall.display(
                        //         calUuid!, // Call UUID v4
                        //         action.stageData?.record?.patientName, // Username
                        //         'http://videodoktor.az/images/logo.png', // Avatar URL
                        //         i18n.t('Incomming Call') // Info text
                        //     );
                        // } else {
                        //     RNCallKeep.displayIncomingCall(calUuid!, action.stageData?.record?.patientName, action.stageData?.record?.patientName, 'generic', true);
                        // }
                    }
                })();
            } else if (stageAction.stage === CallStage.Idle) {
                console.log('endAllCalls on idle');
                // if (!useSimpleUI) {
                //     RNCallKeep.endAllCalls();
                // }
            }
        }
        else
        if (action.type === STAGE_DATA) {
            const stageDataAction = action as StageDataAction;
            console.log('STAGE_DATA ' + call.stageData?.peerUuid, stageDataAction);
            if (stageDataAction.subStage === CallSubStage.IncomeReady && stageDataAction.stageData.peerUuid2 === call.stageData?.peerUuid) {
                store.dispatch(answer2({...call.stageData!, call: stageDataAction.stageData.call}));
            }
            if (stageDataAction.subStage === CallSubStage.OutcomeReady) {
                store.dispatch(answer3(call.connectionData, {...call.stageData!, peerUuid: stageDataAction.stageData.peerUuid}));
                store.dispatch(stage(CallStage.Call, {}));
            }
        }
        return next(action);
    };
}