import React, { Component } from "react";
import { connect } from "react-redux";
import { RootState } from "../../store";
import "./Home.scss";
import FiltersComponentContainer from "./../Filters/Filters";
import { Link, RouteComponentProps } from "react-router-dom";
import { IAuthState } from "../../states/auth-state";
import { WithTranslation, withTranslation } from "react-i18next";
import Alphabet from "../Alphabet/Alphabet";
import { getStatistics } from "../../actions/statistics-actions";
import { IStatistics } from "../../states/statistics";
import Carousel from "react-bootstrap/Carousel";
import history from "./../../history";
import QuickAccessComponentContainer from "../QuickAccess/QuickAccess";
import { ReactComponent as Slider1Ru } from "./../../images/slider1_ru.svg";
import { ReactComponent as Slider1En } from "./../../images/slider1_en.svg";
import { ReactComponent as Slider1Az } from "./../../images/slider1_az.svg";

import {
  Link as ScrollLink,
  Element,
  Events,
  animateScroll as scroll,
  scrollSpy,
  scroller,
} from "react-scroll";
import { toggleAuth, verfyFromParams } from "../../actions/auth-actions";
import MobileBlock from "../MobileBlock/MobileBlock";

import featureDoctors from "./../../images/feature_doctors.png";
import feature_clinics from "./../../images/feature_clinics.png";
import featureMedicalCard from "./../../images/feature_medical_card.png"
import featureVideo from "./../../images/feature_video.png"
import featureOs from "./../../images/feature_os.png"
interface MyProps {
  auth: IAuthState;
  statistics: IStatistics;
  language: string;
  getStatistics: () => void;
  toggleAuth: (b: boolean) => void;
  verfyFromParams: (
    userdata: string,
    code: string,
    operation: string | null
  ) => void;
  location: any;
}

interface MyState {
  slider_index: number;
  scrollToMobile: any;
}

class HomeComponent extends Component<
  MyProps & WithTranslation & RouteComponentProps,
  MyState
> {
  mobileAppBlock: HTMLDivElement | null | undefined;

  constructor(props: MyProps & WithTranslation & RouteComponentProps) {
    super(props);
    this.state = {
      slider_index: 1,
      scrollToMobile: false,
    };

    if (props.location.pathname !== "/verify") {
      return;
    }
    const params = new URLSearchParams(props.location.search);
    const code = params.get("code");
    const userdata = params.get("userdata");
    const operation = params.get("operation");
    if (code && userdata) {
      props.verfyFromParams(userdata, code, operation);
    }
  }

  componentDidMount() {
    this.props.getStatistics();

    const { scrollToMobile } = this.state;
    const { history, location } = this.props;
    if (this.props?.location?.state?.scrollToMobile) {
      console.log("scroll", scrollToMobile);
      scroller.scrollTo("mobile-block", {
        smooth: true,
      });
      history.replace("/");
    }
  }

  render() {
    const { t, language } = this.props;

    const chatExample: { az: string; ru: string; en: string }[] = [
      {
        az: "VideoDoktor nədir?",
        ru: "Что такое VideoDoktor?",
        en: "What is VideoDoktor?",
      },
      {
        az: "Video Doktor bir toxunuşda rahatlıqla səhiyyə xidmətləri təmin edən inteqrasiya olunmuş tibbi rəqəmsal platformadır. Tətbiqi aşağıdakı məqsədlər üçün istifadə edə bilərsiniz (Apple App Store və Google Play Store)",
        ru: "Video Doctor - это интегрированная медицинская цифровая платформа, которая предоставляет медицинские услуги в одно касание. Вы можете использовать приложение для следующих целей (доступно в Apple  App Store и Google Play Store)",
        en: "Video Doktor is an integrated healthcare digital platform providing medical convenience at your fingertips. You can use the app (available on Apple App Store and Google Play Store)",
      },
      {
        az: "Telekonsultasiya üçün ödəniş məbləği nə qədərdir?",
        ru: "Сколько стоит телеконсультация?",
        en: "What are the charges for a teleconsultation?",
      },
      {
        az: "Video Doktor vasitəsilə 30-dəqiqəlik telekonsultasiya üçün ödəniş məbləği __ AZN təşkil edir (dərman preparatlarının çatdırılması daxil olmaqla). 15 dəqiqə xaricində, həkim vaxtın daha 15-dəqiqəlik uzanmasına görə əlavə ödəniş istəyə bilər. Dərman preparatlarına görə ödəniş edilir.",
        ru: "30-минутная телеконсультация через видео-доктора составляет __ AZN (включая доставку лекарственных препаратов). Помимо 15-минутного периода, врач может взимать дополнительную плату за дополнительное 15-минутное продление времени. За лекарства взимается отдельная стоимость.                ",
        en: "Video Doktor charges AZN__ for a 30-minute teleconsultation (inclusive of medication delivery). Beyond 15 minutes, the doctor may request for an additional payment for another 15-minute extension. Medication charges apply.",
      },
      {
        az: "Telekonsultasiya üçün uyğun OLMAYAN vəziyyətlər hansılardır?",
        ru: "Какие ситуации НЕ подходят для телеконсультации?",
        en: "What conditions are NOT suitable for teleconsultation?",
      },
      {
        az: "Təxirəsalınmaz sağlıq problemləri telekonsultasiya üçün uyğun DEYİL. Təxirəsalınmaz tibbi yardım üçün, lütfən ən yaxınlıqda yerləşən Təcili Tibbi Yardım stansiyası və ya xəstəxanasına müraciət edin və ya təcili yardım maşını çağırmaq üçün 103 nömrəsinə zəng vurun.",
        ru: "Неотложные проблемы со здоровьем НЕ подходят для телеконсультации. Для получения неотложной медицинской помощи позвоните в ближайшую станцию скорой помощи или обратитесь в больницу, или позвоните по номеру 103, чтобы вызвать скорую помощь.",
        en: "Emergency health issues are NOT suitable for teleconsultation. Please seek immediate medical assistance at the nearest Emergency Clinic (Təcili Tibbi Yardım stansiyası və ya xəstəxanası) or call 103 for an ambulance.",
      },
      {
        az: "Telekonsultasiyadan kimlər istifadə edə bilər?",
        ru: "Кто может воспользоваться телеконсультацией?",
        en: "Who can use teleconsultation?",
      },
      {
        az: "Telekonsultasiya 2 yaşdan böyük və təxirəsalınmaz sayılmayan xəstəliklərlə əlaqədar müalicəyə ehtiyacı olan hər kəs üçün uyğundur. Lütfən nəzərə alın ki, istifadəçi akkauntunuz digər şəxs tərəfindən istifadə oluna bilməz.",
        ru: "Телеконсультация подходит для всех лиц старше 2 лет, нуждающихся в лечении несрочных заболеваний. Обратите внимание, что ваша учетная запись  пользователя (аккаунт) не может быть использован кем-либо еще.",
        en: "Teleconsultation is suitable for anyone above 2 years old and seeking treatment for non-emergency illnesses. Please note that your user account cannot be used by another person.",
      },
    ];

    return (
      <div className="home">
        <QuickAccessComponentContainer />
        <FiltersComponentContainer />
        <div className="features-block-main">
          <p className="small-title">{t("Also you can")}</p>
          <div className="features-block">
            <div
              className="feature-container"
              onClick={() => history.push("/doctors")}
            >
              <img
                className="feature-image"
                src={featureDoctors}
                alt="feature_doctors"
              />
              <p
                // onClick={() => {
                //     if (!this.props.auth.userToken) {
                //         showAlert('Пожалуйста выполните вход для того чтобы увидеть список врачей!')
                //     }
                // }}
                className="feature-desc"
              >
                {t("Make")}
                <span> {t("an appointment")}</span>
              </p>
            </div>
            <div
              className="feature-container"
              onClick={() =>
                history.push("/clinics", { clinicType: "clinics" })
              }
            >
              <img
                className="feature-image"
                src={feature_clinics}
                alt="feature_clinics"
              />
              <p className="feature-desc">
                {t("Find")}&nbsp;
                <span>{t("the best clinic")}</span>
              </p>
            </div>
            <div
              className="feature-container"
              onClick={() => {
                if (this.props.auth.userToken?.auth) {
                  history.push("/medical-card");
                } else {
                  this.props.toggleAuth(true);
                }
              }}
            >
              <img
                className="feature-image"
                src={featureMedicalCard}
                alt="feature_medical_card"
              />
              <p className="feature-desc">
                {t("Create")} <span>{t("own medcard")}</span>
              </p>
            </div>
            <div
              className="feature-container"
              onClick={() => history.push("/doctors")}
            >
              <img
                className="feature-image"
                src={featureVideo}
                alt="feature_video"
              />
              <p className="feature-desc">
                {t("Make ")} <span>{t("an online appointment")}</span>
              </p>
            </div>
            <div
              className="feature-container"
              onClick={() =>
                this.mobileAppBlock &&
                this.mobileAppBlock.scrollIntoView({ behavior: "smooth" })
              }
            >
              <img
                className="feature-image"
                src={featureOs}
                alt="feature_os"
              />
              <p className="feature-desc">
                {t("Download")} <span>{t("mobile application")}</span>
              </p>
            </div>
          </div>
        </div>

        <div className="reviews-block">
          <h1 className="big-title">
            {t("Why it is worth choosing VideoDoctor?")}
          </h1>
          <div className="reviews-nav">
            <p className="review-item">
              {t(
                "Consultation on a wide range of questions related to prevention and treatment of various diseases"
              )}
            </p>
          </div>

          <div className="about-slider">
            <Carousel fade>
              <Carousel.Item>
                {this.props.i18n.language === "ru" && <Slider1Ru />}
                {this.props.i18n.language === "az" && <Slider1Az />}
                {this.props.i18n.language === "en" && <Slider1En />}
              </Carousel.Item>
            </Carousel>
          </div>

          <div className="counter-block-wrapper">
            <h1 className="big-title">{t("We care about your health")}</h1>
            <div className="reviews-nav">
              <p className="review-item">
                {t(
                  "The service began operating in 2020 under the VideoDoc brand. We have helped millions of people to get help and we continue to improve the quality of medical services."
                )}
              </p>
            </div>
            <div className="counter-block">
              <div className="counter-item">
                <p className="counter-title">
                  {this.props.statistics?.totalRecords}
                </p>
                <p className="counter-desc">{t("Patient appointed")}</p>
              </div>
              <div className="counter-item">
                <p className="counter-title">
                  {this.props.statistics?.totalReviews}
                </p>
                <p className="counter-desc">{t("Comments on site")}</p>
              </div>
              <div className="counter-item">
                <p className="counter-title">
                  {this.props.statistics?.totalDoctors}
                </p>
                <p className="counter-desc">{t("Practice doctor in base")}</p>
              </div>
              <div className="counter-item">
                <p className="counter-title">
                  {this.props.statistics?.totalClinics}
                </p>
                <p className="counter-desc">{t("Clinics in base")}</p>
              </div>
            </div>
          </div>
        </div>
        <Element name="mobile-block">
          <div
            className="content-block"
            id="mobile-app-block"
            ref={(ref) => (this.mobileAppBlock = ref)}
          >
            <h1 className="big-title">{t("Mobile application")}</h1>
            <div className="reviews-nav">
              <p className="review-item">
                {t(
                  "No way to choose a doctor at the computer? No problem! After all, the VideoDoktor mobile application is available from any smartphone! Just follow the link!"
                )}
              </p>
            </div>

            <MobileBlock />
          </div>
        </Element>
        <Alphabet />
      </div>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  form: state.form,
  auth: state.auth,
  statistics: state.statistics,
  language: state.settings.language,
});

const mapDispatchToProps = {
  getStatistics,
  toggleAuth,
  verfyFromParams,
};

let HomeComponentContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(HomeComponent));

export default HomeComponentContainer;
