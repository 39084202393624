import React, { Component } from 'react';
import { connect } from "react-redux";
import { RootState } from "../../store";
import './Welcome.scss';
import ServicesComponentContainer from './../Services/Services';
import faq from './../../images/faq.png'
import main from './../../images/welcome/main.png'
import appIcon from './../../images/app_icon.png'
import qrCode from './../../images/qr_code_img.png'
import appStore from './../../images/app_store.png'
import googlePlay from './../../images/google_play.png'
import appImg1 from './../../images/app_img1.png'
import appImg2 from './../../images/app_img2.png'
interface MyProps { }

interface MyState { }

class WelcomeComponent extends Component<MyProps, MyState> {

    constructor(props: MyProps) {
        super(props);
        this.state = {

        };
    }

    render() {
        return (
            <div className='welcome-main-wrapper'>

                <div className='welcome-main-block'>
                    <div className='welcome-desc-block'>
                        <h1 className='welcome-desc-title'>Помогаем быть здоровым</h1>
                        <p className='welcome-desc-text'>Запись на приём к врачу,<br />
                            онлайн-консультации и другие<br />
                            медицинские сервисы для здоровья вашей семьи
                        </p>
                    </div>
                    <img className='welcome-desc-bg-image' src={main} alt='welcome-main-img' />
                </div>

                <h1 className='medical-services-title'>Медицинские сервисы</h1>

                <div className='medical-services'>

                    <div className='medical-services-item'>
                        <h1 className='medical-services-item-title'>Прием врача в клинике</h1>
                        <div className='make-record-btn'>
                            <p className='blue-btn'>Записаться</p>
                        </div>
                    </div>

                    <div className='medical-services-item'>
                        <h1 className='medical-services-item-title'>Комплексное обследование</h1>
                        <div className='make-record-btn'>
                            <p className='blue-btn'>Записаться</p>
                        </div>
                    </div>

                    <div className='medical-services-item'>
                        <h1 className='medical-services-item-title'>Медицинская карта онлайн</h1>
                        <div className='make-record-btn'>
                            <p className='blue-btn'>Перейти</p>
                        </div>
                    </div>

                    <div className='medical-services-item'>
                        <h1 className='medical-services-item-title'>Онлайн-консультация</h1>
                        <div className='make-record-btn'>
                            <p className='blue-btn'>Записаться</p>
                        </div>
                    </div>

                </div>

                <div className='reviews-block'>
                    <p className='big-title'>Заботимся о вашем здоровье</p>

                    <div className='counter-block-wrapper'>
                        <p className='welcome-about-service'>Сервис начал свою работу в 2020 году под брендом VideoDoc.
                            Мы помогли миллионам людей получить помощь и продолжаем
                            повышать качество медицинских услуг.</p>
                        <div className='counter-block'>
                            <div className='counter-item'>
                                <p className='counter-title'>100</p>
                                <p className='counter-desc'>Пациентов записались через наш сервис</p>
                            </div>
                            <div className='counter-item'>
                                <p className='counter-title'>100</p>
                                <p className='counter-desc'>отзывов оставили на сайте</p>
                            </div>
                            <div className='counter-item'>
                                <p className='counter-title'>100</p>
                                <p className='counter-desc'>практикующих врачей в нашей базе</p>
                            </div>
                            <div className='counter-item'>
                                <p className='counter-title'>100</p>
                                <p className='counter-desc'>клиник подключено к сервису</p>
                            </div>
                        </div>
                        <p className='big-title'>Ваши вопросы</p>
                        <img className='faq' src={faq} alt='faq' />
                    </div>
                </div>

                <div className='content-block'>
                    <div className='mobile-app-block'>
                        <div className='mobile-app-left'>
                            <img className='app_icon' src={appIcon} alt="app_icon" />
                            <p className='mobile-app-title'>VideoDoktor</p>
                            <p className='mobile-app-desc'>Проверенные врачи у вас в телефоне</p>
                            <img className='qr-code-img' src={qrCode} alt="qr_code_img" />
                            <div className='app-images-block'>
                                <div className='app-markets'>
                                    <div><img className='app-store' src={appStore} alt="app_store" /></div>
                                    <div><img className='google-play' src={googlePlay} alt="google_play" /></div>
                                </div>
                            </div>
                        </div>
                        <div className='mobile-app-right'>
                            <img className='app_img1' src={appImg1} alt='app_img1' />
                            <img className='app_img2' src={appImg2} alt='app_img2' />
                        </div>
                    </div>
                </div>
                {/* <ServicesComponentContainer /> */}
            </div >
        )
    }
}

const mapStateToProps = (state: RootState) => ({

});

const mapDispatchToProps = {

};

let WelcomeComponentContainer = connect(mapStateToProps, mapDispatchToProps)(
    WelcomeComponent
);


export default WelcomeComponentContainer