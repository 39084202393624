import React from "react";

import "./DiseaseList.scss";
import { Link } from "react-router-dom";
import LineAlphabet from "../lineAlphabet/LineAlphabet";
import { connect } from "react-redux";
import { WithTranslation, withTranslation } from "react-i18next";
import { IDictionary } from "../../../models/dictionary";
import "./Disease.scss";
import rigthArrow from "../../../images/right-arrow.png";
import Diseases from "../../Diseases/Diseases";
import { RootState } from "../../../store";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { getDiseaseInformation } from "../../../actions/disease-information-actions";
import { IDiseaseInfo } from "../../../models/disease-info";
import MobileBlock from "../../MobileBlock/MobileBlock";
import { HashLink } from "react-router-hash-link";
import Nav from "../../NewNav/Nav";
import { IDoctorFilter } from "../../../models/doctor-filter";
import { getDoctorList } from "../../../actions/doctor-action";
import { IDoctor } from "../../../models/doctor";
import { IDictionaryState } from "../../../states/dictionary-state";
import DoctorsBlockDisease from "./DoctorsBlockDisease";
import diseaseAvatar from "../../../images/disease-avatar.png";
import { getDoctorPrice } from "../../../actions/doctor-action";
import { IDoctorPriceFilter } from "../../../models/doctor-price-filter";
import { UserRole } from "../../../models/user-role.enum";
import { IAuthState } from "../../../states/auth-state";
import { IDoctorState } from "../../../states/doctor-state";

interface DiseaseItemProps extends WithTranslation {
    diseasesMap: { [key: number]: IDictionary } | undefined;
    getDiseaseInformation: (diseaseId) => void;
    diseaseInfo: IDiseaseInfo;
    specializationSorted: { [key: number]: IDictionary } | undefined;
    lang: string;
    getDoctorList: (filter: IDoctorFilter) => void;
    doctors: IDoctor[];
    // dictionary: IDictionaryState;
    getDoctorPrice: (filter: IDoctorPriceFilter) => void;
    auth: IAuthState;
    doctorPrice: any;
}

interface DiseaseItemState {
    showSpecializations: boolean;
    showCount: number;
    diseaseId: number | undefined;
}

interface RouteParams {
    id: string;
    param2?: string;
}

class Disease extends React.Component<DiseaseItemProps & RouteComponentProps<RouteParams>,
    DiseaseItemState> {
    constructor(props: DiseaseItemProps & RouteComponentProps<RouteParams>) {
        super(props);
        this.state = {
            showSpecializations: false,
            diseaseId: undefined,
            showCount: 2,
        };
    }

    async componentDidMount() {
        const diseaseId = +this.props.match.params.id;
        await this.setState({ diseaseId: diseaseId, showCount: 2 });

        await this.props.getDiseaseInformation(diseaseId);

        // this.props.doctors?.length > 0 &&
        //   this.props.doctors.forEach((doctor) => {
        //     this.props.getDoctorPrice({ doctorId: doctor.id });
        //   });
        //  await this.props.getDoctorPrice({ doctorId: 106 });

        //   console.log(this.props.doctorPrice, "doctorPrice");
    }

    async componentDidUpdate(prevProps, prevState) {
        const diseaseId = +this.props.match.params.id;
        if (this.state.diseaseId !== diseaseId) {
            await this.setState({ diseaseId: diseaseId, showCount: 2 });
            await this.props.getDiseaseInformation(diseaseId);
            await this.props.getDoctorList({ disease: diseaseId });
        }
    }

    showSpecializationsHandler() {
        this.setState({ showSpecializations: !this.state.showSpecializations });
    }

    handleClickShow() {
        this.setState({ showCount: this.state.showCount + 2 });
    }

    render() {
        const { t, lang } = this.props;
        console.log("renderDisease");

        //this for change 'az' to Az,"en" to En,"ru" to Ru
        const requestLang =
            lang.substring(0, lang.length - 1).toUpperCase() + lang.substr(1);

        const diseaseId = +this.props.match.params.id;

        const description =
            this.props.diseaseInfo[0]?.["description" + requestLang];
        const reasons = this.props.diseaseInfo[0]?.["reasons" + requestLang];
        const diagnostics =
            this.props.diseaseInfo[0]?.["diagnostics" + requestLang];
        const symptoms = this.props.diseaseInfo[0]?.["symptoms" + requestLang];
        const danger = this.props.diseaseInfo[0]?.["danger" + requestLang];
        const groupOfRisk =
            this.props.diseaseInfo[0]?.["groupOfRisk" + requestLang];
        const prevention = this.props.diseaseInfo[0]?.["prevention" + requestLang];
        const treatment = this.props.diseaseInfo[0]?.["treatment" + requestLang];

        // const price =
        //   this.props.auth.userToken?.role === UserRole.Doctor
        //     ? null
        //     : this.props.doctors.doctorPrice.find(
        //         (p) => p.clinicId === this.state.selectedItem?.clinicId
        //       );
        return (
            <>
                <div className="content-wrapper">
                    <Nav />
                    <h3>
                        {this.props.diseasesMap && this.props.diseasesMap[diseaseId]?.name
                            ? this.props.diseasesMap[diseaseId].name
                            : "Name of disease"}
                    </h3>
                    <div className="disease">
                        <div className="disease-blok">
                            <div className="disease-info__left">
                                <li>
                                    <HashLink
                                        to={`/disease/${diseaseId}#reasons`}
                                        className="disease-info__left-item"
                                    >
                                        {t("Reasons")}
                                    </HashLink>
                                </li>
                                <li>
                                    <HashLink
                                        to={`/disease/${diseaseId}#symptoms`}
                                        className="disease-info__left-item"
                                    >
                                        {t("Symptoms")}
                                    </HashLink>
                                </li>
                                <li>
                                    <HashLink
                                        to={`/disease/${diseaseId}#diagnostics`}
                                        className="disease-info__left-item"
                                    >
                                        {t("Diagnostics")}
                                    </HashLink>
                                </li>
                                <li>
                                    <HashLink
                                        to={`/disease/${diseaseId}#treatment`}
                                        className="disease-info__left-item"
                                    >
                                        {t("Treatment")}
                                    </HashLink>
                                </li>
                                <li>
                                    <HashLink
                                        to={`/disease/${diseaseId}#danger`}
                                        className="disease-info__left-item"
                                    >
                                        {t("Danger")}
                                    </HashLink>
                                </li>
                                <li>
                                    <a href={`#groupOfRisk`} className="disease-info__left-item">
                                        {t("Group of risk")}
                                    </a>
                                </li>
                                <li>
                                    <HashLink
                                        to={`/disease/${diseaseId}#prevention`}
                                        className="disease-info__left-item"
                                    >
                                        {t("Prevention")}
                                    </HashLink>
                                </li>

                                <li>
                                    <Link
                                        to={`/doctors?disease=${this.props.diseasesMap &&
                                            +this.props.diseasesMap[diseaseId]?.id
                                            }`}
                                        className="disease-info__left-item"
                                    >
                                        {t("Make an appointment")}
                                    </Link>
                                </li>
                            </div>

                            <div className="disease-info__right">
                                <h5>
                                    {this.props.diseasesMap &&
                                        this.props.diseasesMap[diseaseId]?.name}
                                </h5>
                                {description
                                    ? description.split("\n").map((des) => <div>{des}</div>)
                                    : t("This page is being updated. Please visit us later.")}
                            </div>
                        </div>
                    </div>
                    <Link
                        to={`/doctors?disease=${this.props.diseasesMap && +this.props.diseasesMap[diseaseId]?.id
                            }`}
                        className="arrow-block"
                    >
                        <img src={diseaseAvatar} alt="" />

                        <div className="arrow-blok__title">
                            {t(`See all doctors treating `)}
                            {t(
                                `${this.props.diseasesMap &&
                                    this.props.diseasesMap[diseaseId]?.name
                                    ? this.props.diseasesMap[diseaseId].name
                                    : "Disease"
                                }`
                            )}
                        </div>
                        <img src={rigthArrow} alt="" />
                    </Link>

                    {description?.length ? (
                        <div className="all-doctor">
                            <h5>
                                {this.props.diseasesMap &&
                                    this.props.diseasesMap[diseaseId]?.name}
                            </h5>

                            {description?.split("\n").map((des) => (
                                <div>{des}</div>
                            ))}
                        </div>
                    ) : null}

                    {reasons?.length ? (
                        <div id="reasons" className="disease-info__block">
                            <h5 className="services-block__title">{t("Reasons")}</h5>
                            {reasons.split("\n").map((des) => (
                                <div>{des}</div>
                            ))}
                        </div>
                    ) : null}
                    <br />
                    {symptoms?.length ? (
                        <div id="symptoms" className="disease-info__block">
                            <h5 className="services-block__title">{t("Symptoms")}</h5>

                            {symptoms.split("\n").map((des) => (
                                <div>{des}</div>
                            ))}
                        </div>
                    ) : null}

                    <DoctorsBlockDisease
                        showCount={this.state.showCount}
                        diseaseId={diseaseId}
                    />

                    {this.state.showCount < this.props.doctors.length && (
                        <div
                            className="grey-button"
                            onClick={() =>
                                this.setState({ showCount: this.state.showCount + 2 })
                            }
                        >
                            {t("Show more")}
                        </div>
                    )}

                    {diagnostics?.length ? (
                        <div id="diagnostics" className="disease-info__block">
                            <h5 className="services-block__title ">{t("Diagnostics")}</h5>
                            {diagnostics.split("\n").map((des) => (
                                <div>{des}</div>
                            ))}
                        </div>
                    ) : null}
                    <br />
                    {treatment?.length ? (
                        <div id="treatment" className="disease-info__block">
                            <h5 className="services-block__title">{t("Treatment")}</h5>

                            {treatment.split("\n").map((des) => (
                                <div>{des}</div>
                            ))}
                        </div>
                    ) : null}
                    <br />
                    {danger?.length ? (
                        <div id="danger" className="disease-info__block">
                            <h5 className="services-block__title">{t("Danger")}</h5>
                            {danger.split("\n").map((des) => (
                                <div>{des}</div>
                            ))}
                        </div>
                    ) : null}
                    <br />
                    {groupOfRisk?.length ? (
                        <div id="groupOfRisk" className="disease-info__block">
                            <h5 className="services-block__title">{t("Group of risk")}</h5>

                            {groupOfRisk.split("\n").map((des) => (
                                <div>{des}</div>
                            ))}
                        </div>
                    ) : null}
                    <br />
                    {prevention?.length ? (
                        <div id="prevention" className="disease-info__block">
                            <h5 className="services-block__title">{t("Prevention")}</h5>

                            {prevention.split("\n").map((des) => (
                                <div>{des}</div>
                            ))}
                        </div>
                    ) : null}

                    <div
                        style={{ padding: "40px  0 10px 0 " }}
                        className="services-block__title"
                    >
                        {t("Directory of diseases")}
                    </div>
                    <LineAlphabet />

                    <div className="doctorSearch-list">
                        <Diseases bigTitle={t("Directory of diseases")} />
                    </div>
                    <div className="all-spec">
                        <div className="title">{t("All specializations")}</div>
                    </div>
                    <div className="content-block">
                        <div
                            className={
                                this.state.showSpecializations ? "alphabet opened" : "alphabet"
                            }
                        >
                            {this.props.specializationSorted &&
                                Object.keys(this.props.specializationSorted).map(
                                    (letter, i) => (
                                        <div className="alphabet-item" key={letter}>
                                            <p className="alphabet-item-title">{letter}</p>
                                            <div className="alphabet-data-container">
                                                {this.props.specializationSorted &&
                                                    this.props.specializationSorted[letter].map(
                                                        (spec) => (
                                                            <Link
                                                                to={`/doctors?specialization=${spec.id}`}
                                                                key={spec.id}
                                                            >
                                                                <p
                                                                    key={spec.name}
                                                                    className="alphabet-data-name"
                                                                >
                                                                    {spec.name}
                                                                </p>
                                                            </Link>
                                                        )
                                                    )}
                                            </div>
                                        </div>
                                    )
                                )}
                            {!this.state.showSpecializations && <div className="gradient" />}
                        </div>
                        <div className="btn-home-alphabet-wrapper">
                            <button
                                onClick={this.showSpecializationsHandler.bind(this)}
                                className="blue-btn btn btn-primary"
                            >
                                {this.state.showSpecializations
                                    ? t("Minimize")
                                    : t("Show more")}
                            </button>
                        </div>
                    </div>
                    <MobileBlock />
                </div>
            </>
        );
    }
}

const mapStateToProps = (state: RootState) => ({
    diseasesMap: state.dictionary?.diseasesMap,
    specializationSorted: state.dictionary.specializationSorted,
    diseaseInfo: state.diseaseInfo.diseaseInfo,
    lang: state.settings.language,
    doctors: state.doctor.doctors,
    doctorPrice: state.doctor.doctorPrice,
    auth: state.auth,
    // dictionary: state.dictionary,
});

const mapDispatchToProps = {
    getDiseaseInformation,
    getDoctorList,
    getDoctorPrice,
};

let DiseaseContainerComponent = connect(
    mapStateToProps,
    mapDispatchToProps
)(Disease);

export default withRouter(withTranslation()(DiseaseContainerComponent));
