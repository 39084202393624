import {LOADING_START, LOADING_END, LoadingActionTypes} from '../actions/loading-actions';
import { ILoadingState, LoadingSubject } from '../states/loading-state';

const initialState: ILoadingState = {
    subjects: new Set<LoadingSubject>()
}

export function loadingReducer(
    state = initialState,
    action: LoadingActionTypes
): ILoadingState {
    switch (action.type) {
        case LOADING_START:
            return {...state, ...{subjects: state.subjects.add(action.subject)}};
        case LOADING_END:
            const subjects = state.subjects;
            subjects.delete(action.subject)
            return {...state, ...{subjects}};
        default:
            return state;
    }
}
