import React, { Component } from 'react';
import Modal from 'react-bootstrap/Modal';
import { connect } from "react-redux";
import { Link } from 'react-router-dom';
import { CallStage } from '../../models/call-stage';
import { ICallState } from '../../states/call-state';
import { RootState } from '../../store';
import history from "../../history";
import { answer, hangUp } from '../../actions/call-actions';
import { IAuthState } from '../../states/auth-state';
import CallIcon from '@material-ui/icons/Call';
import CallEndIcon from '@material-ui/icons/CallEnd';
import VideocamIcon from '@material-ui/icons/Videocam';
import { CallStatus } from '../../models/call-history';
import { fetchCallRecord } from '../../actions/scheduling-item-record-actions';
import { ISchedulingItemRecordsState } from '../../states/scheduling-item-records-state';
import { ISchedulingItemRecord } from '../../models/scheduling-item-record';
import { TFunction } from 'i18next';
import { withTranslation } from 'react-i18next';
import Sound from 'react-sound';

import doctorAva from './../../images/doctor-avatar-fullsize.png'
interface MyProps {
    t: TFunction;
    call: ICallState;
    auth: IAuthState;
    schedulingItemRecords: ISchedulingItemRecordsState;
    hangUp: (status: CallStatus) => void;
    answer: (camera: boolean) => void;
    fetchCallRecord: (item: ISchedulingItemRecord) => void;
}

interface MyState {
}

class CallModal extends Component<MyProps, MyState> {

    constructor(props: MyProps) {
        super(props);
        this.state = {
        };
    }

    componentDidUpdate(prevProps: MyProps) {
        const newProps = this.props;
        if (newProps.call.stage !== (prevProps.call || {}).stage && newProps.call.stage === CallStage.Outcome) {
            history.push('/online-appointment');
        }
        if ((prevProps.call || {}).stage === CallStage.Income && newProps.call.stage === CallStage.Call) {
            history.push('/online-appointment');
        }
        if (newProps.call.stageData?.record?.id && prevProps.call.stageData?.record?.id !== newProps.call.stageData?.record?.id) {
            this.props.fetchCallRecord(newProps.call.stageData.record);
        }
    }

    render() {
        const { t } = this.props;
        const { call, auth, schedulingItemRecords } = this.props;
        console.log('call.stage', call.stage);

        return <div>
            {/* <Sound
                url = {require('../../sounds/ring_sound.aac')}
                loop = {true}
                playStatus={call.stage === CallStage.Income ? Sound.status.PLAYING : Sound.status.STOPPED}
            />
            <Sound
                url = {require('../../sounds/call_sound.aac')}
                loop = {true}
                playStatus={call.stage === CallStage.Outcome ? Sound.status.PLAYING : Sound.status.STOPPED}
            /> */}
            <Modal
                size='sm'
                centered
                show={call.stage === CallStage.Income} onHide={() => this.props.hangUp(CallStatus.Rejected)}
            >
                <Modal.Body className='modal-online-appointment'>
                    <div className='avatar'>
                        <img src={doctorAva} />
                    </div>

                    <Link to='#'>{call.stageData?.record?.doctorId === auth.userToken?.selfId ? schedulingItemRecords.callRecord?.patientName : schedulingItemRecords.callRecord?.doctorName}</Link>
                    <Link to='#'>{t('Income')}</Link>

                    <div className='btn-block'>
                        <button onClick={() => this.props.answer(false)}><CallIcon /></button>
                        <button onClick={() => this.props.answer(true)}><VideocamIcon /></button>
                        <button className="hang-up" onClick={() => this.props.hangUp(CallStatus.Rejected)}><CallEndIcon /></button>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    }
}

const mapStateToProps = (state: RootState) => ({
    call: state.call,
    auth: state.auth,
    schedulingItemRecords: state.schedulingItemRecords,
});

const mapDispatchToProps = {
    hangUp,
    answer,
    fetchCallRecord,
};

let CallModalContainer = connect(mapStateToProps, mapDispatchToProps)(
    withTranslation()(CallModal)
);

export default CallModalContainer;