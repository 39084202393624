import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import queryString from 'query-string';
import history from '../../history';

interface PurchaseProps extends WithTranslation {
    location: any;
}

interface PurchaseState {
    paymentStatus: string;
}

class Purchase extends React.Component<PurchaseProps, PurchaseState> {
    constructor(props: PurchaseProps) {
        super(props);
        this.state = {
            paymentStatus: ''
        }
    }

    componentDidMount() {
        const { location: { search } } = this.props;
        const { Status, ExternalId } = queryString.parse(search);
        console.log('Status: ', Status, 'ExternalId: ', ExternalId);
        if (Status === 'success') {
            this.setState({paymentStatus: Status});
            setTimeout(() => history.push(`/online-appointment?chat=${ExternalId}`), 5000);
        }
    }

    render() {
        const { t } = this.props;
        return(
            <>
                <div className='reviews-block' >
                    <p className='big-title team-big-title'>
                        {
                            this.state.paymentStatus === 'success' ? t('Successful payment! You will be forwarded to chat for 5 seconds!') : t('Something went wrong. Please try again or connect to administrator.')
                        }
                    </p>
                </div>
            </>
        )
    }
}

export default withTranslation()(Purchase);