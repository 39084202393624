import React from "react";
import { connect } from "react-redux";
import {
  Field,
  InjectedFormProps,
  reduxForm,
  FormErrors,
  formValueSelector,
} from "redux-form";
import TextInput from "./FormComponents/TextInput";
import { IProfile } from "../models/profile";
import { RootState } from "../store";
import { TFunction } from "i18next";
import { withTranslation } from "react-i18next";

interface PersonalCabinetPasswordFormProps extends InjectedFormProps<IProfile> {
  t: TFunction;
  display?: string,
  flexDirection?: any;
}

class PersonalCabinetPasswordForm extends React.Component<PersonalCabinetPasswordFormProps> {
  render() {
    const { t } = this.props;

    let textInputClassName =
      "personal-cabinet-patient-styles_text-input edit-mode";

    return (
      <div className="personal-cabinet-doctor-form_main personal-cabinet-patient-form_main">
        <div
          style={{
            display: this.props.display,
            flexDirection: this.props.flexDirection,
          }}
          className="_row"
        >
          <div className="_block0">
            <div style={{ width: "118px" }}></div>
          </div>
          <div className="_block1">
            <Field
              _className={textInputClassName}
              name="oldpassword"
              placeholder={t("Old password")}
              type="password"
              component={TextInput}
            />
          </div>

          <div className="_block2">
            <Field
              _className={textInputClassName}
              name="password"
              placeholder={t("New password")}
              type="password"
              component={TextInput}
            />
            <Field
              _className={textInputClassName}
              name="repeatpassword"
              placeholder={t("Repeat password")}
              type="password"
              component={TextInput}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: RootState) => {
  return {
    initialValues: {},
  };
};

const validate = (
  values: IProfile,
  props: PersonalCabinetPasswordFormProps & { t: TFunction }
): FormErrors<IProfile> => {
  const errors: FormErrors<IProfile> = {};
  const { t } = props;
  if (!values.password) {
    errors.password = t("Field couldn't be empty");
  }
  if (!values.repeatpassword) {
    errors.repeatpassword = t("Field couldn't be empty");
  }
  if (!values.oldpassword) {
    errors.oldpassword = t("Field couldn't be empty");
  }
  if (values.password !== values.repeatpassword) {
    errors.password = t("Passwords do not match");
    errors.repeatpassword = t("Passwords do not match");
  }
  return errors;
};

const form = reduxForm<IProfile, any>({
  form: "PersonalCabinetPasswordForm",
  validate,
})(PersonalCabinetPasswordForm);

export default connect(mapStateToProps)(withTranslation()(form));
