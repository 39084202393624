import { Action, ActionCreator } from "redux";
import { ThunkAction } from "redux-thunk";
import { IFile } from "../models/file";
import { pushAlert } from "./alert-actions";
import * as environment from '../app.json';
import i18n from 'i18next';
import { RootState } from "../store";
import { authFetch } from "./auth-actions";
import { IUpload } from "../models/upload";

function topDoctorData() {
    let topDoctor = environment.topDoctor;
    if (i18n.language == 'az') topDoctor = environment.topDoctorAz;
    if (i18n.language == 'ru') topDoctor = environment.topDoctorRu;
    return topDoctor;
}

export const SAVE_FILES = 'SAVE_FILES';
interface SaveFilesAction {
    type: typeof SAVE_FILES;
    files: IFile[];
    stype?: string;
}

export const _saveFiles: ActionCreator<SaveFilesAction> = (files: IFile[], type?: string) => {
    return {
        type: SAVE_FILES,
        files,
        stype: type
    };
};
export const DELETE_FILE = 'DELETE_FILE';
interface DeleteFileAction {
    type: typeof DELETE_FILE;
    id: number;
    stype?: string;
}

export const _deleteFiles: ActionCreator<DeleteFileAction> = (id: number, type?: string) => {
    return {
        type: DELETE_FILE,
        id,
        stype: type
    };
};
export const SAVE_UPLOADED_FILES = 'SAVE_UPLOADED_FILES';
interface SaveUploadedFilesAction {
    type: typeof SAVE_UPLOADED_FILES;
    uploadedFiles: IFile[];
    stype?: string;
}

export const _saveUploadedFiles: ActionCreator<SaveUploadedFilesAction> = (uploadedFiles: IFile[], type?: string) => {
    return {
        type: SAVE_UPLOADED_FILES,
        uploadedFiles,
        stype: type
    };
};

export const DELETE_UP_FILE = 'DELETE_UP_FILE';
interface DeleteUploadedFileAction {
    type: typeof DELETE_UP_FILE;
    id: number;
    stype?: string;
}

export const _deleteUploadedFiles: ActionCreator<DeleteUploadedFileAction> = (id: number, type?: string) => {
    return {
        type: DELETE_UP_FILE,
        id,
        stype: type
    };
};

export const updateFiles = async (
    getState: () => RootState,
    dispatch: (a: any) => void,
    uploadFiles: IUpload[], deleleFiles: IFile[],
    host_id: number,
    host_type: number,
) => {
    if (uploadFiles?.length) {
        for (const i of uploadFiles) {
            await saveFiles(getState, dispatch, host_type, host_id, i);
        }
    }
    if (deleleFiles?.length) {
        for (const i of deleleFiles) {
            await _deleteFile(getState, dispatch, i.ID);
        }
    }
}

export const saveFiles = async (
    getState: () => RootState,
    dispatch: (a: any) => void,
    host_type: number,
    host_id: number,
    file: IUpload
) => {
    try {
        const response = await authFetch(
            getState, dispatch, topDoctorData() + 'patientfiles', 'POST',
            {
                host_type: host_type,
                host_id: host_id,
                title: file.name,
                filename: file.name,
                uploadfile: file.base64,
                extension: file.name.split('.').pop()
            }, {Accept: 'application/json', 'Content-Type': 'application/json'}, '?token='
        );

        console.log('response3', response);

        const json = await response.json();
        console.log('json3', json);
        if (response.status === 200 && json.status !== false) {
        }
        else {
            //dispatch(errorPatientClinic(json.code, token));
        }
    } catch (e) {
        dispatch(pushAlert('Unknown error. Please, check internet connection'));
    }
}

const _deleteFile = async (
    getState: () => RootState,
    dispatch: (a: any) => void,
    id: number,
) => {
    try {
        const response = await authFetch(
            getState, dispatch, topDoctorData() + 'patientfiles/' + id, 'DELETE',
            null, {Accept: 'application/json', 'Content-Type': 'application/json'}, '?token='
        );

        console.log('response', response);

        if (response.status === 200) {
            
        }
        else {
            dispatch(pushAlert('Unknown error. Please, check internet connection'));
            //dispatch(errorPatientDoctorList(json.code, token));
        }
    } catch (e) {
        dispatch(pushAlert('Unknown error. Please, check internet connection'));
    }
}

export const deleteFile = (
    token: string,
    id: number,
    type?: string
): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch, getState) => {

    try {
        const response = await fetch(topDoctorData() + 'patientfiles/' + id + '?token=' + encodeURIComponent(token), {
            method: 'DELETE',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        });

        console.log('response', response);

        if (response.status === 200) {
            const json = await response.json();
            console.log('json', json);
            dispatch(_deleteUploadedFiles(id, type));
        }
        else {
            dispatch(pushAlert('Unknown error. Please, check internet connection'));
            //dispatch(errorPatientDoctorList(json.code, token));
        }
    } catch (e) {
        dispatch(pushAlert('Unknown error. Please, check internet connection'));
    }
}

export type FilesActionTypes = SaveFilesAction | DeleteFileAction | SaveUploadedFilesAction | DeleteUploadedFileAction