import { ActionCreator, Action } from "redux";
import { ThunkAction } from "redux-thunk";
import { loadingStart, loadingEnd } from "./loading-actions";
import { LoadingSubject } from "../states/loading-state";
import i18n from 'i18next';
import * as environment from '../app.json';
import { pushAlert } from "./alert-actions";
import { authFetch } from "./auth-actions";
import { IPatientMedicine } from "../models/user-medicine";
import { RootState } from "../store";

function topDoctorData() {
    let topDoctor = environment.topDoctor;
    if (i18n.language === 'az') topDoctor = environment.topDoctorAz;
    if (i18n.language === 'ru') topDoctor = environment.topDoctorRu;
    return topDoctor;
}

export const PATIENT_MEDICINE_LIST = 'PATIENT_MEDICINE_LIST';
interface PatientMedicineListAction {
    type: typeof PATIENT_MEDICINE_LIST;
    medicines: IPatientMedicine[];
}

export const _patientMedicineList: ActionCreator<PatientMedicineListAction> = (medicines: IPatientMedicine[]) => {
    return {
        type: PATIENT_MEDICINE_LIST,
        medicines
    };
};

export const PATIENT_MEDICINE_ITEM = 'PATIENT_MEDICINE_ITEM';
interface PatientMedicineItemAction {
    type: typeof PATIENT_MEDICINE_ITEM;
    medicine: IPatientMedicine | null;
}

export const _patientMedicineItem: ActionCreator<PatientMedicineItemAction> = (medicine: IPatientMedicine | null) => {
    return {
        type: PATIENT_MEDICINE_ITEM,
        medicine
    };
};

export type PatientMedicineActionTypes = PatientMedicineListAction | PatientMedicineItemAction;

export const getPatientMedicineList = (
): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch, getState) => {
    dispatch(loadingStart(LoadingSubject.PatientMedicineList));

    try {
        const response = await authFetch(
            getState, dispatch, topDoctorData() + '/patientpills?per-page=1000', 'GET',
            null, {Accept: 'application/json', 'Content-Type': 'application/json'}, '&token='
        );

        console.log('response', response);

        if (response.status === 200) {
            const json = await response.json();
            console.log('json', json);
            dispatch(_patientMedicineList(json));
        }
        else if (response.status === 401) {
            //dispatch(signOut(token));
        }
        else {
            dispatch(pushAlert('Unknown error. Please, check internet connection'));
            //dispatch(errorPatientDoctorList(json.code, token));
        }
    } catch (e) {
        dispatch(pushAlert('Unknown error. Please, check internet connection'));
    } finally {
        dispatch(loadingEnd(LoadingSubject.PatientMedicineList));
    }
}

export const getPatientMedicine = (
    id: number,
): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch, getState) => {
    dispatch(loadingStart(LoadingSubject.PatientMedicine));

    try {
        const response = await authFetch(
            getState, dispatch, topDoctorData() + '/patientpills/' + id , 'GET',
            null, {Accept: 'application/json', 'Content-Type': 'application/json'}, '?token='
        );
        console.log('response', response);

        if (response.status === 200) {
            const json = await response.json();
            console.log('json', json);
            dispatch(_patientMedicineItem(json));
        }
        else if (response.status === 401) {
            //dispatch(signOut(token));
        }
        else {
            dispatch(pushAlert('Unknown error. Please, check internet connection'));
            //dispatch(errorPatientDoctorList(json.code, token));
        }
    } catch (e) {
        dispatch(pushAlert('Unknown error. Please, check internet connection'));
    } finally {
        dispatch(loadingEnd(LoadingSubject.PatientMedicine));
    }
}

export const createPatientMedicine = (
    medicine: IPatientMedicine,
    success: () => void,
): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch, getState) => {
    dispatch(loadingStart(LoadingSubject.PatientMedicine));

    try {
        const response = await authFetch(
            getState, dispatch, topDoctorData() + '/patientpills', 'POST',
            medicine, {Accept: 'application/json', 'Content-Type': 'application/json'}, '?token='
        );

        if (response.status === 200) {
            const json = await response.json();
            console.log('json', json);
            dispatch(getPatientMedicineList());
            success && success();
        }
        else if (response.status === 401) {
            //dispatch(signOut(token));
        }
        else {
            dispatch(pushAlert('Unknown error. Please, check internet connection'));
            //dispatch(errorPatientDoctorList(json.code, token));
        }
    } catch (e) {
        dispatch(pushAlert('Unknown error. Please, check internet connection'));
    } finally {
        dispatch(loadingEnd(LoadingSubject.PatientMedicine));
    }
}


export const deletePatientMedicine = (
    id: number,
): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch, getState) => {
    dispatch(loadingStart(LoadingSubject.PatientMedicine));

    try {
        const response = await authFetch(
            getState, dispatch, topDoctorData() + '/patientpills/' + id, 'DELETE',
            null, {Accept: 'application/json', 'Content-Type': 'application/json'}, '?token='
        );

        console.log('response', response);

        if (response.status === 200) {
            const json = await response.json();
            console.log('json', json);
            dispatch(getPatientMedicineList());
        }
        else if (response.status === 401) {
            //dispatch(signOut(token));
        }
        else {
            dispatch(pushAlert('Unknown error. Please, check internet connection'));
            //dispatch(errorPatientDoctorList(json.code, token));
        }
    } catch (e) {
        dispatch(pushAlert('Unknown error. Please, check internet connection'));
    } finally {
        dispatch(loadingEnd(LoadingSubject.PatientMedicine));
    }
}


export const updatePatientMedicine = (
    medicine: IPatientMedicine,
    success: () => void,
): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch, getState) => {
    dispatch(loadingStart(LoadingSubject.PatientMedicine));

    try {
        const response = await authFetch(
            getState, dispatch, topDoctorData() + '/patientpills/' + medicine.id, 'PATCH',
            medicine, {Accept: 'application/json', 'Content-Type': 'application/json'}, '?token='
        );

        if (response.status === 200) {
            const json = await response.json();
            console.log('json', json);
            dispatch(getPatientMedicineList());
            success && success();
        }
        else if (response.status === 401) {
            //dispatch(signOut(token));
        }
        else {
            dispatch(pushAlert('Unknown error. Please, check internet connection'));
            //dispatch(errorPatientDoctorList(json.code, token));
        }
    } catch (e) {
        dispatch(pushAlert('Unknown error. Please, check internet connection'));
    } finally {
        dispatch(loadingEnd(LoadingSubject.PatientMedicine));
    }
}