import React, { MouseEvent } from 'react';
import './layouts.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CSS from 'csstype';

interface VDButtonProps {
    type?: "button" | "submit" | "reset" | undefined;
    color?: "success" | "primary" | "warning" | "danger" | undefined;
    onClick: () => void;
    label?: string;
    faIcon?: any;
    rounded?: boolean;
    style?: CSS.Properties;
}

export class VDButton extends React.Component<VDButtonProps> {
    
    // onClickHandler = (e: MouseEvent) => {
    //     e.preventDefault();
    //     console.log('e', this.props.onClick);
    //     return this.props.onClick(e);
    // }

    renderButton() {
        const {type, color, label} = this.props;
        return (
            <button type={type} className={`vd-button vd-button_${color}`} style={this.props.style} onClick={this.props.onClick} >
                {label}
            </button>
        )
    }

    renderRounded() {
        const {color, faIcon} = this.props;
    
        return (
            <span className={`vd-rounded-button vd-rounded-button_${color}`} onClick={this.props.onClick}> {faIcon} </span>
        )
    }

    render() {
        if (this.props.rounded) {
            return this.renderRounded()
        } else return this.renderButton()
    }
    
}