import React, { Component } from "react";
import "./block.scss";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { RootState } from "../../../store";
import { IAuthState } from "../../../states/auth-state";
import history from "../../../history";
type FlexDirection =
  | "column"
  | "inherit"
  | "-moz-initial"
  | "initial"
  | "revert"
  | "unset"
  | "column-reverse"
  | "row"
  | "row-reverse"
  | undefined;

interface MyProps {
  title: string;
  slogan: string;
  imgUrl: string;
  background: string;
  button: string;
  fontSize: any;
  display?: string;
  flexDirection?: FlexDirection;
  altImg?: any;
  alignItems?: string;
  link?: any;
  //We want check it is Patient or Doctor, auth returned
  //2 = Doctor
  //3 = Patient
  auth?: IAuthState;
}

interface MyState {}
class BackBlock extends Component<MyProps, MyState> {
  constructor(props: MyProps) {
    super(props);
    this.state = {};
  }

  linkCheck() {
    if (
      this.props.link === "http://drugs-staging.zirinc.com/" ||
      "https://shefakartim.az/"
    ) {
      return (
        <a className="greyButton" href={this.props.link}>
          {this.props.button}
        </a>
      );
    }
    if (this.props.link === "/personal-cabinet-doctor") {
      return history.push("/personal-cabinet-doctor");
    }
    // if (this.props.link === "/personal-cabinet-patient") {
    //   history.push("/personal-cabinet-patient");
    // }
    // if (this.props.link === "/personal-cabinet-clinic") {
    //   history.push("/personal-cabinet-clinic");
    // }
    else {
      return (
        <Link className="greyButton" to={this.props.link}>
          {this.props.button}
        </Link>
      );
    }
  }

  render() {
    return (
      <div
        style={{
          backgroundImage: this.props.background,
          display: this.props.display,
          alignItems: this.props.alignItems,
        }}
        className="services-block"
      >
        <div className="block">
          <div
            style={{ fontSize: this.props.fontSize }}
            className="services-block__title"
          >
            {this.props.title}
          </div>
          <div className="services-block__slogan">{this.props.slogan}</div>
          {this.props.button && this.linkCheck()}
        </div>
        <div
          style={{
            flexDirection: this.props.flexDirection,
            display: this.props.display,
          }}
        >
          <img src={this.props.imgUrl} alt="" />
          {this.props.altImg && (
            <div className="services-block__slogan">{this.props.altImg}</div>
          )}
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state: RootState) => ({
  // auth: state.auth.userToken?.role,
  auth: state.auth,
});
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(BackBlock);
