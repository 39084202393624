import React, { Component } from 'react';
import { connect } from "react-redux";
import { RootState } from "../../../store";
import './MyAllergies.scss';
import { IAuthState } from "../../../states/auth-state";
import { submit, reset, FormAction, change } from 'redux-form';
import { IDictionaryState } from "../../../states/dictionary-state";
import { getPatientAllergyList, deletePatientAllergy } from "../../../actions/patient-allergy-actions";
import Modal from 'react-bootstrap/Modal'
import UserAllergiesForm from '../../../forms/user-allergies-form';
import { IPatientAllergy } from "../../../models/user-allergy";
import { _patientAllergyItem, getPatientAllergy, createPatientAllergy, updatePatientAllergy } from "../../../actions/patient-allergy-actions";
import { IFile } from "../../../models/file";
import { IPatientAllergyState } from "../../../states/patient-allergy-state";
import { IUpload } from '../../../models/upload';
import { i18n, TFunction } from 'i18next';
import { withTranslation } from 'react-i18next';
import { showConfirm } from '../../Dialogs';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { LoadingCenter } from "../../Loading/Loading";
import { ILoadingState, LoadingSubject } from '../../../states/loading-state';
import SaveButton from './../SaveButton/SaveButton';
import myAllergies from "./../../../images/medical-card/md_items_icons/my_allergies.png"

const backgroundImageStyle = {
    backgroundImage: `url(${myAllergies})`
};

interface MyProps {
    t: TFunction;
    i18n: i18n;
    submit: (form: string) => FormAction;
    reset: (form: string) => FormAction;
    auth: IAuthState;
    dictionary: IDictionaryState;
    patientAllergy: IPatientAllergyState;
    getPatientAllergyList: () => void;
    deletePatientAllergy: (id: number) => void;
    createPatientAllergy: (Allergy: IPatientAllergy, uploadFiles: IUpload[], deleleFiles: IFile[], host_type: number, success: () => void) => void;
    updatePatientAllergy: (Allergy: IPatientAllergy, uploadFiles: IUpload[], deleleFiles: IFile[], host_type: number, success: () => void) => void;
    getPatientAllergy: (id: number) => void;
    _patientAllergyItem: (Allergy: IPatientAllergy | null) => void;
    loading: ILoadingState;
    fromDocItems: any;
    forDocItem: boolean;
    patientInfoBlock: JSX.Element;
}

interface MyState {
    show: boolean;
    updateItem: boolean;
    selectedFiles?: IUpload[];
    deletedFiles?: IFile[];
    removeMode: {} | null;
    fromDocItems: any;
    forDocItem: boolean;
}

class MyAllergiesComponent extends Component<MyProps, MyState> {

    constructor(props: MyProps) {
        super(props);

        this.state = {
            show: false,
            updateItem: false,
            removeMode: null,
            fromDocItems: this.props?.fromDocItems,
            forDocItem: this.props?.fromDocItems,
        };
    }

    onSubmit(data: IPatientAllergy) {
        if (this.state.updateItem) {
            this.props.updatePatientAllergy(data, this.state.selectedFiles || [], this.state.deletedFiles || [], 4, this.handleClose);
        } else {
            this.props.createPatientAllergy(data, this.state.selectedFiles || [], this.state.deletedFiles || [], 4, this.handleClose);
        }
    }

    async confirmDelete() {
        const { t } = this.props;

        if (await showConfirm(t("Are you sure you want to delete this item/items?"))) {
            for (const index in this.state.removeMode) {
                if (this.state.removeMode[index]) {
                    let item: any;
                    if (this.props.patientAllergy && this.props.patientAllergy.list) {
                        item = this.props.patientAllergy.list[index];
                    }
                    if (item) {
                        await this.props.deletePatientAllergy(item.id);
                    }
                }
            }
            this.setState({ removeMode: null });
        }
    }

    componentDidMount() {
        this.props.getPatientAllergyList();
    }

    handleClose = () => {
        this.setState({ show: false })
        this.props._patientAllergyItem(null);
        this.setState({ selectedFiles: [], deletedFiles: [] });
    };

    handleShow = () => { this.setState({ show: true }) };

    render() {
        const { fromDocItems, forDocItem } = this.state;
        const { t } = this.props;

        let itemsList = this.props.patientAllergy.list;
        if (fromDocItems) {
            itemsList = fromDocItems;
        }

        const patientAllergyLoading = this.props.loading.subjects.has(LoadingSubject.PatientAllergy);

        return (
            <div className='allergies_wrapper'>
                <Modal centered show={this.state.show} onHide={this.handleClose}>
                    <Modal.Header className='my-meds-modal-header' closeButton>
                        <Modal.Title>{this.state.updateItem ? t('Change allergy') : t('New allergy')}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className='step1'>
                        {
                            fromDocItems && this.state.forDocItem ?
                                Object.entries(this.state.forDocItem).map((itm: any) => {
                                    if (itm[0] !== 'Files') {
                                        return (
                                            <div style={itm[1] ? {} : { display: 'none' }}>
                                                <p
                                                    style={{
                                                        fontWeight: 'bold',
                                                        fontSize: '0.9rem',
                                                        marginBottom: '5px',
                                                        color: '#121C1E',
                                                    }}
                                                >{t(itm[0])}</p>
                                                <p
                                                    style={{
                                                        borderBottom: '1px solid #ccc',
                                                        fontSize: '0.8rem',
                                                        paddingBottom: '2px'
                                                    }}
                                                >{itm[1]}</p>
                                            </div>
                                        )
                                    }
                                })
                                :
                                <React.Fragment>
                                    <UserAllergiesForm onSubmit={(data: any) => this.onSubmit(data)} onFilesChange={(selectedFiles, deletedFiles) => this.setState({ selectedFiles, deletedFiles })} />

                                    <SaveButton text={t('Save')}
                                        loading={patientAllergyLoading}
                                        onClickFunc={() => this.props.submit('UserAllergiesForm')}
                                    />
                                </React.Fragment>
                        }
                    </Modal.Body>
                </Modal>

                <div className='medical_card_item_header'>
                    <div className='md_item_title_image'>
                        <div style={{ backgroundImage: backgroundImageStyle }}></div>
                        <h1>{t('My allergies')}</h1>
                    </div>
                    {this.props.patientInfoBlock}
                    <div className='button_blck' style={fromDocItems ? { display: 'none' } : {}}>
                        <div className='show-more-btn'
                            onClick={() => {
                                this.setState({ updateItem: false })
                                this.props.reset('UserAllergiesForm')
                                this.handleShow();
                            }}
                        >
                            <p className='show-more-btn-title'>{t('Add')}</p>
                        </div>
                    </div>
                </div>

                <div className='medical_card_item_desc' style={
                    this.props.auth?.userToken?.role === 3 ? {} : { display: 'none' }}>
                    <p>{t('In the "My Allergies" section, you can add or correct information about drug allergies and note the allergic reaction caused by taking a particular drug.')}</p>
                </div>

                <div className='medical_card_item_data_block'>
                    <p className='medical_card_item_title'>{this.props.t('All data')}</p>

                    <div className='button_blck' style={fromDocItems ? { display: 'none' } : {}}>
                        {!this.state.removeMode && !!itemsList?.length && <button onClick={() => this.setState({ removeMode: {} })} className='blue-btn btn btn-primary'>{t('Delete')}</button>}
                        {this.state.removeMode && <button onClick={() => this.setState({ removeMode: null })} className='blue-btn btn btn-primary cancel-btn'>{t('Cancel')}</button>}
                        {this.state.removeMode && <button onClick={() => this.confirmDelete()} className='blue-btn btn dark btn-primary'>{t('Confirm')}</button>}
                    </div>
                    <div className='table_data'>
                        <table className={this.props.auth?.userToken?.role === 3 ? 'pointer' : ''}>
                            <tr className='centerItem'>
                                <th>{this.props.t('Allergen')}</th>
                                <th>{this.props.t('Reaction')}</th>
                                <th>{this.props.t('File')}</th>
                            </tr>
                            {itemsList instanceof Array && itemsList.map((item: any, index: any) => {
                                return (
                                    <tr onClick={async () => {
                                        if (this.state.removeMode || forDocItem) {
                                            return;
                                        }
                                        await this.props.getPatientAllergy(item.id);
                                        this.setState({ updateItem: true })
                                        this.handleShow();
                                    }}>
                                        {!forDocItem ?
                                            <React.Fragment>
                                                <td>
                                                    {this.state.removeMode && <div >
                                                        {!this.state.removeMode[index] && <RadioButtonUncheckedIcon onClick={() => this.setState({ removeMode: { ...this.state.removeMode, [`${index}`]: true } })} fontSize='small' htmlColor='#007bff' />}
                                                        {this.state.removeMode[index] && <CheckCircleOutlineIcon onClick={() => this.setState({ removeMode: { ...this.state.removeMode, [`${index}`]: false } })} fontSize='small' htmlColor='#007bff' />}
                                                    </div>}

                                                    {item.name}
                                                </td>
                                                <td>{item.reaction}</td>
                                                <td>{item.uploadedfiles ? item.uploadedfiles.length : 0}</td>
                                            </React.Fragment>
                                            :
                                            <React.Fragment>
                                                <td>
                                                    {this.state.removeMode && <div style={{ alignItems: 'center' }}>
                                                        {!this.state.removeMode[index] && <RadioButtonUncheckedIcon onClick={() => this.setState({ removeMode: { ...this.state.removeMode, [`${index}`]: true } })} fontSize='small' htmlColor='#007bff' />}
                                                        {this.state.removeMode[index] && <CheckCircleOutlineIcon onClick={() => this.setState({ removeMode: { ...this.state.removeMode, [`${index}`]: false } })} fontSize='small' htmlColor='#007bff' />}
                                                    </div>}

                                                    {item.Name}
                                                </td>
                                                <td>{item.Reaction}</td>
                                                {/* <td>{item.Files ? item.Files.length : 0}</td> */}
                                            </React.Fragment>
                                        }
                                    </tr>
                                )
                            })}
                        </table>
                        {this.props.loading.subjects.has(LoadingSubject.PatientAllergyList) ? <div style={{ marginTop: '20px' }}><LoadingCenter /></div> :
                            !itemsList || itemsList.length === 0 && <div className='no-items-no-results'> <p>{t('No data available')}</p></div>}
                    </div>
                </div>

            </div>
        );
    }
}

const mapStateToProps = (state: RootState) => ({
    auth: state.auth,
    dictionary: state.dictionary,
    patientAllergy: state.patientAllergy,
    loading: state.loading,
});

const mapDispatchToProps = {
    submit,
    reset,
    getPatientAllergyList,
    deletePatientAllergy,
    change,

    createPatientAllergy,
    updatePatientAllergy,
    getPatientAllergy,
    _patientAllergyItem,
};

let MyAllergiesComponentContainer = connect(mapStateToProps, mapDispatchToProps)(
    withTranslation()(MyAllergiesComponent)
);

export default MyAllergiesComponentContainer