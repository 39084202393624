import React from "react";
import { WrappedFieldProps } from "redux-form";
import "./TextInput.scss";

import NumberFormat from "react-number-format";

export interface CustomFieldProps {
  name?: string;
  readOnly?: boolean;
  placeholder?: string;
  label?: string;
  type?: string;
  disableInput?: boolean;
  editable?: boolean;
  multiline?: boolean;
  _className?: any;
}

class TextInput extends React.Component<WrappedFieldProps & CustomFieldProps> {
  render() {
    const {
      placeholder,
      label,
      type,
      readOnly,
      disableInput,
      editable,
      multiline,
      _className,
      input: { onChange, onBlur },
      meta: { touched, valid, error },
      name,
    } = this.props;

    let value = this.props.input.value;

    if (type === "datetime-local" && value.length == 19) {
      value = value.replace(" ", "T");
      value = value.substring(0, value.length - 3);
    }

    const errorClassName = !valid && touched ? "error" : "";

    console.log("val after", value);

    return (
      <div
        className={
          "text-input-component reg-input-styles" + " " + (_className || "")
        }
      >
        <p>{label ? label : placeholder}</p>
        {type === "label" && (
          <label className={errorClassName}>{value || placeholder}</label>
        )}
        {(type === "decimal" || type === "integer") && (
          <NumberFormat
            className={errorClassName}
            name={name}
            onChange={onChange}
            value={value}
            readOnly={readOnly}
            placeholder={placeholder}
          />
        )}
        {type !== "decimal" && type !== "integer" && type !== "label" && (
          <input
            className={errorClassName}
            name={name}
            type={type}
            onChange={onChange}
            value={value}
            readOnly={readOnly}
            placeholder={placeholder}
          />
        )}
        {!valid && touched ? (
          <p className="text-input-component__error">{error}</p>
        ) : null}
      </div>
    );
  }
}

export default TextInput;
