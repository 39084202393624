import React from 'react';
import { RootState } from "./../../store";
import { connect } from "react-redux";
import { i18n, TFunction } from 'i18next';
import { WrappedFieldProps } from "redux-form";
import { _saveFiles, _saveUploadedFiles, _deleteFiles, deleteFile } from '../../actions/file-action';
import { IAuthState } from "../../states/auth-state";
import './ImageInput.scss';
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import { withTranslation, WithTranslation } from 'react-i18next';
import * as environment from '../../app.json';

import noUserPhoto from './../../images/no-user-photo.png'
export interface CustomFieldProps {
    label?: string;
    name: string;
    type: string;
    auth: IAuthState;
    separate?: boolean;
    placeholder?: string;
    text: string;
    doctorRegistration: boolean;
    onClose?: (id: number | undefined) => void;
    id?: string;
    htmlFor?: string;
    value?: any;

    readOnly: boolean;
}

export interface State {
}

class ImageInput extends React.Component<WrappedFieldProps & CustomFieldProps & WithTranslation, State> {


    constructor(props: WrappedFieldProps & CustomFieldProps & WithTranslation) {
        super(props);
        this.state = {
        };
        this.uploadImage = this.uploadImage.bind(this)
    }

    uploadImage = async (e) => {
        console.log(e.target.files[0]);
        const fileToSave = e.target.files[0];
        const base64 = await this.convertBase64(fileToSave);
        const extension = fileToSave.name.split('.')[fileToSave.name.split('.').length - 1];
        const file = {
            uploadFile: base64,
            extension: extension!,
            Title: 'title',
        }

        const filesToSave = [file];
        return this.props.input.onChange(filesToSave);
    }

    convertBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file)
            fileReader.onload = () => {
                resolve(fileReader.result);
            }
            fileReader.onerror = (error) => {
                reject(error);
            }
        })
    }

    render() {
        const { t } = this.props;
        const { input: { onChange, value }, readOnly, htmlFor, name, type, label, placeholder, onClose, meta: { error, touched, valid }, doctorRegistration } = this.props;

        console.log('image component value', value);

        const pic = value && value.map ? value[0].uploadFile : value ? environment.uploads + type + '/' + value :
            noUserPhoto;

        return (

            <div>
                <div className='image_upload'>
                    <label className='image_label' htmlFor="image_">
                        <img className='image_' src={pic} width="120px" height="120px" />
                        {!readOnly && <div className='image_hover'>
                            <PhotoCameraIcon fontSize='large' htmlColor='white' />
                        </div>}
                    </label>
                    {!readOnly && <input
                        className='input'
                        name={name}
                        type={'file'}
                        onChange={(e) => this.uploadImage(e)}
                        id="image_"
                        hidden
                    />}

                    {!readOnly && value && !doctorRegistration &&
                        <span className="delete-label"
                            onClick={() => {
                                onChange('');
                            }}
                        >{t('Delete')}</span>
                    }
                </div>
                {!valid && touched ? <p className='text-input-component__error'>{error}</p> : null}
            </div >

        )
    }
}

const mapStateToProps = (state: RootState) => ({
    auth: state.auth,
    file: state.file,
});

const mapDispatchToProps = {
}

let ImageInputContainer = connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ImageInput));

export default ImageInputContainer;