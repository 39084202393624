import React from "react";
import { TFunction } from "i18next";
import { WithTranslation, withTranslation } from "react-i18next";
import { RootState } from "../../store";
import { IDictionary } from "../../models/dictionary";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { ILoadingState } from "../../states/loading-state";
import Spinner from "react-spinner-material";
import { setStateLetter } from "../../actions/alph-actions";
import downArrowDis from "./../../images/down-arrow-dis.png";
interface DiseasesProps {
  t: TFunction;
  loading: ILoadingState;
  bigTitle?: string;
  alphabetDiseases: { [key: number]: IDictionary } | null | undefined;
  letter: string | undefined;
  setStateLetter: (letter: string | undefined) => void;
  diseasesList?: boolean;
}

interface DiseasesState {
  sortedDiseases?: IDictionary[];
  showDiseases: boolean;
  loading: boolean;
  showAll: string[];
}

class Diseases extends React.Component<
  DiseasesProps & WithTranslation,
  DiseasesState
> {
  constructor(props: DiseasesProps & WithTranslation) {
    super(props);
    this.state = {
      sortedDiseases: [],
      showAll: [],
      showDiseases: true,
      loading: true,
    };
  }
  componentDidMount() {
    this.props.setStateLetter("");
  }
  render() {
    const { t, diseasesList } = this.props;

    return (
      <div className="home">
        {this.props.bigTitle && (
          <div className="content-block">
            <h1 className="big-title">{this.props.bigTitle}</h1>
          </div>
        )}

        <div
          className={this.props.letter ? "opened-for-dis" : "alphabet opened"}
        >
          {!this.props.alphabetDiseases ? (
            <Spinner
              radius={24}
              color={"#0279BD"}
              stroke={2}
              visible={true}
            ></Spinner>
          ) : this.props.letter ? (
            <>
              {this.props.alphabetDiseases &&
                this.props.alphabetDiseases?.[
                  this.props.letter?.toUpperCase()
                ].map((disease) => {
                  return disease.name.length < 20 ? (
                    <Link
                      style={{ maxWidth: "120px" }}
                      to={`/disease/${disease.id}`}
                      key={disease.id}
                    >
                      <p className="alphabet-data-name">{disease.name}</p>
                    </Link>
                  ) : (
                    <Link
                      style={{ maxWidth: "120px" }}
                      to={`/disease/${disease.id}`}
                      key={disease.id}
                    >
                      <p className="alphabet-data-name">
                        {disease.name.slice(0, 20) + "..."}
                      </p>
                    </Link>
                  );
                })}
            </>
          ) : (
            Object.keys(this.props.alphabetDiseases).map((letter, i) => {
              return (
                <div
                  style={{ margin: "10px 0" }}
                  className="alphabet-item"
                  key={letter}
                >
                  <p
                    className={
                      diseasesList
                        ? "alphabet-item-title big-letter"
                        : "alphabet-item-title"
                    }
                  >
                    {letter}
                  </p>
                  <div
                    style={{ minHeight: "160px" }}
                    className="alphabet-data-container"
                  >
                    {this.props.alphabetDiseases &&
                      this.props.alphabetDiseases[letter].length < 4 &&
                      this.props.alphabetDiseases[letter]
                        .slice(0, this.props.alphabetDiseases[letter].length)
                        .map((disease) => {
                          return disease.name.length < 20 ? (
                            <Link
                              style={{ maxWidth: "120px" }}
                              to={`/disease/${disease.id}`}
                              key={disease.id}
                            >
                              <p className="alphabet-data-name">
                                {disease.name}
                              </p>
                            </Link>
                          ) : (
                            <Link
                              style={{ maxWidth: "120px" }}
                              to={`/disease/${disease.id}`}
                              key={disease.id}
                            >
                              <p className="alphabet-data-name">
                                {disease.name}
                              </p>
                            </Link>
                          );
                        })}
                    {this.props.alphabetDiseases?.[letter].length > 4 &&
                      !this.state.showAll.includes(letter) &&
                      this.props.alphabetDiseases?.[letter]
                        .slice(0, 4)
                        .map((disease) => {
                          return disease.name.length < 20 ? (
                            <Link
                              style={{ maxWidth: "120px" }}
                              to={`/disease/${disease.id}`}
                              key={disease.id}
                            >
                              <p className="alphabet-data-name">
                                {disease.name}
                              </p>
                            </Link>
                          ) : (
                            <Link
                              style={{ maxWidth: "120px" }}
                              to={`/disease/${disease.id}`}
                              key={disease.id}
                            >
                              <p className="alphabet-data-name">
                                {disease.name.slice(0, 20) + "..."}
                              </p>
                            </Link>
                          );
                        })}

                    {this.props.alphabetDiseases?.[letter].length > 4 &&
                      this.state.showAll.includes(letter) &&
                      this.props.alphabetDiseases?.[letter].map((disease) => {
                        return (
                          <>
                            <Link
                              to={`/disease/${disease.id}`}
                              key={disease.id}
                            >
                              <p className="alphabet-data-name">
                                {disease.name}
                              </p>
                            </Link>
                          </>
                        );
                      })}
                  </div>
                  {this.props.alphabetDiseases?.[letter].length > 4 &&
                    !this.state.showAll.includes(letter) && (
                      <div
                        className="show-more-but"
                        onClick={() =>
                          this.setState((prevS) => {
                            return {
                              ...prevS,
                              showAll: [...prevS.showAll, letter],
                            };
                          })
                        }
                      >
                        {t("Show more")}
                        <img src={downArrowDis} alt="" height={12} />
                      </div>
                    )}
                </div>
              );
            })
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: RootState) => {
  return {
    loading: state.loading,
    alphabetDiseases: state.dictionary?.alphabetDiseases,
    letter: state.alph.letter,
  };
};

const mapDispatchToProps = { setStateLetter };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(Diseases));
