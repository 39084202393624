import React, { Component } from "react";
import { connect } from "react-redux";
import { submit, FormAction, initialize } from "redux-form";
import { RootState } from "../../store";
import "./PersonalCabinetClinic.scss";
import {
  signIn,
  signUp,
  doChange,
  changePassword,
} from "../../actions/auth-actions";
import { IAuthState } from "../../states/auth-state";
import { Link } from "react-router-dom";
import { signOut } from "../../actions/auth-actions";
import QuickAccessComponentContainer from "../QuickAccess/QuickAccess";
import { IProfile } from "../../models/profile";
import { IClinic } from "../../models/clinic";
import { withTranslation, WithTranslation } from "react-i18next";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import PersonalCabinetPasswordForm from "../../forms/PersonalCabinetPasswordForm";
import DescriptionIcon from "@material-ui/icons/Description";
import { IDoctor } from "../../models/doctor";
import PersonalCabinetClinicForm from "../../forms/PersonalCabinetClinicForm";
import Spinner from "react-spinner-material";
import { ILoadingState, LoadingSubject } from "../../states/loading-state";
import mySchedule from "./../../images/doctor-cabinet/my-schedule.png";
interface MyProps {
  submit: (form: string) => FormAction;
  loading: ILoadingState;
  auth: IAuthState;
  signOut: (token: string) => void;
  doChange: (user: IProfile, token: string) => void;
  changePassword: (token: string, user: IProfile | IClinic | IDoctor) => void;
  initialize: (form: string, data: any, keepDirty?: boolean) => void;
  onSubmit?: (data: IProfile) => void;
}

interface MyState {
  editMode: boolean;
  changePasswordMode: boolean;
  fields?: { [name: string]: string };
  changePasswordLoading: boolean;
  selectedIndex: number;
}

class PersonalCabinetClinicComponent extends Component<
  MyProps & WithTranslation,
  MyState
> {
  constructor(props: MyProps & WithTranslation) {
    super(props);
    this.state = {
      editMode: false,
      changePasswordMode: false,
      selectedIndex: 0,
      changePasswordLoading: false,
    };
  }

  onSubmit(data: IDoctor) {
    console.log("user", data);
    if (!this.state.changePasswordMode) {
      this.props.doChange(data, this.props.auth.userToken?.auth as string);
    } else {
      this.setState({ changePasswordLoading: true });
      this.props.changePassword(
        this.props.auth.userToken?.auth as string,
        data
      );
      this.setState({ changePasswordMode: !this.state.changePasswordMode });
    }
    this.setState({ editMode: false, changePasswordMode: false });
  }

  onEdit() {
    if (this.state.editMode) this.props.submit("PersonalCabinetClinicForm");
    this.setState({ editMode: !this.state.editMode });
  }

  onEditPass() {
    if (this.state.changePasswordMode) {
      this.props.submit("PersonalCabinetPasswordForm");
    } else {
      this.props.initialize("PersonalCabinetPasswordForm", {});
      this.setState({ changePasswordMode: !this.state.changePasswordMode });
    }
  }

  render() {
    const { t } = this.props;
    const { changePasswordLoading } = this.state;
    const renderMsg = () => {
      const msgText = this.props.auth.authErrorText;
      if (msgText) {
        return (
          <div className="message-text">
            <p>{msgText}</p>
          </div>
        );
      }
    };
    return (
      <>
        <div className="cabinet-wrapper-clinic">
          <Tabs
            value={this.state.selectedIndex}
            onChange={(_, v) => this.setState({ selectedIndex: v })}
          >
            <Tab label={t("My profile")} {...a11yProps(0)} />
            <Tab label={t("My data")} {...a11yProps(1)} />
          </Tabs>

          <TabPanel value={this.state.selectedIndex} index={1}>
            {renderMsg()}

            <PersonalCabinetClinicForm
              editMode={this.state.editMode}
              onSubmit={(data: IDoctor) => this.onSubmit(data)}
            />
            {this.state.changePasswordMode && (
              <PersonalCabinetPasswordForm
                display="flex"
                flexDirection="column"
                onSubmit={(data: IDoctor) => this.onSubmit(data)}
              />
            )}

            <div className="bottom-block">
              {/* <Link to='/medical-card'>Моя медицинская карта</Link> */}

              {this.state.changePasswordMode && (
                <button
                  onClick={() => this.setState({ changePasswordMode: false })}
                  className="blue-btn btn btn-primary"
                >
                  {t("Cancel")}
                </button>
              )}
              {this.state.editMode && (
                <button
                  className={
                    "blue-btn btn" +
                    (this.state.changePasswordMode ? " dark" : "") +
                    " btn-primary"
                  }
                  onClick={async () => {
                    await this.onEditPass();
                  }}
                  disabled={changePasswordLoading}
                >
                  <div style={{ display: "flex" }}>
                    {changePasswordLoading && (
                      <div style={{ marginRight: "8px" }}>
                        {
                          <Spinner
                            radius={18}
                            color={"#ffffff"}
                            stroke={3}
                            visible={true}
                          />
                        }
                      </div>
                    )}
                    {t("Change Password")}
                  </div>
                </button>
              )}
              {!this.state.changePasswordMode && (
                <button
                  onClick={() => this.onEdit()}
                  className="blue-btn btn dark btn-primary spinner-button"
                >
                  {this.props.loading.subjects.has(
                    LoadingSubject.UpdateProfile
                  ) ||
                    (this.props.loading.subjects.has(
                      LoadingSubject.ChangePswd
                    ) && (
                        <div className="spinner-div">
                          <Spinner
                            radius={19}
                            color={"white"}
                            stroke={2}
                            visible={true}
                          />
                        </div>
                      ))}
                  <div>{this.state.editMode ? t("Save") : t("Edit")}</div>
                </button>
              )}
              {/* }  */}
              {/* <div className='show-more-btn'
                                onClick={() => this.onEdit()}
                            >
                                <p className='show-more-btn-title'>{this.state.editMode ? t('Save') : t('Edit')}</p>
                            </div> */}
            </div>
          </TabPanel>
          <TabPanel value={this.state.selectedIndex} index={0}>
            <div className="my-profile">
              <div className="features-block">
                <Link to="/clinic/schedule-details" className="item">
                  <img
                    src={mySchedule}
                  />
                  <div>
                    <h5>{t("My scheduling")}</h5>
                    <p>
                      {t(
                        "Here you can establish and regulate your daily work hours, as well as accept or reject requests by patients to be seen in the clinic or online. "
                      )}
                    </p>
                  </div>
                </Link>

                <Link to="/profile" className="item">
                  <span className="cabinet-feature-image">
                    <DescriptionIcon />
                  </span>
                  <div>
                    <h5>{t("About me")}</h5>
                    <p>
                      {t(
                        "Here you will place information about yourself that will be seen by and accessible to other clinics, medical professionals and patients."
                      )}
                    </p>
                  </div>
                </Link>
              </div>
            </div>
          </TabPanel>
        </div>

        <QuickAccessComponentContainer />
      </>
    );
  }
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
    value: index,
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography component={"span"}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
const mapStateToProps = (state: RootState) => ({
  loading: state.loading,
  auth: state.auth,
});

const mapDispatchToProps = {
  submit,
  signIn,
  signUp,
  signOut,
  doChange,
  changePassword,
  initialize,
};

const PersonalCabinetClinicComponentContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(PersonalCabinetClinicComponent));

export default PersonalCabinetClinicComponentContainer;
