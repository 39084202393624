import { IDoctorState } from "../states/doctor-state";
import {
  DOCTOR,
  DOCTOR_LIST,
  DOCTOR_RATING,
  DoctorActionTypes,
  DOCTORS_PAGE_SIZE,
  DOCTOR_LIST_COUNT,
  DOCTORS_AUTOCOMPLETE,
  DOCTOR_CACHED_DETAILS,
  DOCTOR_FULL_LIST,
  DOCTOR_PRICE,
  CLINICS_DOCTORS_LIST,
  ADD_CLINIC_DOCTOR,
  SELECTED_SPEC_ID,
  SELECTED_DISEASE_ID,
  SELECTED_PROCEDURE_ID,
  SELECTED_PROCEDURE_ID_DELETE,
  SELECTED_SPECIALIZATION_ID_DELETE,
  SELECTED_DISEASE_ID_DELETE,
  CLEAR_STATE_INFO,
  UNSELECTED_SPEC_ID,
  UNSELECTED_DISEASE_ID,
  UNSELECTED_PROCEDURE_ID,
  SAVE_PREV_DIS_VALUES,
  SAVE_PREV_SPEC_VALUES,
  SAVE_PREV_PROC_VALUES,
} from "../actions/doctor-action";
import { IDoctor } from "../models/doctor";

const initialState: IDoctorState = {
  doctors: [],
  mydoctors: [],
  doctor: undefined,
  doctorRating: undefined,
  doctorFullList: [],
  fullTotalCount: 0,
  mydoctorsPage: 0,
  mydoctorsFinal: false,
  doctorsPage: 0,
  doctorsFinal: false,
  doctorsAutocomplete: [],
  doctorDetails: new Map<number, IDoctor>(),
  doctorPrice: [],
  clinicsDoctorsList: [],
  clinicsDoctors: [],
  doctorInfoId: { specializations: [], diseases: [], procedures: [] },
  doctorInfoIdForDelete: { specializations: [], diseases: [], procedures: [] },
  doctorPrevValues: { specializations: [], diseases: [], procedures: [] },
};

export function doctorReducer(
  state = initialState,
  action: DoctorActionTypes
): IDoctorState {
  switch (action.type) {
    case DOCTOR_LIST:
      if (action.my) {
        const mydoctors = action.doctors;
        return {
          ...state,
          ...{
            mydoctors: mydoctors,
            mydoctorsFinal: action.doctors?.length < DOCTORS_PAGE_SIZE,
            mydoctorsPage: action.page + 1,
          },
        };
      }
      const doctors = action.doctors;
      return {
        ...state,
        ...{
          doctors: doctors,
          doctorsFinal: action.doctors?.length < DOCTORS_PAGE_SIZE,
          doctorsPage: action.page + 1,
        },
      };
    case DOCTOR:
      return { ...state, ...{ doctor: action.doctor } };
    case DOCTOR_RATING:
      return { ...state, ...{ doctorRating: action.doctorRating } };
    case DOCTOR_FULL_LIST:
      state = { ...state, ...{ doctorFullList: action.doctorFullList } };
      console.log(state);
      return state;
    case CLINICS_DOCTORS_LIST:
      return {
        ...state,
        ...{ clinicsDoctorsList: action.clinicsDoctorsList.payload },
      };
    case ADD_CLINIC_DOCTOR:
      return { ...state, ...{ clinicsDoctors: action.data } };
    case DOCTOR_LIST_COUNT:
      return { ...state, ...{ fullTotalCount: action.totalCount } };
    case DOCTORS_AUTOCOMPLETE:
      return {
        ...state,
        ...{ doctorsAutocomplete: action.doctorsAutocomplete },
      };
    case DOCTOR_CACHED_DETAILS:
      const doctorMap: Map<number, IDoctor> = new Map<number, IDoctor>(
        state.doctorDetails
      );
      doctorMap.set(action.doctor.id, action.doctor);
      return { ...state, ...{ doctorDetails: doctorMap } };
    case DOCTOR_PRICE:
      return { ...state, ...{ doctorPrice: action.doctorPrice } };
    case SELECTED_SPEC_ID:
      return {
        ...state,
        doctorInfoId: {
          ...state.doctorInfoId,
          specializations: [
            ...state.doctorInfoId.specializations,
            action.specializationsId,
          ],
        },
      };

    case UNSELECTED_SPEC_ID:
      return {
        ...state,
        doctorInfoId: {
          ...state.doctorInfoId,
          specializations: state.doctorInfoId.specializations.filter(
            (i) => i !== action.specializationsUnId
          ),
          // specializations: state.doctorInfoId.specializations.filter((item, index) => item !== action.specializationsUnId)
        },
      };
    case SELECTED_DISEASE_ID:
      return {
        ...state,
        doctorInfoId: {
          ...state.doctorInfoId,
          diseases: [...state.doctorInfoId.diseases, action.diseasesId],
        },
      };
    case UNSELECTED_DISEASE_ID:
      return {
        ...state,
        doctorInfoId: {
          ...state.doctorInfoId,
          diseases: state.doctorInfoId.diseases.filter(
            (i) => i !== action.diseasesUnId
          ),
        },
      };
    case SELECTED_PROCEDURE_ID:
      return {
        ...state,
        doctorInfoId: {
          ...state.doctorInfoId,
          procedures: [...state.doctorInfoId.procedures, action.proceduresId],
        },
      };
    case UNSELECTED_PROCEDURE_ID:
      return {
        ...state,
        doctorInfoId: {
          ...state.doctorInfoId,
          procedures: state.doctorInfoId.procedures.filter(
            (i) => i !== action.proceduresUnId
          ),
        },
      };
    case SELECTED_PROCEDURE_ID_DELETE:
      return {
        ...state,
        doctorInfoIdForDelete: {
          ...state.doctorInfoIdForDelete,
          procedures: [
            ...state.doctorInfoIdForDelete.procedures,
            action.proceduresIdDelete,
          ],
        },
      };
    case SELECTED_SPECIALIZATION_ID_DELETE:
      return {
        ...state,
        doctorInfoIdForDelete: {
          ...state.doctorInfoIdForDelete,
          specializations: [
            ...state.doctorInfoIdForDelete.specializations,
            action.specializationsIdDelete,
          ],
        },
      };
    case SELECTED_DISEASE_ID_DELETE:
      return {
        ...state,
        doctorInfoIdForDelete: {
          ...state.doctorInfoIdForDelete,
          diseases: [
            ...state.doctorInfoIdForDelete.diseases,
            action.diseasesIdDelete,
          ],
        },
      };

    case SAVE_PREV_DIS_VALUES:
      return {
        ...state,
        doctorPrevValues: {
          ...state.doctorPrevValues,
          diseases: action.prevDis,
        },
      };
      case SAVE_PREV_SPEC_VALUES:
        return {
          ...state,
          doctorPrevValues: {
            ...state.doctorPrevValues,
            specializations: action.prevSpec,
          },
        };
        case SAVE_PREV_PROC_VALUES:
          return {
            ...state,
            doctorPrevValues: {
              ...state.doctorPrevValues,
              procedures: action.prevProc,
            },
          };

    case CLEAR_STATE_INFO:
      return {
        ...state,
        doctorInfoIdForDelete: {
          ...state.doctorInfoIdForDelete,
          diseases: [...(state.doctorInfoIdForDelete.diseases = [])],
          specializations: [
            ...(state.doctorInfoIdForDelete.specializations = []),
          ],
          procedures: [...(state.doctorInfoIdForDelete.procedures = [])],
        },
        doctorInfoId: {
          ...state.doctorInfoId,
          diseases: [...(state.doctorInfoId.diseases = [])],
          specializations: [...(state.doctorInfoId.specializations = [])],
          procedures: [...(state.doctorInfoId.procedures = [])],
        },
      };
    default:
      return state;
  }
}
