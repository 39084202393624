import { ActionCreator, Action } from "redux";
import { ThunkAction } from "redux-thunk";
import { loadingStart, loadingEnd } from "./loading-actions";
import { LoadingSubject } from "../states/loading-state";
import i18n from 'i18next';
import * as environment from '../app.json';
import { pushAlert } from "./alert-actions";
import { authFetch } from "./auth-actions";
import { IPatientAnalyze } from "../models/user-analyze";
import { IFile } from "../models/file";
import { updateFiles } from "./file-action";
import { RootState } from "../store";
import { IUpload } from "../models/upload";

function topDoctorData() {
    let topDoctor = environment.topDoctor;
    if (i18n.language === 'az') topDoctor = environment.topDoctorAz;
    if (i18n.language === 'ru') topDoctor = environment.topDoctorRu;
    return topDoctor;
}

export const PATIENT_ANALYZE_LIST = 'PATIENT_ANALYZE_LIST';
interface PatientAnalyzeListAction {
    type: typeof PATIENT_ANALYZE_LIST;
    analyzes: IPatientAnalyze[];
}

export const _patientAnalyzeList: ActionCreator<PatientAnalyzeListAction> = (analyzes: IPatientAnalyze[]) => {
    return {
        type: PATIENT_ANALYZE_LIST,
        analyzes
    };
};

export const PATIENT_ANALYZE_ITEM = 'PATIENT_ANALYZE_ITEM';
interface PatientAnalyzeItemAction {
    type: typeof PATIENT_ANALYZE_ITEM;
    analyze: IPatientAnalyze | null;
}

export const _patientAnalyzeItem: ActionCreator<PatientAnalyzeItemAction> = (analyze: IPatientAnalyze | null) => {
    return {
        type: PATIENT_ANALYZE_ITEM,
        analyze
    };
};

export type PatientAnalyzeActionTypes = PatientAnalyzeListAction | PatientAnalyzeItemAction;

export const getPatientAnalyzeList = (
): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch, getState) => {
    dispatch(loadingStart(LoadingSubject.PatientAnalyzeList));

    try {
        const response = await authFetch(
            getState, dispatch, topDoctorData() + '/patientanalyses?per-page=1000', 'GET',
            null, {Accept: 'application/json', 'Content-Type': 'application/json'}, '&token='
        );

        console.log('response', response);

        if (response.status === 200) {
            const json = await response.json();
            console.log('json', json);
            dispatch(_patientAnalyzeList(json));
        }
        else if (response.status === 401) {
            //dispatch(signOut(token));
        }
        else {
            dispatch(pushAlert('Unknown error. Please, check internet connection'));
            //dispatch(errorPatientDoctorList(json.code, token));
        }
    } catch (e) {
        dispatch(pushAlert('Unknown error. Please, check internet connection'));
    } finally {
        dispatch(loadingEnd(LoadingSubject.PatientAnalyzeList));
    }
}

export const getPatientAnalyze = (
    id: number,
): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch, getState) => {
    dispatch(loadingStart(LoadingSubject.PatientAnalyze));

    try {
        const response = await authFetch(
            getState, dispatch, topDoctorData() + '/patientanalyses/' + id, 'GET',
            null, {Accept: 'application/json', 'Content-Type': 'application/json'}, '?token='
        );

        console.log('response', response);

        if (response.status === 200) {
            const json = await response.json();
            console.log('json', json);
            dispatch(_patientAnalyzeItem(json));
        }
        else if (response.status === 401) {
            //dispatch(signOut(token));
        }
        else {
            dispatch(pushAlert('Unknown error. Please, check internet connection'));
            //dispatch(errorPatientDoctorList(json.code, token));
        }
    } catch (e) {
        dispatch(pushAlert('Unknown error. Please, check internet connection'));
    } finally {
        dispatch(loadingEnd(LoadingSubject.PatientAnalyze));
    }
}

export const createPatientAnalyze = (
    analyze: IPatientAnalyze,
    uploadFiles: IUpload[], deleleFiles: IFile[],
    host_type: number,
    success: () => void,
): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch, getState) => {
    dispatch(loadingStart(LoadingSubject.PatientAnalyze));
    console.warn('surver', analyze);
    try {
        const response = await authFetch(
            getState, dispatch, topDoctorData() + '/patientanalyses', 'POST',
            analyze, {Accept: 'application/json', 'Content-Type': 'application/json'}, '?token='
        );

        console.log('response', response);

        if (response.status === 200) {
            const json = await response.json();
            console.log('json', json);
            const id = json.payload.id;
            await updateFiles(getState, dispatch, uploadFiles, deleleFiles, id, host_type);
            dispatch(getPatientAnalyzeList());
            success && success();
        }
        else if (response.status === 401) {
            //dispatch(signOut(token));
        }
        else {
            dispatch(pushAlert('Unknown error. Please, check internet connection'));
            //dispatch(errorPatientDoctorList(json.code, token));
        }
    } catch (e) {
        dispatch(pushAlert('Unknown error. Please, check internet connection'));
    } finally {
        dispatch(loadingEnd(LoadingSubject.PatientAnalyze));
    }
}


export const deletePatientAnalyze = (
    id: number,
): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch, getState) => {
    dispatch(loadingStart(LoadingSubject.PatientAnalyze));

    try {
        const response = await authFetch(
            getState, dispatch, topDoctorData() + '/patientanalyses/' + id, 'DELETE',
            null, {Accept: 'application/json', 'Content-Type': 'application/json'}, '?token='
        );

        console.log('response', response);

        if (response.status === 200) {
            const json = await response.json();
            console.log('json', json);
            dispatch(getPatientAnalyzeList());
        }
        else if (response.status === 401) {
            //dispatch(signOut(token));
        }
        else {
            dispatch(pushAlert('Unknown error. Please, check internet connection'));
            //dispatch(errorPatientDoctorList(json.code, token));
        }
    } catch (e) {
        dispatch(pushAlert('Unknown error. Please, check internet connection'));
    } finally {
        dispatch(loadingEnd(LoadingSubject.PatientAnalyze));
    }
}


export const updatePatientAnalyze = (
    analyze: IPatientAnalyze,
    uploadFiles: IUpload[], deleleFiles: IFile[],
    host_type: number,
    success: () => void,
): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch, getState) => {
    dispatch(loadingStart(LoadingSubject.PatientAnalyze));

    try {
        const response = await authFetch(
            getState, dispatch, topDoctorData() + '/patientanalyses/' + analyze.id, 'PATCH',
            analyze, {Accept: 'application/json', 'Content-Type': 'application/json'}, '?token='
        );

        console.log('response', response);

        if (response.status === 200) {
            const json = await response.json();
            console.log('json', json);
            await updateFiles(getState, dispatch, uploadFiles, deleleFiles, analyze.id, host_type);
            dispatch(getPatientAnalyzeList());
            success && success();
        }
        else if (response.status === 401) {
            //dispatch(signOut(token));
        }
        else {
            dispatch(pushAlert('Unknown error. Please, check internet connection'));
            //dispatch(errorPatientDoctorList(json.code, token));
        }
    } catch (e) {
        dispatch(pushAlert('Unknown error. Please, check internet connection'));
    } finally {
        dispatch(loadingEnd(LoadingSubject.PatientAnalyze));
    }
}