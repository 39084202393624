import { ActionCreator } from "redux";

export const SET_STATE_LETTER = "SET_STATE_LETTER";
interface setStateLetterAction {
  type: typeof SET_STATE_LETTER;
  letter: string | undefined;
}

export const setStateLetter: ActionCreator<setStateLetterAction> = (
  letter: string | undefined
) => {
  return {
    type: SET_STATE_LETTER,
    letter,
  };
};

export type AlphActionTypes = setStateLetterAction;
