import { IPatientDoctorAccessState } from "../states/patient-doctor-access-state";
import { PatientDoctorAccessActionTypes, PATIENT_DOCTOR_ACCESS_LIST, PATIENT_DOCTOR_ACCESS_ITEM, PATIENT_SHARED_PROFILE } from "../actions/patient-doctor-access-actions";

const initialState: IPatientDoctorAccessState = {
    list: []
}

export function patientDoctorAccessReducer(
    state = initialState,
    action: PatientDoctorAccessActionTypes
): IPatientDoctorAccessState {
    switch (action.type) {
        case PATIENT_DOCTOR_ACCESS_LIST:
            return {...state, ...{list: action.doctorAccess}};
            // return {
            //     ...state,
            //     list: [...state.list, action.doctorAccess]
            // }
        case PATIENT_DOCTOR_ACCESS_ITEM:
            return { ...state, ...{ selected: action.doctorAccess } };
        case PATIENT_SHARED_PROFILE:
            return { ...state, ...{ sharedProfile: action.sharedProfile } };
        default:
            return state;
    }
}
