import { IChatMessage } from "./chat-message";

export interface IWebsocketInMessage {
    partialId?: string;
    type: InMessageType;
    messages: IChatMessage[];
}

export enum InMessageType {
    Start = 0,
    Message = 1,
    Forbidden = 401,
    Partial = 2,
}