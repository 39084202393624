import React, { Component } from 'react';
import { connect } from "react-redux";
import { RootState } from "../../store";
import './Header.css';
import Dropdown from 'react-bootstrap/Dropdown'
import AuthComponentContainer from './../Auth/Auth';
import { IAuthState } from '../../states/auth-state';
import { language } from "../../actions/settings-actions";
import { Link, Redirect, RouteComponentProps, withRouter } from 'react-router-dom';
import { WithTranslation, withTranslation } from 'react-i18next';
import logo from "./../../images/logo.png";
interface MyProps {
    auth: IAuthState;
    lang: string;
    language: (language: string) => void;
}

interface MyState { }

class HeaderComponent extends Component<MyProps & RouteComponentProps & WithTranslation, MyState> {

    constructor(props: MyProps & RouteComponentProps & WithTranslation) {
        super(props);
        this.state = {
        };
    }

    render() {

        if (this.props.location?.pathname?.indexOf('/home-new') === 0) {
            return null;
        }

        return (
            <div>
                <div className='header'>
                    <div className='header-wrapper'>
                        <Link to='/app'>
                            <div className='logo-block'>
                                <img className='header-logo' src={logo} alt='logo' />
                                <p className='header-title'>VideoDoktor</p>
                            </div>
                        </Link>
                        <div className='contact-information'>
                            {/*<Dropdown>
                                <Dropdown.Toggle variant="link" id="dropdown-basic">
                                    Баку
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item href="#/action-1">Москва</Dropdown.Item>
                                    <Dropdown.Item href="#/action-2">Санкт-Петербу́рг</Dropdown.Item>
                                    <Dropdown.Item href="#/action-3">Лос-Анджелес</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>*/}
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                {/* <p className='header-phone'>8- 800 - 999999999</p> */}
                                <AuthComponentContainer />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: RootState) => ({
    auth: state.auth,
    lang: state.settings.language
});

const mapDispatchToProps = {
    language
};



let HeaderComponentContainer = connect(mapStateToProps, mapDispatchToProps)(
    withTranslation()(withRouter(HeaderComponent))
);


export default HeaderComponentContainer