import React from 'react';
import { connect } from 'react-redux';
import { Field, InjectedFormProps, reduxForm, FormErrors } from 'redux-form';
import { IUserSignUpRequest } from "../../models/user-sign-up-request";
import TextInput from './../FormComponents/TextInput';
import TextInputMask from './../FormComponents/TextInputMask';
import { TFunction } from 'i18next';
import { withTranslation } from 'react-i18next';

class RegistrationFormUser extends React.Component<{t: TFunction} & InjectedFormProps<IUserSignUpRequest>> {
    render() {
        const { t } = this.props;
        return (
            <div>
                <Field name="login" placeholder={t('Login')} component={TextInput}/>
                <Field name="phone" component={TextInputMask} label={t('Phone number')}/>
                <Field name="email" placeholder={t('Email if you have')} component={TextInput}/>
                <Field name="password" placeholder={t('Password')} type='password' component={TextInput}/>
                <Field name="password_repeat" placeholder={t('Repeat password')} type='password' component={TextInput}/>
            </div>
        );
    }
}

const loginValidator = /^\w+$/g;

const validate = (values: IUserSignUpRequest, props: {t: TFunction} & InjectedFormProps<IUserSignUpRequest>): FormErrors<IUserSignUpRequest> => {
    const errors: FormErrors<IUserSignUpRequest> = {};
    const { t } = props;

    if (!values.login || values.login.trim() === '') {
        errors.login = t('Field couldn\'t be empty');
    } else if (!values.login.match(loginValidator)) {
        errors.login = 'login_info';
    }
    if (!values.phone || values.phone.trim() === '') {
        errors.phone = t('Field couldn\'t be empty');
    }
    if (!values.password || values.password.trim() === '') {
        errors.password = t('Field couldn\'t be empty');
    }
    if (!values.password_repeat || values.password_repeat.trim() === '') {
        errors.password_repeat = t('Field couldn\'t be empty');
    }
    if (values.password !== values.password_repeat) {
        errors.password_repeat = t('Passwords do not match');
    }

    return errors;
};

const form = reduxForm<IUserSignUpRequest, any>({
    form: 'RegistrationFormUser',
    validate
})(RegistrationFormUser);

export default connect(null)(withTranslation()(form));
