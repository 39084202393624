import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import CSS from 'csstype';

class UnderConstruction extends React.Component<WithTranslation, {}> {
    render() {
        const { t } = this.props;
        return <div style={pageStyle}>
            <h3>{t('Under construction')}</h3>
        </div>
    }
}

const pageStyle: CSS.Properties = {
    height: 'calc(100vh - 455px - 110px)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
}

export default withTranslation()(UnderConstruction);