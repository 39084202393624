import { ActionCreator, Action } from "redux";
import { ThunkAction } from "redux-thunk";
import { loadingStart, loadingEnd } from "./loading-actions";
import { LoadingSubject } from "../states/loading-state";
import i18n from 'i18next';
import * as environment from '../app.json';
import { pushAlert } from "./alert-actions";
import { authFetch, signOut } from "./auth-actions";
import { IPatientNote } from "../models/user-note";
import { RootState } from "../store";

function topDoctorData() {
    let topDoctor = environment.topDoctor;
    if (i18n.language == 'az') topDoctor = environment.topDoctorAz;
    if (i18n.language == 'ru') topDoctor = environment.topDoctorRu;
    return topDoctor;
}

export const PATIENT_NOTE_LIST = 'PATIENT_NOTE_LIST';
interface PatientNoteListAction {
    type: typeof PATIENT_NOTE_LIST;
    notes: IPatientNote[];
}

export const _patientNoteList: ActionCreator<PatientNoteListAction> = (notes: IPatientNote[]) => {
    return {
        type: PATIENT_NOTE_LIST,
        notes
    };
};

export const PATIENT_NOTE_ITEM = 'PATIENT_NOTE_ITEM';
interface PatientNoteItemAction {
    type: typeof PATIENT_NOTE_ITEM;
    note: IPatientNote | null;
}

export const _patientNoteItem: ActionCreator<PatientNoteItemAction> = (note: IPatientNote | null) => {
    return {
        type: PATIENT_NOTE_ITEM,
        note
    };
};

export type PatientNoteActionTypes = PatientNoteListAction | PatientNoteItemAction;

export const getPatientNoteList = (
): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch, getState) => {
    dispatch(loadingStart(LoadingSubject.PatientNoteList));

    try {
        const response = await authFetch(
            getState, dispatch, topDoctorData() + 'patientnotes', 'GET',
            null, {Accept: 'application/json', 'Content-Type': 'application/json'}, '?token='
        );

        console.log('response', response);

        if (response.status === 200) {
            const json = await response.json();
            console.log('json', json);
            dispatch(_patientNoteList(json));
        }
        else if (response.status === 401) {
            //dispatch(signOut(token));
        }
        else {
            dispatch(pushAlert('Unknown error. Please, check internet connection'));
            //dispatch(errorPatientDoctorList(json.code, token));
        }
    } catch (e) {
        dispatch(pushAlert('Unknown error. Please, check internet connection'));
    } finally {
        dispatch(loadingEnd(LoadingSubject.PatientNoteList));
    }
}

export const getPatientNote = (
    id: number,
): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch, getState) => {
    dispatch(loadingStart(LoadingSubject.PatientNote));

    try {
        const response = await authFetch(
            getState, dispatch, topDoctorData() + 'patientnotes/' + id, 'GET',
            null, {Accept: 'application/json', 'Content-Type': 'application/json'}, '?token='
        );

        console.log('response', response);

        if (response.status === 200) {
            const json = await response.json();
            console.log('json', json);
            dispatch(_patientNoteItem(json));
        }
        else if (response.status === 401) {
            //dispatch(signOut(token));
        }
        else {
            dispatch(pushAlert('Unknown error. Please, check internet connection'));
            //dispatch(errorPatientDoctorList(json.code, token));
        }
    } catch (e) {
        dispatch(pushAlert('Unknown error. Please, check internet connection'));
    } finally {
        dispatch(loadingEnd(LoadingSubject.PatientNote));
    }
}

export const createPatientNote = (
    note: IPatientNote,
    success: () => void,
): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch, getState) => {
    dispatch(loadingStart(LoadingSubject.PatientNote));

    try {
        const response = await authFetch(
            getState, dispatch, topDoctorData() + 'patientnotes', 'POST',
            note, {Accept: 'application/json', 'Content-Type': 'application/json'}, '?token='
        );
        
        console.log('response', response);

        if (response.status === 200) {
            const json = await response.json();
            console.log('json', json);
            dispatch(getPatientNoteList());
            success && success();
        }
        else if (response.status === 401) {
            //dispatch(signOut(token));
        }
        else {
            dispatch(pushAlert('Unknown error. Please, check internet connection'));
            //dispatch(errorPatientDoctorList(json.code, token));
        }
    } catch (e) {
        dispatch(pushAlert('Unknown error. Please, check internet connection'));
    } finally {
        dispatch(loadingEnd(LoadingSubject.PatientNote));
    }
}


export const deletePatientNote = (
    id: number,
): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch, getState) => {
    dispatch(loadingStart(LoadingSubject.PatientNote));

    try {
        const response = await authFetch(
            getState, dispatch, topDoctorData() + 'patientnotes/' + id, 'DELETE',
            null, {Accept: 'application/json', 'Content-Type': 'application/json'}, '?token='
        );

        console.log('response', response);

        if (response.status === 200) {
            const json = await response.json();
            console.log('json', json);
            dispatch(getPatientNoteList());
        }
        else if (response.status === 401) {
            //dispatch(signOut(token));
        }
        else {
            dispatch(pushAlert('Unknown error. Please, check internet connection'));
            //dispatch(errorPatientDoctorList(json.code, token));
        }
    } catch (e) {
        dispatch(pushAlert('Unknown error. Please, check internet connection'));
    } finally {
        dispatch(loadingEnd(LoadingSubject.PatientNote));
    }
}


export const updatePatientNote = (
    note: IPatientNote,
    success: () => void,
): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch, getState) => {
    dispatch(loadingStart(LoadingSubject.PatientNote));

    try {
        const response = await authFetch(
            getState, dispatch, topDoctorData() + 'patientnotes/' + note.id, 'PATCH',
            note, {Accept: 'application/json', 'Content-Type': 'application/json'}, '?token='
        );

        console.log('response', response);

        if (response.status === 200) {
            const json = await response.json();
            console.log('json', json);
            dispatch(getPatientNoteList());
            success && success();
        }
        else if (response.status === 401) {
            //dispatch(signOut(token));
        }
        else {
            dispatch(pushAlert('Unknown error. Please, check internet connection'));
            //dispatch(errorPatientDoctorList(json.code, token));
        }
    } catch (e) {
        dispatch(pushAlert('Unknown error. Please, check internet connection'));
    } finally {
        dispatch(loadingEnd(LoadingSubject.PatientNote));
    }
}