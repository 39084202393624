import React, { Component } from 'react';
import { connect } from "react-redux";
import { RootState } from "../../../store";
import './MyIndicators.scss';
import FiltersComponentContainer from './../../Filters/Filters';
import QuickAccessComponentContainer from './../../QuickAccess/QuickAccess';
import Breadcrumbs from '../../Breadcrumbs/Breadcrumbs';
import { IAuthState } from "../../../states/auth-state";
import { submit, reset, FormAction, change } from 'redux-form';
import { ILoadingState, LoadingSubject } from "../../../states/loading-state";
import { IDictionaryState } from "../../../states/dictionary-state";
import Modal from 'react-bootstrap/Modal';
import { TFunction } from 'i18next';
import { WithTranslation, withTranslation } from 'react-i18next';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import { IIndicatorsState } from '../../../states/indicators-state';
import { ChartType, IIndicator } from '../../../models/indicator';
import { GroupType, IIndicatorValueFilter } from '../../../models/indicator-value-filter';
import moment from 'moment';
import { IIndicatorLine } from '../../../models/indicator-line';
import { IIndicatorValue } from '../../../models/indicator-value';
import { getIndicatorValues, valueDeleteBulk, valueSaveBulk } from '../../../actions/indicator-actions';
import { Chart } from 'react-charts';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import InfiniteScroll from 'react-infinite-scroll-component';
import Spinner from 'react-spinner-material';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { showConfirm } from '../../Dialogs';
import IndicatorForm from './IndicatorForm';
import { IPatientDoctorAccessState } from '../../../states/patient-doctor-access-state';
import { getPatientDoctorAccessList } from '../../../actions/patient-doctor-access-actions';
import { UserRole } from '../../../models/user-role.enum';
import history from "./../../../history";

import sortDown from '../../../images/medical-card/small-icons/sort-down.svg';
import doctorAccessUserIcon from './../../../images/doctor-access-user-icon.png'
interface MyProps {
    t: TFunction;
    submit: (form: string) => FormAction;
    reset: (form: string) => FormAction;
    loading: ILoadingState;
    auth: IAuthState;
    dictionary: IDictionaryState;
    pushAlert: (text: string) => void;
    location: any;
    value: number;
    indicators: IIndicatorsState;
    getIndicatorValues: (filter: IIndicatorValueFilter, page?: number) => void;
    valueDeleteBulk: (values: IIndicatorValue[]) => void;
    valueSaveBulk: (values: IIndicatorValue[], success: () => void) => void;

    patientDoctorAccess: IPatientDoctorAccessState;
    getPatientDoctorAccessList: () => void;
}

interface MyState {
    show: boolean;
    updateItem: IIndicatorValue[] | null;
    readonly: boolean;
    selectedValues: IIndicatorValue[] | null;

    removeMode: {} | null;

    selected?: IIndicator;
    indicatorLines: IIndicatorLine[];

    selectedIndex: number;
    offset: number;
    yPattern: string;
    dateLabel?: string;

    loading: boolean;
    patient: number;
    item: number;
}

class MyIndicatorsComponent extends Component<MyProps & WithTranslation, MyState> {


    constructor(props: MyProps & WithTranslation) {
        super(props);

        const params = new URLSearchParams(this.props.location.search);
        this.state = {
            selectedValues: null,

            show: false,
            updateItem: null,
            readonly: false,
            removeMode: null,

            indicatorLines: [],
            selectedIndex: 0,
            offset: 0,
            yPattern: 'HH:00',
            dateLabel: '',

            loading: false,
            item: params.get('item') ? Number(params.get('item')) : 0,
            patient: params.get('patient') ? Number(params.get('patient')) : 0,
        };

        if (!props.patientDoctorAccess.list && this.state.patient !== 0) {
            props.getPatientDoctorAccessList();
        }
    }

    componentDidMount() {
        if (this.state.patient === 0 && this.props.auth.userToken?.role !== UserRole.Patient) {
            history.push('/');
        }
    }

    setActive(i: IIndicator) {
        const indicatorLines = this.props.indicators.indicatorsInfo?.indicatorLines.filter(v => v.indicatorCode === i.code) || [];
        this.setState({
            selected: i,
            loading: true,
            removeMode: null,
            indicatorLines: indicatorLines
        }, () => {
            if (indicatorLines.length > 0) {
                this.updateChartData();
                this.updateListData(0);
            }
        });
    }

    updateListData(page: number) {
        this.props.getIndicatorValues({ groupType: GroupType.None, userId: this.state.patient, indicatorLineCodes: this.state.indicatorLines.map(v => v.code) }, page);
    }

    updateChartData() {
        const index = this.state.selected?.hasHour ? this.state.selectedIndex : this.state.selectedIndex + 1;
        const offset = this.state.offset;

        let groupType: GroupType;
        let to;
        let from;
        let state;
        switch (index) {
            case 0:
                groupType = GroupType.None;
                to = moment().endOf('hour').subtract(offset, 'hours').toDate();
                from = moment(to).subtract(1, 'hours').toDate();
                this.setState(state = { loading: false, yPattern: 'HH:mm', dateLabel: `${moment(from).format("HH:mm")} - ${moment(to).format("HH:mm")}, ${moment(to).format("LL")}` })
                break;
            case 1:
                groupType = GroupType.None;
                to = moment().endOf('day').subtract(offset, 'days').toDate();
                from = moment(to).subtract(1, 'days').toDate();
                this.setState(state = { loading: false, yPattern: 'HH:00', dateLabel: moment(to).format("LL") })
                break;
            case 2:
                groupType = GroupType.Day;
                to = moment().endOf('week').subtract(offset, 'weeks').toDate();
                from = moment(to).subtract(1, 'weeks').toDate();
                if (from.getMonth() === to.getMonth()) {
                    this.setState(state = { loading: false, yPattern: 'E', dateLabel: `${moment(from).format("D")} - ${moment(to).format("D")}, ${moment(to).format("MMMM YYYY")}` });
                } else {
                    this.setState(state = { loading: false, yPattern: 'E', dateLabel: `${moment(from).format("D MMMM")} - ${moment(to).format("D MMMM")}, ${moment(to).format("YYYY")}` });
                }
                break;
            case 3:
                groupType = GroupType.Day;
                to = moment().endOf('month').subtract(offset, 'months').toDate();
                from = moment(to).subtract(1, 'months').toDate();
                this.setState(state = { loading: false, yPattern: 'D', dateLabel: moment(to).format("MMMM YYYY") });
                break;
            default:
                groupType = GroupType.Month;
                to = moment().endOf('year').subtract(offset, 'years').toDate();
                from = moment(to).subtract(1, 'years').toDate();
                this.setState(state = { loading: false, yPattern: 'MMM', dateLabel: moment(to).format("YYYY") });
                break;
        }
        this.props.getIndicatorValues({ indicatorLineCodes: this.state.indicatorLines.map(v => v.code), groupType, from: from, to: to, userId: this.state.patient });
        return state;
    }

    async confirmDelete() {
        if (await showConfirm("Are you sure to delete this indicators?")) {
            const lines: IIndicatorValue[] = [];
            for (const index in this.state.removeMode) {
                if (this.state.removeMode[index]) {
                    const item = this.props.indicators.allValues[index];
                    this.state.indicatorLines.forEach(l => lines.push(item[l.code]));
                }
            }
            this.props.valueDeleteBulk(lines);
            this.setState({ removeMode: null });
        }
    }

    renderListItem(item: { [key: number]: IIndicatorValue }, index: number) {
        const values = this.state.indicatorLines.map(l => item[l.code]).filter(v => !!v);
        values.forEach(v => {
            if (v && v.value !== undefined) {
                v.value = v.value.toString() as any;
            }
        });
        
        const date = values[0] && values[0].dateTime ? moment(values[0].dateTime).format("DD.MM.YYYY HH:mm") : '';
        
        const valuesText = this.state.indicatorLines.map(l => {
            const value = item[l.code];
            return value && value.value !== undefined ? `${value.value} ${l.unit}` : '';
        }).filter(Boolean).join(', ');

        return <div className="single-data-item" onClick={() => {
            if (this.state.removeMode) {
                return;
            }
            this.setState({ selectedValues: values }, () => this.setState({ show: true }));
        }}>
            <div className="single-data-item-indicator-data">
                {this.state.removeMode && <div style={{ alignItems: 'center' }}>
                    {!this.state.removeMode[index] && <RadioButtonUncheckedIcon onClick={() => this.setState({ removeMode: { ...this.state.removeMode, [`${index}`]: true } })} fontSize='small' htmlColor='#007bff' />}
                    {this.state.removeMode[index] && <CheckCircleOutlineIcon onClick={() => this.setState({ removeMode: { ...this.state.removeMode, [`${index}`]: false } })} fontSize='small' htmlColor='#007bff' />}
                </div>}

                {!this.state.removeMode && <div style={{ height: 20, width: 20, display: 'inline-block', background: '#0279BD', WebkitMask: `url(${'data:image/png;base64,' + this.state.selected?.icon}) center/contain`, mask: `url(${'data:image/png;base64,' + this.state.selected?.icon}) center/contain` }} />}

                <div className="single-data-item-indicator-data-text">
                    {valuesText}
                </div>
            </div>
            <div>
                {date}
            </div>
        </div>
    }

    onSubmit(data: any) {
        console.log('data', data);
        let values = this.state.selectedValues;
        if (values) {
            for (const v of values) {
                v.value = data['v' + v.indicatorLineCode];
                v.dateTime = moment(data.dateTime).toDate();
            }
        } else {
            values = [];
            for (const l of this.state.indicatorLines) {
                values.push({
                    indicatorLineCode: l.code,
                    dateTime: moment(data.dateTime).toDate(),
                    value: data['v' + l.code],
                });
            }
        }
        console.log('valueSaveBulk', values);
        this.props.valueSaveBulk(values, () => this.setState({ show: false, selectedValues: null }));
    }

    render() {
        const { t } = this.props;

        const handleClose = () => {
            this.setState({ show: false, selectedValues: null })
        };

        let dataSets: { label: string, data: number[][] }[];
        const lines = this.state.indicatorLines;

        if (lines.length === 0 || this.state.loading || this.props.loading.subjects.has(LoadingSubject.IndicatorsChart)) {
            dataSets = [];
        } else {
            if (this.state.selected?.chartType === ChartType.Candle) {
                console.log('Candle', this.props.indicators.chartAllValues);
                if (Array.isArray(this.props.indicators.chartAllValues)) {
                    dataSets = this.props.indicators.chartAllValues.map(v => {
                        if (v[0] && v[1] && v[0].dateTime && v[1].dateTime) {
                            return {
                                data: [[new Date(v[0].dateTime).getTime(), v[0].value], [new Date(v[1].dateTime).getTime() + 1, v[1].value]],
                                label: '',
                            };
                        }
                        return { data: [], label: '' }; 
                    });
                } else {
                    dataSets = [];
                }
            } 
            else {
                const chartValues = this.props.indicators.chartValues;
                dataSets = lines.filter(l => !l.hidden).map(
                    l => ({
                        data: chartValues[l.code] ? chartValues[l.code].map(v => ([
                            Math.round(new Date(v.dateTime).getTime()), v.value])) : [],
                        label: ''
                    }));
            }
        }

        const axes = [
            { primary: true, position: 'bottom', type: 'time', hardMin: this.props.indicators.filter?.from?.getTime(), hardMax: this.props.indicators.filter?.to?.getTime() },
            { position: 'left', type: 'linear', stacked: true }
        ];

        let userData: any;
        if (this.state.patient !== 0) {
            const listData = this.props.patientDoctorAccess.list || [];
            const fromDocItems = listData.find(l => (l as any).patient?.ID === this.state.patient) as any;

            userData = fromDocItems?.patient;
        }
        return (
            <div className='home'>
                {this.state.selected && <Modal centered show={this.state.show} onHide={handleClose}>
                    <Modal.Header className='my-meds-modal-header' closeButton>
                        <Modal.Title className='indicators-modal-title'>
                            {
                                this.state.updateItem ?
                                    t('Change indicator')
                                    : this.state.readonly ?
                                        t('Show indicator')
                                        : t('New indicator')
                            }
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <IndicatorForm onSubmit={(data: any) => this.onSubmit(data)} indicatorLines={this.state.indicatorLines} selectedValues={this.state.selectedValues} />
                        <div className='modal-button-block'>
                            <button className='blue-btn btn btn-primary auth-button'
                                onClick={handleClose}>{t('Cancel')}
                            </button>
                            <button className='blue-btn btn btn-primary dark auth-button'
                                onClick={() => this.props.submit('IndicatorForm')}> {t('Save')}
                            </button>
                        </div>
                    </Modal.Body>
                </Modal>}

                {this.state.patient === 0 && <div className="content-block">
                    <h1 className='big-title'>{t('My indicators list')}</h1>
                    <div className='reviews-nav'>
                        <p className='review-item'>{t('Monitor your health indicators daily. Observe changes not only in numbers, but also on visual, dynamic graphs.')}</p>
                    </div>
                </div>}

                <div style={{ width: '100%', maxWidth: '910px', margin: '4px 0' }}><Breadcrumbs location={this.props.location} /></div>

                <div className='my-in-wrapper'>
                    <div className="left-block">
                        {!this.props.indicators.indicatorsInfo && <Spinner radius={24} color={"#0279BD"} stroke={2} visible={true}></Spinner>}
                        {this.props.indicators.indicatorsInfo?.indicators?.map(i => <div className={'item' + (i.code === this.state.selected?.code ? ' item-active' : '')} onClick={() => this.setActive(i)}>
                            <div style={{ height: 24, width: 24, display: 'inline-block', background: '#0279BD', WebkitMask: `url(${'data:image/png;base64,' + i.icon}) center/contain`, mask: `url(${'data:image/png;base64,' + i.icon}) center/contain` }} />
                            <span>{i.name}</span>
                            <img className="arrow-left" src={sortDown} />
                        </div>)}
                    </div>

                    {!this.state.selected && <div className="right-block">
                        {this.state.patient !== 0 && <div className='user_data_block'>
                            <img src={
                                userData?.Image ? 'https://topdoktor.org/uploads/patients/' + userData?.Image :
                                    doctorAccessUserIcon}
                            />
                            <div>
                                <p>{this.props.t('Name')}<span>{userData?.Name}</span></p>
                                <p>{this.props.t('Gender')}<span>{ }</span></p>
                            </div>
                            <div>
                                <p>{this.props.t('Blood type')}<span>{ }</span></p>
                                <p>{this.props.t('Birthdate')}<span>{moment(userData?.Birthdate).format("L")}</span></p>
                            </div>
                        </div>}
                        <div className="no-item-selected">{t('No indicator selected')}</div>
                    </div>}
                    {this.state.selected && <div className="right-block">
                        {this.state.patient !== 0 && <div className='user_data_block'>
                            <img src={
                                userData?.Image ? 'https://topdoktor.org/uploads/patients/' + userData?.Image :
                                    doctorAccessUserIcon}
                            />
                            <div>
                                <p>{this.props.t('Name')}<span>{userData?.Name}</span></p>
                                <p>{this.props.t('Gender')}<span>{ }</span></p>
                            </div>
                            <div>
                                <p>{this.props.t('Blood type')}<span>{ }</span></p>
                                <p>{this.props.t('Birthdate')}<span>{moment(userData?.Birthdate).format("L")}</span></p>
                            </div>
                        </div>}
                        <div className='container-header'>
                            <div className='title-action'>
                                <span>{this.state.indicatorLines.filter(l => !l.hidden).map(l => `${l.name} (${l.unit})`).join(', ')}</span>
                                {this.state.patient === 0 && <div className="btns">
                                    <button className='blue-btn btn btn-primary' onClick={() => this.setState({ show: true })}>{t('Add')}</button>
                                </div>}
                            </div>
                        </div>
                        <div className='container-section-1'>
                            <div className='tabs-wrapper'>
                                <Tabs value={this.state.selectedIndex} onChange={(_, v) => this.setState({ selectedIndex: v, offset: 0 }, () => this.updateChartData())}>
                                    <Tab label={t('Day').toLocaleUpperCase(this.props.i18n.language)} {...a11yProps(0)} />
                                    <Tab label={t('Week').toLocaleUpperCase(this.props.i18n.language)} {...a11yProps(1)} />
                                    <Tab label={t('Month').toLocaleUpperCase(this.props.i18n.language)} {...a11yProps(2)} />
                                    <Tab label={t('Year').toLocaleUpperCase(this.props.i18n.language)} {...a11yProps(3)} />
                                </Tabs>
                                <div className='navigation-buttons'>
                                    <PlayArrowIcon onClick={() => { this.setState({ offset: this.state.offset + 1 }, () => this.updateChartData()) }} htmlColor='#0279BD' className='navigation-button' style={{ transform: "rotate(180deg)" }} />
                                    {this.state.dateLabel}
                                    <PlayArrowIcon onClick={() => { this.setState({ offset: this.state.offset - 1 }, () => this.updateChartData()) }} htmlColor='#0279BD' className='navigation-button' />
                                </div>
                            </div>
                            <div className="chart-wrapper">
                                {!this.props.loading.subjects.has(LoadingSubject.IndicatorsChart) && this.props.indicators.indicatorsInfo && <Chart key={this.props.indicators.chartKey} data={dataSets} axes={axes} getSeriesStyle={() => ({ fill: '#7ECCC4', color: '#7ECCC4', r: '3px' })} />}
                            </div>
                        </div>
                        <div className='container-header'>
                            <div className='title-action'>
                                <span>{t('All data')}</span>
                                {this.state.patient === 0 && !!this.props.indicators.allValues?.length && <div className="btns">
                                    {!this.state.removeMode && <button onClick={() => this.setState({ removeMode: {} })} className='blue-btn btn btn-primary'>{t('Delete')}</button>}
                                    {this.state.removeMode && <button onClick={() => this.setState({ removeMode: null })} className='blue-btn btn btn-primary'>{t('Cancel')}</button>}
                                    {this.state.removeMode && <button onClick={() => this.confirmDelete()} className='blue-btn btn dark btn-primary'>{t('Confirm')}</button>}
                                </div>}
                            </div>
                        </div>
                        <div className='container-section-2'>
                            <div id="all-data" className='all-data'>
                                {!this.state.loading && this.props.indicators.indicatorsInfo && <><InfiniteScroll
                                    next={() => {
                                        if (this.props.loading.subjects.has(LoadingSubject.IndicatorValues) || this.props.loading.subjects.has(LoadingSubject.IndicatorValuesNext)) {
                                            return;
                                        }
                                        this.updateListData(this.props.indicators.allValuesPage);
                                    }}
                                    scrollableTarget="all-data"
                                    hasMore={this.props.indicators.allValuesHasMore}
                                    loader={<Spinner radius={24} color={"#0279BD"} stroke={2} visible={true}></Spinner>}
                                    dataLength={this.props.indicators.allValues?.length || 0}
                                >
                                    {!this.props.loading.subjects.has(LoadingSubject.IndicatorValues) && this.props.indicators.allValues?.map((r, i) => this.renderListItem(r, i))}
                                </InfiniteScroll>
                                    {!this.props.indicators.allValues?.length && !this.props.indicators.allValuesHasMore && <div className='no-items-no-results'> <p>{t('No data available')}</p></div>}
                                </>}
                            </div>
                        </div>
                    </div>}
                </div>
                {/* <Services /> */}

                <QuickAccessComponentContainer />
                <FiltersComponentContainer />
            </div>
        );
    }
}

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
        value: index,
    };
}

const mapStateToProps = (state: RootState) => ({
    auth: state.auth,
    dictionary: state.dictionary,
    indicators: state.indicators,
    loading: state.loading,
    patientDoctorAccess: state.patientDoctorAccess,
});

const mapDispatchToProps = {
    submit,
    reset,
    change,

    getIndicatorValues,
    valueDeleteBulk,
    valueSaveBulk,

    getPatientDoctorAccessList,
};

let MyIndicatorsComponentContainer = connect(mapStateToProps, mapDispatchToProps)(
    withTranslation()(MyIndicatorsComponent)
);

export default MyIndicatorsComponentContainer