import React, { Component } from 'react';
import { connect } from "react-redux";
import { RootState } from "../../store";
import './Ratings.scss';
import { TFunction, i18n } from 'i18next';
import { withTranslation } from 'react-i18next';
import Modal from 'react-bootstrap/Modal'
import StarRatings from "react-star-ratings";
import { IClinic } from '../../models/clinic';
import { IAuthState } from "../../states/auth-state";
import { createClinicRating } from "../../actions/rating-actions";
import { IClinicRating } from '../../models/clinic-rating';
import { showAlert, showConfirm } from "../Dialogs";

interface MyProps {
    t: TFunction;
    i18n: i18n;
    clinic: IClinic;
    auth: IAuthState;
    createClinicRating: (
        rating: IClinicRating,
        id: number,
        token: string,
    ) => void;
}

interface MyState {
    full_rating: IClinicRating,
    selectable: boolean;
    isOpen: boolean;
}

const initialRating = {
    atmosphere: 0,
    care_staff: 0,
    diagnosis_clear: 0,
    latrines_bathroom: 0,
    medicines: 0,
    mindfulness: 0,
    professionalism: 0,
    proficiency_doctors: 0,
    quality_service: 0,
    recommended_friends: 0,
    relevance_price: 0,
    rendered_assistance: 0,
    service_registry: 0,
    total: 0,
    waiting_time: 0,
    ward: 0,
    id: 0
}

class ClinicsRatings extends Component<MyProps, MyState> {

    constructor(props: MyProps) {
        super(props);
        this.state = {
            full_rating: {
                id: 0,
                atmosphere: 0,
                care_staff: 0,
                diagnosis_clear: 0,
                latrines_bathroom: 0,
                medicines: 0,
                mindfulness: 0,
                professionalism: 0,
                proficiency_doctors: 0,
                quality_service: 0,
                recommended_friends: 0,
                relevance_price: 0,
                rendered_assistance: 0,
                service_registry: 0,
                total: 0,
                waiting_time: 0,
                ward: 0
            },
            selectable: false,
            isOpen: false
        }
    }

    componentDidMount() {
        this.disableEditable();
    }
    // componentDidUpdate(prevProps: MyProps) {
    //     if(prevProps.clinic != this.props.clinic) {
    //         this.disableEditable();
    //     }
    // }

    handleClose = () => {
        this.setState({ isOpen: false });
    }

    checkAllowSchedule = () => {
        if (!this.props.auth.userToken) {
            showAlert(this.props.t('Please Sing in for appointment!'));
        }
        return !!this.props.auth.userToken;
    }

    enableEditable = () => {
        this.setState({ selectable: true, full_rating: initialRating });
    }

    disableEditable = () => {
        const { clinic } = this.props;
        this.setState({ selectable: false, full_rating: clinic?.full_rating });
    }

    saveRatings = () => {
        this.props.createClinicRating(
            this.state.full_rating,
            this.props.clinic.id || 0,
            this.props.auth.userToken!.auth
        );
       this.disableEditable();
       this.handleClose();
    }

    getAmountReview = (clinic: IClinic) => {
        if(!clinic) { return; } 
        switch(this.props.i18n.language) {
            case 'az':
                return clinic?.full_rating?.count + " istifadəçinin fikirləri əsasında";
            case 'en':
                return "Based on opinions of " + clinic?.full_rating?.count + " users";
            case 'ru':
                return "Основано на мнении " + clinic?.full_rating?.count + " пользователей";
            default:
                return "";
        }
    }

    render() {
        const { t, clinic } = this.props;
        const { isOpen, full_rating, selectable } = this.state;
        const { waiting_time, professionalism, latrines_bathroom, recommended_friends, care_staff, relevance_price, medicines, ward, proficiency_doctors, rendered_assistance, service_registry, diagnosis_clear, mindfulness, atmosphere, quality_service, total } = full_rating || {};
        const amount_people = this.getAmountReview(clinic);

        return (
            <div>
                <div onClick={() => this.setState({ isOpen: true })}>
                    <StarRatings
                        isSelectable={false}
                        maxStars={5}
                        rating={clinic?.total_raiting}
                        starRatedColor="#FCE205"
                        starHoverColor="#FCE205"
                        numberOfStars={5}
                        starDimension="20px"
                        starSpacing="2px"
                        name='rating'
                    />
                    <p className='reviews-count'>{clinic?.full_rating?.count || 0}  {t('reviews')}</p>
                </div>
                <Modal centered show={isOpen} onHide={this.handleClose}>
                    <Modal.Header closeButton>{t('Clinic rating')}</Modal.Header>
                    <Modal.Body>
                        <div className="rating-doctor">{clinic?.name}</div>
                        <div className="rating-row">
                            <div className="rating-element">
                                <span>1. {t('Waiting time')}</span>
                                <StarRatings
                                    changeRating={selectable ? (rate) => this.setState({ full_rating: {...full_rating, waiting_time: rate} }) : undefined}
                                    isSelectable={selectable}
                                    maxStars={5}
                                    rating={waiting_time}
                                    starRatedColor="#FCE205"
                                    starHoverColor="#FCE205"
                                    numberOfStars={5}
                                    starDimension="20px"
                                    starSpacing="2px"
                                    name='rating'
                                />
                            </div>
                            <div className="rating-element">
                                <span>2. {t('Medicines')}</span>
                                <StarRatings
                                    changeRating={selectable ? (rate) => this.setState({ full_rating: {...full_rating, medicines: rate} }): undefined}
                                    isSelectable={selectable}
                                    maxStars={5}
                                    rating={medicines}
                                    starRatedColor="#FCE205"
                                    starHoverColor="#FCE205"
                                    numberOfStars={5}
                                    starDimension="20px"
                                    starSpacing="2px"
                                    name='rating'
                                />
                            </div>
                        </div>
                        <div className="rating-row">
                            <div className="rating-element">
                                <span>3. {t('Quality service')}</span>
                                <StarRatings
                                    changeRating={selectable ? (rate) => this.setState({ full_rating: {...full_rating, quality_service: rate} }): undefined}
                                    isSelectable={selectable}
                                    maxStars={5}
                                    rating={quality_service}
                                    starRatedColor="#FCE205"
                                    starHoverColor="#FCE205"
                                    numberOfStars={5}
                                    starDimension="20px"
                                    starSpacing="2px"
                                    name='rating'
                                />
                            </div>
                            <div className="rating-element">
                                <span>4. {t('Atmosphere')}</span>
                                <StarRatings
                                    changeRating={selectable ? (rate) => this.setState({ full_rating: {...full_rating, atmosphere: rate} }): undefined}
                                    isSelectable={selectable}
                                    maxStars={5}
                                    rating={atmosphere}
                                    starRatedColor="#FCE205"
                                    starHoverColor="#FCE205"
                                    numberOfStars={5}
                                    starDimension="20px"
                                    starSpacing="2px"
                                    name='rating'
                                />
                            </div>
                        </div>
                        <div className="rating-row">
                            <div className="rating-element">
                                <span>5. {t('Relevance price')}</span>
                                <StarRatings
                                    changeRating={selectable ? (rate) => this.setState({ full_rating: {...full_rating, relevance_price: rate} }): undefined}
                                    isSelectable={selectable}
                                    maxStars={5}
                                    rating={relevance_price}
                                    starRatedColor="#FCE205"
                                    starHoverColor="#FCE205"
                                    numberOfStars={5}
                                    starDimension="20px"
                                    starSpacing="2px"
                                    name='rating'
                                />
                            </div>
                            <div className="rating-element">
                                <span>6. {t('Rendered assistance')}</span>
                                <StarRatings
                                    changeRating={selectable ? (rate) => this.setState({ full_rating: {...full_rating, rendered_assistance: rate} }): undefined}
                                    isSelectable={selectable}
                                    maxStars={5}
                                    rating={rendered_assistance}
                                    starRatedColor="#FCE205"
                                    starHoverColor="#FCE205"
                                    numberOfStars={5}
                                    starDimension="20px"
                                    starSpacing="2px"
                                    name='rating'
                                />
                            </div>
                        </div>
                        <div className="rating-row">
                            <div className="rating-element">
                                <span>7. {t('Service registry')}</span>
                                <StarRatings
                                    changeRating={selectable ? (rate) => this.setState({ full_rating: {...full_rating, service_registry: rate} }): undefined}
                                    isSelectable={selectable}
                                    maxStars={5}
                                    rating={service_registry}
                                    starRatedColor="#FCE205"
                                    starHoverColor="#FCE205"
                                    numberOfStars={5}
                                    starDimension="20px"
                                    starSpacing="2px"
                                    name='rating'
                                />
                            </div>
                            <div className="rating-element">
                                <span>8. {t('Proficiency doctors')}</span>
                                <StarRatings
                                    changeRating={selectable ? (rate) => this.setState({ full_rating: {...full_rating, proficiency_doctors: rate} }): undefined}
                                    isSelectable={selectable}
                                    maxStars={5}
                                    rating={proficiency_doctors}
                                    starRatedColor="#FCE205"
                                    starHoverColor="#FCE205"
                                    numberOfStars={5}
                                    starDimension="20px"
                                    starSpacing="2px"
                                    name='rating'
                                />
                            </div>
                        </div>
                        <div className="rating-row">
                            <div className="rating-element">
                                <span>9. {t('Ward')}</span>
                                <StarRatings
                                    changeRating={selectable ? (rate) => this.setState({ full_rating: {...full_rating, ward: rate} }): undefined}
                                    isSelectable={selectable}
                                    maxStars={5}
                                    rating={ward}
                                    starRatedColor="#FCE205"
                                    starHoverColor="#FCE205"
                                    numberOfStars={5}
                                    starDimension="20px"
                                    starSpacing="2px"
                                    name='rating'
                                />
                            </div>
                            <div className="rating-element">
                                <span>10. {t('Latrines / bathroom')}</span>
                                <StarRatings
                                    changeRating={selectable ? (rate) => this.setState({ full_rating: {...full_rating, latrines_bathroom: rate} }): undefined}
                                    isSelectable={selectable}
                                    maxStars={5}
                                    rating={latrines_bathroom}
                                    starRatedColor="#FCE205"
                                    starHoverColor="#FCE205"
                                    numberOfStars={5}
                                    starDimension="20px"
                                    starSpacing="2px"
                                    name='rating'
                                />
                            </div>
                        </div>
                        <div className="rating-row">
                            <div className="rating-element">
                                <span>11. {t('Diagnosis clear')}</span>
                                <StarRatings
                                    changeRating={selectable ? (rate) => this.setState({ full_rating: {...full_rating, diagnosis_clear: rate} }): undefined}
                                    isSelectable={selectable}
                                    maxStars={5}
                                    rating={diagnosis_clear}
                                    starRatedColor="#FCE205"
                                    starHoverColor="#FCE205"
                                    numberOfStars={5}
                                    starDimension="20px"
                                    starSpacing="2px"
                                    name='rating'
                                />
                            </div>
                            <div className="rating-element">
                                <span>12. {t('Professionalism')}</span>
                                <StarRatings
                                    changeRating={selectable ? (rate) => this.setState({ full_rating: {...full_rating, professionalism: rate} }): undefined}
                                    isSelectable={selectable}
                                    maxStars={5}
                                    rating={professionalism}
                                    starRatedColor="#FCE205"
                                    starHoverColor="#FCE205"
                                    numberOfStars={5}
                                    starDimension="20px"
                                    starSpacing="2px"
                                    name='rating'
                                />
                            </div>
                        </div>
                        <div className="rating-row">
                            <div className="rating-element">
                                <span>13. {t('Mindfulness')}</span>
                                <StarRatings
                                    changeRating={selectable ? (rate) => this.setState({ full_rating: {...full_rating, mindfulness: rate} }): undefined}
                                    isSelectable={selectable}
                                    maxStars={5}
                                    rating={mindfulness}
                                    starRatedColor="#FCE205"
                                    starHoverColor="#FCE205"
                                    numberOfStars={5}
                                    starDimension="20px"
                                    starSpacing="2px"
                                    name='rating'
                                />
                            </div>
                            <div className="rating-element">
                                <span>14. {t('Care staff')}</span>
                                <StarRatings
                                    changeRating={selectable ? (rate) => this.setState({ full_rating: {...full_rating, care_staff: rate} }): undefined}
                                    isSelectable={selectable}
                                    maxStars={5}
                                    rating={care_staff}
                                    starRatedColor="#FCE205"
                                    starHoverColor="#FCE205"
                                    numberOfStars={5}
                                    starDimension="20px"
                                    starSpacing="2px"
                                    name='rating'
                                />
                            </div>
                        </div>
                        <div className="rating-row">
                            <div className="rating-element">
                                <span>15. {t('Total')}</span>
                                <StarRatings
                                    changeRating={selectable ? (rate) => this.setState({ full_rating: {...full_rating, total: rate} }): undefined}
                                    isSelectable={selectable}
                                    maxStars={5}
                                    rating={total}
                                    starRatedColor="#FCE205"
                                    starHoverColor="#FCE205"
                                    numberOfStars={5}
                                    starDimension="20px"
                                    starSpacing="2px"
                                    name='rating'
                                />
                            </div>
                            <div className="rating-element">
                                <span>16. {t('Recommended friends')}</span>
                                <StarRatings
                                    changeRating={selectable ? (rate) => this.setState({ full_rating: {...full_rating, recommended_friends: rate} }): undefined}
                                    isSelectable={selectable}
                                    maxStars={5}
                                    rating={recommended_friends}
                                    starRatedColor="#FCE205"
                                    starHoverColor="#FCE205"
                                    numberOfStars={5}
                                    starDimension="20px"
                                    starSpacing="2px"
                                    name='rating'
                                />
                            </div>
                        </div>
                        <div className="rating-review">
                            {!selectable ? amount_people: ""}
                        </div>
                        {!selectable && <div className='show-more-btn'
                            style={{
                                width: '150px',
                                fontSize: '13px',
                                margin: '0',
                                marginTop: '25px',
                                marginLeft: 'auto'
                            }}
                            onClick={() => {
                                if(this.checkAllowSchedule()) {
                                    this.enableEditable();
                                }
                            }}>
                            <p className='show-more-btn-title'>{t('Rate by yourself')}</p>
                        </div>}
                        {selectable && <div className='show-more-btn'
                            style={{
                                width: '150px',
                                fontSize: '13px',
                                margin: '0',
                                marginTop: '25px',
                                marginLeft: 'auto'
                            }}
                            onClick={() => {
                                this.saveRatings();
                            }}>
                            <p className='show-more-btn-title'>{t('Save')}</p>
                        </div>}

                    </Modal.Body>
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = (state: RootState) => ({
    auth: state.auth
});

const mapDispatchToProps = {
    createClinicRating
};

let ClinicsRatingsContainer = connect(mapStateToProps, mapDispatchToProps)(
    withTranslation()(ClinicsRatings)
);

export default ClinicsRatingsContainer;