import {Action, ActionCreator} from "redux";
import {ThunkAction} from "redux-thunk";
import {loadingEnd, loadingStart} from "./loading-actions";
import {LoadingSubject} from "../states/loading-state";
import {pushAlert} from "./alert-actions";
import * as environment from '../app.json';
import {IClinicFilter, ClinicSort} from "../models/clinic-filter";
import {IClinic} from "../models/clinic";
import i18n from 'i18next';
import { RootState } from "../store";

function topDoctorData() {
    let topDoctor = environment.topDoctor;
    if (i18n.language == 'az') topDoctor = environment.topDoctorAz;
    if (i18n.language == 'ru') topDoctor = environment.topDoctorRu;
    return topDoctor;
}

export const CLINICS_PAGE_SIZE = 20;

export const CLINIC_LIST = 'CLINIC_LIST';
interface ClinicListAction {
    type: typeof CLINIC_LIST;
    clinics: IClinic[];
    total: number;
    page: number;
    end: boolean;
}

export const _clinicList: ActionCreator<ClinicListAction> = (clinics: IClinic[], total: number, page: number, end: boolean) => {
    return {
        type: CLINIC_LIST,
        clinics,
        total,
        page,
        end,
    };
};

export const CLINIC = 'CLINIC';
interface ClinicAction {
    type: typeof CLINIC;
    clinic: IClinic;
}

const _clinic: ActionCreator<ClinicAction> = (clinic: IClinic) => {
    return {
        type: CLINIC,
        clinic
    };
};

export type ClinicActionTypes = ClinicAction | ClinicListAction

export const getClinicList = (
    filter: IClinicFilter | undefined,
    page: number = 1,
): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch, getState) => {
    dispatch(loadingStart(page > 0 ? LoadingSubject.ClinicListNextPage : LoadingSubject.ClinicList));
    try {
        let filterPath = '';
        if (filter) {
            for (let key in filter) {
                if (key === 'sort' || key === 'desc') {
                    continue;
                }
                if (filter.hasOwnProperty(key) && filter[key]) {
                    filterPath += filterPath === '' ? `?${key}=${filter[key]}` : `&${key}=${filter[key]}`;
                }
            }
            console.error('filter', filter);
            if (filter.sort !== undefined) {
                let sort;
                switch (Number(filter.sort)) {
                    case ClinicSort.Rating:
                        sort = 'total_rating';
                        break;
                    case ClinicSort.Name:
                        sort = 'name';
                            break;
                    default:
                        break;
                }
                if (filter.desc) {
                    sort = '-' + sort;
                }
                filterPath += filterPath === '' ? `?sort=${sort}` : `&sort=${sort}`;
            }
        }
        const response = await fetch(topDoctorData() + 'clinics' + (!!filterPath ? '/search' + filterPath + `&page=${page + 1}`: `?page=${page + 1}`), {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
        });


        const json = await response.json();
        if (response.status === 200) {
            const end = response.headers.get('X-Pagination-Page-Count') === response.headers.get('X-Pagination-Current-Page');
            const total = response.headers.get('X-Pagination-Total-Count');
            dispatch(_clinicList(json, total, page, end));
        }
        else if (response.status === 401) {
            //dispatch(signOut(token));
        }
        else {
            dispatch(errorClinicList(json.code));
        }
    } catch (e) {
        dispatch(pushAlert('Unknown error. Please, check internet connection'));
    } finally {
        dispatch(loadingEnd(page > 1 ? LoadingSubject.ClinicListNextPage : LoadingSubject.ClinicList));
    }
}

export const getClinic = (
    id: number
): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch, getState) => {
    dispatch(loadingStart(LoadingSubject.Clinic));
    dispatch(_clinic(undefined));

    try {
        const response = await fetch(topDoctorData() + 'clinics/' + id, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        });

        console.log('response', response);

        const json = await response.json();
        console.log('json', json);
        if (response.status === 200) {
            dispatch(_clinic(json));
        }
        else if (response.status === 401) {
            //dispatch(signOut(token));
        }
        else {
            dispatch(errorClinic(json.code));
        }
    } catch (e) {
        dispatch(pushAlert('Unknown error. Please, check internet connection'));
    } finally {
        dispatch(loadingEnd(LoadingSubject.Clinic));
    }
}

export const errorClinicList = (code: number): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch, getState) => {
    //console.warn('code', code);
    dispatch((pushAlert('Error')));
}
export const errorClinic = (code: number): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch, getState) => {
    //console.warn('code', code);
    dispatch((pushAlert('Error')));
}
