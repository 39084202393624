import React from 'react';
import { connect } from 'react-redux';
import { Field, InjectedFormProps, reduxForm, formValueSelector, FormErrors } from 'redux-form';
import { IPatientNote } from "../models/user-note";
import { IPatientNoteState } from '../states/patient-note-state';
import { RootState } from '../store';
import TextInput from './FormComponents/TextInput';
import { TFunction } from 'i18next';
import { withTranslation } from 'react-i18next';

type Props = {
    t: TFunction
    type: number;
    patientNote: IPatientNoteState;
}

class UserNotesForm extends React.Component<InjectedFormProps<IPatientNote> & Props> {

    componentWillMount() { this.props.patientNote.selected && this.props.initialize(this.props.patientNote.selected); }

    render() {
        const { t } = this.props;
        return (
            <div>
                <Field
                    name="name"
                    style={{
                        width: '70%',
                        marginBottom: '10px',
                        paddingLeft: '5px',
                    }}
                    placeholder={t('Name')}
                    component={TextInput}
                />

                <Field
                    name="description"
                    style={{
                        width: '70%',
                        marginBottom: '10px',
                        paddingLeft: '5px',
                    }}
                    placeholder={t('Description')}
                    component={TextInput}
                />

                <Field
                    name="date"
                    type='date'
                    style={{
                        width: '70%',
                        marginBottom: '10px',
                        paddingLeft: '5px',
                    }}
                    placeholder={t('Date')}
                    component={TextInput}
                />
            </div>
        );
    }
}

const validate = (values: IPatientNote, props: {t: TFunction}): FormErrors<IPatientNote> => {
    const errors: FormErrors<IPatientNote> = {};
    if (!values.name) {
        errors.name = props.t('Field couldn\'t be empty');
    }
    return errors;
};

const form = reduxForm<IPatientNote & { isChecked: boolean }, any>({
    form: 'UserNotesForm',
    validate,
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
})(UserNotesForm);

const selector = formValueSelector('UserNotesForm');
const mapStateToProps = (state: RootState) => {
    const type = selector(state, 'type') || null;
    return {
        patientNote: state.patientNote,
        type
    }
};

export default connect(mapStateToProps)(withTranslation()(form));