import React from 'react';
import { WrappedFieldProps } from "redux-form";
import './TextInputMask.scss';
import InputMask from 'react-input-mask';

export interface CustomFieldProps {
    name?: string;
    placeholder?: string;
    label?: string;
    type?: string;
    disableInput?: boolean;
    editable?: boolean;
    multiline?: boolean;
    _className?: any;
}

class TextInputMask extends React.Component<WrappedFieldProps & CustomFieldProps> {

    render() {
        const { placeholder, label, type, disableInput, editable, multiline, _className,
            input: { onChange, onBlur }, meta: { touched, valid, error }, name
        } = this.props;

        let value = this.props.input.value;

        if (type === 'datetime-local' && value.length == 19) {
            value = value.replace(" ", "T");
            value = value.substring(0, value.length - 3);
        }

        const errorClassName = !valid && touched ? 'error' : '';

        return (
            <div className={'text-input-component reg-input-styles' + ' ' + _className}>
                <p>{label ? label : placeholder}</p>
                <InputMask
                    mask="099 999-99-99"
                    maskChar={null}
                    placeholder={'0XX XXX-XX-XX'}
                    name={name}
                    className={errorClassName}
                    onChange={(event: any) => {
                        let _value = event.target.value;
                        if (_value.length === 13) {
                            _value = _value.replace(/ |-/g, '').substring(1);
                        }
                        onChange(_value);
                    }}
                    value={value}
                />
                {!valid && touched ? <p className='text-input-component__error'>{error}</p> : null}
            </div>
        );
    }
}

export default TextInputMask;