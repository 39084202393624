import { ActionCreator, Action } from "redux";
import { RootState } from "../store";
import { ThunkAction } from "redux-thunk";
import { loadingStart, loadingEnd } from "./loading-actions";
import { LoadingSubject } from "../states/loading-state";
import { pushAlert } from "./alert-actions";
import { IDiseaseInfo } from "../models/disease-info";

export const DISEASE_INFORMATION = "DISEASE_INFORMATION";

interface DiseaseInfo {
  type: typeof DISEASE_INFORMATION;
  information: IDiseaseInfo;
  id: number;
}
export const DiseaseInfo: ActionCreator<DiseaseInfo> = (
  information: IDiseaseInfo,
  id: number
) => {
  return {
    type: DISEASE_INFORMATION,
    information,
    id,
  };
};

export type DiseaseInfoActionTypes = DiseaseInfo;

export const getDiseaseInformation =
  (diseaseId: string): ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch, getState) => {
    console.log("getDiseaseInformation")
    dispatch(loadingStart(LoadingSubject.DiseaseInfo));

    try {
      const response = await fetch(
        //`http://videodoctor-strapi-dev.zirinc.com/disease-infos?diseaseId_in=${diseaseId}`
        `/api3/disease-infos?diseaseId_in=${diseaseId}`
      );
      if (response.status === 200) {
        const json = await response.json();
        console.log(json, "11111");
        dispatch(DiseaseInfo(json));
      } else {
        dispatch(pushAlert("Unknown error. Please, check internet connection"));
      }
    } catch (error) {
      dispatch(pushAlert("Unknown error. Please, check internet connection"));
    } finally {
      dispatch(loadingEnd(LoadingSubject.DiseaseInfo));
    }
  };
