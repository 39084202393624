import React, { Component } from 'react';
import { connect } from "react-redux";
import { RootState } from "../../store";
import './MedicalCard.scss';
import { IAuthState } from "../../states/auth-state";
import { TFunction } from 'i18next';
import { withTranslation } from 'react-i18next';
import history from "./../../history";

import MyMedicinesComponentContainer from './MyMedicines/MyMedicines';
import MySurveysComponentContainer from './MySurveys/MySurveys';
import MyAllergiesComponentContainer from './MyAllergies/MyAllergies';
import MyVaccinationsComponentContainer from './MyVaccinations/MyVaccinations';
import MyAnalyzesComponentContainer from './MyAnalyzes/MyAnalyzes';
import MyNotesComponentContainer from './MyNotes/MyNotes';
import MyConsultationsHistoryComponentContainer from './MyConsultationsHistory/MyConsultationsHistory';
import DoctorsAccessComponentContainer from './DoctorsAccess/DoctorsAccess';
import moment from 'moment';
import { IPatientDoctorAccessState } from '../../states/patient-doctor-access-state';
import { getPatientDoctorAccessList } from '../../actions/patient-doctor-access-actions';
import { UserRole } from '../../models/user-role.enum';
import { LoadingCenter } from '../Loading/Loading';
import { ILoadingState, LoadingSubject } from '../../states/loading-state';
import Breadcrumbs from './../Breadcrumbs/Breadcrumbs';

import doctorAccessUserIcon from "./../../images/doctor-access-user-icon.png"
import sortDown from "./../../images/medical-card/small-icons/sort-down.svg"
interface MyProps {
    auth: IAuthState;
    t: TFunction;
    location: Location;
    loading: ILoadingState;
    patientDoctorAccess: IPatientDoctorAccessState;
    getPatientDoctorAccessList: () => void;
}

interface MyState {
    selected: number;
    item?: number;
}

class MedicalCardComponent extends Component<MyProps, MyState> {

    constructor(props: MyProps) {
        super(props);
        if (props.auth.userToken?.role !== UserRole.Doctor) {
            this.state = {
                selected: -1,
            };
            return;
        }
        if (!props.patientDoctorAccess.list) {
            props.getPatientDoctorAccessList();
        }
        const params = new URLSearchParams(this.props.location.search);
        this.state = {
            selected: -1,
            item: Number(params.get('item')),
        };
    }

    componentDidMount() {
        if (!this.state.item && this.props.auth.userToken?.role !== UserRole.Patient) {
            history.push('/');
        }
    }

    render() {
        const { t } = this.props;
        const { selected } = this.state;
        const patient = this.props.auth?.userToken?.role === 3;

        const listData = this.props.patientDoctorAccess.list || [];
        const fromDocItems = listData.find(l => l.id === this.state.item) as any;
        const userData = fromDocItems ? fromDocItems.patient : null;

        console.log('this.props med card', fromDocItems);

        const patientInfoBlock = fromDocItems ? <div className='user_data_block'>
            <img src={
                userData.Image ? 'https://topdoktor.org/uploads/patients/' + userData.Image :
                    doctorAccessUserIcon}
            />
            <div>
                <p>{this.props.t('Name')}<span>{userData.Name}</span></p>
                <p>{this.props.t('Gender')}<span>{ }</span></p>
            </div>
            <div>
                <p>{this.props.t('Blood type')}<span>{ }</span></p>
                <p>{this.props.t('Birthdate')}<span>{moment(userData.Birthdate).format("L")}</span></p>
            </div>
        </div> : <div></div>;

        const renderItem = () => {
            if (selected >= 0) {
                if (selected === 0) {
                    return (
                        <MyMedicinesComponentContainer
                            patientInfoBlock={patientInfoBlock}
                            fromDocItems={fromDocItems ? fromDocItems.pills : null}
                            forDocItem={fromDocItems ? true : false}
                        />
                    )
                } else if (selected === 1) {
                    return (
                        <MySurveysComponentContainer
                            patientInfoBlock={patientInfoBlock}
                            fromDocItems={fromDocItems ? fromDocItems.examination : null}
                            forDocItem={fromDocItems ? true : false}
                        />
                    )
                } else if (selected === 2) {
                    return (
                        <MyAllergiesComponentContainer
                            patientInfoBlock={patientInfoBlock}
                            fromDocItems={fromDocItems ? fromDocItems.allergy : null}
                            forDocItem={fromDocItems ? true : false}
                        />
                    )
                } else if (selected === 3) {
                    return (
                        <MyVaccinationsComponentContainer
                            patientInfoBlock={patientInfoBlock}
                            fromDocItems={fromDocItems ? fromDocItems.inoculation : null}
                            forDocItem={fromDocItems ? true : false}
                        />
                    )
                } else if (selected === 4) {
                    return (
                        <MyAnalyzesComponentContainer
                            patientInfoBlock={patientInfoBlock}
                            fromDocItems={fromDocItems ? fromDocItems.analysis : null}
                            forDocItem={fromDocItems ? true : false}
                        />
                    )
                } else if (selected === 5) {
                    return (
                        <MyNotesComponentContainer />
                    )
                } else if (selected === 6) {
                    return (
                        <MyConsultationsHistoryComponentContainer
                            patientInfoBlock={patientInfoBlock}
                            fromDocItems={fromDocItems ? fromDocItems.consultation : null}
                            forDocItem={fromDocItems ? true : false}
                        />
                    )
                } else if (selected === 7) {
                    return (
                        <DoctorsAccessComponentContainer />
                    )
                }
            } else {
                return (<>
                    <div className="no-item-patient-info-container">{patientInfoBlock}</div>
                    <div className="no-item-selected">
                        {t('No medical card item selected')}
                    </div>
                </>)
            }
        }

        return (
            <div className='home medical_card_main' style={patient ? {} : { marginTop: '10px' }}>
                <div className="content-block" style={patient ? {} : { display: 'none' }}>
                    <h1 className='big-title'>{t('My Medical Chart')}</h1>
                    <div className='reviews-nav'>
                        <p className='review-item'>{t('med_card_desc')}</p>
                    </div>
                </div>
                <div style={{ minWidth: '1100px', display: 'flex', margin: '3px auto' }}><Breadcrumbs location={this.props.location} /></div>
                <div className='my-in-wrapper'>

                    <div className="left-block">

                        <div className={selected === 0 ? 'item item-active' : 'item'} onClick={() => { this.setState({ selected: 0 }) }}>
                            <div></div>
                            <span>{t('My medicines')}</span>
                            <img className="arrow-left" src={sortDown} />
                        </div>

                        <div className={selected === 1 ? 'item item-active' : 'item'} onClick={() => { this.setState({ selected: 1 }) }}>
                            <div></div>
                            <span>{t('My examinations')}</span>
                            <img className="arrow-left" src={sortDown} />
                        </div>

                        <div className={selected === 2 ? 'item item-active' : 'item'} onClick={() => { this.setState({ selected: 2 }) }}>
                            <div></div>
                            <span>{t('My allergies')}</span>
                            <img className="arrow-left" src={sortDown} />
                        </div>

                        <div className={selected === 3 ? 'item item-active' : 'item'} onClick={() => { this.setState({ selected: 3 }) }}>
                            <div></div>
                            <span>{t('My vaccinations')}</span>
                            <img className="arrow-left" src={sortDown} />
                        </div>

                        <div className={selected === 4 ? 'item item-active' : 'item'} onClick={() => { this.setState({ selected: 4 }) }}>
                            <div></div>
                            <span>{t('My analyzes')}</span>
                            <img className="arrow-left" src={sortDown} />
                        </div>

                        <div style={patient ? {} : { display: 'none' }} className={selected === 5 ? 'item item-active' : 'item'} onClick={() => { this.setState({ selected: 5 }) }}>
                            <div></div>
                            <span>{t('My notes')}</span>
                            <img className="arrow-left" src={sortDown} />
                        </div>

                        <div className={selected === 6 ? 'item item-active' : 'item'} onClick={() => { this.setState({ selected: 6 }) }}>
                            <div></div>
                            <span>{t('My consultations history')}</span>
                            <img className="arrow-left" src={sortDown} />
                        </div>

                        <div style={patient ? {} : { display: 'none' }} className={selected === 7 ? 'item item-active' : 'item'} onClick={() => { this.setState({ selected: 7 }) }}>
                            <div></div>
                            <span>{t('Access for doctors')}</span>
                            <img className="arrow-left" src={sortDown} />
                        </div>

                        <div className={'item'} onClick={() => history.push('/my-indicators' + (patient ? '' : '?patient=' + fromDocItems.patient.ID))}>
                            <div></div>
                            <span>{t('My indicators')}</span>
                            <img className="arrow-left" src={sortDown} />
                        </div>

                    </div>

                    <div className="right-block">
                        {renderItem()}

                    </div>

                </div>
            </div >

        );
    }
}

const mapStateToProps = (state: RootState) => ({
    auth: state.auth,
    patientDoctorAccess: state.patientDoctorAccess,
    loading: state.loading,
});

const mapDispatchToProps = {
    getPatientDoctorAccessList,
};

let MedicalCardComponentContainer = connect(mapStateToProps, mapDispatchToProps)(
    withTranslation()(MedicalCardComponent)
);

export default MedicalCardComponentContainer