import {ISchedulingItemsState} from "../states/scheduling-items-state";
import {
    DELETE_SCHEDULING_ITEM,
    SAVE_SCHEDULING_ITEM,
    SCHEDULING_ITEMS_LIST,
    SAVE_MULTIPLE_SCHEDULING_ITEM,
    SchedulingItemsType
} from "../actions/scheduling-item-actions";
import { CALL_RECORD, SchedulingItemRecordsType, SCHEDULING_ITEM_RECORD } from "../actions/scheduling-item-record-actions";

const initialState: ISchedulingItemsState = {
    schedulingItemsList: undefined,
    schedulingItemsUpdate: 0
}

export function schedulingItemsReducer(
    state = initialState,
    action: SchedulingItemsType | SchedulingItemRecordsType
): ISchedulingItemsState {
    switch (action.type) {
        case SCHEDULING_ITEM_RECORD:
            console.warn('SCHEDULING_ITEM_RECORD');
            // if (!state.schedulingItemsList) {
            //     return state;
            // }
            // const schedulingItemsList = [];
            // for (const item of state.schedulingItemsList) {
            //     schedulingItemsList.push({...item})
            // }
            // return {...state,...{schedulingItemsList}};
            return state;
        case SCHEDULING_ITEMS_LIST:
            return {...state,...{schedulingItemsList: action.schedulingItemsList, schedulingItemsUpdate: state.schedulingItemsUpdate + 1}};
        case SAVE_SCHEDULING_ITEM:
             return state;
        case SAVE_MULTIPLE_SCHEDULING_ITEM:
            return {
                schedulingItemsList: state.schedulingItemsList?.concat(action.schedulingItemsList) || action.schedulingItemsList, 
                schedulingItemsUpdate: state.schedulingItemsUpdate + 1}
        case DELETE_SCHEDULING_ITEM:
            const list = state.schedulingItemsList && state.schedulingItemsList.length ?
                state.schedulingItemsList.filter((el) => el.id !== action.item.id) : undefined;
            return {...state,...{schedulingItemsList: list, schedulingItemsUpdate: state.schedulingItemsUpdate + 1}};
        default:
            return state;
    }
}
