import React from 'react';
import { RootState } from "./../../store";
import { connect } from "react-redux";
import * as env from '../../app.json';
import FileBase64 from 'react-file-base64';
import { WrappedFieldProps } from "redux-form";
import { _saveFiles, _saveUploadedFiles, _deleteFiles, deleteFile } from '../../actions/file-action';
import { IFile } from "../../models/file";
import { IFileState } from "../../states/file-state";
import { IAuthState } from "../../states/auth-state";
import './FilesComponent.scss';
import AttachmentIcon from '@material-ui/icons/Attachment';
import ClearIcon from '@material-ui/icons/Clear';
import * as UUID from 'uuid';

export interface CustomFieldProps {
    label?: string;
    name: string;
    file: IFileState;
    files: IFile[];
    type: string;
    auth: IAuthState;
    separate?: boolean;
    placeholder?: string;
    text: string;
    doctorRegistration: boolean;
    sizeLimit: number;
    onClose?: (id: number | undefined) => void;
    _saveFiles: (files: IFile[], type?: string) => void;
    _saveUploadedFiles: (files: IFile[], type?: string) => void;
    _deleteFiles: (id: number, type?: string) => void;
    deleteFile: (token: string, id: number, type?: string) => void;
}

export interface State {
    id: number;
    files: any;

    uuid: string;
}

class FilesComponent extends React.Component<WrappedFieldProps & CustomFieldProps, State> {

    constructor(props: any) {
        super(props);

        this.state = {
            files: [],
            id: 0,
            uuid: '1',
        };
    }

    file() {
        return this.props.separate ? this.props.file.separate[this.props.type] || {} : this.props.file;
    }

    type() {
        return this.props.separate ? this.props.type : undefined;
    }

    componentDidMount() {
        if (this.props.files) {
            this.props._saveUploadedFiles(this.props.files, this.type())
        }
        else this.props._saveFiles([], this.type());
    }

    componentWillUnmount() {
        this.props._saveFiles([], this.type())
    }

    getFiles(files: any) {

        if (this.props.doctorRegistration) {
            this.props._saveFiles([], this.type());
            this.setState({ files: [] });
        }

        const base64 = files.base64;
        const buffer = Buffer.from(base64.substring(base64.indexOf(',') + 1));
        this.setState({ id: this.state.id + 1, files })
        console.log('files component files', files);

        const extension = (files.type)?.split('/')[1];
        const file = {
            ID: this.state.id,
            uri: files.uri,
            Filename: files.fileName || new Date().getTime().toString() + '.' + extension,
            uploadFile: base64,
            extension: extension!,
            Title: 'title',
            size: buffer.length,
        }

        //const filesLocal = this.file().files;
        const filesToSave = [file];

        this.props._saveFiles(filesToSave, this.type());

        return this.props.input.onChange(filesToSave);
    }

    render() {
        const { uuid } = this.state;
        const { input: { onChange, value }, label, name, placeholder, onClose, meta: { error, touched, valid }, doctorRegistration } = this.props;

        console.log('files component state', this.state.files.name);
        console.log('files component props', this.props);
        let filename = this.state.files.name;
        if (!filename && value && !Array.isArray(value)) {
            filename = value;
        }

        return (
            <div className={'mini-text-input-component reg-input-styles'}>
                    <p>{label}</p>
                    <div className='mini-text-input-component__file-input-imitation'>
                        <span>{filename  || placeholder}</span>
                        {value && !doctorRegistration ? <ClearIcon htmlColor='#03a8f5'/> : <AttachmentIcon htmlColor='#03a8f5' fontSize='large'/> }
                        <div>
                            <FileBase64
                                key={uuid}
                                onDone={this.getFiles.bind(this)}
                                value={value}
                            />
                            {value && !doctorRegistration &&
                                <span
                                    onClick={() => {
                                        this.props._saveFiles([], undefined);
                                        this.setState({ files: [], uuid: UUID.v4() });
                                        this.props.input.onChange([]);
                                        this.props.input.value = '';
                                    }}
                                ></span>
                            }
                        </div>
                    </div>
                    {!valid && touched ? <p className='mini-text-input-component__error offset32'>{error}</p> : null}
            </div >
        )
    }
}

const mapStateToProps = (state: RootState) => ({
    auth: state.auth,
    file: state.file,
});

const mapDispatchToProps = {
    _saveFiles,
    _saveUploadedFiles,
    _deleteFiles,
    deleteFile,
}

let FilesComponentContainer = connect(mapStateToProps, mapDispatchToProps)(FilesComponent);

export default FilesComponentContainer;