import React from "react";
import { RootState } from "../store";
import { reduxForm, InjectedFormProps, Field, FormErrors } from "redux-form";
import { connect } from "react-redux";
import { WithTranslation, withTranslation } from "react-i18next";
import { IDoctor_Add } from "../models/doctor-clinic-add";
import TextInput from "./FormComponents/TextInput";
import TextInputMask from "./FormComponents/TextInputMask";
import { TFunction } from "i18next";
import { IClinicDoctorSignUp } from "../models/clinic-doctor-sign-up";

interface Props extends InjectedFormProps<{ [key: string]: any }> {
  t: TFunction;
}

interface State {}

class CreateAccount extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  render() {
    const { t } = this.props;
    return (
      <>
        <Field name="username" placeholder={t("Login")} component={TextInput} />
        <Field
          name="password"
          placeholder={t("Password")}
          type="password"
          component={TextInput}
        />
        <Field
          name="phone"
          component={TextInputMask}
          label={t("Phone number")}
        />
        <Field
          name="email"
          placeholder={t("Email if you have")}
          component={TextInput}
        />
      </>
    );
  }
}
const loginValidator = /^\w+$/g;
const validate = (
  values: IClinicDoctorSignUp,
  props: { t: TFunction } & InjectedFormProps<IClinicDoctorSignUp>
): FormErrors<IClinicDoctorSignUp> => {
  const errors: FormErrors<IClinicDoctorSignUp> = {};
  const { t } = props;

  if (!values.username || values.username.trim() === "") {
    errors.username = t("Field couldn't be empty");
  } else if (!values.username.match(loginValidator)) {
    errors.username = "login_info";
  }
  if (!values.phone || values.phone.trim() === "") {
    errors.phone = t("Field couldn't be empty");
  }
  if (!values.password || values.password.trim() === "") {
    errors.password = t("Field couldn't be empty");
  }

  return errors;
};

const CreateAccountForm = reduxForm<IClinicDoctorSignUp, any>({
  form: "createDoctorAccount",
  validate,
})(CreateAccount);

const mapStateToProps = (state: RootState) => {};

const mapDispatchToProps = {};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(CreateAccountForm));
