import { faCaretDown, faCheckCircle as fasCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { faCheckCircle as farCheckCircle } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Fragment } from 'react';
import { IDropdownItem } from '../../models/dropdown-item';


interface DropdownProps {
    title: string;
    text?: string;
    items?: IDropdownItem[];
}

interface DropdownState {
    isActive: boolean;
    isItemActive: boolean[];
}

class VDDropdown extends React.Component<DropdownProps, DropdownState> {
    constructor(props: DropdownProps) {
        super(props);
        this.state = {
            isActive: false,
            isItemActive: new Array(this.props.items?.length).fill(false),
        }
    }

    // componentDidMount() {
    //     const { isActive } = this.state;
    //     for (let isA of isActive) {

    //     }
    // }

    render() {
        const { title, text, items } = this.props;
        return(
            <div className='vd-dropdown'>
                <div className='vd-dropdown__title' onClick={() => this.setState({isActive: !this.state.isActive})}>
                    <p>{title}</p>
                    <p className='vd-dropdown__drop'><FontAwesomeIcon size={'lg'} icon={faCaretDown} /></p>
                </div>
                {this.state.isActive && (
                    <div className='vd-dropdown__item'>
                        {this.props.items instanceof Array && this.props.items.map((item: IDropdownItem, i) => (
                            <Fragment key={i}>
                                <p
                                    onClick={() => {
                                        let tmpArr: boolean[] = this.state.isItemActive;
                                        tmpArr[i] = !tmpArr[i];
                                        this.setState({isItemActive: tmpArr});
                                    }} 
                                    className={this.state.isItemActive[i] ? 'vd-dropdown__item_title item-selected' : 'vd-dropdown__item_title'}>
                                    <FontAwesomeIcon color='#03a8f5' icon={farCheckCircle} /> {item.title}
                                </p>
                                {this.state.isItemActive[i] && 
                                    <div className='vd-dropdown__item_text' dangerouslySetInnerHTML={{__html: item.text || ''}}/>
                                }
                            </Fragment>
                        ))}
                    </div>
                )}
            </div>
        )
    }

}

export default VDDropdown;