import React from "react";
import { TFunction } from "i18next";
import { WithTranslation, withTranslation } from "react-i18next";
import { RootState } from "../../../store";
import { Link } from "react-router-dom";
import * as env from "../../../app.json";
import danger from "../../../images/danger.png";

import { connect } from "react-redux";
import { IDoctor } from "../../../models/doctor";
import { IDictionaryState } from "../../../states/dictionary-state";
import DoctorRatings from "../../Ratings/DoctorRatings";
import { IDictionary } from "../../../models/dictionary";
import { getDoctorPriceNoRedux } from "../../../actions/doctor-action";
import { IDoctorPriceFilter } from "../../../models/doctor-price-filter";
import { getDoctorList } from "../../../actions/doctor-action";
import { IDoctorFilter } from "../../../models/doctor-filter";
import { IClinic } from "../../../models/clinic";
import manat from "../../../images/manat.png";
import shield from "../../../images/shield-dis.png";
import { IDoctorPrice } from "../../../models/doctor-price";

import doctorDefImg from "./../../../images/doctor-default-img.png"

interface DiseasesProps {
  doctors: IDoctor[];
  showCount: number;
  clinics: IClinic[];
  diseasesMap: { [key: number]: IDictionary } | undefined;
  diseaseId: number;
  doctorPrice: any;
  academicDegree: IDictionary[] | undefined;
  getDoctorList: (filter: IDoctorFilter) => void;
  getDoctorPriceNoRedux: (filter: IDoctorPriceFilter) => any;
}

interface DiseasesState {
  doctorsPrice: { price: IDoctorPrice[]; doctorId: number }[];
}

class DoctorsBlockDisease extends React.Component<
  DiseasesProps & WithTranslation,
  DiseasesState
> {
  constructor(props: DiseasesProps & WithTranslation) {
    super(props);

    this.state = { doctorsPrice: [] };
  }

  async componentDidMount() {
    await this.props.getDoctorList({ disease: this.props.diseaseId });
    if (this.props.doctors.length > 0) {
      this.props.doctors.forEach((doctor) => {
        this.props.getDoctorPriceNoRedux({ doctorId: doctor.id }).then((data) =>
          this.setState((prevS) => {
            return {
              ...prevS,
              doctorsPrice: [
                ...prevS.doctorsPrice,
                { price: data, doctorId: doctor.id },
              ],
            };
          })
        );
      });
    }
  }

  async componentDidUpdate(prevProps: DiseasesProps, prevState) {
    if (prevProps.diseaseId !== this.props.diseaseId) {
      await this.setState({ doctorsPrice: [] });
      await this.props.getDoctorList({ disease: this.props.diseaseId });
      if (this.props.doctors.length > 0) {
        this.props.doctors.forEach((doctor) => {
          this.props
            .getDoctorPriceNoRedux({ doctorId: doctor.id })
            .then((data) =>
              this.setState((prevS) => {
                return {
                  ...prevS,
                  doctorsPrice: [
                    ...prevS.doctorsPrice,
                    { price: data, doctorId: doctor.id },
                  ],
                };
              })
            );
        });
      }
    }
  }
  checkPrice(doctor) {
    for (let index = 0; index < this.state.doctorsPrice.length; index++) {
      if (
        this.state.doctorsPrice[index].doctorId === doctor.id &&
        this.state.doctorsPrice[index]?.price?.length > 0
      ) {
        return (
          <div className="price-block-wrapper">
            <div className="price-block">
              {" "}
              {this.state.doctorsPrice[index].price.map((price) => (
                <div>{price.firstOnline}</div>
              ))}
            </div>
            <img height={18} src={manat} alt="AZN" />
          </div>
        );
      }
    }
  }
  findPrices(doctor) {
    for (let index = 0; index < this.state.doctorsPrice.length; index++) {
      if (
        this.state.doctorsPrice[index].doctorId === doctor.id &&
        this.state.doctorsPrice[index]?.price?.length > 0
      ) {
        return (
          <div className="shield-block">
            <img src={shield} alt="" />
            <div className="shield-block-prices">
              {this.state.doctorsPrice[index].price.map((price) => (
                <div className="shield-block-prices__main">
                  <div className="shield-block-prices__clinic">
                    {this.props.clinics?.map((stateClinic) => {
                      if (stateClinic.id === price.clinicId) {
                        return (
                          <>
                            <Link
                              className="shield-block-prices__clinic-name"
                              to={`/clinics?name=${stateClinic.name}`}
                            >
                              {stateClinic.name}
                            </Link>
                            {/* <div>{stateClinic.description}</div> */}
                          </>
                        );
                      }
                    })}
                  </div>
                  <div className="shield-block-prices__price">
                    {price.firstOffline}
                    <img height={14} src={manat} alt="AZN" />
                  </div>
                </div>
              ))}
              <div className="shield-block-info">
                <div className="shield-block-title">Стандарт приема</div>
                <div className="shield-block-context">
                  Video-doktor разработал единый стандарт первичного приема.
                  Этот стандарт гарантирует, что в стоимость приема врача входит
                  консультация, осмотр, назначение лечения или диагностики, если
                  без дополнительных исследований постановка предварительного
                  диагноза невозможна.
                </div>
                <Link
                  className="shield-block-show-more"
                  to="/reception-standards"
                >
                  Узнать подробнее
                </Link>
              </div>
            </div>
          </div>
        );
      }
    }
  }
  render() {
    const { t, showCount, diseaseId } = this.props;
    return (
      <>
        <div>
          <div className="danger-block">
            <img src={danger} alt="" />

            <p className="danger-block__title">
              {t(`If you experience similar symptoms, `)}
              <Link
                style={{ textDecoration: "none" }}
                to={`/doctors?disease=${this.props.diseasesMap &&
                  +this.props.diseasesMap[diseaseId]?.id
                  }`}
              >
                {" "}
                {t("contact your doctor immediately")}.{" "}
              </Link>
              {t(
                `It is easier to prevent a disease than to deal with the consequences.`
              )}
            </p>
          </div>

          {this.props.doctors.length > 0 ? (
            <div className="disease-doctor">
              {this.props.doctors.slice(0, showCount).map((doctor) => (
                <>
                  <div
                    key={doctor.id}
                    className="doctor-block__disease-wrapper"
                  >
                    <div className="photos-rating-block">
                      <div className="photos-rating-block__photo">
                        <img
                          src={
                            doctor?.imageFile
                              ? env.uploads + "doctors/" + doctor?.imageFile
                              : doctorDefImg
                          }
                          alt="doctor-default-img"
                        />
                      </div>

                      <div className="rating-block">
                        <DoctorRatings doctor={doctor} />
                      </div>
                    </div>

                    <div className="doctor-block__disease">
                      <div className="doctor-block__disease-exp-academic">
                        <div className="doctor-block__disease-exp">
                          {t("Experience") +
                            " " +
                            doctor.work_experience +
                            " " +
                            t("years")}
                        </div>
                        {!!doctor?.scientific_degree && (
                          <div className="doctor-block__disease-academic">
                            {this.props.academicDegree
                              ?.filter((a) => a.id == doctor?.scientific_degree)
                              .map((a) => a.name)}
                          </div>
                        )}
                      </div>

                      <div className="doctor-block__disease-name">
                        <Link
                          to={`/doctors?name=${doctor.surname}%20${doctor.name}`}
                        >
                          {doctor &&
                            doctor?.surname +
                            " " +
                            doctor?.name +
                            " " +
                            doctor?.patronymic}
                        </Link>
                      </div>

                      {doctor?.specializations &&
                        doctor?.specializations != "None" && (
                          <div className="doctor-block__disease__spec">
                            {doctor.specializations.join(", ")}
                          </div>
                        )}

                      {doctor && doctor.diseases !== "None" && (
                        <div className="doctor-block__disease__spec">
                          {doctor.diseases?.join(", ")}
                        </div>
                      )}
                      {this.checkPrice(doctor)}
                      {this.findPrices(doctor)}
                      <div className="discount">
                        {t(
                          "Exclusive discount for the first appointment at the clinic"
                        )}
                      </div>
                    </div>
                  </div>
                </>
              ))}
            </div>
          ) : null}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  doctors: state.doctor.doctors,
  academicDegree: state.dictionary.academicDegree,
  doctorPrice: state.doctor.doctorPrice,
  diseasesMap: state.dictionary?.diseasesMap,
  clinics: state.dictionary.clinics,
});

const mapDispatchToProps = {
  getDoctorList,
  getDoctorPriceNoRedux,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(DoctorsBlockDisease));
