import { IDiseaseInfo } from "./../models/disease-info";
import {
  DiseaseInfoActionTypes,
  DISEASE_INFORMATION,
} from "../actions/disease-information-actions";
import { IDiseaseInfoState } from "../states/disease-info-state";

const initialState: IDiseaseInfoState = {
  diseaseInfo: {
    id: undefined,
    descriptionEn: undefined,
    descriptionAz: undefined,
    descriptionRu: undefined,
    reasonsAz: undefined,
    reasonsEn: undefined,
    reasonsRu: undefined,
    diagnosticsAz: undefined,
    diagnosticsRu: undefined,
    diagnosticsEn: undefined,
    treatmentAz: undefined,
    treatmentRu: undefined,
    treatmentEn: undefined,
    dangerRu: undefined,
    dangerEn: undefined,
    dangerAz: undefined,
    preventionAz: undefined,
    preventionRu: undefined,
    preventionEn: undefined,
    symptomsRu: undefined,
    symptomsEn: undefined,
    symptomsAz: undefined,
    groupOfRiskEn: undefined,
    groupOfRiskRu: undefined,
    groupOfRiskAz: undefined,
  },
};

export function diseaseInformation(
  state = initialState,
  action: DiseaseInfoActionTypes
): IDiseaseInfoState {
  switch (action.type) {
    case DISEASE_INFORMATION:
      return { ...state, ...{ diseaseInfo: action.information } };
    default:
      return state;
  }
}
