import {IClinicState} from "../states/clinic-state";
import {CLINIC, CLINIC_LIST, ClinicActionTypes, CLINICS_PAGE_SIZE} from "../actions/clinic-action";

const initialState: IClinicState = {
    clinics: [],
    totalCount: 0,
    clinic: undefined,
    page: 1,
    final: false,
}

export function clinicReducer(
    state = initialState,
    action: ClinicActionTypes
): IClinicState {
    switch (action.type) {
        case CLINIC_LIST:
            console.log('total', action.total);
            const clinics = action.clinics;
            return {...state, ...{clinics: clinics, totalCount: action.total, final: action.clinics?.length < CLINICS_PAGE_SIZE || action.end, page: action.page + 1}};
        case CLINIC:
            return {...state, ...{clinic: action.clinic}};
        default:
            return state;
    }
}
