import { GET_STATISTICS, StatisticsAction } from '../actions/statistics-actions';
import { IStatistics } from '../states/statistics';

const initialState: IStatistics = {
    totalClinics: 0,
    totalDoctors: 0,
    totalRecords: 0,
    totalReviews: 0
}

export function statisticReducer(state: IStatistics = initialState, action: StatisticsAction) {
    switch(action.type) {
        case GET_STATISTICS:
            return {
                ...state,
                ...action.statistics
            }
        default: return state;
    }
}