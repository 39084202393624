import React from 'react';
import { connect } from 'react-redux';
import { Field, InjectedFormProps, reduxForm, formValueSelector } from 'redux-form';
import { IDutyState } from '../states/duty-state';
import { RootState } from '../store';
import { ISchedulingItem } from '../models/scheduling-item';
import TextInput from './FormComponents/TextInput';
import ChexBox from './FormComponents/CheckBox';
import { TFunction } from 'i18next';
import { withTranslation } from 'react-i18next';
import moment from 'moment';

interface DutyProps extends InjectedFormProps<ISchedulingItem> {
    t: TFunction;
    type: number;
    duty: IDutyState;
}

class DoctorDutyForm extends React.Component<DutyProps> {
    constructor(props: DutyProps) {
        super(props);
        this.state = {
        };
    }

    componentDidMount() { 
        console.log(this.props.duty.item); 
        this.props.duty.item && this.props.initialize(
            {...this.props.duty.item, 
                start: moment(this.props.duty.item.start).format("HH:mm"),
                end: moment(this.props.duty.item.end).format("HH:mm")
            });
    }

    render() {
        const { t, duty } = this.props;
        return (
            <div style={{ padding: '30px 0 30px 0' }}>
                <Field
                    name="onDuty"
                    placeholder={t('On duty')}
                    component={ChexBox}
                />

                <Field
                    name="start"
                    type='time'
                    style={{
                        width: '70%',
                        marginBottom: '10px',
                        paddingLeft: '5px',
                    }}
                    placeholder={t('Duty time start')}
                    component={TextInput}
                />

                <Field
                    name="end"
                    type='time'
                    style={{
                        width: '70%',
                        marginBottom: '10px',
                        paddingLeft: '5px',
                    }}
                    placeholder={t('Time end')}
                    component={TextInput}
                />
            </div>
        );
    }
}

const validate = (values: any, props: InjectedFormProps<any>): any => {

    console.log('values', values);
};

const form = reduxForm<ISchedulingItem, any>({
    form: 'DoctorDutyForm',
    validate,
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
})(DoctorDutyForm);

const selector = formValueSelector('DoctorDutyForm');
const mapStateToProps = (state: RootState) => {
    const type = selector(state, 'type') || null;
    console.log('doctor duty form', state)
    return {
        duty: state.duty,
        type
    }
};

export default connect(mapStateToProps)(withTranslation()(form));