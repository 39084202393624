export interface IIndicatorValueFilter {
    indicatorLineCodes: number[],
    userId: number;
    from?: Date,
    to?: Date,
    groupType: GroupType,
}

export enum GroupType
{
    None = 0, Day = 1, Month = 2
}