import React, { Component } from "react";
import { IStatistics } from "../../states/statistics";
import { IAuthState } from "../../states/auth-state";
import { WithTranslation, withTranslation } from "react-i18next";
import { Link, RouteComponentProps } from "react-router-dom";
import {
  Link as ScrollLink,
  Element,
  animateScroll as scroll,
  scroller,
} from "react-scroll";
import { getStatistics } from "../../actions/statistics-actions";
import "./Landing.scss";
import BackBlock from "./backBlok/block";
import { connect } from "react-redux";
import InfoBlock from "./infoBlock/infoBlock";
import Diseases from "../Diseases/Diseases";
import LineAlphabet from "./lineAlphabet/LineAlphabet";
import { getDoctorsPostsList } from "../../actions/doctors-posts-actions";
import { RootState } from "../../store";
import { IDoctorPosts } from "../../models/doctors-posts";
import Nav from "../NewNav/Nav";
import Counter from "../Counter/Counter";
import MobileBlock from "../MobileBlock/MobileBlock";
import history from "../../history";

import figma1 from "./../../images/figma1.png";
import figma2 from "./../../images/figma2.png";
import figma3 from "./../../images/figma3.png";
import figma4 from "./../../images/figma4.png";
import figma5 from "./../../images/figma5.png";
import figma6 from "./../../images/figma6.png";
import figma7 from "./../../images/figma7.png";
import figma8 from "./../../images/figma8.png";
import figma9 from "./../../images/figma9.png";
import figma11 from "./../../images/figma11.png";
import aptekSvg from "./../../images/aptek.svg";
import onlineDiscussion from "./../../images/online-discussion.jpg";

interface MyProps {
  auth: IAuthState;
  statistics: IStatistics;
  language: string;
  getDoctorsPostsList: () => void;
  getStatistics: () => void;
  posts: IDoctorPosts[];
}

interface MyState {
  showDisease: boolean;
}

class Lending extends Component<
  MyProps & WithTranslation & RouteComponentProps,
  MyState
> {
  mobileAppBlock: HTMLDivElement | null | undefined;

  constructor(props: MyProps & WithTranslation & RouteComponentProps) {
    super(props);
    this.state = {
      showDisease: false,
    };
  }

  componentDidMount() {
    this.props.getStatistics();
    this.props.getDoctorsPostsList();
  }

  showDisaseHandler() {
    this.setState({ showDisease: !this.state.showDisease });
  }

  checkLink() {
    if (this.props.auth?.userToken?.role === 2) {
      return "/personal-cabinet-doctor";
    }
    if (this.props.auth?.userToken?.role === 3) {
      return "/medical-card";
    }
    if (this.props.auth.userToken?.role === 1) {
      return "/personal-cabinet-clinic";
    }
  }

  render() {
    const { t, language } = this.props;
    console.log(this.props.posts, "PostsForLand");
    let requestLang =
      language.substring(0, language.length - 1).toUpperCase() +
      language.substr(1);
    return (
      <>
        <div className="content-wrapper">
          <Nav />
          <div className="helpBlock">
            <div>
              <div className="helpBlock__title">
                <p>{t("Helping to be healthy")}</p>
              </div>
              <div className="helpBlock-slogan">
                {t(
                  "Appointment with a doctor, online consultations and other medical services for the health of your family"
                )}
              </div>
            </div>
            <div>
              <img src={figma1} alt="logo" />
            </div>
          </div>
        </div>

        <div className="content-wrapper">
          <div className="bestDoctors-wrapper">
            <div className="bestDoctors">
              <div className="bestDoctors-block">
                <div className="bestDoctors-title">
                  {t("The best doctors on our site")}
                </div>
                <div className="bestDoctors-slogan">
                  {t(
                    "Find out more on our website! All reviews and ratings in one place."
                  )}
                </div>
                <Link className="greyButton" to="/doctors">
                  {t("Go to the site")}
                </Link>
              </div>
              <div className="bestDoctors-image">
                <img src={figma2} alt="" />
              </div>
            </div>
          </div>
          <div className="content-wrapper">
            <div className="title">{t("Medical services")}</div>
          </div>
          <div className="medical-service">
            <BackBlock
              link="/doctors"
              fontSize="20px"
              button={t("Select doctor")}
              title={t("Doctor's appointment at the clinic")}
              slogan={t(
                "Doctors with verified reviews in any area of the city and quick appointment at the right time"
              )}
              imgUrl={figma3}
              background="linear-gradient(45deg, #91BCE6, #EBFCFD)"
            />
            <BackBlock
              link="/doctors"
              fontSize="20px"
              button={t("Make an appointment")}
              title={t("Online Consultation")}
              slogan={t(
                "Medical consultations 24/7, faster and cheaper than in a hospital"
              )}
              imgUrl={figma4}
              background="linear-gradient(45deg, #C0EAF3, #C0EAF3)"
            />
          </div>
          <div className="medical-service">
            <BackBlock
              display="grid"
              fontSize="15px"
              button=""
              title={t("Solutions for corporate clients")}
              slogan=""
              imgUrl={figma5}
              background="linear-gradient(45deg, #91DFE6, #EBFCFD)"
            />

            <BackBlock
              //there i need help with optimization
              link={
                this.props.auth?.userToken?.auth
                  ? this.checkLink()
                  : "https://shefakartim.az/"
              }
              display="flex"
              altImg={t("My Medical Chart")}
              flexDirection="column"
              //   alignItems="center"
              fontSize="15px"
              button={t("My Medical Chart")}
              title={t("My Medical Chart")}
              slogan={t("A single space for medical data")}
              imgUrl={figma6}
              background="linear-gradient(45deg, #91BCE6, #EBFCFD)"
            />

            <BackBlock
              fontSize="15px"
              button=""
              title={t("Diagnostics")}
              slogan={t("MRI, CT, ultrasound, echocardiography, X-ray")}
              imgUrl={figma7}
              background="linear-gradient(45deg, #D6E9FB, #D6E9FB)"
            />
          </div>
          <div className="medical-service">
            <BackBlock
              link={
                this.props.auth?.userToken?.role === 3
                  ? "/my-indicators"
                  : "https://shefakartim.az/"
              }
              fontSize="15px"
              button={t("Go")}
              title={t("My indicators")}
              slogan={t("A single space for medical data")}
              imgUrl={figma8}
              background="linear-gradient(45deg,#B3E0DC, #B3E0DC)"
            />
            <BackBlock
              display="grid"
              fontSize="15px"
              button=""
              title={t("Services")}
              slogan={t("Treatments and cures")}
              imgUrl={figma9}
              background="linear-gradient(45deg, #D7E6F1, #D7E6F1)"
            />
            <BackBlock
              link="http://drugs-staging.zirinc.com/"
              fontSize="15px"
              button={t("Go")}
              title={t("Dərmanlarım.az")}
              slogan={t("Delivery of health products")}
              imgUrl={figma11}
              background="linear-gradient(45deg, #91DFE6, #EBFCFD)"
            />
            <BackBlock
              // link="http://drugs-staging.zirinc.com/"
              fontSize="15px"
              button={t("Go")}
              title={t("E-pharmacy")}
              slogan={t("Delivery of health products")}
              imgUrl={aptekSvg}
              background="linear-gradient(45deg, #91DFE6, #EBFCFD)"
            />
          </div>

          <div className="counter-block-wrapper">
            <div className="title"> {t("We care about your health")}</div>
            <div className="title-info">
              {t(
                "The service began operating in 2020 under the VideoDoc brand. We have helped millions of people to get help and we continue to improve the quality of medical services."
              )}
            </div>
            <Counter />
          </div>
          <div className="title">{t("We write about the important")}</div>

          <div className="info-collector">
            {this.props.posts.map((p) => {
              let title = p?.["title" + requestLang];
              let content = p?.["content" + requestLang];
              console.log(p, "drop");
              let background;

              if (p.id && p.id % 2) {
                background = "linear-gradient(320deg, #F5FF9A, #91DFE6)";
              } else {
                background = "linear-gradient(166deg, #9ADDE9, #2290FF)";
              }
              return (
                // <InfoBlock
                //   maxWidth="290px"
                //   key={p.id}
                //   background={background}
                //   title={title}
                //   info={content}
                //   date={t(p.date)}
                // />
                <></>
              );
            })}
            <a
              className="post-block__chanel"
              href="https://www.mdjournaltv.az/"
            >
              <img className="post-block__chanel-image" src={onlineDiscussion} alt="Online Discussion"
                width={290}
              />

              {/*<InfoBlock*/}
              {/*  href=""*/}
              {/*  title=""*/}
              {/*  border="1px solid #CACACA"*/}
              {/*  maxWidth="290px"*/}
              {/*  info={t(`More articles on our VideoDoktor channel`)}*/}
              {/*/>*/}
            </a>

          </div>

          <div className="title">{t("Directory of diseases")}</div>
          <LineAlphabet />
          <div className="content-block">
            <div
              style={{ display: "flex" }}
              className={
                this.state.showDisease ? "alphabet opened" : "alphabet"
              }
            >
              <Diseases />
              {!this.state.showDisease && <div className="gradient" />}
            </div>
            <div className="btn-home-alphabet-wrapper">
              <button
                onClick={this.showDisaseHandler.bind(this)}
                className="blue-btn btn btn-primary"
              >
                {this.state.showDisease ? t("Minimize") : t("Show more")}
              </button>
            </div>
          </div>

          <Element name="mobile-block">
            <div
              className="content-block"
              id="mobile-app-block"
              ref={(ref) => (this.mobileAppBlock = ref)}
            >
              <MobileBlock />
            </div>
          </Element>
        </div>
      </>
    );
  }
}
const mapStateToProps = (state: RootState) => ({
  form: state.form,
  auth: state.auth,
  statistics: state.statistics,
  language: state.settings.language,
  posts: state.posts.posts,
});

const mapDispatchToProps = {
  getStatistics,
  getDoctorsPostsList,
};

let LendingComponentContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(Lending));

export default LendingComponentContainer;
