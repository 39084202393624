import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import Download from '../Download/Download';
import './MobileHome.scss';

import logo from './../../images/logo.png'
import mobile from './../../images/mobile.png'
import appStore from './../../images/appstore.png'
import playMarket from './../../images/playmarket.png'
import sn from './../../images/sn.png'
import isim from './../../images/isim.png'
import undp from './../../images/undp.png'
import ezgil from './../../images/ezgil.png'
interface MyProps extends WithTranslation {
}

interface MyState {
}

class MobileHomeComponent extends Component<MyProps, MyState> {

    constructor(props: MyProps) {
        super(props);
        this.state = {
        };
        let language = (window.navigator as any)?.userLanguage?.substring(0, 2) || window.navigator?.language?.substring(0, 2);
        if (language !== 'az' && language !== 'en' && language !== 'ru') {
            language = 'az';
        }
        const currentLang = localStorage.getItem('az.ezgil.videodoctor.language') || language;
        props.i18n?.changeLanguage(currentLang);
    }

    render() {
        const { t } = this.props;
        return (
            <Router>
                <Route path='/download' component={Download} />
                <Route>
                    <header className='mobile-home_header'>
                        <img className='mobile-home_header_logo' src={logo} alt='logo' />
                        <span className='mobile-home_header_text'>VideoDoktor</span>
                    </header>
                    <div className='mobile-home_main'>
                        <div>
                            <h5>Скачивайте наше мобильное приложение<br />VideoDoktor</h5>
                            <span>Проверенные врачи у вас в телефоне</span>
                        </div>
                        <img className='mobile-home_main_image' src={mobile} />
                    </div>
                    <div className='mobile-home_container'>
                        <div className='mobile-home_container_appstore'>
                            <a href='https://apps.apple.com/ru/app/video-doktor/id1520369601' target='__blank' ><img src={appStore} /></a>
                        </div>
                        <div className='mobile-home_container_playmarket'>
                            <a href='https://play.google.com/store/apps/details?id=az.ezgil.videodoctor' target='__blank'><img src={playMarket} /></a>
                        </div>
                    </div>
                    <div className='mobile-home_container'>
                        <div className='mobile-home_container_contacts'>
                            <div>{t('Call us')}:</div>
                            <div style={{ width: '100%' }}>(+994)51-206-38-13<br />(+994)51-206-38-13</div>
                        </div>
                        <div className='mobile-home_container_contacts'>
                            <div>{t('Write us')}:</div>
                            <div style={{ width: '100%' }}>ezgilmedical@gmail.com<br />info@ezgil.az</div>
                        </div>
                    </div>
                    <div className='mobile-home_partners'>
                        <img className='mobile-home_partners_sn' src={sn} />
                        <img className='mobile-home_partners_isim' src={isim} />
                        <img className='mobile-home_partners_undp' src={undp} />
                    </div>
                    <div className='mobile-home_footer'>
                        <img src={ezgil} />
                    </div>
                </Route>

            </Router>
        )
    }
}


export default withTranslation()(MobileHomeComponent)