import { ThunkAction } from "redux-thunk";
import { Action, ActionCreator } from "redux";
import { loadingStart, loadingEnd } from "./loading-actions";
import { LoadingSubject } from "../states/loading-state";
import i18n from 'i18next';
import * as environment from '../app.json';
import { pushAlert } from "./alert-actions";
import { signOut } from "./auth-actions";
import { IClinicComment } from "../models/clinic-comment";
import { IDoctorComment } from "../models/doctor-comment";
import { IDoctorRating } from "../models/doctor-rating";
import { IClinicRating } from "../models/clinic-rating";
import { getDoctor } from "./doctor-action";
import history from "./../history";

function topDoctorData() {
    let topDoctor = environment.topDoctor;
    if (i18n.language == 'az') topDoctor = environment.topDoctorAz;
    if (i18n.language == 'ru') topDoctor = environment.topDoctorRu;
    return topDoctor;
}


export const DOCTOR_SELF_RATING = 'DOCTOR_SELF_RATING';
interface DoctorSelfRatingAction {
    type: typeof DOCTOR_SELF_RATING;
    rating: IDoctorRating;
}

export const _doctorSelfRating: ActionCreator<DoctorSelfRatingAction> = (rating: IDoctorRating) => {
    return {
        type: DOCTOR_SELF_RATING,
        rating
    };
};

export const CLINIC_SELF_RATING = 'CLINIC_SELF_RATING';
interface ClinicSelfRatingAction {
    type: typeof CLINIC_SELF_RATING;
    rating: IClinicRating;
}

export const _clinicSelfRating: ActionCreator<ClinicSelfRatingAction> = (rating: IClinicRating) => {
    return {
        type: CLINIC_SELF_RATING,
        rating
    };
};

export type RatingActionTypes = ClinicSelfRatingAction | DoctorSelfRatingAction;


export const createClinicComment = (
    comment: IClinicComment,
    token: string,
): ThunkAction<void, {}, unknown, Action<string>> => async dispatch => {
    dispatch(loadingStart(LoadingSubject.CreateClinicComment));

    try {
        const response = await fetch(topDoctorData() + '/comments/cliniccreate' + '?token=' + encodeURIComponent(token), {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(comment)
        });

        console.log('response', response);

        if (response.status === 200) {
            const json = await response.json();
            console.log('json', json);
            // TODO: call action
        }
        else if (response.status === 401) {
            // dispatch(signOut(token));
        }
        else {
            dispatch(pushAlert('Unknown error. Please, check internet connection'));
            //dispatch(errorPatientDoctorList(json.code, token));
        }
    } catch (e) {
        dispatch(pushAlert('Unknown error. Please, check internet connection'));
    } finally {
        dispatch(loadingEnd(LoadingSubject.CreateClinicComment));
    }
}

export const createDoctorComment = (
    comment: IDoctorComment,
    token: string,
): ThunkAction<void, {}, unknown, Action<string>> => async dispatch => {
    dispatch(loadingStart(LoadingSubject.CreateDoctorComment));

    try {
        const response = await fetch(topDoctorData() + '/comments/doctorcreate' + '?token=' + encodeURIComponent(token), {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(comment)
        });

        console.log('response', response);

        if (response.status === 200) {
            const json = await response.json();
            console.log('json', json);
            // TODO: call action
        }
        else if (response.status === 401) {
            // dispatch(signOut(token));
        }
        else {
            dispatch(pushAlert('Unknown error. Please, check internet connection'));
            //dispatch(errorPatientDoctorList(json.code, token));
        }
    } catch (e) {
        dispatch(pushAlert('Unknown error. Please, check internet connection'));
    } finally {
        dispatch(loadingEnd(LoadingSubject.CreateDoctorComment));
    }
}


export const createClinicRating = (
    rating: IClinicRating,
    id: number,
    token: string,
): ThunkAction<void, {}, unknown, Action<string>> => async dispatch => {
    dispatch(loadingStart(LoadingSubject.CreateClinicRating));

    try {
        const response = await fetch(topDoctorData() + 'clinicratings?id=' + id + '&token=' + encodeURIComponent(token), {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(rating)
        });

        console.log('response', response);

        if (response.status === 200) {
            const json = await response.json();
            console.log('json', json);
            dispatch(_clinicSelfRating(rating));
            history.go(0);
            // TODO: call action
            dispatch(pushAlert('Your rating successfully saved!'));
        }
        else if (response.status === 401) {
            // dispatch(signOut(token));
        }
        else {
            dispatch(pushAlert('Unknown error. Please, check internet connection'));
            //dispatch(errorPatientDoctorList(json.code, token));
        }
    } catch (e) {
        dispatch(pushAlert('Unknown error. Please, check internet connection'));
    } finally {
        dispatch(loadingEnd(LoadingSubject.CreateClinicRating));
    }
}

export const createDoctorRating = (
    rating: IDoctorRating,
    id: number,
    token: string,
): ThunkAction<void, {}, unknown, Action<string>> => async dispatch => {
    dispatch(loadingStart(LoadingSubject.CreateDoctorRating));

    try {
        const response = await fetch(topDoctorData() + 'doctorratings?id=' + id + '&token=' + encodeURIComponent(token), {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(rating)
        });

        console.log('response', response);

        if (response.status === 200) {
            const json = await response.json();
            console.log('json', json);
            dispatch(_doctorSelfRating(rating));
            dispatch<any>(getDoctor(id));
            // TODO: call action
            dispatch(pushAlert('Your rating successfully saved!'));
        }
        else if (response.status === 401) {
            // dispatch(signOut(token));
        }
        else {
            console.log(await response.text());
            dispatch(pushAlert('Unknown error. Please, check internet connection'));
            //dispatch(errorPatientDoctorList(json.code, token));
        }
    } catch (e) {
        dispatch(pushAlert('Unknown error. Please, check internet connection'));
    } finally {
        dispatch(loadingEnd(LoadingSubject.CreateDoctorRating));
    }
}

export const getDoctorSelfRating = (
    id: number,
    token: string,
): ThunkAction<void, {}, unknown, Action<string>> => async dispatch => {
    dispatch(loadingStart(LoadingSubject.CreateDoctorRating));

    try {
        const response = await fetch(topDoctorData() + 'doctors/' + id + '?token=' + encodeURIComponent(token), {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        });

        console.log('response', response);

        if (response.status === 200) {
            const json = await response.json();
            console.log('json', json.full_rating);
            dispatch(_doctorSelfRating(json.full_rating));
            // TODO: call action
        }
        else if (response.status === 401) {
            // dispatch(signOut(token));
        }
        else {
            console.log(await response.text());
            dispatch(pushAlert('Unknown error. Please, check internet connection'));
            //dispatch(errorPatientDoctorList(json.code, token));
        }
    } catch (e) {
        dispatch(pushAlert('Unknown error. Please, check internet connection'));
    } finally {
        dispatch(loadingEnd(LoadingSubject.CreateDoctorRating));
    }
}

export const getClinicSelfRating = (
    id: number,
    token: string,
): ThunkAction<void, {}, unknown, Action<string>> => async dispatch => {
    dispatch(loadingStart(LoadingSubject.CreateClinicRating));

    try {
        const response = await fetch(topDoctorData() + 'clinics/' + id + '?token=' + encodeURIComponent(token), {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        });

        console.log('response', response);

        if (response.status === 200) {
            const json = await response.json();
            console.log('json', json.full_rating);
            dispatch(_clinicSelfRating(json.full_rating));
            // TODO: call action
        }
        else if (response.status === 401) {
            // dispatch(signOut(token));
        }
        else {
            console.log(await response.text());
            dispatch(pushAlert('Unknown error. Please, check internet connection'));
            //dispatch(errorPatientClinicList(json.code, token));
        }
    } catch (e) {
        dispatch(pushAlert('Unknown error. Please, check internet connection'));
    } finally {
        dispatch(loadingEnd(LoadingSubject.CreateClinicRating));
    }
}