import React, { Component } from 'react';
import { connect } from "react-redux";
import { RootState } from "../../../store";
import './MyNotes.scss';
import { IAuthState } from "../../../states/auth-state";
import { submit, reset, FormAction, change } from 'redux-form';
import { IDictionaryState } from "../../../states/dictionary-state";
import { IPatientNoteState } from "../../../states/patient-note-state";
import { getPatientNoteList, deletePatientNote } from "../../../actions/patient-note-actions";
import Modal from 'react-bootstrap/Modal'
import UserNotesForm from '../../../forms/user-notes-form';
import { IPatientNote } from "../../../models/user-note";
import { _patientNoteItem, getPatientNote, createPatientNote, updatePatientNote } from "../../../actions/patient-note-actions";
import { TFunction } from 'i18next';
import { withTranslation } from 'react-i18next';
import { showAlert, showConfirm } from "../../Dialogs";
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { LoadingCenter } from "../../Loading/Loading";
import { ILoadingState, LoadingSubject } from '../../../states/loading-state';
import SaveButton from './../SaveButton/SaveButton';
import myNotes from "./../../../images/medical-card/md_items_icons/my_notes.png"
const backgroundImageStyle = {
    backgroundImage: `url(${myNotes})`
};
interface MyProps {
    t: TFunction;
    auth: IAuthState;
    submit: (form: string) => FormAction;
    reset: (form: string) => FormAction;
    change: (form: string, field: string, value: any, touch?: boolean, persistentSubmitErrors?: boolean) => FormAction;
    dictionary: IDictionaryState;
    patientNote: IPatientNoteState
    getPatientNoteList: () => void;
    deletePatientNote: (id: number) => void;
    onSubmit?: (data: IPatientNote) => void;
    createPatientNote: (note: IPatientNote, success: () => void) => void;
    updatePatientNote: (note: IPatientNote, success: () => void) => void;
    getPatientNote: (id: number) => void;
    _patientNoteItem: (note: IPatientNote | null) => void;
    loading: ILoadingState;
}

interface MyState {
    show: boolean;
    updateItem: boolean;
    removeMode: {} | null;
}

class MyNotesComponent extends Component<MyProps, MyState> {

    constructor(props: MyProps) {
        super(props);

        this.state = {
            show: false,
            updateItem: false,
            removeMode: null,
        };
    }

    componentDidMount() {
        this.props.getPatientNoteList();
    }

    onSubmit(data: IPatientNote) {
        if (this.state.updateItem) {
            this.props.updatePatientNote(data, this.handleClose);
        } else {
            this.props.createPatientNote(data, this.handleClose);
        }
    }

    async confirmDelete() {
        const { t } = this.props;

        if (await showConfirm(t("Are you sure you want to delete this item/items?"))) {
            for (const index in this.state.removeMode) {
                if (this.state.removeMode[index]) {
                    let item: any;
                    if (this.props.patientNote && this.props.patientNote.list) {
                        item = this.props.patientNote.list[index];
                    }
                    if (item) {
                        await this.props.deletePatientNote(item.id);
                    }
                }
            }
            this.setState({ removeMode: null });
        }
    }

    handleClose = () => {
        this.setState({ show: false })
        this.props._patientNoteItem(null);
    };

    handleShow = () => { this.setState({ show: true }) };

    render() {
        const { t } = this.props;
        const itemsList = this.props.patientNote.list;
        const patientNoteLoading = this.props.loading.subjects.has(LoadingSubject.PatientNote);

        return (
            <div className='notes_wrapper'>
                <Modal centered show={this.state.show} onHide={this.handleClose}>
                    <Modal.Header className='my-meds-modal-header' closeButton>
                        <Modal.Title>{this.state.updateItem ? t('Change note') : t('New note')}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <UserNotesForm onSubmit={(data: any) => this.onSubmit(data)} />

                        <SaveButton text={t('Save')}
                            loading={patientNoteLoading}
                            onClickFunc={() => this.props.submit('UserNotesForm')}
                        />
                    </Modal.Body>
                </Modal>

                <div className='medical_card_item_header'>
                    <div className='md_item_title_image'>
                        <div style={backgroundImageStyle}></div>
                        <h1>{t('My notes')}</h1>
                    </div>
                    <div className='button_blck'>
                        <div className='show-more-btn'
                            onClick={() => {
                                this.setState({ updateItem: false })
                                this.props.reset('UserNotesForm')
                                this.handleShow();
                            }}
                        >
                            <p className='show-more-btn-title'>{t('Add')}</p>
                        </div>
                    </div>
                </div>

                <div className='medical_card_item_desc'>
                    <p>{t('In the "My Notes" section you will be able to add your notes, any additional information that may be useful to medical professionals who provide you with medical care.')}</p>
                </div>

                <div className='medical_card_item_data_block'>
                    <p className='medical_card_item_title'>{this.props.t('All data')}</p>

                    <div className='button_blck'>
                        {!this.state.removeMode && !!itemsList?.length && <button onClick={() => this.setState({ removeMode: {} })} className='blue-btn btn btn-primary'>{t('Delete')}</button>}
                        {this.state.removeMode && <button onClick={() => this.setState({ removeMode: null })} className='blue-btn btn btn-primary cancel-btn'>{t('Cancel')}</button>}
                        {this.state.removeMode && <button onClick={() => this.confirmDelete()} className='blue-btn btn dark btn-primary'>{t('Confirm')}</button>}
                    </div>
                    <div className='table_data'>
                        <table className={this.props.auth?.userToken?.role === 3 ? 'pointer' : ''}>
                            <tr className='centerItem'>
                                <th>{this.props.t('Name')}</th>
                                <th>{this.props.t('Description')}</th>
                                <th>{this.props.t('Date')}</th>
                            </tr>
                            {itemsList instanceof Array && itemsList.map((item: any, index: any) => {
                                return (
                                    <tr onClick={async () => {
                                        if (this.state.removeMode) {
                                            return;
                                        }
                                        await this.props.getPatientNote(item.id);
                                        this.setState({ updateItem: true })
                                        this.handleShow();
                                    }}>
                                        <td>
                                            {this.state.removeMode && <div style={{ alignItems: 'center' }}>
                                                {!this.state.removeMode[index] && <RadioButtonUncheckedIcon onClick={() => this.setState({ removeMode: { ...this.state.removeMode, [`${index}`]: true } })} fontSize='small' htmlColor='#007bff' />}
                                                {this.state.removeMode[index] && <CheckCircleOutlineIcon onClick={() => this.setState({ removeMode: { ...this.state.removeMode, [`${index}`]: false } })} fontSize='small' htmlColor='#007bff' />}
                                            </div>}

                                            {item.name}
                                        </td>
                                        <td>{item.description}</td>
                                        <td>{item.date}</td>
                                    </tr>
                                )
                            })}
                        </table>
                        {this.props.loading.subjects.has(LoadingSubject.PatientNoteList) ? <div style={{ marginTop: '20px' }}><LoadingCenter /></div> :
                            !itemsList || itemsList.length === 0 && <div className='no-items-no-results'> <p>{t('No data available')}</p></div>}
                    </div>
                </div>


            </div>
        );
    }
}

const mapStateToProps = (state: RootState) => ({
    auth: state.auth,
    dictionary: state.dictionary,
    patientNote: state.patientNote,
    loading: state.loading,
});

const mapDispatchToProps = {
    submit,
    reset,
    getPatientNoteList,
    deletePatientNote,
    change,

    createPatientNote,
    updatePatientNote,
    getPatientNote,
    _patientNoteItem,
};

let MyNotesComponentContainer = connect(mapStateToProps, mapDispatchToProps)(
    withTranslation()(MyNotesComponent)
);

export default MyNotesComponentContainer