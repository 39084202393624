import { VisitType } from "./doctor-filter";

export interface ISchedulingItemRecord {
    asPatient?: boolean;
    schedulingItemId: number;
    patientId?: number;
    patientName?: string;
    end?: string;
    start?: string;
    doctorName?: string;
    doctorImage?: string;
    doctorId?: number;
    doctorDescId?: number;
    doctorSpecIds?: number[];
    status: SchedulingItemRecordStatus;
    type: SchedulingItemRecordType;
    id?: number;
    patientImage?: string;
    primary?: boolean;
    price?: number;
}

export enum SchedulingItemRecordStatus {
    Created = 0,
    Approved = 1,
    Paid = 2,
    Declined = 3,
    CanceledByDoctor = 4,
    CanceledByPatient = 5,
    Ready = 6,
    MissedByDoctor = 7,
    MissedByPatient = 8,
    FailedByDoctor = 9,
    FailedBySystem = 10,
    Success = 11,
    Initiated = 12,
    Accepted = 13
}

export enum SchedulingItemRecordType
{
    Online = 0, Offline = 1, Home = 2, Duty = 3
}