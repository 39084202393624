import { IPatientSurvey } from "../models/user-surveys";
import { ActionCreator, Action } from "redux";
import { ThunkAction } from "redux-thunk";
import { loadingStart, loadingEnd } from "./loading-actions";
import { LoadingSubject } from "../states/loading-state";
import i18n from 'i18next';
import * as environment from '../app.json';
import { pushAlert } from "./alert-actions";
import { authFetch } from "./auth-actions";
import { IFile } from "../models/file";
import { updateFiles } from "./file-action";
import { RootState } from "../store";
import { IUpload } from "../models/upload";

function topDoctorData() {
    let topDoctor = environment.topDoctor;
    if (i18n.language === 'az') topDoctor = environment.topDoctorAz;
    if (i18n.language === 'ru') topDoctor = environment.topDoctorRu;
    return topDoctor;
}

export const PATIENT_SURVEY_LIST = 'PATIENT_SURVEY_LIST';
interface PatientSurveyListAction {
    type: typeof PATIENT_SURVEY_LIST;
    surveys: IPatientSurvey[];
}

export const _patientSurveyList: ActionCreator<PatientSurveyListAction> = (surveys: IPatientSurvey[]) => {
    return {
        type: PATIENT_SURVEY_LIST,
        surveys
    };
};

export const PATIENT_SURVEY_ITEM = 'PATIENT_SURVEY_ITEM';
interface PatientSurveyItemAction {
    type: typeof PATIENT_SURVEY_ITEM;
    survey: IPatientSurvey | null;
}

export const _patientSurveyItem: ActionCreator<PatientSurveyItemAction> = (survey: IPatientSurvey | null) => {
    return {
        type: PATIENT_SURVEY_ITEM,
        survey
    };
};

export type PatientSurveyActionTypes = PatientSurveyListAction | PatientSurveyItemAction;

export const getPatientSurveyList = (
): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch, getState) => {
    dispatch(loadingStart(LoadingSubject.PatientSurveyList));

    try {
        const response = await authFetch(
            getState, dispatch, topDoctorData() + 'patientexaminations?per-page=1000', 'GET',
            null, {Accept: 'application/json', 'Content-Type': 'application/json'}, '&token='
        );

        console.log('response', response);

        if (response.status === 200) {
            const json = await response.json();
            console.log('json11111', json);
            for(const j of json){
                console.log('jsonuploadedfiles', j.uploadedfiles);
            }
            dispatch(_patientSurveyList(json));
        }
        else if (response.status === 401) {
            //dispatch(signOut(token));
        }
        else {
            dispatch(pushAlert('Unknown error. Please, check internet connection'));
            //dispatch(errorPatientDoctorList(json.code, token));
        }
    } catch (e) {
        dispatch(pushAlert('Unknown error. Please, check internet connection'));
    } finally {
        dispatch(loadingEnd(LoadingSubject.PatientSurveyList));
    }
}

export const getPatientSurvey = (
    id: number,
): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch, getState) => {
    dispatch(loadingStart(LoadingSubject.PatientSurvey));

    try {
        const response = await authFetch(
            getState, dispatch, topDoctorData() + 'patientexaminations/' + id, 'GET',
            null, {Accept: 'application/json', 'Content-Type': 'application/json'}, '?token='
        );

        console.log('response', response);

        if (response.status === 200) {
            const json = await response.json();
            console.log('json', json);
            dispatch(_patientSurveyItem(json));
        }
        else if (response.status === 401) {
            //dispatch(signOut(token));
        }
        else {
            dispatch(pushAlert('Unknown error. Please, check internet connection'));
            //dispatch(errorPatientDoctorList(json.code, token));
        }
    } catch (e) {
        dispatch(pushAlert('Unknown error. Please, check internet connection'));
    } finally {
        dispatch(loadingEnd(LoadingSubject.PatientSurvey));
    }
}
export const createPatientSurvey = (
    survey: IPatientSurvey,
    uploadFiles: IUpload[], deleleFiles: IFile[],
    host_type: number,
    success: () => void,
): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch, getState) => {
    dispatch(loadingStart(LoadingSubject.PatientSurvey))
    try {
        //@ts-ignore
        let forsave = {...survey, procedures: survey.procedures?.filter(v => v)}
        const response = await authFetch(
            getState, dispatch, topDoctorData() + 'patientexaminations', 'POST',
            forsave, {Accept: 'application/json', 'Content-Type': 'application/json'}, '?token='
        );

        console.log('response', response);

        if (response.status === 200) {
            const json = await response.json();
            console.log('createPatientSurvey', json);
            const id = json.payload.id;
            await updateFiles(getState, dispatch, uploadFiles, deleleFiles, id, host_type);
            dispatch(getPatientSurveyList());
            success && success();
        }
        else if (response.status === 401) {
            //dispatch(signOut(token));
        }
        else {
            dispatch(pushAlert('Unknown error. Please, check internet connection'));
            //dispatch(errorPatientDoctorList(json.code, token));
        }
    } catch (e) {
        dispatch(pushAlert('Unknown error. Please, check internet connection'));
    } finally {
        dispatch(loadingEnd(LoadingSubject.PatientSurvey));
    }

}


export const deletePatientSurvey = (
    id: number,
): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch, getState) => {
    dispatch(loadingStart(LoadingSubject.PatientSurvey));

    try {
        const response = await authFetch(
            getState, dispatch, topDoctorData() + 'patientexaminations/' + id, 'DELETE',
            null, {Accept: 'application/json', 'Content-Type': 'application/json'}, '?token='
        );

        console.log('response', response);

        if (response.status === 200) {
            const json = await response.json();
            console.log('json', json);
            dispatch(getPatientSurveyList());
        }
        else if (response.status === 401) {
            //dispatch(signOut(token));
        }
        else {
            dispatch(pushAlert('Unknown error. Please, check internet connection'));
            //dispatch(errorPatientDoctorList(json.code, token));
        }
    } catch (e) {
        dispatch(pushAlert('Unknown error. Please, check internet connection'));
    } finally {
        dispatch(loadingEnd(LoadingSubject.PatientSurvey));
    }
}


export const updatePatientSurvey = (
    survey: IPatientSurvey,
    uploadFiles: IUpload[], deleleFiles: IFile[],
    host_type: number,
    success: () => void,
): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch, getState) => {
    dispatch(loadingStart(LoadingSubject.PatientSurvey));

    try {
        //@ts-ignore
        let forsave = {...survey, procedures: survey.procedures?.filter(v => v)}
        const response = await authFetch(
            getState, dispatch, topDoctorData() + 'patientexaminations/' + survey.id, 'PATCH',
            forsave, {Accept: 'application/json', 'Content-Type': 'application/json'}, '?token='
        );

        console.log('response', response);

        if (response.status === 200) {
            const json = await response.json();
            console.log('json2', json);
            await updateFiles(getState, dispatch, uploadFiles, deleleFiles, survey.id, host_type);
            dispatch(getPatientSurveyList());
            success && success();
        }
        else if (response.status === 401) {
            //dispatch(signOut(token));
        }
        else {
            dispatch(pushAlert('Unknown error. Please, check internet connection'));
            //dispatch(errorPatientDoctorList(json.code, token));
        }
    } catch (e) {
        dispatch(pushAlert('Unknown error. Please, check internet connection'));
    } finally {
        dispatch(loadingEnd(LoadingSubject.PatientSurvey));
    }
}