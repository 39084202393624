import { IPatientConsultationHistoryState } from "./../states/patient-consultation-history-state";
import { PatientConsultationHistoryActionTypes, PATIENT_CONSULTATION_HISTORY_LIST, PATIENT_CONSULTATION_HISTORY_ITEM } from "../actions/patient-consultaion-history-actions";

const initialState: IPatientConsultationHistoryState = {
}

export function patientConsultationHistoryReducer(
    state = initialState,
    action: PatientConsultationHistoryActionTypes
): IPatientConsultationHistoryState {
    switch (action.type) {
        case PATIENT_CONSULTATION_HISTORY_LIST:
            return {...state, ...{list: action.consultationHistories}};
        case PATIENT_CONSULTATION_HISTORY_ITEM:
            return {...state, ...{selected: action.consultationHistory}};
        default:
            return state;
    }
}
